import React from "react";
import { Pages } from "../../routers";
import PageContainer from "../Page/PageContainer";
import SecurityContainer from "./SecurityContainer";

const SecurityPage: React.FunctionComponent = () => (
  <PageContainer
    name={Pages.SETTINGS_SECURITY}
    displayErrors={false}
    keyboardAvoiding={false}
  >
    <SecurityContainer />
  </PageContainer>
);

export default SecurityPage;
