import Constants from "expo-constants";
import * as Application from 'expo-application';

const extra = Constants.manifest!.extra!;

// Are we running in electron environment?
export const IS_ELECTRON: boolean = extra.isElectron;

// Enable storybook or not
export const ENABLE_STORYBOOK: boolean = extra.enableStorybook;

// the base API URL
export const API_BASE_URL: string = extra.apiBaseURL;

// the base webSocket URL
export const WEB_SOCKET_BASE_URL: string = extra.webSocketBaseURL;

// The name of app
export const APP_NAME: string = Constants.manifest!.name;

// The tagline of app
export const APP_TAGLINE: string = "Your personal micro journal";

// The version of app
export const APP_VERSION: string =
  Application.nativeApplicationVersion ?? Constants.manifest?.version ?? "unknown";

// The build number of the app
export const BUILD_NUMBER: string = Application.nativeBuildVersion ?? "unknown";

// The electron update channel name
export const ELECTRON_UPDATE_CHANNEL: string | undefined =
  extra.electronUpdateChannel;

// API key for amplitude
export const AMPLITUDE_API_KEY: string | null = extra.amplitudeAPIKey ?? null;
