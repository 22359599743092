import React, { FunctionComponent, useEffect, useRef } from "react";
import {
  Animated,
  Easing,
  FlatList,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from "react-native";
import HashtagOption from "./HashtagOption";

const styles = StyleSheet.create({
  animatedContainer: {},
  container: {
    flex: -1,
  },
  list: {
    flexGrow: 0,
  },
  option: {
    paddingVertical: 5,
    paddingHorizontal: 10,
    height: 40,
  },
});

export interface Hashtag {
  readonly name: string;
  readonly description?: string;
}

export interface Props {
  readonly hashtags: Array<Hashtag>;
  readonly visible?: boolean;
  readonly style?: StyleProp<ViewStyle>;
  readonly prefixBoldLength?: number;
  readonly onPress?: (id: string) => void;
}

const calcualteHeight = (commandCount: number) => {
  return Math.min(commandCount, 5.5) * 41;
};

const HashtagList: FunctionComponent<Props> = ({
  style,
  hashtags,
  visible,
  prefixBoldLength,
  onPress,
}: Props) => {
  const currentHeight = calcualteHeight(hashtags.length);
  const animatedProgress = useRef(new Animated.Value(0)).current;
  const animatedHeight = useRef(new Animated.Value(currentHeight)).current;
  const easing = Easing.inOut(Easing.cubic);
  const duration = 300;

  useEffect(() => {
    Animated.timing(animatedProgress, {
      toValue: visible ?? false ? 1 : 0,
      easing,
      duration,
      useNativeDriver: false,
    }).start();
    return () => {
      animatedProgress.stopAnimation();
    };
  }, [visible]);

  useEffect(() => {
    Animated.timing(animatedHeight, {
      toValue: visible ?? false ? currentHeight : 0,
      easing,
      duration,
      useNativeDriver: false,
    }).start();
    return () => {
      animatedHeight.stopAnimation();
    };
  }, [visible, currentHeight]);

  return (
    <Animated.View
      style={[
        styles.animatedContainer,
        {
          opacity: animatedProgress,
          height: animatedHeight,
        },
        style,
      ]}
    >
      <View style={[styles.container]}>
        <FlatList
          data={hashtags}
          style={[styles.list]}
          keyboardShouldPersistTaps="always"
          renderItem={({ item }) => (
            <HashtagOption
              style={styles.option}
              name={item.name}
              description={item.description}
              prefixBoldLength={(prefixBoldLength ?? 0) + 1}
              onPress={onPress}
            />
          )}
          keyExtractor={({ name }) => name}
        />
      </View>
    </Animated.View>
  );
};

export default HashtagList;
