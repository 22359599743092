import React, { FunctionComponent, useEffect, useRef } from "react";
import {
  Animated,
  Easing,
  FlatList,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from "react-native";
import CommandOption from "./CommandOption";

const styles = StyleSheet.create({
  animatedContainer: {},
  container: {
    flex: -1,
  },
  list: {
    flexGrow: 0,
  },
  option: {
    paddingVertical: 5,
    paddingHorizontal: 10,
    height: 40,
  },
});

export interface Command {
  readonly id: string;
  readonly icon: string;
  readonly command: string;
  readonly parameters?: Array<string>;
  readonly description: string;
  readonly commandPrefixBoldLength?: number;
}

export interface Props {
  readonly commands: Array<Command>;
  readonly visible?: boolean;
  readonly style?: StyleProp<ViewStyle>;
  readonly onPress?: (id: string) => void;
}

const calcualteHeight = (commandCount: number) => {
  return commandCount * 41;
};

const CommandList: FunctionComponent<Props> = ({
  style,
  commands,
  visible,
  onPress,
}: Props) => {
  const currentHeight = calcualteHeight(commands.length);
  const animatedProgress = useRef(new Animated.Value(0)).current;
  const animatedHeight = useRef(new Animated.Value(currentHeight)).current;
  const easing = Easing.inOut(Easing.cubic);
  const duration = 300;

  useEffect(() => {
    Animated.timing(animatedProgress, {
      toValue: visible ?? false ? 1 : 0,
      easing,
      duration,
      useNativeDriver: false,
    }).start();
    return () => {
      animatedProgress.stopAnimation();
    };
  }, [visible]);

  useEffect(() => {
    Animated.timing(animatedHeight, {
      toValue: visible ?? false ? currentHeight : 0,
      easing,
      duration,
      useNativeDriver: false,
    }).start();
    return () => {
      animatedHeight.stopAnimation();
    };
  }, [visible, currentHeight]);

  return (
    <Animated.View
      style={[
        styles.animatedContainer,
        {
          opacity: animatedProgress,
          height: animatedHeight,
        },
        style,
      ]}
    >
      <View style={[styles.container]}>
        <FlatList
          data={commands}
          style={[styles.list]}
          keyboardShouldPersistTaps="always"
          renderItem={({ item }) => (
            <CommandOption
              id={item.id}
              style={styles.option}
              icon={item.icon}
              command={item.command}
              parameters={item.parameters}
              description={item.description}
              commandPrefixBoldLength={item.commandPrefixBoldLength}
              onPress={onPress}
            />
          )}
          keyExtractor={({ id }) => id}
        />
      </View>
    </Animated.View>
  );
};

export default CommandList;
