import React from "react";
import {
  ActivityIndicator,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from "react-native";
import { Colors } from "./Colors";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
  },
  loadingText: {
    textAlign: "center",
  },
});

export interface Props {
  readonly style?: StyleProp<ViewStyle>;
}

const Loading: React.FunctionComponent<Props> = ({ style }: Props) => {
  return (
    <View style={[styles.container, style]}>
      <ActivityIndicator size="large" color={Colors.Launch} />
    </View>
  );
};

export default Loading;
