import { storiesOf } from "@storybook/react-native";
import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import File, { Mode } from "../../../components/File/File";

const storyName = "Components/File/" + File.name;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  uploadFile: {
    width: "100%",
    paddingHorizontal: 20,
  },
});

storiesOf(storyName, module).add("uploaded", () => (
  <View style={styles.container}>
    <File
      style={styles.uploadFile}
      fileName="software-contracting-agreement.pdf"
      mode={Mode.UPLOADED}
    />
  </View>
));

storiesOf(storyName, module).add("failed", () => (
  <View style={styles.container}>
    <File
      style={styles.uploadFile}
      fileName="software-contracting-agreement.pdf"
      mode={Mode.FAILED}
    />
  </View>
));

storiesOf(storyName, module).add("uploading zero progress", () => (
  <View style={styles.container}>
    <File
      style={styles.uploadFile}
      fileName="software-contracting-agreement.pdf"
      progress={0}
      mode={Mode.UPLOADING}
    />
  </View>
));

storiesOf(storyName, module).add("uploading in progress", () => (
  <View style={styles.container}>
    <File
      style={styles.uploadFile}
      fileName="software-contracting-agreement.pdf"
      progress={23}
      mode={Mode.UPLOADING}
    />
  </View>
));

storiesOf(storyName, module).add("uploading in progress animation", () => {
  const [progress, setProgress] = useState(23);
  setTimeout(() => setProgress(53), 1000);
  return (
    <View style={styles.container}>
      <File
        style={styles.uploadFile}
        fileName="software-contracting-agreement.pdf"
        progress={progress}
        mode={Mode.UPLOADING}
      />
    </View>
  );
});

storiesOf(storyName, module).add("uploading complete progress", () => (
  <View style={styles.container}>
    <File
      style={styles.uploadFile}
      fileName="software-contracting-agreement.pdf"
      progress={100}
      mode={Mode.UPLOADING}
    />
  </View>
));

storiesOf(storyName, module).add("message", () => (
  <View style={styles.container}>
    <File
      style={styles.uploadFile}
      fileName="software-contracting-agreement.pdf"
      mode={Mode.MESSAGE}
    />
  </View>
));
