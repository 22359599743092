import { FontAwesome5 } from "@expo/vector-icons";
import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import {
  Animated,
  Easing,
  StyleProp,
  StyleSheet,
  Text,
  View,
  ViewStyle,
} from "react-native";
import { Colors } from "../Colors";
import IconButton from "../IconButton";

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.Warning,
    borderRadius: 10,
    overflow: "hidden",
  },
  innerContainer: {
    paddingHorizontal: 10,
    paddingVertical: 10,
    flexDirection: "row",
    alignItems: "center",
  },
  message: {
    flex: 1,
    marginRight: 8,
    marginLeft: 10,
  },
});

export interface Props {
  readonly id: string;
  readonly style?: StyleProp<ViewStyle>;
  readonly message: string;
  readonly dismissing?: boolean;
  readonly onDismiss?: (id: string) => void;
  readonly onDismissAnimationFinish?: (id: string) => void;
}

const ErrorMessage: FunctionComponent<Props> = ({
  id,
  style,
  message,
  dismissing,
  onDismiss,
  onDismissAnimationFinish,
}: Props) => {
  const dismissingValue = dismissing ?? false;
  const animatedProgress = useRef(new Animated.Value(0)).current;
  const easing = Easing.inOut(Easing.cubic);
  const duration = 300;

  useEffect(() => {
    Animated.timing(animatedProgress, {
      toValue: dismissingValue ? 2 : 1,
      easing,
      duration,
      useNativeDriver: false,
    }).start(({ finished }) => {
      if (!finished || !dismissingValue) {
        return;
      }
      onDismissAnimationFinish?.(id);
    });
    return () => {
      animatedProgress.stopAnimation();
    };
  }, [dismissingValue]);

  const [currentHeight, setCurrentHeight] = useState(51);

  return (
    <Animated.View
      style={[
        styles.container,
        dismissingValue
          ? {
              height: animatedProgress.interpolate({
                inputRange: [0, 1, 2],
                outputRange: [currentHeight, currentHeight, 0],
              }),
            }
          : null,
        {
          opacity: animatedProgress.interpolate({
            inputRange: [0, 1, 2],
            outputRange: [0.0, 1.0, 0.0],
          }),
        },
        style,
      ]}
      onLayout={(event) => {
        if (dismissingValue) {
          return;
        }
        setCurrentHeight(event.nativeEvent.layout.height);
      }}
      pointerEvents={dismissingValue ? "none" : "auto"}
    >
      <View style={styles.innerContainer}>
        <FontAwesome5
          name={"exclamation-circle"}
          size={20}
          color={Colors.Platform}
        />
        <Text style={styles.message}>{message}</Text>
        <IconButton
          name="times"
          color={Colors.MediumGrey}
          onPress={() => onDismiss?.(id)}
          size={20}
        />
      </View>
    </Animated.View>
  );
};

export default ErrorMessage;
