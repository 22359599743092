import { actionCreator } from "./shared";
import { EventPage } from "../services/api/interface";

export interface LoadEventRequest {
  readonly eventStreamId: string;
  readonly lastSequenceId: number;
}

export interface EventUpdate {
  readonly eventStreamId: string;
  readonly eventPage: EventPage;
}

export const prefix = "EVENT/";
const actions = {
  loadEvents: actionCreator.async<LoadEventRequest, EventPage>(
    prefix + "LOAD_EVENTS"
  ),
  allEventsLoaded: actionCreator<string>(prefix + "ALL_EVENTS_LOADED"),
  subscribeEventUpdates: actionCreator.async<void, void>(
    prefix + "SUBSCRIBE_EVENT_UPDATES"
  ),
  eventUpdate: actionCreator<EventUpdate>(prefix + "EVENT_UPDATE"),
};
export default actions;
