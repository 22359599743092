import {
  FileUploadRequest,
  FileUploadEvent,
  FileUploadProgress,
  FileUploadResponse,
} from "../services/api/interface";
import { CommandId } from "../states";
import { actionCreator } from "./shared";
import {
  MessageRequest,
  MessageResponse,
  Preview,
} from "../services/api/interface";

export class PickFileCancelError extends Error {
  constructor(message: string) {
    super(message);
    // Set the prototype explicitly.
    Object.setPrototypeOf(this, PickFileCancelError.prototype);
  }
}

export interface File {
  readonly id: string;
  readonly uri: string;
  readonly size: number;
  readonly name: string;
}

export interface LocalCommand {
  readonly id: CommandId;
  readonly content?: string;
}

export interface Hashtag {
  readonly name: string;
  readonly description?: string;
}

export interface MatchedHashtags {
  readonly hashtags: Array<Hashtag>;
  readonly prefixLength: number;
}

export const prefix = "INPUT/";

const actions = {
  enterCommandMode: actionCreator<CommandId>(prefix + "ENTER_COMMAND_MODE"),
  leaveCommandMode: actionCreator(prefix + "LEAVE_COMMAND_MODE"),
  addFileTapped: actionCreator(prefix + "ADD_FILE_TAPPED"),
  deleteFile: actionCreator<string>(prefix + "FILE_DELETE"),
  pickUploadingFile: actionCreator.async<void, File, PickFileCancelError>(
    prefix + "PICK_UPLOADING_FILE"
  ),
  uploadFileProgressUpdate: actionCreator<{
    params: FileUploadRequest;
    result: FileUploadProgress;
  }>(prefix + "UPLOAD_PROGRESS_UPDATE"),
  updateText: actionCreator<string>(prefix + "UPDATE_TEXT"),
  sendLocalCommand: actionCreator<LocalCommand>(prefix + "SEND_LOCAL_COMMAND"),
  updateMatchedHashtags: actionCreator<MatchedHashtags>(
    prefix + "UPDATE_MATCHED_HASHTAGS"
  ),
  // Component events
  onSend: actionCreator(prefix + "ON_SEND"),
  onInputSubmit: actionCreator(prefix + "ON_INPUT_SUBMIT"),
  onTextChanged: actionCreator<string>(prefix + "ON_TEXT_CHANGE"),
  onMenuTapped: actionCreator(prefix + "ON_MENU_TAPPED"),
  onBackspacePressed: actionCreator(prefix + "ON_BACKSPACE_PRESSED"),
  onDeleteFileTapped: actionCreator<string>(prefix + "ON_DELETE_FILE_TAPPED"),
  onCommandTapped: actionCreator<string>(prefix + "ON_COMMAND_TAPPED"),
  onHashtagTapped: actionCreator<string>(prefix + "ON_HASHTAG_TAPPED"),
  onCancelUploadingTapped: actionCreator(prefix + "ON_CANCEL_UPLOADING_TAPPED"),
  onCancelSearchTapped: actionCreator(prefix + "ON_CANCEL_SEARCH_TAPPED"),
  // API calls
  sendMessage: actionCreator.async<MessageRequest, MessageResponse>(
    prefix + "SEND_MESSAGE"
  ),
  uploadFile: actionCreator.async<FileUploadRequest, FileUploadResponse>(
    prefix + "UPLOAD_FILE"
  ),
  createPreview: actionCreator.async<string, Preview>(
    prefix + "CREATE_PREVIEW"
  ),
};
export default actions;
