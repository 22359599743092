import { reducerWithInitialState } from "typescript-fsa-reducers";
import loginActions from "../actions/Login";
import routerActions from "../actions/Router";
import settingsActions, { InputType } from "../actions/Settings";
import { Pages } from "../routers";
import { SettingsConfig, SettingsState } from "../states";

export const defaultSettings: SettingsConfig = {
  displayMessageTime: false,
  disablePreview: false,
  deviceNotification: false,
};

const settings = reducerWithInitialState<SettingsState>({
  config: {},
  changePassword: {},
})
  .case(loginActions.logout, (state, settings) => ({
    ...state,
    changePassword: {},
  }))
  .case(settingsActions.updateSettings, (state, settings) => ({
    ...state,
    config: {
      ...settings,
    },
  }))
  .case(settingsActions.loadSettings.done, (state, { result }) => ({
    ...state,
    config: {
      ...defaultSettings,
      ...result,
    },
  }))
  .case(settingsActions.onMenuInputValueChange, (state, { type, value }) => ({
    ...state,
    changePassword: {
      ...state.changePassword,
      currentPassword:
        type === InputType.CHANGE_PASSWORD_CURRENT_PASSWORD
          ? value
          : state.changePassword.currentPassword,
      newPassword:
        type === InputType.CHANGE_PASSWORD_NEW_PASSWORD
          ? value
          : state.changePassword.newPassword,
      newPasswordRepeat:
        type === InputType.CHANGE_PASSWORD_NEW_PASSWORD_REPEAT
          ? value
          : state.changePassword.newPasswordRepeat,
    },
  }))
  .case(settingsActions.updatePassword.started, (state, type) => ({
    ...state,
    changePassword: {
      ...state.changePassword,
      updating: true,
    },
  }))
  .case(settingsActions.updatePassword.failed, (state) => ({
    ...state,
    changePassword: {
      ...state.changePassword,
      updating: false,
      finished: false,
    },
  }))
  .case(settingsActions.updatePassword.done, (state) => ({
    ...state,
    changePassword: {
      finished: true,
    },
  }))
  .case(routerActions.onNavigatorStateChange, (state, { routes }) => ({
    ...state,
    // If the change password page is not in the route, it means
    // we already leve the change password page, let's reset the state
    changePassword:
      routes.findIndex(
        (route) => route.name === Pages.SETTINGS_CHANGE_PASSWORD
      ) === -1
        ? {}
        : state.changePassword,
  }));

export default settings;
