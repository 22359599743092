import { FontAwesome5 } from "@expo/vector-icons";
import React, { FunctionComponent } from "react";
import {
  Pressable,
  StyleProp,
  StyleSheet,
  Text,
  ViewStyle,
} from "react-native";

const styles = StyleSheet.create({
  text: {},
  icon: {
    marginRight: 5,
  },
  container: {
    backgroundColor: "#eee",
    borderColor: "#bbb",
    borderWidth: 1,
    borderRadius: 5,
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: 40,
    paddingRight: 40,
    flexDirection: "row",
  },
});

export interface Props {
  readonly text: string;
  readonly onClick: () => void;
  readonly style?: StyleProp<ViewStyle>;
}

const ScrollToLatestButton: FunctionComponent<Props> = (props: Props) => {
  const { text, onClick, style } = props;
  return (
    <Pressable onPressIn={onClick} style={[styles.container, style]}>
      <FontAwesome5
        name={"angle-down"}
        size={14}
        color={"black"}
        style={styles.icon}
      />
      <Text selectable style={styles.text} adjustsFontSizeToFit={true}>
        {text}
      </Text>
    </Pressable>
  );
};

export default ScrollToLatestButton;
