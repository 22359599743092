import { StyleSheet } from "react-native";
import { Colors } from "../Colors";

const styles = StyleSheet.create({
  heading: {
    fontWeight: "bold",
  },
  heading1: {
    fontSize: 32,
  },
  heading2: {
    fontSize: 24,
  },
  heading3: {
    fontSize: 18,
  },
  heading4: {
    fontSize: 16,
  },
  heading5: {
    fontSize: 13,
  },
  heading6: {
    fontSize: 11,
  },
  link: {
    textDecorationLine: "underline",
    color: Colors.Launch,
  },
  hashtag: {
    color: Colors.Launch,
  },
  text: {},
  paragraph: {},
  hr: {
    backgroundColor: Colors.DarkGrey,
    height: 1,
  },
  codeBlock: {
    backgroundColor: Colors.CodeBackground,
    borderColor: Colors.CodeBorder,
    borderRadius: 3,
    borderWidth: 1,
    padding: 5,
  },
  del: {
    textDecorationLine: "line-through",
  },
  strong: {
    fontWeight: "bold",
  },
  em: {
    fontStyle: "italic",
  },
  u: {
    textDecorationLine: "underline",
  },
  inlineCode: {
    backgroundColor: Colors.CodeBackground,
    borderColor: Colors.CodeBorder,
    borderRadius: 3,
    borderWidth: 1,
    fontWeight: "bold",
  },
  blockQuoteContainer: { flexDirection: "row" },
  blockQuoteBar: {
    width: 3,
    backgroundColor: "#dddddd",
    marginRight: 15,
  },
  blockQuoteText: {},
  listItem: {
    flexDirection: "row",
    alignItems: "center",
  },
  listItemNumber: {
    fontWeight: "bold",
  },
});

export default styles;
