import { FontAwesome5 } from "@expo/vector-icons";
import React, { useEffect, useRef } from "react";
import {
  Animated,
  Easing,
  StyleProp,
  StyleSheet,
  Text,
  ViewStyle,
} from "react-native";

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
    flexDirection: "row",
    paddingVertical: 14,
    paddingHorizontal: 20,
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    paddingRight: 10,
  },
  text: {
    fontSize: 18,
    color: "black",
  },
});

export interface Props {
  readonly style?: StyleProp<ViewStyle>;
  readonly icon?: string;
  readonly text?: string;
  readonly visible?: boolean;
}

const MenuItemText: React.FunctionComponent<Props> = ({
  style,
  icon,
  text,
  visible,
}: Props) => {
  const visibleValue = visible ?? true;
  const animatedProgress = useRef(
    new Animated.Value(visibleValue ? 1 : 0)
  ).current;
  const easing = Easing.inOut(Easing.cubic);
  const duration = 300;

  useEffect(() => {
    Animated.timing(animatedProgress, {
      toValue: visibleValue ? 1.0 : 0,
      easing,
      duration,
      useNativeDriver: true,
    }).start(({ finished }) => {
      if (!finished || visibleValue) {
        return;
      }
    });
  }, [visibleValue]);

  return (
    <Animated.View
      style={[
        styles.container,
        {
          opacity: animatedProgress,
        },
        style,
      ]}
    >
      {icon !== undefined ? (
        <FontAwesome5 name={icon} size={18} style={styles.icon} />
      ) : null}
      <Text style={styles.text}>{text}</Text>
    </Animated.View>
  );
};

export default MenuItemText;
