import { reducerWithInitialState } from "typescript-fsa-reducers";
import forgotPasswordActions from "../actions/ForgotPassword";
import { ForgotPasswordState } from "../states";

const forgotPassword = reducerWithInitialState<ForgotPasswordState>({
  loadingForgotPassword: false,
  loadingResetPassword: false,
})
  .case(forgotPasswordActions.forgotPassword.started, (state, payload) => ({
    ...state,
    loadingForgotPassword: true,
  }))
  .cases(
    [
      forgotPasswordActions.forgotPassword.done,
      forgotPasswordActions.forgotPassword.failed,
    ],
    (state, payload) => ({
      ...state,
      loadingForgotPassword: false,
    })
  )
  .case(forgotPasswordActions.resetPassword.started, (state, payload) => ({
    ...state,
    loadingResetPassword: true,
  }))
  .cases(
    [
      forgotPasswordActions.resetPassword.done,
      forgotPasswordActions.resetPassword.failed,
    ],
    (state, payload) => ({
      ...state,
      loadingResetPassword: false,
    })
  );
export default forgotPassword;
