import React, { useEffect, useRef, useState } from "react";
import {
  Animated,
  Easing,
  LayoutChangeEvent,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from "react-native";
import { pure } from "recompose";
import { Colors } from "../Colors";
import IconButton from "../IconButton";
import Markdown, { fullWhitelist } from "../Markdown/Markdown";

const styles = StyleSheet.create({
  animatedContainer: {
    flex: -1,
    backgroundColor: "#DEEDFF",
  },
  textContainer: { paddingVertical: 10, paddingHorizontal: 20, flex: -1 },
  text: {},
  buttonContainer: {},
  dismissButton: {
    height: 30,
    backgroundColor: Colors.MediumGrey,
    borderRadius: 10,
    justifyContent: "center",
    marginBottom: 10,
    marginHorizontal: 10,
  },
});

export interface ButtonRecord {
  readonly text: string;
  readonly id: string;
  readonly icon: string;
  readonly style?: StyleProp<ViewStyle>;
}

export interface Props {
  readonly id: string;
  readonly message: string;
  readonly dismissing?: boolean;
  readonly buttons?: Array<ButtonRecord>;
  readonly style?: StyleProp<ViewStyle>;
  readonly onButtonPressed?: (id: string, buttonId: string) => void;
  readonly onLinkPressed?: (id: string, url: string) => void;
  readonly onLayout?: (event: LayoutChangeEvent) => void;
  readonly onDismissAnimationFinish?: (id: string) => void;
}

const SystemMessage: React.FunctionComponent<Props> = (props: Props) => {
  const {
    message,
    dismissing,
    style,
    buttons,
    id,
    onButtonPressed,
    onLayout,
    onLinkPressed,
    onDismissAnimationFinish,
  } = props;

  const animatedProgress = useRef(new Animated.Value(0)).current;
  const easing = Easing.inOut(Easing.cubic);

  const dismissingValue = dismissing ?? false;
  const [currentHeight, setCurrentHeight] = useState(57);
  useEffect(() => {
    let toValue: number = dismissingValue ? 1 : 0;
    Animated.timing(animatedProgress, {
      toValue,
      easing,
      duration: 500,
      useNativeDriver: false,
    }).start(({ finished }) => {
      if (!finished) {
        return;
      }
      if (!dismissingValue) {
        return;
      }
      onDismissAnimationFinish?.(id);
    });
    return () => {
      animatedProgress.stopAnimation();
    };
  }, [dismissingValue]);

  return (
    <Animated.View
      style={[
        styles.animatedContainer,
        dismissingValue
          ? {
              height: animatedProgress.interpolate({
                inputRange: [0, 1],
                outputRange: [currentHeight, 0],
              }),
              overflow: "hidden",
              opacity: animatedProgress.interpolate({
                inputRange: [0, 1],
                outputRange: [1.0, 0.0],
              }),
            }
          : null,
        style,
      ]}
      onLayout={(event) => {
        setCurrentHeight(event.nativeEvent.layout.height);
        onLayout?.(event);
      }}
      pointerEvents={dismissingValue ? "none" : "auto"}
    >
      <View style={styles.textContainer}>
        <Markdown
          id={id}
          styles={{ view: styles.text }}
          message={message}
          whitelist={fullWhitelist}
          onLinkPressed={onLinkPressed}
        />
      </View>
      <View style={styles.buttonContainer}>
        {buttons?.map((button) => (
          <IconButton
            key={button.id}
            name={button.icon}
            text={button.text}
            color={Colors.Launch}
            size={12}
            style={[styles.dismissButton, button.style]}
            onPress={() => onButtonPressed?.(id, button.id)}
          />
        ))}
      </View>
    </Animated.View>
  );
};

export default pure(SystemMessage);
