import React from "react";
import { StyleSheet, View } from "react-native";
import { Colors } from "../Colors";
import TooManyDevicesView, { Props } from "./TooManyDevicesView";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 50,
    backgroundColor: Colors.MediumGrey,
  },
  tooManyDevices: {
    flex: 1,
    backgroundColor: "white",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
  },
  background: {
    position: "absolute",
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  },
  closeButton: {
    left: -20,
    top: -20,
    width: 40,
    height: 40,
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Colors.LightGrey,
    borderRadius: 20,
  },
});

const TooManyDevicesModal: React.FunctionComponent<Props> = ({
  style,
  ...restProps
}: Props) => {
  return (
    <View style={[styles.container, style]}>
      <View style={styles.tooManyDevices}>
        <TooManyDevicesView {...restProps} />
      </View>
    </View>
  );
};
export default TooManyDevicesModal;
