import * as Haptics from "expo-haptics";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import {
  ActivityIndicator,
  Animated,
  Easing,
  ImageSourcePropType,
  LayoutChangeEvent,
  Platform,
  Pressable,
  StyleProp,
  StyleSheet,
  Text,
  View,
  ViewStyle,
} from "react-native";
import { pure } from "recompose";
import { Colors } from "../Colors";
import FileComponent, { Mode } from "../File/File";
import Markdown from "../Markdown/Markdown";
import Preview from "../Preview/Preview";
import EditField from "./EditField";
import EditMenu from "./EditMenu";
import RetryMenu from "./RetryMenu";
import SelectedMenu from "./SelectedMenu";

const styles = StyleSheet.create({
  animatedContainer: {
    flex: -1,
  },
  pressableContainer: {
    flexDirection: "column",
    paddingLeft: 20,
    paddingTop: 14,
    paddingRight: 10,
  },
  noTimePressableContainer: {
    paddingRight: 20,
  },
  contentConatiner: {
    flexDirection: "row",
    paddingBottom: 14,
  },
  textContainer: {
    flex: 1,
  },
  text: {},
  timeContainer: {
    width: 70,
    marginLeft: 10,
    justifyContent: "center",
  },
  time: {
    textAlign: "right",
    color: Colors.MediumGrey,
    width: "100%",
    fontSize: 14,
  },
  timeFailed: {
    color: Colors.DarkGrey,
  },
  failedButtonContainer: {
    position: "absolute",
    right: 0,
    top: 0,
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 8,
  },
  retryButton: {},
  deleteButton: {},
  file: {
    paddingVertical: 5,
  },
  preview: {},
  fileHint: {
    color: Colors.MediumGrey,
  },
  editInput: {},
});

export enum MessageState {
  PENDING = "PENDING",
  FAILED = "FAILED",
  CONFIRMED = "CONFIRMED",
  SELECTED = "SELECTED",
  EDIT = "EDIT",
}

export enum MessageDeletingState {
  DELETING = "DELETING",
  DELETED = "DELETED",
}

export interface File {
  readonly id: string;
  readonly name: string;
  readonly icon: string;
}

export interface Preview {
  readonly id: string;
  readonly url: string;
  readonly title?: string;
  readonly description?: string;
  readonly thumbnail?: ImageSourcePropType;
}

export interface Props {
  readonly id: string;
  readonly message: string;
  readonly time?: string;
  readonly state: MessageState;
  readonly deletingState?: MessageDeletingState;
  readonly style?: StyleProp<ViewStyle>;
  readonly files?: Array<File>;
  readonly previews?: Array<Preview>;
  readonly displayTime?: boolean;
  readonly displayShareButton?: boolean;
  readonly renderEditField?: () => ReactElement;
  readonly onPressed?: (id: string) => void;
  readonly onEdit?: (id: string) => void;
  readonly onShare?: (id: string) => void;
  readonly onRetry?: (id: string) => void;
  readonly onDelete?: (id: string) => void;
  readonly onEditSave?: (id: string) => void;
  readonly onEditCancel?: (id: string) => void;
  readonly onEditValueUpdate?: (text: string) => void;
  readonly onLinkPressed?: (id: string, url: string) => void;
  readonly onHashtagPressed?: (id: string, hashtag: string) => void;
  readonly onPreviewDelete?: (messageId: string, previewId: string) => void;
  readonly onPreviewLinkPress?: (messageId: string, previewId: string) => void;
  readonly onDownloadFile?: (messageId: string, fileId: string) => void;
  readonly onLayout?: (event: LayoutChangeEvent) => void;
  readonly onDeleteAnimationFinish?: (id: string) => void;
}

const Message: React.FunctionComponent<Props> = (props: Props) => {
  const {
    message,
    state,
    deletingState,
    style,
    id,
    time,
    files,
    previews,
    displayTime,
    displayShareButton,
    renderEditField,
    onPressed,
    onEdit,
    onShare,
    onRetry,
    onDelete,
    onLayout,
    onEditSave,
    onEditCancel,
    onEditValueUpdate,
    onPreviewLinkPress,
    onPreviewDelete,
    onDownloadFile,
    onLinkPressed,
    onHashtagPressed,
    onDeleteAnimationFinish,
  } = props;

  const animatedProgress = useRef(new Animated.Value(0)).current;
  const deletionAnimatedProgress = useRef(new Animated.Value(0)).current;
  const easing = Easing.inOut(Easing.cubic);
  const duration = 300;

  let currentBackgroundColor: string;
  let toValue: number = 0;
  switch (state) {
    case MessageState.SELECTED:
    case MessageState.EDIT: {
      currentBackgroundColor = Colors.LightGrey;
      toValue = 1;
      break;
    }
    case MessageState.FAILED: {
      currentBackgroundColor = Colors.Warning;
      toValue = 1;
      break;
    }
    default:
      currentBackgroundColor = "#ffffff";
  }

  const [currentHeight, setCurrentHeight] = useState(57);
  useEffect(() => {
    Animated.timing(animatedProgress, {
      toValue,
      easing,
      duration,
      useNativeDriver: false,
    }).start();
    return () => {
      animatedProgress.stopAnimation();
    };
  }, [state]);

  useEffect(() => {
    let toValue: number = 0;
    switch (deletingState) {
      case undefined: {
        toValue = 0;
        break;
      }
      case MessageDeletingState.DELETING: {
        toValue = 1;
        break;
      }
      case MessageDeletingState.DELETED: {
        toValue = 2;
        break;
      }
    }
    Animated.timing(deletionAnimatedProgress, {
      toValue,
      easing,
      duration: 500,
      useNativeDriver: false,
    }).start(({ finished }) => {
      if (!finished) {
        return;
      }
      if (deletingState !== MessageDeletingState.DELETED) {
        return;
      }
      onDeleteAnimationFinish?.(id);
    });
    return () => {
      deletionAnimatedProgress.stopAnimation();
    };
  }, [deletingState]);

  const [displaySelectedMenu, setDisplaySelectedMenu] = useState(
    state === MessageState.SELECTED
  );
  if (!displaySelectedMenu && state === MessageState.SELECTED) {
    setDisplaySelectedMenu(true);
  }

  const [displayRetryMenu, setDisplayRetryMenu] = useState(
    state === MessageState.FAILED
  );
  if (!displayRetryMenu && state === MessageState.FAILED) {
    setDisplayRetryMenu(true);
  }

  const [displayEditMenu, setDisplayEditMenu] = useState(
    state === MessageState.EDIT
  );
  if (!displayEditMenu && state === MessageState.EDIT) {
    setDisplayEditMenu(true);
  }

  const viewRef = useRef<View>(null);
  const displayTimeValue =
    state === MessageState.EDIT ||
    state === MessageState.SELECTED ||
    state === MessageState.PENDING ||
    (displayTime ?? true);
  return (
    <Animated.View
      ref={viewRef}
      style={[
        styles.animatedContainer,
        {
          backgroundColor: animatedProgress.interpolate({
            inputRange: [0, 1],
            outputRange: ["#ffffff", currentBackgroundColor],
          }),
        },
        deletingState === MessageDeletingState.DELETED
          ? {
              height: deletionAnimatedProgress.interpolate({
                inputRange: [0, 1, 2],
                outputRange: [currentHeight, currentHeight, 0],
              }),
              overflow: "hidden",
            }
          : null,
        {
          opacity: deletionAnimatedProgress.interpolate({
            inputRange: [0, 0.5, 1, 1.2, 1.8, 2],
            outputRange: [1.0, 0.5, 0.5, 0.2, 0.0, 0.0],
          }),
        },
        style,
      ]}
      onLayout={(event) => {
        if (viewRef.current === null) {
          // Notice: somehow, onLayout gets called even after unmounted. To avoid that
          //         we check the ref first before doing anything
          return;
        }
        setCurrentHeight(event.nativeEvent.layout.height);
        onLayout?.(event);
      }}
      pointerEvents={deletingState !== undefined ? "none" : "auto"}
    >
      <Pressable
        style={[
          styles.pressableContainer,
          displayTimeValue ? null : styles.noTimePressableContainer,
        ]}
        delayLongPress={100}
        onPress={(event) => {
          if ((event.nativeEvent as any).pointerType !== "mouse") {
            return;
          }
          if (Platform.OS !== "web") {
            Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light).then(
              () => {}
            );
          }
          return onPressed?.(id);
        }}
        onLongPress={() => {
          if (Platform.OS !== "web") {
            Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light).then(
              () => {}
            );
          }
          return onPressed?.(id);
        }}
      >
        <View style={styles.contentConatiner}>
          <View style={styles.textContainer}>
            {state !== MessageState.EDIT ? (
              message.length === 0 && (files?.length ?? 0) > 0 ? (
                <Text style={styles.fileHint}>
                  No comment for {files!.length}{" "}
                  {files!.length > 1 ? "files" : "file"}
                </Text>
              ) : (
                <Markdown
                  id={id}
                  style={styles.text}
                  message={message}
                  onLinkPressed={onLinkPressed}
                  onHashtagPressed={onHashtagPressed}
                />
              )
            ) : renderEditField !== undefined ? (
              renderEditField()
            ) : (
              <EditField
                style={styles.editInput}
                value={message}
                onChangeText={(text) => onEditValueUpdate?.(text)}
                onSubmit={() => {}}
              />
            )}
          </View>
          {displayTimeValue ? (
            <View style={styles.timeContainer}>
              {state === MessageState.PENDING ? (
                <ActivityIndicator color={Colors.MediumGrey} />
              ) : (
                <Text
                  style={[
                    styles.time,
                    state === MessageState.FAILED ? styles.timeFailed : null,
                  ]}
                >
                  {time ?? ""}
                </Text>
              )}
            </View>
          ) : null}
        </View>
        {files !== undefined
          ? files.map((file) => (
              <FileComponent
                style={styles.file}
                key={file.id}
                fileName={file.name}
                icon={file.icon}
                mode={Mode.MESSAGE}
                onDownload={() => onDownloadFile?.(id, file.id)}
              />
            ))
          : null}
        {previews !== undefined
          ? previews.map((preview) => (
              <Preview
                style={styles.preview}
                key={preview.id}
                url={preview.url}
                title={preview.title}
                description={preview.description}
                thumbnail={preview.thumbnail}
                editing={state === MessageState.SELECTED}
                onLinkPress={() => onPreviewLinkPress?.(id, preview.id)}
                onDelete={() => onPreviewDelete?.(id, preview.id)}
              />
            ))
          : null}
        {displayRetryMenu && (
          <RetryMenu
            visible={state === MessageState.FAILED}
            onRetry={() => onRetry?.(id)}
            onDelete={() => onDelete?.(id)}
            onDismissAnimationFinished={() => setDisplayRetryMenu(false)}
          />
        )}
        {displaySelectedMenu && (
          <SelectedMenu
            displayShareButton={displayShareButton}
            visible={state === MessageState.SELECTED}
            onEdit={() => onEdit?.(id)}
            onShare={() => onShare?.(id)}
            onDelete={() => onDelete?.(id)}
            onDismissAnimationFinished={() => setDisplaySelectedMenu(false)}
          />
        )}
        {displayEditMenu && (
          <EditMenu
            visible={state === MessageState.EDIT}
            onSave={() => onEditSave?.(id)}
            onCancel={() => onEditCancel?.(id)}
            onDismissAnimationFinished={() => setDisplaySelectedMenu(false)}
          />
        )}
      </Pressable>
    </Animated.View>
  );
};

export default pure(Message);
