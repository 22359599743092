import { StackActions } from "@react-navigation/routers";
import { combineEpics, Epic } from "redux-observable";
import { ignoreElements, map, mapTo, mergeMap } from "rxjs/operators";
import { Action, AnyAction, isType } from "typescript-fsa";
import { ofAction, ofActionPayload } from "typescript-fsa-redux-observable";
import feedbacksActions from "../actions/Feedbacks";
import routerActions from "../actions/Router";
import { State } from "../states";
import { alertMessage, makeAPICallEpic } from "./helpers";

const sendFeedback = makeAPICallEpic<string, void>(
  feedbacksActions.sendFeedback,
  (content, { apiClient }) => apiClient.createFeedback(content)
);

const startSendingFeedback: Epic<AnyAction, AnyAction, State> = (
  action$,
  state
) =>
  action$.pipe(
    ofActionPayload(feedbacksActions.onSubmitFeedback),
    map((content) => feedbacksActions.sendFeedback.started(content))
  );

const alertFeedbackResult: Epic<AnyAction, AnyAction, State> = (
  action$,
  state
) =>
  action$.pipe(
    ofActionPayload(feedbacksActions.sendFeedback.done),
    mergeMap(() =>
      alertMessage(
        "Thank you for your feedbacks!",
        "Thank you so much for your feedbacks! We've sent you a copy of your feedback content to your email, we will get back to you shortly"
      )
    ),
    mapTo(routerActions.route(StackActions.pop()))
  );

const alertFeedbackFailure: Epic<AnyAction, AnyAction, State> = (
  action$,
  state
) =>
  action$.pipe(
    ofActionPayload(feedbacksActions.sendFeedback.failed),
    mergeMap(() =>
      alertMessage(
        "Something went wrong",
        "Cannot send feedback due to system error, please try again later"
      )
    ),
    ignoreElements()
  );

// TODO: find a better way to do this
const preventNavigation: Epic<AnyAction, Action<boolean>, State> = (
  action$,
  state
) =>
  action$.pipe(
    ofAction(
      feedbacksActions.sendFeedback.started,
      feedbacksActions.sendFeedback.done,
      feedbacksActions.sendFeedback.failed
    ),
    map((action) =>
      routerActions.setPreventNavigation(
        isType(action, feedbacksActions.sendFeedback.started)
      )
    )
  );

const forgotPasswordEpic = combineEpics(
  sendFeedback,
  startSendingFeedback,
  alertFeedbackResult,
  alertFeedbackFailure,
  preventNavigation
);

export default forgotPasswordEpic;
