import { pick } from "lodash";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import routerActions from "../actions/Router";
import { pageConfigs, Pages } from "../routers";
import { RouterState } from "../states";

const router = reducerWithInitialState<RouterState>({
  initialized: false,
  preventNavigation: false,
  focusPage: Pages.LOADING,
  readyStatus: {},
})
  .case(routerActions.setPreventNavigation, (state, value) => ({
    ...state,
    preventNavigation: value,
  }))
  .case(routerActions.onNavigatorStateChange, (state, value) => ({
    ...state,
    routerState: value,
    readyStatus: pick(
      {
        ...Object.fromEntries(
          value.routes.map((route) => {
            const page = Pages[route.name as keyof typeof Pages];
            const { readyStatus } = pageConfigs[page];
            return [route.key, readyStatus ?? true];
          })
        ),
        ...state.readyStatus,
      },
      value.routes.flatMap((route) =>
        route.name !== Pages.LOADING ? [route.key] : []
      )
    ),
  }))
  .case(routerActions.setPageReadyStatus, (state, { key, value }) => ({
    ...state,
    readyStatus: {
      ...state.readyStatus,
      [key]: value,
    },
  }))
  .case(routerActions.onPageFocus, (state, focusPage) => ({
    ...state,
    focusPage,
  }));
export default router;
