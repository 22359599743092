import AsyncStorage from "@react-native-async-storage/async-storage";
import * as SecureStore from "expo-secure-store";
import { Platform } from "react-native";
import { combineEpics, Epic } from "redux-observable";
import { EMPTY, from, of, zip } from "rxjs";
import { map, mapTo, mergeMap, mergeMapTo, tap } from "rxjs/operators";
import { AnyAction } from "typescript-fsa";
import { ofActionPayload } from "typescript-fsa-redux-observable";
import { v4 as uuid } from "uuid";
import analyticsActions from "../actions/Analytics";
import appActions, { AppState as AppStateEnum } from "../actions/App";
import loginActions from "../actions/Login";
import routerActions from "../actions/Router";
import settingsActions from "../actions/Settings";
import { API_BASE_URL, APP_VERSION, WEB_SOCKET_BASE_URL } from "../shared/constants";
import { State } from "../states";
import { observeAppState } from "./helpers";

const DEVICE_LOCAL_ID_KEY = "device_local_id";

const initApp: Epic<AnyAction, AnyAction, State> = () => of(appActions.init());

const startInitJobs: Epic<AnyAction, AnyAction, State> = (action$, state) =>
  action$.pipe(
    ofActionPayload(appActions.init),
    tap(()=> {
      console.info("App init, version=", APP_VERSION, "api_url=", API_BASE_URL, "web_socket_url=", WEB_SOCKET_BASE_URL)
    }),
    mergeMapTo(
      of(
        appActions.ensureDeviceLocalId.started(),
        routerActions.getInitialURL.started(),
        loginActions.loadAuthToken.started(),
        settingsActions.loadSettings.started(),
        analyticsActions.startAnalytics.started()
      )
    )
  );

const ensureLocalDeviceId: Epic<AnyAction, AnyAction, State> = (
  action$,
  state
) =>
  action$.pipe(
    ofActionPayload(appActions.ensureDeviceLocalId.started),
    mergeMap(() =>
      Platform.OS === "web"
        ? AsyncStorage.getItem(DEVICE_LOCAL_ID_KEY)
        : SecureStore.getItemAsync(DEVICE_LOCAL_ID_KEY)
    ),
    mergeMap((deviceId) => {
      if (deviceId !== null) {
        return of(appActions.ensureDeviceLocalId.done({ result: deviceId }));
      }

      const newDeviceId = uuid();
      console.log(
        "First time launch app, generated local device id",
        newDeviceId
      );
      return from(
        Platform.OS === "web"
          ? AsyncStorage.setItem(DEVICE_LOCAL_ID_KEY, newDeviceId)
          : SecureStore.setItemAsync(DEVICE_LOCAL_ID_KEY, newDeviceId)
      ).pipe(
        mapTo(appActions.ensureDeviceLocalId.done({ result: newDeviceId }))
      );
    })
  );

const appReady: Epic<AnyAction, AnyAction, State> = (action$, state) =>
  zip(
    action$.pipe(ofActionPayload(routerActions.getInitialURL.done)),
    action$.pipe(ofActionPayload(appActions.ensureDeviceLocalId.done)),
    action$.pipe(ofActionPayload(settingsActions.loadSettings.done)),
    action$.pipe(ofActionPayload(loginActions.loadAuthToken.done)),
    action$.pipe(ofActionPayload(analyticsActions.startAnalytics.done)),
    action$.pipe(ofActionPayload(routerActions.onNavigatorReady))
  ).pipe(map(([{ result }, ..._]) => appActions.ready(result)));

const appStateChange: Epic<AnyAction, AnyAction, State> = (action$, state) =>
  observeAppState().pipe(
    mergeMap((nextAppState) => {
      var appState: AppStateEnum;
      switch (nextAppState) {
        case "active": {
          appState = AppStateEnum.ACTIVE;
          break;
        }
        case "background": {
          appState = AppStateEnum.BACKGROUND;
          break;
        }
        case "inactive": {
          appState = AppStateEnum.INACTIVE;
          break;
        }
        default:
          console.error(`Unknown state ${nextAppState}`);
          return EMPTY;
      }
      return of(appActions.stateChange(appState));
    })
  );

const appEpic = combineEpics(
  initApp,
  startInitJobs,
  ensureLocalDeviceId,
  appReady,
  appStateChange
);

export default appEpic;
