import { HeaderBackButton } from "@react-navigation/elements";
import { FontAwesome5 } from "@expo/vector-icons";
import {
  createNavigationContainerRef,
  EventArg,
  NavigationAction,
  NavigationContainer,
  NavigationState,
  ParamListBase,
  Route,
  StackNavigationState,
} from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import React, { useRef } from "react";
import { Platform, StyleSheet } from "react-native";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AnyAction } from "typescript-fsa";
import routerActions, { RoutingAction } from "../actions/Router";
import { makeGetStateFromPath, Pages, screens, URLPrefixes } from "../routers";
import { APP_NAME, APP_TAGLINE, IS_ELECTRON } from "../shared/constants";
import { State } from "../states";
import FeedbackPage from "./Feedbacks/FeedbackPage";
import ForgotPasswordPage from "./ForgotPassword/ForgotPasswordPage";
import ResetPasswordPage from "./ForgotPassword/ResetPasswordPage";
import LoadingPage from "./Loading/LoadingPage";
import LoginPage from "./Login/LoginPage";
import MessagePage from "./Message/MessagePage";
import TooManyDevicesPage from "./TooManyDevices/TooManyDevicesPage";
import AccountPage from "./Settings/AccountPage";
import ChangePasswordPage from "./Settings/ChangePasswordPage";
import GeneralPage from "./Settings/GeneralPage";
import SecurityPage from "./Settings/SecurityPage";
import SettingsPage from "./Settings/SettingsPage";
import SignUpPage from "./SignUp/SignUpPage";
import DeleteAccountPage from "./DeleteAccount/DeleteAccountPage";
import SubscriptionPage from "./Subscription/SubscriptionPage";

const styles = StyleSheet.create({
  backIcon: {
    width: 24,
    height: 24,
    alignContent: "center",
    justifyContent: "center",
  },
});

const Stack = createNativeStackNavigator();
export const navigationRef = createNavigationContainerRef();

export interface Props {
  readonly signedIn?: boolean;
  readonly preventNavigation?: boolean;
  readonly disableLinking: boolean;
  readonly onNavigatorReady?: () => void;
  readonly onNavigatorStateChange?: (
    state: StackNavigationState<ParamListBase>
  ) => void;
  readonly onNavigatorBeforeRemove?: (action: NavigationAction) => void;
}

const mapStateToProps = (
  state: State
): Pick<Props, "signedIn" | "preventNavigation" | "disableLinking"> => ({
  signedIn: state.login.tokenLoaded ? state.login.signedIn : undefined,
  preventNavigation:
    state.router.preventNavigation || state.router.focusPage === Pages.LOADING,
  disableLinking: state.device.currentDevice?.tooManyDevices ?? false,
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): Pick<
  Props,
  "onNavigatorReady" | "onNavigatorStateChange" | "onNavigatorBeforeRemove"
> => {
  return {
    onNavigatorReady: () => {
      dispatch(routerActions.onNavigatorReady());
    },
    onNavigatorStateChange: (state: StackNavigationState<ParamListBase>) => {
      dispatch(routerActions.onNavigatorStateChange(state));
    },
    onNavigatorBeforeRemove: (action: NavigationAction) => {
      dispatch(routerActions.onNavigatorBeforeRemove(action));
    },
  };
};

const App: React.FunctionComponent<Props> = ({
  signedIn,
  preventNavigation,
  disableLinking,
  onNavigatorReady,
  onNavigatorStateChange,
  onNavigatorBeforeRemove,
}: Props) => {
  const routerState = useRef<NavigationState | undefined>();
  return (
    <NavigationContainer
      ref={navigationRef}
      linking={
        IS_ELECTRON || disableLinking
          ? undefined
          : {
              prefixes: URLPrefixes,
              config: {
                initialRouteName: Pages.LOADING as any,
                screens,
              },
              getStateFromPath: (path, options) => {
                const getStateFromPath = makeGetStateFromPath(
                  signedIn,
                  routerState.current
                );
                return getStateFromPath(path, options);
              },
            }
      }
      documentTitle={{
        formatter: (
          options?: Record<string, any>,
          route?: Route<string, object | undefined>
        ) => {
          if (options?.title !== undefined && !IS_ELECTRON) {
            return `${APP_NAME} - ${options.title}`;
          }
          return `${APP_NAME} - ${APP_TAGLINE}`;
        },
      }}
      onReady={onNavigatorReady}
    >
      <Stack.Navigator
        initialRouteName={Pages.LOADING}
        screenOptions={
          Platform.OS === "web"
            ? {
                // Electron somehow cannot pack the build-in back button image
                // asset, to make it consistant across web experience, we
                // use fontawesome anyway here to fix that problem
                headerLeft: ({ tintColor, canGoBack }) => {
                  return (
                    <HeaderBackButton
                      tintColor={tintColor}
                      canGoBack={canGoBack}
                      onPress={navigationRef.goBack}
                      backImage={(tintColor) => (
                        <FontAwesome5
                          name={"arrow-left"}
                          size={24}
                          color={tintColor}
                          style={styles.backIcon}
                        />
                      )}
                    />
                  );
                },
              }
            : undefined
        }
        screenListeners={{
          state: (event: EventArg<"state", false, any>) => {
            routerState.current = event.data!.state;
            onNavigatorStateChange?.(event.data!.state);
          },
          beforeRemove: ((event: EventArg<"beforeRemove", true, any>) => {
            const action: RoutingAction = event.data!.action;
            onNavigatorBeforeRemove?.(action);
            const byUser = action?.payload?.byUser ?? true;
            if (preventNavigation && byUser) {
              console.warn(
                "Prevent navigation action",
                action,
                "preventNavigation",
                preventNavigation,
                "byUser=",
                byUser
              );
              event.preventDefault();
            }
          }) as any,
        }}
      >
        <Stack.Screen
          name={Pages.LOADING}
          component={LoadingPage}
          options={{ headerShown: false, gestureEnabled: false }}
        />
        <Stack.Screen
          name={Pages.LOGIN}
          component={LoginPage}
          options={{ headerShown: false, title: "Login" }}
        />
        <Stack.Group>
          <Stack.Screen
            name={Pages.SIGNUP}
            component={SignUpPage}
            options={{
              headerShown: true,
              title: "Sign Up",
            }}
          />
          <Stack.Screen
            name={Pages.FORGOT_PASSWORD}
            component={ForgotPasswordPage}
            options={{
              headerShown: true,
              title: "Forgot Password",
            }}
          />
        </Stack.Group>
        <Stack.Screen
          name={Pages.RESET_PASSWORD}
          component={ResetPasswordPage}
          options={{
            headerShown: true,
            title: "Reset Password",
          }}
        />
        <Stack.Screen
          name={Pages.MESSAGE}
          component={MessagePage}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name={Pages.FEEDBACKS}
          component={FeedbackPage}
          options={{ headerShown: false, presentation: "transparentModal" }}
        />
        <Stack.Screen
          name={Pages.SUBSCRIPTION}
          component={SubscriptionPage}
          options={{ headerShown: false, presentation: "transparentModal" }}
        />
        <Stack.Screen
          name={Pages.TOO_MANY_DEVICES}
          component={TooManyDevicesPage}
          options={{ headerShown: false, presentation: "transparentModal" }}
        />
        <Stack.Group screenOptions={{ headerShown: true }}>
          <Stack.Screen
            name={Pages.SETTINGS}
            component={SettingsPage}
            options={{ title: "Settings" }}
          />
          <Stack.Group>
            <Stack.Screen
              name={Pages.SETTINGS_ACCOUNT}
              component={AccountPage}
              options={{ title: "Account Settings" }}
            />
            <Stack.Screen
              name={Pages.SETTINGS_ACCOUNT_DELETE}
              component={DeleteAccountPage}
              options={{ title: "Delete Account" }}
            />
            <Stack.Group>
              <Stack.Screen
                name={Pages.SETTINGS_CHANGE_PASSWORD}
                component={ChangePasswordPage}
                options={{ title: "Change Password" }}
              />
            </Stack.Group>
            <Stack.Screen
              name={Pages.SETTINGS_GENERAL}
              component={GeneralPage}
              options={{ title: "General Settings" }}
            />
            <Stack.Screen
              name={Pages.SETTINGS_SECURITY}
              component={SecurityPage}
              options={{ title: "Security Settings" }}
            />
          </Stack.Group>
        </Stack.Group>
      </Stack.Navigator>
    </NavigationContainer>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
