import { storiesOf } from "@storybook/react-native";
import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import { Colors } from "../../../components/Colors";
import EditMenu from "../../../components/Message/EditMenu";

const storyName = "Components/Message/" + EditMenu.name;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  editMenu: {
    width: "100%",
    paddingHorizontal: 20,
    backgroundColor: Colors.MediumGrey,
  },
});

storiesOf(storyName, module).add("visible", () => (
  <View style={styles.container}>
    <EditMenu style={styles.editMenu} visible />
  </View>
));

storiesOf(storyName, module).add("invisible", () => {
  const [visible, setVisible] = useState(true);
  setTimeout(() => setVisible(false), 1000);
  return (
    <View style={styles.container}>
      <EditMenu style={styles.editMenu} visible={visible} />
    </View>
  );
});
