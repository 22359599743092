import { some } from "lodash";
import { Platform } from "react-native";
import { combineEpics, Epic } from "redux-observable";
import { EMPTY, of } from "rxjs";
import {
  catchError,
  filter,
  ignoreElements,
  mapTo,
  mergeMap,
  tap,
} from "rxjs/operators";
import { ActionCreator, AnyAction, isType } from "typescript-fsa";
import { ofAction, ofActionPayload } from "typescript-fsa-redux-observable";
import analyticsActions from "../actions/Analytics";
import appActions from "../actions/App";
import forgotPasswordActions from "../actions/ForgotPassword";
import inputActions from "../actions/Input";
import loginActions from "../actions/Login";
import messageActions from "../actions/Message";
import routerActions from "../actions/Router";
import settingsActions from "../actions/Settings";
import deviceActions from "../actions/Device";
import signUpActions from "../actions/SignUp";
import subscriptionActions from "../actions/Subscription";
import userActions from "../actions/User";
import { APP_VERSION, BUILD_NUMBER, IS_ELECTRON } from "../shared/constants";
import { State } from "../states";
import { Dependencies } from "./dependencies";

const startAnalytics: Epic<AnyAction, AnyAction, State, Dependencies> = (
  action$,
  state,
  { analytics }
) =>
  action$.pipe(
    ofActionPayload(analyticsActions.startAnalytics.started),
    mergeMap(() => analytics.initialize()),
    mapTo(analyticsActions.startAnalytics.done({}))
  );

const updateCollecting: Epic<AnyAction, AnyAction, State, Dependencies> = (
  action$,
  state,
  { analytics }
) =>
  action$.pipe(
    ofActionPayload(
      userActions.loadCurrentUser.done,
      userActions.updateSettings.done
    ),
    tap(({ result }) => {
      const collecting = result.settings.share_analytics_data ?? false;
      console.info("Update data collecting to", collecting);
      analytics.setCollecting(collecting);
    }),
    ignoreElements()
  );

const logEvents: Epic<AnyAction, AnyAction, State, Dependencies> = (
  action$,
  state,
  { analytics }
) =>
  action$.pipe(
    filter((action) =>
      some(
        [
          routerActions.getInitialURL.done,
          loginActions.login.started,
          loginActions.logout,
          loginActions.signOutByUser,
          inputActions.leaveCommandMode,
          inputActions.onMenuTapped,
          inputActions.onHashtagTapped,
          inputActions.onCancelUploadingTapped,
          settingsActions.updatePassword.started,
          forgotPasswordActions.forgotPassword.started,
          forgotPasswordActions.resetPassword.started,
          messageActions.onScrollToLatestMessageButtonClicked,
          messageActions.selectMessage,
          messageActions.onShareMessageTapped,
          messageActions.onDeleteMessageTapped,
          messageActions.onMessageHashtagPressed,
          messageActions.onMessageLinkPressed,
          messageActions.deleteConfirmedMessage.started,
          messageActions.onDeleteFailedMessage,
          messageActions.onEditMessageTapped,
          messageActions.onEditCancelTapped,
          messageActions.onDownloadFileTapped,
          messageActions.onRetryFailedMessage,
          deviceActions.onUpgradePress,
          subscriptionActions.onCheckout,
        ],
        (actionCreactor: ActionCreator<any>) => isType(action, actionCreactor)
      )
    ),
    mergeMap((action) => {
      console.debug("Analytic event", action.type);
      return of(analytics.logEvent(action.type)).pipe(
        catchError((error) => {
          console.error("Analytic event error", error);
          return EMPTY;
        })
      );
    }),
    ignoreElements()
  );

const logEventsWithProperties: Epic<
  AnyAction,
  AnyAction,
  State,
  Dependencies
> = (action$, state, { analytics }) =>
  action$.pipe(
    filter((action) =>
      some(
        [
          appActions.ready,
          appActions.stateChange,
          signUpActions.signUp.done,
          inputActions.enterCommandMode,
          inputActions.sendLocalCommand,
          inputActions.sendMessage.started,
          inputActions.onCommandTapped,
          inputActions.uploadFile.done,
          settingsActions.onMenuButtonTap,
          settingsActions.onMenuSwitchValueChange,
          settingsActions.updateSettings,
          routerActions.onPageFocus,
          routerActions.onPageBlur,
          messageActions.onSystemMessageButtonPressed,
          deviceActions.onSignOutDevice,
          subscriptionActions.onCheckout,
        ],
        (actionCreactor: ActionCreator<any>) => isType(action, actionCreactor)
      )
    ),
    mergeMap((action: AnyAction) => {
      let properties: Record<string, any> = {};
      if (isType(action, appActions.ready)) {
        properties = {
          version: APP_VERSION,
          build_number: BUILD_NUMBER,
          platform_os: Platform.OS,
          is_electron: IS_ELECTRON,
        };
      } else if (isType(action, appActions.stateChange)) {
        properties = { state: action.payload };
      } else if (isType(action, signUpActions.signUp.done)) {
        properties = { user_id: action.payload.result.id };
      } else if (isType(action, inputActions.enterCommandMode)) {
        properties = { mode: action.payload };
      } else if (isType(action, inputActions.sendLocalCommand)) {
        properties = { mode: action.payload.id };
      } else if (isType(action, inputActions.onCommandTapped)) {
        properties = { command: action.payload };
      } else if (isType(action, inputActions.sendMessage.started)) {
        properties = {
          length: action.payload.content.length,
          mode: state.value.input.activeCommandMode,
        };
      } else if (isType(action, inputActions.uploadFile.done)) {
        properties = {
          size: action.payload.result.size,
          mime: action.payload.result.mimeType,
        };
      } else if (isType(action, settingsActions.updateSettings)) {
        properties = action.payload;
      } else if (isType(action, settingsActions.onMenuButtonTap)) {
        properties = { type: action.payload };
      } else if (isType(action, settingsActions.onMenuSwitchValueChange)) {
        properties = {
          type: action.payload.type,
          value: action.payload.value,
        };
      } else if (isType(action, routerActions.onPageFocus)) {
        properties = { page: action.payload };
      } else if (isType(action, routerActions.onPageBlur)) {
        properties = { page: action.payload };
      } else if (isType(action, messageActions.onSystemMessageButtonPressed)) {
        properties = { button_id: action.payload.buttonId };
      } else if (isType(action, deviceActions.onSignOutDevice)) {
        properties = { device_id: action.payload };
      }

      console.debug("Analytic event", action.type, "properties=", properties);
      return of(analytics.logEventWithProperties(action.type, properties)).pipe(
        catchError((error) => {
          console.error("Analytic event with properties error", error);
          return EMPTY;
        })
      );
    }),
    ignoreElements()
  );

const setUserId: Epic<AnyAction, AnyAction, State, Dependencies> = (
  action$,
  state,
  { analytics }
) =>
  action$.pipe(
    ofActionPayload(userActions.loadCurrentUser.done),
    mergeMap(({ result }) => {
      console.debug("Analytic set user id", result.id);
      return of(analytics.setUserId(result.id)).pipe(
        catchError((error) => {
          console.error("Analytic set user id error", error);
          return EMPTY;
        })
      );
    }),
    ignoreElements()
  );

const clearUserProperties: Epic<AnyAction, AnyAction, State, Dependencies> = (
  action$,
  state,
  { analytics }
) =>
  action$.pipe(
    ofAction(loginActions.logout),
    mergeMap(() => {
      console.debug("Analytic clear user properties");
      return of(analytics.clearUserProperties()).pipe(
        catchError((error) => {
          console.error("Analytic clean user properties error", error);
          return EMPTY;
        })
      );
    }),
    ignoreElements()
  );

const analyticsEpic = combineEpics(
  startAnalytics,
  updateCollecting,
  logEvents,
  logEventsWithProperties,
  setUserId,
  clearUserProperties
);

export default analyticsEpic;
