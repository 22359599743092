import { storiesOf } from "@storybook/react-native";
import React from "react";
import { StyleSheet, View } from "react-native";
import MenuItemTextField from "../../../components/Settings/MenuItemTextField";

const storyName = "Components/Settings/" + MenuItemTextField.name;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
  },
  menuItem: {
    flex: -1,
    width: "100%",
    borderWidth: 1,
  },
});

storiesOf(storyName, module).add("with icon", () => (
  <View style={styles.container}>
    <MenuItemTextField
      style={styles.menuItem}
      icon="envelope"
      title="Email"
      text="support@monoline.io"
    />
  </View>
));

storiesOf(storyName, module).add("without icon", () => (
  <View style={styles.container}>
    <MenuItemTextField
      style={styles.menuItem}
      title="Email"
      text="support@monoline.io"
    />
  </View>
));

storiesOf(storyName, module).add("progress", () => (
  <View style={styles.container}>
    <MenuItemTextField
      style={styles.menuItem}
      icon="database"
      title="Data Storage"
      text="1MB / 200MB"
      progress={0.35}
    />
  </View>
));

storiesOf(storyName, module).add("action", () => (
  <View style={styles.container}>
    <MenuItemTextField
      style={styles.menuItem}
      icon="user"
      title="Account Type"
      text="FREE"
      actionTitle="Upgrade to Pro"
      actionIcon="star"
    />
  </View>
));
