import { storiesOf } from "@storybook/react-native";
import React from "react";
import { StyleSheet, View } from "react-native";
import DeleteAccount from "../../components/DeleteAccount";

const storyName = "Components/DeleteAccount/" + DeleteAccount.name;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  deleteAccountModal: {
    flex: 1,
    paddingHorizontal: 20,
  },
});

const email = "fangpen@launchplatform.com";

storiesOf(storyName, module).add("delete account", () => (
  <View style={styles.container}>
    <DeleteAccount style={styles.deleteAccountModal} email={email} />
  </View>
));

storiesOf(storyName, module).add("delete account loading", () => (
  <View style={styles.container}>
    <DeleteAccount style={styles.deleteAccountModal} email={email} loading />
  </View>
));
