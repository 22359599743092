import { combineEpics, Epic } from "redux-observable";
import { map, mapTo } from "rxjs/operators";
import { AnyAction } from "typescript-fsa";
import { ofActionPayload } from "typescript-fsa-redux-observable";
import loginActions from "../actions/Login";
import userActions from "../actions/User";
import { DeleteAccountRequest, User } from "../services/api/interface";
import { State } from "../states";
import { makeAPICallEpic } from "./helpers";

const loadCurrentUser = makeAPICallEpic<void, User>(
  userActions.loadCurrentUser,
  (params, { apiClient }) => apiClient.getCurrentUser()
);

const deleteAccount = makeAPICallEpic<DeleteAccountRequest, void>(
  userActions.deleteAccount,
  (request, { apiClient }) => apiClient.deleteAccount(request)
);

const updateSettings = makeAPICallEpic<Record<string, any>, User>(
  userActions.updateSettings,
  (params, { apiClient }) => apiClient.updateSettings(params)
);

const startLoadingUser: Epic<AnyAction, AnyAction, State> = (action$, state) =>
  action$.pipe(
    ofActionPayload(loginActions.updateAuthToken.done),
    map(() => userActions.loadCurrentUser.started())
  );

const logoutAfterDeleteAccount: Epic<AnyAction, AnyAction, State> = (
  action$,
  state
) =>
  action$.pipe(
    ofActionPayload(userActions.deleteAccount.done),
    mapTo(loginActions.logout())
  );

const userEpic = combineEpics(
  loadCurrentUser,
  deleteAccount,
  updateSettings,
  startLoadingUser,
  logoutAfterDeleteAccount
);

export default userEpic;
