import React, { FunctionComponent } from "react";
import {
  StyleProp,
  StyleSheet,
  Text,
  TouchableOpacity,
  ViewStyle,
} from "react-native";
import { Colors } from "../Colors";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  icon: {
    minWidth: 24,
  },
  text: {
    flex: 1,
    paddingLeft: 10,
  },
  boldPrefix: {
    fontWeight: "bold",
    color: Colors.Launch,
  },
  name: {
    color: Colors.Launch,
  },
  description: {},
});

export interface Props {
  readonly style?: StyleProp<ViewStyle>;
  readonly name: string;
  readonly description?: string;
  readonly prefixBoldLength?: number;
  readonly onPress?: (id: string) => void;
}

const HashtagOption: FunctionComponent<Props> = ({
  style,
  name,
  description,
  prefixBoldLength,
  onPress,
}: Props) => {
  const prefixBoldLengthValue = prefixBoldLength ?? 0;
  const boldNamePrefix = name.substr(0, prefixBoldLengthValue);
  const nameSuffix = name.substr(prefixBoldLengthValue);
  return (
    <TouchableOpacity
      style={[styles.container, style]}
      onPress={() => onPress?.(name)}
    >
      <Text style={styles.text} numberOfLines={1}>
        <Text style={styles.boldPrefix}>{boldNamePrefix}</Text>
        <Text style={styles.name}>{nameSuffix}</Text>
        {description !== undefined ? (
          <>
            {" - "}
            <Text style={styles.description}>{description}</Text>
          </>
        ) : null}
      </Text>
    </TouchableOpacity>
  );
};

export default HashtagOption;
