import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AnyAction } from "typescript-fsa";
import actions from "../../actions/Input";
import HashtagList, { Props } from "../../components/Hashtag/HashtagList";
import { State } from "../../states";

const mapStateToProps = (
  state: State
): Pick<Props, "hashtags" | "prefixBoldLength" | "visible"> => {
  const { input } = state;
  return {
    hashtags: input.matchedHashtags.map((hashtag) => {
      return {
        name: hashtag.name,
        description: hashtag.description,
      };
    }),
    prefixBoldLength: input.matchedHashtagPrefixLength,
    visible: input.displayHashtags,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): Pick<Props, "onPress"> => {
  return {
    onPress: (name: string) => {
      dispatch(actions.onHashtagTapped(name));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HashtagList);
