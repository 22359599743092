import React, { FunctionComponent } from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { Colors } from "../Colors";
import IconButton from "../IconButton";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

export interface Props {
  readonly style?: StyleProp<ViewStyle>;
  readonly addButtonDisabled?: boolean;
  readonly onAdd?: () => void;
  readonly onCancel?: () => void;
}

const UploadingMenu: FunctionComponent<Props> = ({
  style,
  addButtonDisabled,
  onAdd,
  onCancel,
}: Props) => {
  return (
    <View style={[styles.container, style]}>
      <IconButton
        name="plus"
        text="Add more"
        onPress={onAdd}
        disabled={addButtonDisabled}
      />
      <IconButton
        name="times-circle"
        text="Cancel"
        color={Colors.Danger}
        onPress={onCancel}
      />
    </View>
  );
};

export default UploadingMenu;
