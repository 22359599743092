import { Linking, Platform } from "react-native";
import { combineEpics, Epic } from "redux-observable";
import { EMPTY, from } from "rxjs";
import {
  catchError,
  filter,
  ignoreElements,
  mapTo,
  mergeMap,
} from "rxjs/operators";
import { AnyAction } from "typescript-fsa";
import { ofActionPayload } from "typescript-fsa-redux-observable";
import routerActions from "../actions/Router";
import settingsActions, { ButtonType } from "../actions/Settings";
import subscriptionActions, { CheckoutSource } from "../actions/Subscription";
import { Pages } from "../routers";
import { Checkout, PortalSession } from "../services/api/interface";
import { IS_ELECTRON } from "../shared/constants";
import { State } from "../states";
import { makeAPICallEpic } from "./helpers";

const createCheckout = makeAPICallEpic<CheckoutSource, Checkout>(
  subscriptionActions.createCheckout,
  (_, { apiClient }) => apiClient.createCheckout()
);

const createPortalSession = makeAPICallEpic<void, PortalSession>(
  subscriptionActions.createPortalSession,
  (_, { apiClient }) => apiClient.createPortalSession()
);

const checkoutSession: Epic<AnyAction, AnyAction, State> = (action$, state) =>
  action$.pipe(
    ofActionPayload(subscriptionActions.onCheckout),
    mapTo(subscriptionActions.createCheckout.started(CheckoutSource.click))
  );

const startCreatePortalSession: Epic<AnyAction, AnyAction, State> = (
  action$,
  state
) =>
  action$.pipe(
    ofActionPayload(settingsActions.onMenuButtonTap),
    filter(
      (buttonType) => buttonType === ButtonType.ACCOUNT_MANAGE_SUBSCRIPTION
    ),
    mapTo(subscriptionActions.createPortalSession.started())
  );

const openCheckoutPage: Epic<AnyAction, AnyAction, State> = (action$, state) =>
  action$.pipe(
    ofActionPayload(subscriptionActions.createCheckout.done),
    mergeMap(({ params, result }) =>
      from(
        Linking.openURL(
          result.url,
          params === CheckoutSource.auto &&
            Platform.OS === "web" &&
            !IS_ELECTRON
            ? "_self"
            : undefined
        )
      ).pipe(
        catchError((error) => {
          console.error("Failed to open URL", error);
          return EMPTY;
        })
      )
    ),
    ignoreElements()
  );

const openPortalSession: Epic<AnyAction, AnyAction, State> = (action$, state) =>
  action$.pipe(
    ofActionPayload(subscriptionActions.createPortalSession.done),
    mergeMap(({ result }) =>
      from(Linking.openURL(result.url)).pipe(
        catchError((error) => {
          console.error("Failed to open URL", error);
          return EMPTY;
        })
      )
    ),
    ignoreElements()
  );

const createCheckoutForSubscriptionPage: Epic<AnyAction, AnyAction, State> = (
  action$,
  state
) =>
  action$.pipe(
    ofActionPayload(routerActions.onPageFocus),
    filter((page) => page === Pages.SUBSCRIPTION),
    mapTo(subscriptionActions.createCheckout.started(CheckoutSource.auto))
  );

const subscriptionEpic = combineEpics(
  createCheckout,
  createPortalSession,
  startCreatePortalSession,
  checkoutSession,
  openCheckoutPage,
  openPortalSession,
  createCheckoutForSubscriptionPage
);

export default subscriptionEpic;
