import { storiesOf } from "@storybook/react-native";
import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import PreviewBody from "../../../components/Preview/PreviewBody";

const storyName = "Components/Preview/" + PreviewBody.name;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  preview: {
    flex: -1,
    borderWidth: 1,
    paddingHorizontal: 20,
  },
});

const thumbnail = {
  uri: "https://i.ytimg.com/vi/dQw4w9WgXcQ/maxresdefault.jpg",
  width: 1280,
  height: 720,
};

storiesOf(storyName, module).add("thumbnail", () => (
  <View style={styles.container}>
    <PreviewBody style={styles.preview} thumbnail={thumbnail} />
  </View>
));

storiesOf(storyName, module).add("description", () => (
  <View style={styles.container}>
    <PreviewBody
      style={styles.preview}
      description="The official video for “Never Gonna Give You Up” by Rick AstleyTaken from the album ‘Whenever You Need Somebody’ – deluxe 2CD and digital deluxe out 6th May ..."
    />
  </View>
));

storiesOf(storyName, module).add("thumbnail and description", () => (
  <View style={styles.container}>
    <PreviewBody
      style={styles.preview}
      thumbnail={thumbnail}
      description="The official video for “Never Gonna Give You Up” by Rick AstleyTaken from the album ‘Whenever You Need Somebody’ – deluxe 2CD and digital deluxe out 6th May ..."
    />
  </View>
));
