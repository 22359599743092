import { combineReducers } from "redux";
import { AnyAction } from "typescript-fsa";
import { State } from "../states";
import app from "./app";
import error from "./error";
import event from "./event";
import input from "./input";
import login from "./login";
import messages from "./message";
import signUp from "./signUp";
import forgotPassword from "./forgotPassword";
import settings from "./settings";
import user from "./user";
import device from "./device";
import feedbacks from "./feedbacks";
import subscription from "./subscription";
import router from "./router";

const rootReducer = combineReducers<State, AnyAction>({
  app,
  login,
  signUp,
  forgotPassword,
  messages,
  user,
  device,
  event,
  input,
  error,
  settings,
  feedbacks,
  subscription,
  router,
});

export default rootReducer;
