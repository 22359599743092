import { storiesOf } from "@storybook/react-native";
import React from "react";
import { StyleSheet, View } from "react-native";
import Login from "../../components/Login";

const storyName = "Components/" + Login.name;

const styles = StyleSheet.create({
  login: {
    flex: 1,
  },
});

storiesOf(storyName, module).add("login", () => <Login style={styles.login} />);

storiesOf(storyName, module).add("login loading", () => (
  <Login style={styles.login} loading />
));
