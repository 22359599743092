import { StackActions, useNavigation } from "@react-navigation/native";
import React, { useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AnyAction } from "typescript-fsa";
import feedbackActions from "../../actions/Feedbacks";
import Feedbacks, { Props as FeedbackProps } from "../../components/Feedbacks";
import { confirmAlert } from "../../epics/helpers";
import { State } from "../../states";

export type Props =
  | Omit<
      FeedbackProps,
      "onCancel" | "onContentChange" | "content" | "contentValid" | "onSubmit"
    > & { readonly onSubmit: (content: string) => void };

const FeedbacksContainer: React.FunctionComponent<Props> = ({
  onSubmit,
  ...props
}: Props) => {
  const [value, setValue] = useState<string | undefined>();
  const valueEmpty = (value?.trim().length ?? 0) === 0;
  const navigation = useNavigation();
  return (
    <Feedbacks
      content={value}
      contentValid={!valueEmpty}
      onContentChange={setValue}
      onSubmit={() => {
        onSubmit?.(value!);
      }}
      onCancel={async () => {
        if (!valueEmpty) {
          try {
            await confirmAlert(
              "Are you sure",
              "You have unsent feedback content, are you sure you want to cancel?"
            );
          } catch (error) {
            return;
          }
        }
        navigation.dispatch(StackActions.pop());
      }}
      {...props}
    />
  );
};

const mapStateToProps = (state: State): Pick<Props, "loading"> => ({
  loading: state.feedbacks.loading,
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): Pick<Props, "onSubmit"> => {
  return {
    onSubmit: (content: string) => {
      dispatch(feedbackActions.onSubmitFeedback(content));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FeedbacksContainer);
