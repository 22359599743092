import { UpdatePasswordRequest, User } from "../services/api/interface";
import { SettingsConfig } from "../states";
import { actionCreator } from "./shared";

export enum ButtonType {
  SETTINGS_ACCOUNT = "SETTINGS_ACCOUNT",
  SETTINGS_GENERAL = "SETTINGS_GENERAL",
  SETTINGS_SECURITY = "SETTINGS_SECURITY",
  ACCOUNT_REMOVE_WELCOME_MESSAGES = "ACCOUNT_REMOVE_WELCOME_MESSAGES",
  ACCOUNT_UPGRADE_ACCOUNT = "ACCOUNT_UPGRADE_ACCOUNT",
  ACCOUNT_MANAGE_SUBSCRIPTION = "ACCOUNT_MANAGE_SUBSCRIPTION",
  ACCOUNT_CHANGE_PASSWORD = "ACCOUNT_CHANGE_PASSWORD",
  ACCOUNT_DELETE = "ACCOUNT_DELETE",
  ACCOUNT_SIGN_OUT = "ACCOUNT_SIGN_OUT",
  CHANGE_PASSWORD_UPDATE = "CHANGE_PASSWORD_UPDATE",
  GENERAL_RESET_SETTINGS = "GENERAL_RESET_SETTINGS",
}

export enum SwitchType {
  GENERAL_DISPLAY_MESSAGE_TIME = "GENERAL_DISPLAY_MESSAGE_TIME",
  DISABLE_URL_PREVIEW = "DISABLE_URL_PREVIEW",
  MESSAGE_NOTIFICATION_ENABLED = "MESSAGE_NOTIFICATION_ENABLED",
  ACCOUNT_SHARE_DATA = "ACCOUNT_SHARE_DATA",
  SECURITY_TWO_FACTOR_AUTH = "SECURITY_TWO_FACTOR_AUTH",
}

export enum InputType {
  CHANGE_PASSWORD_CURRENT_PASSWORD = "CHANGE_PASSWORD_CURRENT_PASSWORD",
  CHANGE_PASSWORD_NEW_PASSWORD = "CHANGE_PASSWORD_NEW_PASSWORD",
  CHANGE_PASSWORD_NEW_PASSWORD_REPEAT = "CHANGE_PASSWORD_NEW_PASSWORD_REPEAT",
}

export const prefix = "SETTINGS/";
const actions = {
  updatePassword: actionCreator.async<UpdatePasswordRequest, User>(
    prefix + "UPDATE_PASSWORD"
  ),
  validatePassword: actionCreator(prefix + "VALIDATE_PASSWORD"),
  updateSettings: actionCreator<Partial<SettingsConfig>>(
    prefix + "UPDATE_SETTINGS"
  ),
  loadSettings: actionCreator.async<void, Partial<SettingsConfig>>(
    prefix + "LOAD_SETTINGS"
  ),
  onMenuButtonTap: actionCreator<ButtonType>(prefix + "ON_MENU_BUTTON_TAP"),
  onMenuSwitchValueChange: actionCreator<{ type: SwitchType; value: boolean }>(
    prefix + "ON_MENU_SWITCH_VALUE_CHANGE"
  ),
  onMenuInputValueChange: actionCreator<{ type: InputType; value: string }>(
    prefix + "ON_MENU_INPUT_VALUE_CHANGE"
  ),
  onMenuInputSubmit: actionCreator<{ type: InputType; value: string }>(
    prefix + "ON_MENU_INPUT_SUBMIT"
  ),
};
export default actions;
