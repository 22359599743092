import React from "react";
import { Pages } from "../../routers";
import PageContainer from "../Page/PageContainer";
import FeedbackContainer from "./FeedbackContainer";

const FeedbackPage: React.FunctionComponent = () => {
  return (
    <PageContainer name={Pages.LOGIN}>
      <FeedbackContainer />
    </PageContainer>
  );
};

export default FeedbackPage;
