import { storiesOf } from "@storybook/react-native";
import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import MenuItemSwitch from "../../../components/Settings/MenuItemSwitch";

const storyName = "Components/Settings/" + MenuItemSwitch.name;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
  },
  menuItem: {
    flex: -1,
    width: "100%",
    borderWidth: 1,
  },
});

storiesOf(storyName, module).add("off", () => (
  <View style={styles.container}>
    <MenuItemSwitch
      style={styles.menuItem}
      icon="clock"
      title="Display message time"
    />
  </View>
));

storiesOf(storyName, module).add("on", () => (
  <View style={styles.container}>
    <MenuItemSwitch
      style={styles.menuItem}
      icon="clock"
      title="Display message time"
      value
    />
  </View>
));

storiesOf(storyName, module).add("interactive", () => {
  const [value, setValue] = useState(false);
  return (
    <View style={styles.container}>
      <MenuItemSwitch
        style={styles.menuItem}
        icon="clock"
        title="Display message time"
        value={value}
        onValueChange={setValue}
      />
    </View>
  );
});

storiesOf(storyName, module).add("interactive without icon", () => {
  const [value, setValue] = useState(false);
  return (
    <View style={styles.container}>
      <MenuItemSwitch
        style={styles.menuItem}
        title="Display message time"
        value={value}
        onValueChange={setValue}
      />
    </View>
  );
});
