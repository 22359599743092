import React, { FunctionComponent, useRef, useEffect } from "react";
import {
  StyleProp,
  StyleSheet,
  Text,
  View,
  ViewStyle,
  Animated,
  Easing,
} from "react-native";
import { Colors } from "../Colors";
import { FontAwesome5 } from "@expo/vector-icons";
import IconButton from "../IconButton";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  failedContainer: {
    backgroundColor: Colors.Warning,
  },
  fileName: {
    flex: 1,
    fontSize: 14,
    color: Colors.Launch,
    marginHorizontal: 15,
  },
  deleteButton: {},
  progressBar: {
    flex: 1,
    backgroundColor: Colors.MediumGrey,
    height: 5,
    marginHorizontal: 15,
  },
  progressBarIndicator: {
    backgroundColor: Colors.Launch,
    height: 5,
  },
});

export enum Mode {
  UPLOADING = "UPLOADING",
  UPLOADED = "UPLOADED",
  FAILED = "FAILED",
  MESSAGE = "MESSAGE",
}

export interface Props {
  readonly style?: StyleProp<ViewStyle>;
  readonly icon?: string;
  readonly fileName: string;
  readonly progress?: number;
  readonly mode: Mode;
  readonly onDelete?: () => void;
  readonly onDownload?: () => void;
}

const File: FunctionComponent<Props> = ({
  style,
  icon,
  fileName,
  progress,
  mode,
  onDelete,
  onDownload,
}: Props) => {
  const progressValue = progress ?? 100;
  const animatedProgress = useRef(new Animated.Value(progressValue)).current;
  const easing = Easing.inOut(Easing.cubic);
  const duration = 300;

  useEffect(() => {
    Animated.timing(animatedProgress, {
      toValue: progressValue,
      easing,
      duration,
      useNativeDriver: false,
    }).start();
    return () => {
      animatedProgress.stopAnimation();
    };
  }, [progressValue]);

  return (
    <View
      style={[
        styles.container,
        mode === Mode.FAILED ? styles.failedContainer : null,
        style,
      ]}
    >
      <FontAwesome5 name={icon ?? "file"} size={25} color={Colors.Launch} />
      <Text style={styles.fileName} numberOfLines={1}>
        {fileName}
      </Text>
      {mode === Mode.UPLOADING ? (
        <View style={styles.progressBar}>
          <Animated.View
            style={[
              styles.progressBarIndicator,
              {
                width: animatedProgress.interpolate({
                  inputRange: [0, 100],
                  outputRange: ["0%", "100%"],
                }),
              },
            ]}
          />
        </View>
      ) : null}
      {mode === Mode.UPLOADING ||
      mode === Mode.UPLOADED ||
      mode === Mode.FAILED ? (
        <IconButton
          name="trash-alt"
          color={Colors.Danger}
          onPress={onDelete}
          size={20}
        />
      ) : (
        <IconButton
          name="file-download"
          color={Colors.Launch}
          onPress={onDownload}
          size={20}
        />
      )}
    </View>
  );
};

export default File;
