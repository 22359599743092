import { storiesOf } from "@storybook/react-native";
import React from "react";
import { StyleSheet, View } from "react-native";
import Feedbacks from "../../components/Feedbacks";

const storyName = "Components/" + Feedbacks.name;

const styles = StyleSheet.create({
  Feedbacks: {
    flex: 1,
  },
});

storiesOf(storyName, module).add("feedback", () => (
  <Feedbacks style={styles.feedback} />
));
