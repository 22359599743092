import React from "react";
import { Pages } from "../../routers";
import PageContainer from "../Page/PageContainer";
import DeleteAccountContainer from "./DeleteAccountContainer";

const DeleteAccountPage: React.FunctionComponent = () => (
  <PageContainer name={Pages.SETTINGS_ACCOUNT_DELETE}>
    <DeleteAccountContainer />
  </PageContainer>
);

export default DeleteAccountPage;
