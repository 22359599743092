import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AnyAction } from "typescript-fsa";
import actions from "../../actions/Input";
import SearchBar, { Props } from "../../components/Input/SearchBar";
import { BuildInCommandType, State } from "../../states";

const mapStateToProps = (state: State): Pick<Props, "keywords" | "visible"> => {
  return {
    keywords: state.messages.searchKeywords ?? undefined,
    visible: state.input.activeCommandMode === BuildInCommandType.SEARCH,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): Pick<Props, "onCancel"> => ({
  onCancel: () => {
    dispatch(actions.onCancelSearchTapped(undefined));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
