import { storiesOf } from "@storybook/react-native";
import React, { useState } from "react";
import {
  ImageProps,
  ImageSourcePropType,
  StyleSheet,
  View,
} from "react-native";
import Message, {
  MessageDeletingState,
  MessageState,
  Preview,
} from "../../../components/Message/Message";

const storyName = "Components/Message/Message";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
  },
  message: {
    flex: -1,
    width: "100%",
    borderWidth: 1,
  },
});

const shortText = "Should buy milk later";
const longText =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.";
const previewThumbnail: ImageSourcePropType = {
  uri: "https://i.ytimg.com/vi/dQw4w9WgXcQ/maxresdefault.jpg",
  width: 1280,
  height: 720,
};
const previewTitle =
  "Rick Astley - Never Gonna Give You Up (Official Music Video)";
const previewDescription =
  "The official video for “Never Gonna Give You Up” by Rick AstleyTaken from the album ‘Whenever You Need Somebody’ – deluxe 2CD and digital deluxe out 6th May ...";

storiesOf(storyName, module).add("short pending", () => (
  <View style={styles.container}>
    <Message
      id=""
      style={styles.message}
      message={shortText}
      time="9:41 AM"
      state={MessageState.PENDING}
    />
  </View>
));

storiesOf(storyName, module).add("long pending", () => (
  <View style={styles.container}>
    <Message
      id=""
      style={styles.message}
      message={longText}
      time="9:41 AM"
      state={MessageState.PENDING}
    />
  </View>
));

storiesOf(storyName, module).add("short files pending", () => (
  <View style={styles.container}>
    <Message
      id=""
      style={styles.message}
      message={shortText}
      time="9:41 AM"
      state={MessageState.PENDING}
      files={[
        {
          id: "file-00",
          name: "software-contracting-agreement.pdf",
          icon: "file-pdf",
        },
        { id: "file-01", name: "NDA.doc", icon: "file-word" },
      ]}
    />
  </View>
));

storiesOf(storyName, module).add("long files pending", () => (
  <View style={styles.container}>
    <Message
      id=""
      style={styles.message}
      message={longText}
      time="9:41 AM"
      state={MessageState.PENDING}
      files={[
        {
          id: "file-00",
          name: "software-contracting-agreement.pdf",
          icon: "file-pdf",
        },
        { id: "file-01", name: "NDA.doc", icon: "file-word" },
      ]}
    />
  </View>
));

storiesOf(storyName, module).add("short confirmed", () => (
  <View style={styles.container}>
    <Message
      id=""
      style={styles.message}
      message={shortText}
      time="9:41 AM"
      state={MessageState.CONFIRMED}
    />
  </View>
));

storiesOf(storyName, module).add("long confirmed", () => (
  <View style={styles.container}>
    <Message
      id=""
      style={styles.message}
      message={longText}
      time="9:41 AM"
      state={MessageState.CONFIRMED}
    />
  </View>
));

storiesOf(storyName, module).add("short confirmed without time", () => (
  <View style={styles.container}>
    <Message
      id=""
      style={styles.message}
      message={shortText}
      time="9:41 AM"
      state={MessageState.CONFIRMED}
      displayTime={false}
    />
  </View>
));

storiesOf(storyName, module).add("long confirmed without time", () => (
  <View style={styles.container}>
    <Message
      id=""
      style={styles.message}
      message={longText}
      time="9:41 AM"
      state={MessageState.CONFIRMED}
      displayTime={false}
    />
  </View>
));

storiesOf(storyName, module).add("empty message files confirmed", () => (
  <View style={styles.container}>
    <Message
      id=""
      style={styles.message}
      message={""}
      time="9:41 AM"
      state={MessageState.CONFIRMED}
      files={[
        {
          id: "file-00",
          name: "software-contracting-agreement.pdf",
          icon: "file-pdf",
        },
        { id: "file-01", name: "NDA.doc", icon: "file-word" },
      ]}
    />
  </View>
));

storiesOf(storyName, module).add("short files confirmed", () => (
  <View style={styles.container}>
    <Message
      id=""
      style={styles.message}
      message={shortText}
      time="9:41 AM"
      state={MessageState.CONFIRMED}
      files={[
        {
          id: "file-00",
          name: "software-contracting-agreement.pdf",
          icon: "file-pdf",
        },
        { id: "file-01", name: "NDA.doc", icon: "file-word" },
      ]}
    />
  </View>
));

storiesOf(storyName, module).add("long files confirmed", () => (
  <View style={styles.container}>
    <Message
      id=""
      style={styles.message}
      message={longText}
      time="9:41 AM"
      state={MessageState.CONFIRMED}
      files={[
        {
          id: "file-00",
          name: "software-contracting-agreement.pdf",
          icon: "file-pdf",
        },
        { id: "file-01", name: "NDA.doc", icon: "file-word" },
      ]}
    />
  </View>
));

storiesOf(storyName, module).add("short files confirmed without time", () => (
  <View style={styles.container}>
    <Message
      id=""
      style={styles.message}
      message={shortText}
      time="9:41 AM"
      state={MessageState.CONFIRMED}
      files={[
        {
          id: "file-00",
          name: "software-contracting-agreement.pdf",
          icon: "file-pdf",
        },
        { id: "file-01", name: "NDA.doc", icon: "file-word" },
      ]}
      displayTime={false}
    />
  </View>
));

storiesOf(storyName, module).add("long files confirmed without time", () => (
  <View style={styles.container}>
    <Message
      id=""
      style={styles.message}
      message={longText}
      time="9:41 AM"
      state={MessageState.CONFIRMED}
      files={[
        {
          id: "file-00",
          name: "software-contracting-agreement.pdf",
          icon: "file-pdf",
        },
        { id: "file-01", name: "NDA.doc", icon: "file-word" },
      ]}
      displayTime={false}
    />
  </View>
));

storiesOf(storyName, module).add("short failed", () => (
  <View style={styles.container}>
    <Message
      id=""
      style={styles.message}
      message={shortText}
      time="9:41 AM"
      state={MessageState.FAILED}
    />
  </View>
));

storiesOf(storyName, module).add("long failed", () => (
  <View style={styles.container}>
    <Message
      id=""
      style={styles.message}
      message={longText}
      time="9:41 AM"
      state={MessageState.FAILED}
    />
  </View>
));

storiesOf(storyName, module).add("short selected", () => (
  <View style={styles.container}>
    <Message
      id=""
      style={styles.message}
      message={shortText}
      time="9:41 AM"
      state={MessageState.SELECTED}
    />
  </View>
));

storiesOf(storyName, module).add("long selected", () => (
  <View style={styles.container}>
    <Message
      id=""
      style={styles.message}
      message={longText}
      time="9:41 AM"
      state={MessageState.SELECTED}
    />
  </View>
));

storiesOf(storyName, module).add("short confirmed deleting", () => (
  <View style={styles.container}>
    <Message
      id=""
      style={styles.message}
      message={shortText}
      time="9:41 AM"
      state={MessageState.CONFIRMED}
      deletingState={MessageDeletingState.DELETING}
    />
  </View>
));

storiesOf(storyName, module).add("long confirmed deleting", () => (
  <View style={styles.container}>
    <Message
      id=""
      style={styles.message}
      message={longText}
      time="9:41 AM"
      state={MessageState.CONFIRMED}
      deletingState={MessageDeletingState.DELETING}
    />
  </View>
));

storiesOf(storyName, module).add("short failed deleting", () => (
  <View style={styles.container}>
    <Message
      id=""
      style={styles.message}
      message={shortText}
      time="9:41 AM"
      state={MessageState.FAILED}
      deletingState={MessageDeletingState.DELETING}
    />
  </View>
));

storiesOf(storyName, module).add("long failed deleting", () => (
  <View style={styles.container}>
    <Message
      id=""
      style={styles.message}
      message={longText}
      time="9:41 AM"
      state={MessageState.FAILED}
      deletingState={MessageDeletingState.DELETING}
    />
  </View>
));

storiesOf(storyName, module).add("short confirmed deleted", () => {
  const [deletingState, setDeletingState] = useState(
    MessageDeletingState.DELETING
  );
  setTimeout(() => setDeletingState(MessageDeletingState.DELETED), 1000);
  return (
    <View style={styles.container}>
      <Message
        id=""
        style={styles.message}
        message={shortText}
        time="9:41 AM"
        state={MessageState.CONFIRMED}
        deletingState={deletingState}
      />
    </View>
  );
});

storiesOf(storyName, module).add("long confirmed deleted", () => {
  const [deletingState, setDeletingState] = useState(
    MessageDeletingState.DELETING
  );
  setTimeout(() => setDeletingState(MessageDeletingState.DELETED), 1000);
  return (
    <View style={styles.container}>
      <Message
        id=""
        style={styles.message}
        message={longText}
        time="9:41 AM"
        state={MessageState.CONFIRMED}
        deletingState={deletingState}
      />
    </View>
  );
});

storiesOf(storyName, module).add("short failed deleted", () => {
  const [deletingState, setDeletingState] = useState(
    MessageDeletingState.DELETING
  );
  setTimeout(() => setDeletingState(MessageDeletingState.DELETED), 1000);
  return (
    <View style={styles.container}>
      <Message
        id=""
        style={styles.message}
        message={shortText}
        time="9:41 AM"
        state={MessageState.FAILED}
        deletingState={deletingState}
      />
    </View>
  );
});

storiesOf(storyName, module).add("long failed deleted", () => {
  const [deletingState, setDeletingState] = useState(
    MessageDeletingState.DELETING
  );
  setTimeout(() => setDeletingState(MessageDeletingState.DELETED), 1000);
  return (
    <View style={styles.container}>
      <Message
        id=""
        style={styles.message}
        message={longText}
        time="9:41 AM"
        state={MessageState.FAILED}
        deletingState={deletingState}
      />
    </View>
  );
});
storiesOf(storyName, module).add("short edit", () => (
  <View style={styles.container}>
    <Message
      id=""
      style={styles.message}
      message={shortText}
      time="9:41 AM"
      state={MessageState.EDIT}
    />
  </View>
));

storiesOf(storyName, module).add("long edit", () => (
  <View style={styles.container}>
    <Message
      id=""
      style={styles.message}
      message={longText}
      time="9:41 AM"
      state={MessageState.EDIT}
    />
  </View>
));

storiesOf(storyName, module).add("short confirmed with preview", () => (
  <View style={styles.container}>
    <Message
      id=""
      style={styles.message}
      message={shortText}
      time="9:41 AM"
      state={MessageState.CONFIRMED}
      previews={[
        {
          id: "preview0",
          title: previewTitle,
          description: previewDescription,
          thumbnail: previewThumbnail,
        } as Preview,
        {
          id: "preview1",
          title: previewTitle,
          description: previewDescription,
          thumbnail: previewThumbnail,
        } as Preview,
      ]}
    />
  </View>
));

storiesOf(storyName, module).add("long confirmed with preview", () => (
  <View style={styles.container}>
    <Message
      id=""
      style={styles.message}
      message={longText}
      time="9:41 AM"
      state={MessageState.CONFIRMED}
      previews={[
        {
          id: "preview0",
          title: previewTitle,
          description: previewDescription,
          thumbnail: previewThumbnail,
        } as Preview,
        {
          id: "preview1",
          title: previewTitle,
          description: previewDescription,
          thumbnail: previewThumbnail,
        } as Preview,
      ]}
    />
  </View>
));
