import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import {
  ReturnKeyTypeOptions,
  StyleProp,
  StyleSheet,
  TextInput,
  ViewStyle,
} from "react-native";
import { Colors } from "../Colors";

const styles = StyleSheet.create({
  textInput: {
    minHeight: 32,
    paddingLeft: 10,
    paddingVertical: 10,
    borderWidth: 1,
    borderColor: Colors.DarkGrey,
    backgroundColor: "white",
    borderRadius: 10,
    fontSize: 14,
  },
});

export interface Props {
  readonly placeholder?: string;
  readonly value?: string;
  readonly autoFocus?: boolean;
  readonly style?: StyleProp<ViewStyle>;
  readonly returnKeyType?: ReturnKeyTypeOptions;
  readonly focusCounter?: number;
  readonly onChangeText: (value: string) => void;
  readonly onSubmit: () => void;
  readonly onBackspace?: () => void;
}

const EditField: FunctionComponent<Props> = ({
  placeholder,
  value,
  autoFocus,
  style,
  returnKeyType,
  focusCounter,
  onChangeText,
  onSubmit,
  onBackspace,
}: Props) => {
  const textRef = useRef<TextInput>(null);
  useEffect(() => {
    textRef.current?.focus();
  }, [focusCounter]);
  const [contentHeight, setContentHeight] = useState(32);
  return (
    <TextInput
      ref={textRef}
      style={[styles.textInput, { height: contentHeight }, style]}
      placeholder={placeholder}
      onChangeText={(text) => onChangeText?.(text)}
      blurOnSubmit={false}
      onKeyPress={(event) => {
        if (event.nativeEvent.key == "Backspace") {
          onBackspace?.();
        }
      }}
      onSubmitEditing={onSubmit}
      value={value}
      returnKeyType={returnKeyType ?? "done"}
      autoFocus={autoFocus ?? false}
      onContentSizeChange={({
        nativeEvent: {
          contentSize: { width, height },
        },
      }) => {
        setContentHeight(height);
      }}
      multiline
    />
  );
};

export default EditField;
