import { storiesOf } from "@storybook/react-native";
import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import EditField from "../../../components/Message/EditField";

const storyName = "Components/Message/" + EditField.name;

const placeholder = "Type something here";
const shortText = "Some text here";
const longText =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    paddingHorizontal: 10,
  },
});

storiesOf(storyName, module).add("with placeholder", () => (
  <View style={styles.container}>
    <EditField
      placeholder={placeholder}
      onSubmit={() => {}}
      onChangeText={() => {}}
    />
  </View>
));

storiesOf(storyName, module).add("with short text", () => {
  const [value, setValue] = useState(shortText);
  return (
    <View style={styles.container}>
      <EditField
        value={shortText}
        onSubmit={() => {}}
        onChangeText={(value: string) => {
          setValue(value);
        }}
      />
    </View>
  );
});

storiesOf(storyName, module).add("with long text", () => {
  const [value, setValue] = useState(longText);
  return (
    <View style={styles.container}>
      <EditField
        value={value}
        onSubmit={() => {}}
        onChangeText={(value: string) => {
          setValue(value);
        }}
      />
    </View>
  );
});
