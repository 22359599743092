import { actionCreator } from "./shared";

export const prefix = "APP/";

export enum AppState {
  ACTIVE = "ACTIVE",
  BACKGROUND = "BACKGROUND",
  INACTIVE = "INACTIVE",
}

const actions = {
  init: actionCreator(prefix + "INIT"),
  ready: actionCreator<string | null>(prefix + "READY"),
  credentialsLoaded: actionCreator(prefix + "CREDENTIALS_LOADED"),
  ensureDeviceLocalId: actionCreator.async<void, string>(
    prefix + "ENSURE_DEVICE_LOCAL_ID"
  ),
  stateChange: actionCreator<AppState>(prefix + "STATE_CHANGE"),
};

export default actions;
