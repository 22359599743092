import React from "react";
import { Pages } from "../../routers";
import PageContainer from "../Page/PageContainer";
import ChangePasswordContainer from "./ChangePasswordContainer";

const ChangePasswordPage: React.FunctionComponent = () => (
  <PageContainer
    name={Pages.SETTINGS_CHANGE_PASSWORD}
    displayErrors
    keyboardAvoiding
  >
    <ChangePasswordContainer />
  </PageContainer>
);

export default ChangePasswordPage;
