import { reducerWithInitialState } from "typescript-fsa-reducers";
import actions from "../actions/Event";
import loginActions from "../actions/Login";
import { EventState } from "../states";

const event = reducerWithInitialState<EventState>({
  streams: {},
})
  .case(loginActions.logout, (state, payload) => ({
    ...state,
    streams: {},
  }))
  .case(actions.loadEvents.started, (state, params) => ({
    ...state,
    streams: {
      ...state.streams,
      [params.eventStreamId]: {
        lastSequenceId: params.lastSequenceId,
        waitingForUpdate: false,
      },
    },
  }))
  .case(actions.loadEvents.done, (state, { params, result }) => ({
    ...state,
    streams: {
      ...state.streams,
      [params.eventStreamId]: {
        ...state.streams[params.eventStreamId],
        lastSequenceId: Math.max(
          state.streams[params.eventStreamId]?.lastSequenceId ?? 0,
          result.lastSequenceId ?? 0
        ),
      },
    },
  }))
  .case(actions.eventUpdate, (state, payload) => ({
    ...state,
    streams: {
      ...state.streams,
      [payload.eventStreamId]: {
        ...state.streams[payload.eventStreamId],
        lastSequenceId: Math.max(
          state.streams[payload.eventStreamId]?.lastSequenceId ?? 0,
          payload.eventPage.lastSequenceId ?? 0
        ),
      },
    },
  }))
  .case(actions.allEventsLoaded, (state, eventStreamId) => ({
    ...state,
    streams: {
      ...state.streams,
      [eventStreamId]: {
        ...state.streams[eventStreamId],
        waitingForUpdate: true,
      },
    },
  }));
export default event;
