import { StackActions, useNavigation } from "@react-navigation/native";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AnyAction } from "typescript-fsa";
import subscriptionActions from "../../actions/Subscription";
import SubscriptionModal, {
  Props,
} from "../../components/Subscription/SubscriptionModal";

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): Pick<Props, "onCheckout" | "onDismiss"> => {
  const navigation = useNavigation();
  return {
    onCheckout: () => {
      dispatch(subscriptionActions.onCheckout());
    },
    onDismiss: () => {
      navigation.dispatch(StackActions.pop());
    },
  };
};

export default connect(undefined, mapDispatchToProps)(SubscriptionModal);
