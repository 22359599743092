import React from "react";
import { Pages } from "../../routers";
import PageContainer from "../Page/PageContainer";
import TooManyDevicesContainer from "./TooManyDevicesContainer";

const TooManyDevicesPage: React.FunctionComponent = () => (
  <PageContainer
    name={Pages.TOO_MANY_DEVICES}
    displayErrors={false}
    keyboardAvoiding={false}
  >
    <TooManyDevicesContainer />
  </PageContainer>
);

export default TooManyDevicesPage;
