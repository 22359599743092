import React, { useState, useRef } from "react";
import {
  ScrollView,
  Image,
  StyleProp,
  StyleSheet,
  TextInput,
  View,
  ViewStyle,
} from "react-native";
import { Colors } from "./Colors";
import IconButton from "./IconButton";
import icon from "../assets/adaptive-icon.png";

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
  },
  contentContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 20,
  },
  inputContainer: {
    width: "100%",
    maxWidth: 350,
  },
  icon: {
    width: 250,
    height: 250,
  },
  input: {
    width: "100%",
    height: 45,
    paddingLeft: 10,
    borderWidth: 1,
    borderColor: Colors.DarkGrey,
    backgroundColor: "white",
    borderRadius: 10,
    fontSize: 14,
  },
  email: {
    marginBottom: 20,
  },
  password: {
    marginBottom: 20,
  },
  signInButton: {
    height: 40,
    backgroundColor: Colors.Launch,
    borderRadius: 10,
    justifyContent: "center",
    marginBottom: 10,
  },
  menu: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

export interface Props {
  readonly style?: StyleProp<ViewStyle>;
  readonly loading?: boolean;
  readonly username?: string;
  readonly password?: string;
  readonly onSignIn?: (username: string, password: string) => void;
  readonly onSignUp?: () => void;
  readonly onForgetPassword?: () => void;
}

const Login: React.FunctionComponent<Props> = ({
  style,
  loading,
  username: usernameProps,
  password: passwordProps,
  onSignIn,
  onSignUp,
  onForgetPassword,
}: Props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const usernameRef = useRef<TextInput>(null);
  const passwordRef = useRef<TextInput>(null);
  const loginEnabled = username.length > 0 && password.length > 0;
  const loadingValue = loading ?? false;
  return (
    <ScrollView
      style={[styles.container, style]}
      contentContainerStyle={styles.contentContainer}
    >
      <Image style={styles.icon} source={icon} />
      <View style={[styles.inputContainer, style]}>
        <TextInput
          ref={usernameRef}
          style={[styles.input, styles.email]}
          placeholder="Email"
          autoComplete="email"
          textContentType="emailAddress"
          keyboardType="email-address"
          value={usernameProps ?? username}
          onChangeText={(text) => {
            setUsername(text);
          }}
          onSubmitEditing={() => {
            passwordRef.current?.focus();
          }}
          returnKeyType="next"
          blurOnSubmit={false}
          autoCapitalize="none"
          editable={!loadingValue}
          autoFocus
        />
        <TextInput
          ref={passwordRef}
          style={[styles.input, styles.password]}
          placeholder="Password"
          autoComplete="password"
          textContentType="password"
          value={passwordProps ?? password}
          autoCapitalize="none"
          editable={!loadingValue}
          onChangeText={(text) => {
            setPassword(text);
          }}
          onSubmitEditing={() => {
            onSignIn?.(username, password);
          }}
          secureTextEntry
        />
        <IconButton
          name="sign-in-alt"
          text="SIGN IN"
          color="white"
          size={18}
          style={styles.signInButton}
          loading={loadingValue}
          activityIndicatorColor="white"
          disabled={!loginEnabled}
          onPress={() => {
            onSignIn?.(username, password);
          }}
        />
        <View style={styles.menu}>
          <IconButton
            name="check"
            text="Sign up"
            onPress={() => {
              onSignUp?.();
            }}
            disabled={loadingValue}
          />
          <IconButton
            name="question-circle"
            text="Forgot password"
            onPress={() => {
              onForgetPassword?.();
            }}
            disabled={loadingValue}
          />
        </View>
      </View>
    </ScrollView>
  );
};

export default Login;
