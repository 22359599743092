import { storiesOf } from "@storybook/react-native";
import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import PreviewHeader from "../../../components/Preview/PreviewHeader";

const storyName = "Components/Preview/" + PreviewHeader.name;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  preview: {
    flex: -1,
    borderWidth: 1,
    paddingHorizontal: 20,
  },
});

storiesOf(storyName, module).add("titile", () => (
  <View style={styles.container}>
    <PreviewHeader
      style={styles.preview}
      title={"Rick Astley - Never Gonna Give You Up (Official Music Video)"}
    />
  </View>
));

storiesOf(storyName, module).add("no titile", () => (
  <View style={styles.container}>
    <PreviewHeader style={styles.preview} />
  </View>
));
