import { storiesOf } from "@storybook/react-native";
import React from "react";
import { StyleSheet, View } from "react-native";
import ProgressBar from "../../components/ProgressBar";

const storyName = "Components/" + ProgressBar.name;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  progressBar: {
    width: "80%",
    height: 10,
  },
});

storiesOf(storyName, module).add("empty", () => (
  <View style={styles.container}>
    <ProgressBar style={styles.progressBar} progress={0} />
  </View>
));

storiesOf(storyName, module).add("35%", () => (
  <View style={styles.container}>
    <ProgressBar style={styles.progressBar} progress={0.35} />
  </View>
));

storiesOf(storyName, module).add("50%", () => (
  <View style={styles.container}>
    <ProgressBar style={styles.progressBar} progress={0.5} />
  </View>
));

storiesOf(storyName, module).add("75%", () => (
  <View style={styles.container}>
    <ProgressBar style={styles.progressBar} progress={0.75} />
  </View>
));

storiesOf(storyName, module).add("100%", () => (
  <View style={styles.container}>
    <ProgressBar style={styles.progressBar} progress={1} />
  </View>
));
