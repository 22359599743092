import React, { useState, useRef } from "react";
import {
  ScrollView,
  StyleProp,
  StyleSheet,
  TextInput,
  View,
  ViewStyle,
  Switch,
  Text,
  Linking,
  Platform,
} from "react-native";
import { Colors } from "./Colors";
import IconButton from "./IconButton";
import ThemedSwitch from "./ThemedSwitch";

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 20,
  },
  inputContainer: {
    width: "100%",
    maxWidth: 350,
  },
  input: {
    height: 45,
    paddingLeft: 10,
    borderWidth: 1,
    borderColor: Colors.DarkGrey,
    backgroundColor: "white",
    borderRadius: 10,
    fontSize: 14,
  },
  email: {
    marginBottom: 20,
  },
  password: {
    marginBottom: 20,
  },
  signUpButton: {
    height: 40,
    backgroundColor: Colors.Launch,
    borderRadius: 10,
    justifyContent: "center",
    marginBottom: 10,
  },
  menu: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  termsContainer: {
    flexDirection: "row",
    paddingBottom: 20,
    justifyContent: "space-between",
    alignItems: "center",
  },
  agreeTermsText: {
    paddingLeft: 10,
  },
  termsLink: {
    color: Colors.Launch,
    textDecorationLine: "underline",
  },
});

export interface Props {
  readonly style?: StyleProp<ViewStyle>;
  readonly loading?: boolean;
  readonly onSignUp?: (username: string, password: string) => void;
}

const SignUp: React.FunctionComponent<Props> = ({
  style,
  loading,
  onSignUp,
}: Props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [agreed, setAgreed] = useState(false);
  const usernameRef = useRef<TextInput>(null);
  const passwordRef = useRef<TextInput>(null);
  const loginEnabled = username.length > 0 && password.length > 0 && agreed;
  return (
    <ScrollView
      style={[styles.container, style]}
      contentContainerStyle={styles.contentContainer}
    >
      <View style={styles.inputContainer}>
        <TextInput
          ref={usernameRef}
          style={[styles.input, styles.email]}
          placeholder="Email"
          autoComplete="email"
          textContentType="emailAddress"
          keyboardType="email-address"
          value={username}
          onChangeText={(text) => {
            setUsername(text);
          }}
          onSubmitEditing={() => {
            passwordRef.current?.focus();
          }}
          returnKeyType="next"
          blurOnSubmit={false}
          autoCapitalize="none"
          editable={!(loading || false)}
          autoFocus
        />
        <TextInput
          ref={passwordRef}
          style={[styles.input, styles.password]}
          placeholder="Password"
          autoComplete="password"
          textContentType="password"
          value={password}
          autoCapitalize="none"
          editable={!(loading || false)}
          onChangeText={(text) => {
            setPassword(text);
          }}
          onSubmitEditing={() => {
            onSignUp?.(username, password);
          }}
          secureTextEntry
        />
        <View style={styles.termsContainer}>
          <Text style={styles.agreeTermsText}>
            I've read and agreed to{" "}
            <Text
              style={styles.termsLink}
              onPress={() => {
                const tosURL = "https://monoline.io/terms-of-service";
                if (Platform.OS === "web") {
                  window.open(tosURL, "_blank");
                } else {
                  Linking.openURL(tosURL);
                }
              }}
            >
              Terms of Services
            </Text>
          </Text>
          <ThemedSwitch
            value={agreed}
            onValueChange={(value) => setAgreed(value)}
          />
        </View>
        <IconButton
          name="sign-in-alt"
          text="SIGN UP"
          color="white"
          size={18}
          style={styles.signUpButton}
          loading={loading}
          activityIndicatorColor="white"
          disabled={!loginEnabled}
          onPress={() => {
            onSignUp?.(username, password);
          }}
        />
      </View>
    </ScrollView>
  );
};

export default SignUp;
