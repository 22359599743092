import { useFocusEffect } from "@react-navigation/native";
import React, { useCallback, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AnyAction } from "typescript-fsa";
import routerActions from "../../actions/Router";
import Page, { Props as PageProps } from "../../components/Page";
import { Pages } from "../../routers";
import { useRoute, Route } from "@react-navigation/native";

export type Props = PageProps & {
  readonly name: Pages;
  // Set loading value to True to avoid it navigates away
  readonly loading?: boolean;
  readonly onFocus?: () => void;
  readonly onBlur?: () => void;
  readonly onMount?: (route: Route<Pages>) => void;
  readonly onUnmount?: () => void;
};

const PageContainer: React.FunctionComponent<Props> = ({
  name,
  onFocus,
  onBlur,
  onMount,
  onUnmount,
  ...props
}: Props) => {
  useFocusEffect(
    // Notice: seeing warning
    //
    //     index.js:1 Warning: Do not call Hooks inside useEffect(...), useMemo(...), or other built-in Hooks. You can only call Hooks at the top level of your React function.
    //
    // but this is what was shown in the example here https://reactnavigation.org/docs/use-focus-effect/
    // not sure what's best way to do instead of this :/
    useCallback(() => {
      onFocus?.();
      return () => onBlur?.();
    }, [onFocus, onBlur])
  );

  const onUnmountRef = useRef<() => void | undefined>();
  onUnmountRef.current = onUnmount;

  const route: Route<Pages> = useRoute();

  useEffect(() => {
    onMount?.(route);
    return () => {
      onUnmountRef?.current?.();
    };
  }, []);
  return <Page {...props} />;
};

export const mapDispatchToProps =
  (_: Dispatch<AnyAction>, { name }: Pick<Props, "name">) =>
  (
    dispatch: Dispatch<AnyAction>
  ): Pick<Props, "onFocus" | "onBlur" | "onMount" | "onUnmount"> => ({
    onFocus: () => {
      dispatch(routerActions.onPageFocus(name));
    },
    onBlur: () => {
      dispatch(routerActions.onPageBlur(name));
    },
    onMount: (route: Route<Pages>) => {
      dispatch(routerActions.onPageMount(route));
    },
    onUnmount: () => {
      dispatch(routerActions.onPageUnmount(name));
    },
  });

export default connect(null, mapDispatchToProps)(PageContainer);
