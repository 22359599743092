import React, { useEffect, useRef } from "react";
import { Animated, StyleSheet } from "react-native";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AnyAction } from "typescript-fsa";
import actions from "../../actions/Message";
import ScrollToLatestButton, {
  Props,
} from "../../components/ScrollToLatestButton";
import { State } from "../../states";

interface WrapperProps extends Props {
  readonly displayButton: boolean;
}

function ScrollToLatestButtonWrapper(props: WrapperProps) {
  const fadeAnim = useRef(new Animated.Value(0)).current;
  const { displayButton, ...restProps } = props;
  useEffect(() => {
    Animated.timing(fadeAnim, {
      toValue: displayButton ? 1 : 0,
      duration: 150,
      useNativeDriver: true,
    }).start();
  }, [displayButton]);
  return (
    <Animated.View
      style={[styles.scrollToLatestButtonWrapper, { opacity: fadeAnim }]}
      pointerEvents={displayButton ? "box-none" : "none"}
    >
      <ScrollToLatestButton {...restProps} />
    </Animated.View>
  );
}

const styles = StyleSheet.create({
  scrollToLatestButtonWrapper: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 5,
    height: 30,
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  scrollToLatestButton: {},
});

const mapStateToProps = (
  state: State
): Pick<WrapperProps, "text" | "displayButton"> => {
  return {
    text: "Scroll to bottom",
    displayButton: state.messages.displayScrollToLatestButton,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): Pick<WrapperProps, "onClick"> => {
  return {
    onClick: () => dispatch(actions.onScrollToLatestMessageButtonClicked()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScrollToLatestButtonWrapper);
