import { storiesOf } from "@storybook/react-native";
import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import MenuItemText from "../../../components/Settings/MenuItemText";

const storyName = "Components/Settings/" + MenuItemText.name;

const shortText = "Should buy milk later";
const longText =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
  },
  menuItem: {
    flex: -1,
    width: "100%",
    borderWidth: 1,
  },
});

storiesOf(storyName, module).add("short text", () => {
  const [visible, setVisible] = useState(false);
  setTimeout(() => {
    setVisible(true);
  }, 1000);
  return (
    <View style={styles.container}>
      <MenuItemText
        style={styles.menuItem}
        text={shortText}
        visible={visible}
      />
    </View>
  );
});

storiesOf(storyName, module).add("long text", () => {
  const [visible, setVisible] = useState(false);
  setTimeout(() => {
    setVisible(true);
  }, 1000);
  return (
    <View style={styles.container}>
      <MenuItemText style={styles.menuItem} text={longText} visible={visible} />
    </View>
  );
});

storiesOf(storyName, module).add("short text with icon", () => {
  const [visible, setVisible] = useState(false);
  setTimeout(() => {
    setVisible(true);
  }, 1000);
  return (
    <View style={styles.container}>
      <MenuItemText
        style={styles.menuItem}
        text={shortText}
        visible={visible}
        icon="check-circle"
      />
    </View>
  );
});

storiesOf(storyName, module).add("long text with icon", () => {
  const [visible, setVisible] = useState(false);
  setTimeout(() => {
    setVisible(true);
  }, 1000);
  return (
    <View style={styles.container}>
      <MenuItemText
        style={styles.menuItem}
        text={longText}
        visible={visible}
        icon="check-circle"
      />
    </View>
  );
});

storiesOf(storyName, module).add("hide animation", () => {
  const [visible, setVisible] = useState(true);
  setTimeout(() => {
    setVisible(false);
  }, 1000);
  return (
    <View style={styles.container}>
      <MenuItemText
        style={styles.menuItem}
        text={shortText}
        visible={visible}
        icon="check-circle"
      />
    </View>
  );
});
