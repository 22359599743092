import {
  MessageResponse,
  MessageUpdateRequest,
} from "../services/api/interface";
import { actionCreator } from "./shared";

export enum SystemMessageButtonId {
  DISMISS = "DISMISS",
  YES_SHARE_DATA = "YES_SHARE_DATA",
  NO_SHARE_DATA = "NO_SHARE_DATA",
}

export interface SystemMessageButton {
  readonly id: SystemMessageButtonId;
  readonly text: string;
  readonly icon: string;
}

export interface SystemMessage {
  readonly id: string;
  readonly content: string;
  readonly time: Date;
  readonly buttons?: Array<SystemMessageButton>;
}

export interface CancelEditRequestTapButton {
  readonly type: "tapButton";
}

export interface CancelEditRequestSelectMessage {
  readonly type: "selectMessage";
  readonly id: string;
}

export type CancelEditRequest =
  | CancelEditRequestTapButton
  | CancelEditRequestSelectMessage;

export const prefix = "MESSAGE/";
const actions = {
  addSystemMessage: actionCreator<SystemMessage>(prefix + "ADD_SYSTEM_MESSAGE"),
  deleteConfirmedMessage: actionCreator.async<string, void>(
    prefix + "DELETE_CONFIRMED_MESSAGE"
  ),
  deletePreview: actionCreator.async<
    { messageId: string; previewId: string },
    void
  >(prefix + "DELETE_PREVIEW"),
  scrollToLatestMessage: actionCreator(prefix + "SCROLL_TO_LATEST_MESSAGE"),
  scrollToMessage: actionCreator<string>(prefix + "SCROLL_TO_MESSAGE"),
  updateDisplayScrollToLatestMessageButton: actionCreator<boolean>(
    prefix + "UPDATE_DISPLAY_SCROLL_TO_LATEST_MESSAGE_BUTTON"
  ),
  confirmCancelEditMessage: actionCreator<CancelEditRequest>(
    prefix + "CONFIRM_CANCEL_EDIT_MESSAGE"
  ),
  cancelEditMessage: actionCreator(prefix + "CANCEL_EDIT_MESSAGE"),
  selectMessage: actionCreator<string>(prefix + "SELECT_MESSAGE"),
  deleteWelcomeMessages: actionCreator.async<void, void, void>(
    prefix + "DELETE_WELCOME_MESSAGES"
  ),
  // Component events
  onMessageDeletionAnimationFinish: actionCreator<string>(
    prefix + "ON_MESSAGE_DELETION_ANIMATION_FINISH"
  ),
  onRetryFailedMessage: actionCreator<string>(
    prefix + "ON_RETRY_FAILED_MESSAGE"
  ),
  onDeleteFailedMessage: actionCreator<string>(
    prefix + "ON_DELETE_FAILED_MESSAGE"
  ),
  onMessagePressed: actionCreator<string>(prefix + "ON_MESSAGE_PRESSED"),
  onMessageLinkPressed: actionCreator<{ id: string; url: string }>(
    prefix + "ON_MESSAGE_LINK_PRESSED"
  ),
  onMessageHashtagPressed: actionCreator<{ id: string; hashtag: string }>(
    prefix + "ON_MESSAGE_HASHTAG_PRESSED"
  ),
  onPreviewLinkPressed: actionCreator<{ messageId: string; previewId: string }>(
    prefix + "ON_PREVIEW_LINK_PRESSED"
  ),
  onPreviewDelete: actionCreator<{ messageId: string; previewId: string }>(
    prefix + "ON_PREVIEW_DELETE"
  ),
  onDownloadFileTapped: actionCreator<{ messageId: string; fileId: string }>(
    prefix + "ON_DOWNLOAD_FILE_TAPPED"
  ),
  onDeleteMessageTapped: actionCreator<string>(
    prefix + "ON_DELETE_MESSAGE_TAPPED"
  ),
  onShareMessageTapped: actionCreator<string>(
    prefix + "ON_SHARE_MESSAGE_TAPPED"
  ),
  onEditMessageTapped: actionCreator<string>(prefix + "ON_EDIT_MESSAGE_TAPPED"),
  onEditSaveTapped: actionCreator<string>(prefix + "ON_EDIT_SAVE_TAPPED"),
  onEditCancelTapped: actionCreator<string>(prefix + "ON_EDIT_CANCEL_TAPPED"),
  onEditValueUpdated: actionCreator<string>(prefix + "ON_EDIT_VALUE_UPDATED"),
  onScrollToLatestMessageButtonClicked: actionCreator(
    prefix + "ON_SCROLL_TO_LATEST_MESSAGE_BUTTON_CLICKED"
  ),
  onViewingLatestChanged: actionCreator<boolean>(
    prefix + "ON_VIEWING_LATEST_CHANGED"
  ),
  dismissSystemMessage: actionCreator<string>(
    prefix + "DISMISS_SYSTEM_MESSAGE"
  ),
  onSystemMessageButtonPressed: actionCreator<{ id: string; buttonId: string }>(
    prefix + "ON_SYSTEM_MESSAGE_BUTTON_PRESSED"
  ),
  onSystemMessageLinkPressed: actionCreator<{ id: string; url: string }>(
    prefix + "ON_SYSTEM_MESSAGE_LINK_PRESSED"
  ),
  onSystemMessageDismissAnimationFinish: actionCreator<string>(
    prefix + "ON_SYSTEM_MESSAGE_DISMISS_ANIMATION_FINISH"
  ),
  // API calls
  updateMessage: actionCreator.async<MessageUpdateRequest, MessageResponse>(
    prefix + "UPDATE_MESSAGE"
  ),
  downloadFile: actionCreator.async<
    { messageId: string; fileId: string },
    undefined
  >(prefix + "DOWNLOAD_FILE"),
};
export default actions;
