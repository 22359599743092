import AnalyticsInterface from "./interface";
import Amplitude, { AmplitudeClient } from "amplitude-js";

export default class WebAnalytics implements AnalyticsInterface {
  private readonly apiKey: string;
  private readonly client: AmplitudeClient;
  private collecting: boolean;
  constructor(apiKey: string) {
    this.apiKey = apiKey;
    this.client = Amplitude.getInstance();
    this.collecting = false;
  }

  initialize(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.client.init(this.apiKey, undefined, undefined, () => {
        resolve();
      });
    });
  }

  setCollecting(value: boolean): void {
    this.collecting = value;
  }

  logEvent(event: string): Promise<void> {
    if (!this.collecting) {
      return Promise.resolve();
    }
    return new Promise<void>((resolve, reject) => {
      this.client.logEvent(event, undefined, () => {
        resolve();
      });
    });
  }

  logEventWithProperties(
    event: string,
    properties: Record<string, any>
  ): Promise<void> {
    if (!this.collecting) {
      return Promise.resolve();
    }
    return new Promise<void>((resolve, reject) => {
      this.client.logEvent(event, properties, () => {
        resolve();
      });
    });
  }

  setUserId(userId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.client.setUserId(userId);
      resolve();
    });
  }

  clearUserProperties(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.client.clearUserProperties();
      resolve();
    });
  }
}
