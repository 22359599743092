import { storiesOf } from "@storybook/react-native";
import React from "react";
import { StyleSheet, View } from "react-native";
import ErrorMessageList from "../../../components/Error/ErrorMessageList";

const storyName = "Components/Error/" + ErrorMessageList.name;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  errorMessageList: {
    width: "100%",
    borderWidth: 1,
  },
});

storiesOf(storyName, module).add("list", () => {
  return (
    <View style={styles.container}>
      <ErrorMessageList
        style={styles.errorMessageList}
        errors={[
          {
            id: "error-00",
            message: 'Failed to post message "hello"',
          },
          {
            id: "error-01",
            message: 'Failed to post message "baby"',
          },
          {
            id: "error-02",
            message:
              'Failed to upload file "software-contracting-agreement.pdf", exceed maximum file size limitation 10 MB',
          },
        ]}
      />
    </View>
  );
});
