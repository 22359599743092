import React from "react";
import {
  StyleProp,
  StyleSheet,
  Text,
  View,
  ViewStyle,
  Image,
  ActivityIndicator,
} from "react-native";
import { Colors } from "../Colors";
import logoText from "../../assets/logo-text.png";
import IconButton from "../IconButton";

const styles = StyleSheet.create({
  container: {
    flex: -1,
    alignItems: "center",
  },
  logo: {
    width: 321,
    height: 92,
  },
  checkoutConatiner: {
    flex: -1,
    flexDirection: "column",
    justifyContent: "center",
    marginBottom: 20,
    marginTop: 70,
  },
  opneing: {
    fontWeight: "bold",
    textAlign: "center",
    paddingVertical: 15,
    fontSize: 22,
  },
  checkoutButtonWrapper: {
    height: 40,
    marginHorizontal: 5,
    marginBottom: 10,
  },
  checkoutButton: {
    flex: 1,
    backgroundColor: Colors.Launch,
    color: "white",
    borderRadius: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  fallback: {
    marginVertical: 20,
  },
  spacer: {
    flex: 1,
    flexBasis: 50,
  },
});

export interface Props {
  readonly style?: StyleProp<ViewStyle>;
  readonly onCheckout?: () => void;
}

const SubscriptionView: React.FunctionComponent<Props> = ({
  style,
  onCheckout,
}: Props) => {
  return (
    <View style={[styles.container, style]}>
      <Image style={styles.logo} source={logoText} />
      <View style={styles.checkoutConatiner}>
        <View>
          <Text style={styles.opneing}>Opening checkout page ...</Text>
          <ActivityIndicator />
        </View>
        <Text style={styles.fallback}>
          If it doesn't redirect, please click the following button
        </Text>
        <View style={styles.checkoutButtonWrapper}>
          <IconButton
            style={styles.checkoutButton}
            name="external-link-alt"
            text="Open"
            color="white"
            size={18}
            onPress={onCheckout}
          />
        </View>
      </View>
    </View>
  );
};
export default SubscriptionView;
