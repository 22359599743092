import React, { FunctionComponent } from "react";
import {
  Image,
  ImageSourcePropType,
  StyleProp,
  StyleSheet,
  Text,
  View,
  ViewStyle,
} from "react-native";
import { Colors } from "../Colors";
import NativeLink from "../Markdown/NativeLink";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
  },
  image: {
    height: 60,
    width: 90,
    resizeMode: "contain",
  },
  description: {
    flex: 1,
    padding: 5,
    color: Colors.DarkGrey,
  },
});

export interface Props {
  readonly style?: StyleProp<ViewStyle>;
  readonly thumbnail?: ImageSourcePropType;
  readonly description?: string;
  readonly url: string;
  readonly onLinkPress?: () => void;
}

const PreviewBody: FunctionComponent<Props> = ({
  style,
  thumbnail,
  description,
  url,
  onLinkPress,
}: Props) => (
  <View style={[styles.container, style]}>
    {thumbnail !== undefined ? (
      <NativeLink url={url} onPress={onLinkPress}>
        <Image style={styles.image} source={thumbnail} />
      </NativeLink>
    ) : null}
    <Text style={styles.description}>
      {description !== undefined ? description : "No description"}
    </Text>
  </View>
);

export default PreviewBody;
