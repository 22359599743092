import jwtDecode, { JwtPayload } from "jwt-decode";
import { isNull, omitBy } from "lodash";
import { Platform } from "react-native";
import { from, Observable, of, throwError } from "rxjs";
import { catchError, map, mapTo, mergeMap } from "rxjs/operators";
import { APP_VERSION, BUILD_NUMBER } from "../../shared/constants";
import APIInterface, {
  AuthToken,
  Checkout,
  Credentials,
  DeleteAccountRequest,
  Device,
  DeviceInfo,
  DevicePage,
  DeviceUpdateRequest,
  EventPage,
  FileUploadEvent,
  FileUploadRequest,
  LoginInfo,
  MessageRequest,
  MessageResponse,
  MessageUpdateRequest,
  PortalSession,
  ResetPasswordRequest,
  UpdatePasswordRequest,
  User,
  WebSocketConfig,
} from "./interface";
import {
  parseAccessToken,
  parseAuthToken,
  parseCheckout,
  parseDevice,
  parseDevicePage,
  parseEventPage,
  parseFile,
  parseMessage,
  parsePortalSession,
  parseUser,
  parseWebSocketConfig,
} from "./parsers";

export class APIClientError extends Error {
  readonly response: Response;
  readonly jsonPayload: any;
  constructor(message: string, jsonPaylod: any, response: Response) {
    super(message);
    this.response = response;
    this.jsonPayload = jsonPaylod;
    // Set the prototype explicitly.
    Object.setPrototypeOf(this, APIClientError.prototype);
  }
}

export class XMLHTTPRequestAPIClientError extends Error {
  readonly request: XMLHttpRequest;
  readonly jsonPayload: any;
  constructor(message: string, jsonPaylod: any, request: XMLHttpRequest) {
    super(message);
    this.request = request;
    this.jsonPayload = jsonPaylod;
    // Set the prototype explicitly.
    Object.setPrototypeOf(this, XMLHTTPRequestAPIClientError.prototype);
  }
}

export default class APIClient implements APIInterface {
  private tokenType: string | null = null;
  private accessToken: string | null = null;
  private refreshToken: string | null = null;
  private deviceInfo: DeviceInfo | null = null;
  private tokenUpdateCallback?: (token: AuthToken) => void;
  private readonly baseURL: string;
  private readonly refreshTokenAheadSeconds: number;

  constructor(baseURL: string, refreshTokenAheadSeconds?: number) {
    this.baseURL = baseURL;
    this.refreshTokenAheadSeconds = refreshTokenAheadSeconds ?? 60 * 60 * 24;
  }

  private makeDeviceInfoHeaders(): Record<string, string> {
    const { deviceInfo } = this;
    return omitBy(
      {
        "X-Device-Local-Id": deviceInfo?.localId,
        "X-Device-Name": deviceInfo?.name,
        "X-Device-Brand": deviceInfo?.brand,
        "X-Device-Manufacturer": deviceInfo?.manufacturer,
        "X-Device-Model-Name": deviceInfo?.modelName,
        "X-Device-Model-Id": deviceInfo?.modelId,
        "X-Device-Os-Name": deviceInfo?.osName,
        "X-Device-Os-Version": deviceInfo?.osVersion,
        "X-Device-Os-Build-Id": deviceInfo?.osBuildId,
        "X-Is-Device": deviceInfo?.isDevice,
        "X-Device-Platform": Platform.OS,
      },
      isNull
    ) as Record<string, string>;
  }

  setDeviceInfo(deviceInfo: DeviceInfo): void {
    this.deviceInfo = deviceInfo;
  }

  setTokenUpdateCallback(tokenUpdateCallback?: (token: AuthToken) => void) {
    this.tokenUpdateCallback = tokenUpdateCallback;
  }

  setAuthInfo(
    tokenType: string | null,
    accessToken: string | null,
    refreshToken: string | null
  ) {
    this.tokenType = tokenType;
    this.accessToken = accessToken;
    this.refreshToken = refreshToken;
  }

  ensureTokenRefreshed(): Observable<void> {
    // TODO: maybe need to avoid race condition, find a way to limit and only allow
    //       refreshing token once a time
    return of(undefined).pipe(
      mergeMap(() => {
        const { exp } = jwtDecode<JwtPayload>(this.accessToken!);
        const timestamp = Date.now();
        const secondsTillExpire = exp! - timestamp / 1000;
        if (secondsTillExpire > this.refreshTokenAheadSeconds) {
          return of(undefined);
        }
        console.info(
          "Refreshing token with",
          "secondsTillExpire=",
          secondsTillExpire
        );
        const request = this.makeRequest("/api/v1/login/refresh", {
          method: "POST",
          headers: {
            Authorization: `${this.tokenType} ${this.refreshToken}`,
            ...this.makeDeviceInfoHeaders(),
          },
          noAuth: true,
        });
        return from(fetch(request)).pipe(
          mergeMap((response) => {
            if (response.status >= 400) {
              return from(response.json()).pipe(
                catchError((error) => {
                  console.warn("Failed to parse error JSON response", error);
                  return of(null);
                }),
                mergeMap((jsonPayload) => {
                  console.warn(
                    `API call failed with bad status code ${response.status}`,
                    request,
                    jsonPayload
                  );
                  return throwError(
                    new APIClientError(
                      `API call failed with bad status code ${response.status} returned from server`,
                      jsonPayload,
                      response
                    )
                  );
                })
              );
            }
            return response.json();
          }),
          map(parseAccessToken),
          map((token) => {
            this.accessToken = token.accessToken;
            this.tokenType = token.tokenType;
            this.tokenUpdateCallback?.({
              accessToken: this.accessToken,
              tokenType: this.tokenType,
              refreshToken: this.refreshToken!,
            });
            console.info("Token refreshed");
            return undefined;
          }),
          catchError((error) => {
            console.warn("Failed to parse error JSON response", error);
            return of(undefined);
          })
        );
      })
    );
  }

  sendRequest(
    makeRequest: () => Request,
    options?: {
      returnResponse?: boolean;
      refreshToken?: boolean;
    }
  ): Observable<any> {
    const ensureTokenRefreshed =
      options?.refreshToken ?? true
        ? this.ensureTokenRefreshed()
        : of(undefined);
    return ensureTokenRefreshed.pipe(
      mergeMap(() => {
        const request = makeRequest();
        return from(fetch(request)).pipe(
          mergeMap((response) => {
            if (response.status >= 400) {
              return from(response.json()).pipe(
                catchError((error) => {
                  console.warn("Failed to parse error JSON response", error);
                  return of(null);
                }),
                mergeMap((jsonPayload) => {
                  console.warn(
                    `API call failed with bad status code ${response.status}`,
                    request,
                    jsonPayload
                  );
                  return throwError(
                    new APIClientError(
                      `API call failed with bad status code ${response.status} returned from server`,
                      jsonPayload,
                      response
                    )
                  );
                })
              );
            }
            if (options?.returnResponse ?? false) {
              return of(response);
            }
            return response.json();
          })
        );
      })
    );
  }

  makeRequest(
    path: string,
    options?: {
      params?: URLSearchParams;
      body?: BodyInit_;
      method?: string;
      headers?: Record<string, string>;
      noAuth?: boolean;
    }
  ): Request {
    const headers: Record<string, string> = {
      Accept: "application/json",
      "X-App-Version": APP_VERSION,
      "X-App-Build": BUILD_NUMBER,
    };

    if (options?.body !== undefined) {
      if (options.body instanceof URLSearchParams) {
        headers["Content-Type"] = "application/x-www-form-urlencoded";
        // Need to convert the body to a string as for mobile devices,
        // passing a native URLSearchParams is not supported
        options.body = options.body.toString();
      } else if (options?.body instanceof FormData) {
        // Let the RN fetch implementation generate the multi-part header for us
      } else {
        headers["Content-Type"] = "application/json";
      }
    }
    const { tokenType: authType, accessToken: authToken } = this;
    if (
      !(options?.noAuth ?? false) &&
      authType !== null &&
      authToken !== null
    ) {
      headers["Authorization"] = `${authType} ${authToken}`;
    }
    const suffix =
      options?.params !== undefined ? "?" + options.params.toString() : "";
    const url = new URL(path, this.baseURL);
    return new Request(url.href + suffix, {
      body: options?.body,
      method: options?.method,
      headers: { ...headers, ...(options?.headers ?? {}) },
    });
  }

  login({ credentials }: LoginInfo): Observable<AuthToken> {
    return this.sendRequest(
      () =>
        this.makeRequest("/api/v1/login/access-token", {
          method: "POST",
          body: new URLSearchParams({
            username: credentials.username,
            password: credentials.password,
          }),
          headers: this.makeDeviceInfoHeaders(),
          noAuth: true,
        }),
      { refreshToken: false }
    ).pipe(map(parseAuthToken));
  }

  signUp(credentials: Credentials): Observable<User> {
    return this.sendRequest(
      () =>
        this.makeRequest("/api/v1/users/", {
          method: "POST",
          body: JSON.stringify({
            email: credentials.username,
            password: credentials.password,
          }),
          noAuth: true,
        }),
      { refreshToken: false }
    ).pipe(map(parseUser));
  }

  updatePassword(request: UpdatePasswordRequest): Observable<User> {
    return this.sendRequest(() =>
      this.makeRequest("/api/v1/users/me", {
        method: "PUT",
        body: JSON.stringify({
          current_password: request.currentPassword,
          password: request.newPassword,
        }),
      })
    ).pipe(map(parseUser));
  }

  updateSettings(settings: Record<string, any>): Observable<User> {
    return this.sendRequest(() =>
      this.makeRequest("/api/v1/users/me/settings", {
        method: "PUT",
        body: JSON.stringify({
          settings,
        }),
      })
    ).pipe(map(parseUser));
  }

  forgotPassword(email: string): Observable<void> {
    return this.sendRequest(
      () =>
        this.makeRequest("/api/v1/users/forgot_password", {
          method: "POST",
          body: JSON.stringify({
            email,
          }),
          noAuth: true,
        }),
      { refreshToken: false }
    ).pipe(mapTo(undefined));
  }

  checkResetPasswordToken(token: string): Observable<void> {
    return this.sendRequest(
      () =>
        this.makeRequest("/api/v1/users/reset_password", {
          method: "GET",
          params: new URLSearchParams({
            token,
          }),
          noAuth: true,
        }),
      { refreshToken: false }
    ).pipe(mapTo(undefined));
  }

  resetPassword({ token, password }: ResetPasswordRequest): Observable<void> {
    return this.sendRequest(
      () =>
        this.makeRequest("/api/v1/users/reset_password", {
          method: "POST",
          params: new URLSearchParams({
            token,
          }),
          body: JSON.stringify({
            password,
          }),
          noAuth: true,
        }),
      { refreshToken: false }
    ).pipe(mapTo(undefined));
  }

  getStreamEvents(
    eventStreamId: string,
    lastSequenceId: number
  ): Observable<EventPage> {
    return this.sendRequest(() =>
      this.makeRequest(`/api/v1/event_streams/events/${eventStreamId}`, {
        params: new URLSearchParams({
          last_sequence_id: lastSequenceId.toString(),
        }),
      })
    ).pipe(map(parseEventPage));
  }

  getCurrentUser(): Observable<User> {
    return this.sendRequest(() => this.makeRequest("/api/v1/users/me")).pipe(
      map(parseUser)
    );
  }

  getCurrentDevice(): Observable<Device> {
    return this.sendRequest(() =>
      this.makeRequest("/api/v1/devices/current")
    ).pipe(map(parseDevice));
  }

  listDevices(): Observable<DevicePage> {
    return this.sendRequest(() => this.makeRequest("/api/v1/devices/")).pipe(
      map(parseDevicePage)
    );
  }

  updateDevice(request: DeviceUpdateRequest): Observable<Device> {
    return this.sendRequest(() =>
      this.makeRequest("/api/v1/devices/current", {
        method: "PUT",
        body: JSON.stringify({
          notification_token: request.notificationToken,
          notification_environment: request.notificationEnvironment,
          notification_enabled: request.notificationEnabled,
        }),
      })
    ).pipe(map(parseDevice));
  }

  signOutDevice(id: string): Observable<Device> {
    return this.sendRequest(() =>
      this.makeRequest(`/api/v1/devices/sign_out/${id}`, { method: "POST" })
    ).pipe(map(parseDevice));
  }

  createMessage(request: MessageRequest): Observable<MessageResponse> {
    return this.sendRequest(() =>
      this.makeRequest("/api/v1/messages/", {
        method: "POST",
        body: JSON.stringify({
          content: request.content,
          local_id: request.localId,
          ...(request.files === undefined
            ? {}
            : { file_ids: request.files.map((file) => file.id) }),
          ...(request.previewURLs === undefined
            ? {}
            : { preview_urls: request.previewURLs }),
        }),
      })
    ).pipe(map(parseMessage));
  }

  updateMessage(request: MessageUpdateRequest): Observable<MessageResponse> {
    return this.sendRequest(() =>
      this.makeRequest(`/api/v1/messages/${request.id}`, {
        method: "PUT",
        body: JSON.stringify({
          content: request.content,
          ...(request.previewURLs === undefined
            ? {}
            : { preview_urls: request.previewURLs }),
        }),
      })
    ).pipe(map(parseMessage));
  }

  deleteMessage(id: string): Observable<void> {
    return this.sendRequest(
      () =>
        this.makeRequest(`/api/v1/messages/${id}`, {
          method: "DELETE",
        }),
      { returnResponse: true }
    ).pipe(mapTo(undefined));
  }

  deleteSystemMessages(tag: string): Observable<void> {
    return this.sendRequest(
      () =>
        this.makeRequest(`/api/v1/messages/system_messages/${tag}`, {
          method: "DELETE",
        }),
      { returnResponse: true }
    ).pipe(mapTo(undefined));
  }

  createFile(request: FileUploadRequest): Observable<FileUploadEvent> {
    return this.ensureTokenRefreshed().pipe(
      mergeMap(() => {
        return new Observable<FileUploadEvent>((observer) => {
          const formData = new FormData();
          // Notice: for utf8 filename, it seems like we will see multi-part
          //         body parsing sissue. so just set a dummy filename here,
          //         the file name will be coming from `details.name`
          formData.append("file", request.data, "uploaded_file");
          formData.append(
            "details",
            JSON.stringify({
              local_id: request.localId,
              name: request.name,
            })
          );
          const url = new URL("/api/v1/files/", this.baseURL);
          const { tokenType: authType, accessToken: authToken } = this;
          // fetch() doesn't provide upload progress, use XMLHttpRequest here for now
          const httpRequest = new XMLHttpRequest();
          // 3 minutes
          httpRequest.timeout = 3 * 60 * 1000;
          httpRequest.addEventListener("readystatechange", (event) => {
            if (httpRequest.status >= 400) {
              let payload: any = null;
              try {
                payload = JSON.parse(httpRequest.responseText);
              } catch (error) {
                console.warn("Cannot parse HTTP request response text", error);
              }
              console.warn(
                `Create file failed with bad status code ${httpRequest.status} ${httpRequest.statusText}`,
                request,
                payload,
                httpRequest
              );
              observer.error(
                new XMLHTTPRequestAPIClientError(
                  `Create file failed with bad status code ${httpRequest.status} ${httpRequest.statusText} returned from server`,
                  payload,
                  httpRequest
                )
              );
              return;
            }
          });
          httpRequest.addEventListener("load", (event) => {
            const payload = JSON.parse(httpRequest.responseText);
            const file = parseFile(payload);
            observer.next({ ...file, type: "done" } as FileUploadEvent);
            observer.complete();
          });
          httpRequest.addEventListener("error", (event) => {
            console.warn(
              "Create file failed with unknown error",
              request,
              httpRequest
            );
            observer.error(
              new XMLHTTPRequestAPIClientError(
                "Create file failed with unknown error",
                null,
                httpRequest
              )
            );
          });
          httpRequest.addEventListener("timeout", (event) => {
            console.warn("Create file timeout", request, httpRequest);
            observer.error(
              new XMLHTTPRequestAPIClientError(
                "Create file timeout",
                null,
                httpRequest
              )
            );
          });
          httpRequest.upload.addEventListener("progress", (event) => {
            observer.next({
              type: "progress",
              loaded: event.loaded,
              total: event.total,
            });
          });
          httpRequest.open("POST", url.href);
          httpRequest.setRequestHeader(
            "Authorization",
            `${authType} ${authToken}`
          );
          httpRequest.send(formData);
        });
      })
    );
  }

  downloadFile(id: string): Observable<string> {
    return this.sendRequest(() =>
      this.makeRequest(`/api/v1/files/${id}/download`)
    ).pipe(map((resp) => resp.download_url));
  }

  createFeedback(content: string): Observable<void> {
    return this.sendRequest(() =>
      this.makeRequest("/api/v1/feedbacks/", {
        method: "POST",
        body: JSON.stringify({
          content,
        }),
      })
    ).pipe(mapTo(undefined));
  }

  getWebSocketConfig(): Observable<WebSocketConfig> {
    return this.sendRequest(() =>
      this.makeRequest("/api/v1/event_streams/web_socket_config")
    ).pipe(map(parseWebSocketConfig));
  }

  createCheckout(): Observable<Checkout> {
    return this.sendRequest(() =>
      this.makeRequest("/api/v1/subscriptions/checkout", {
        method: "POST",
        body: JSON.stringify({}),
      })
    ).pipe(map(parseCheckout));
  }

  createPortalSession(): Observable<PortalSession> {
    return this.sendRequest(() =>
      this.makeRequest("/api/v1/payment/stripe-portal-session", {
        method: "POST",
      })
    ).pipe(map(parsePortalSession));
  }

  deletePreview(id: string): Observable<void> {
    return this.sendRequest(
      () =>
        this.makeRequest(`/api/v1/previews/${id}`, {
          method: "DELETE",
        }),
      { returnResponse: true }
    ).pipe(mapTo(undefined));
  }

  deleteAccount(request: DeleteAccountRequest): Observable<void> {
    return this.sendRequest(
      () =>
        this.makeRequest("/api/v1/users/me/delete", {
          method: "PUT",
          body: JSON.stringify({
            password: request.password,
            ...(request.feedback !== undefined &&
            request.feedback.trim().length > 0
              ? { feedback: request.feedback }
              : {}),
          }),
        }),
      { returnResponse: true }
    ).pipe(mapTo(undefined));
  }
}
