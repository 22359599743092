import React, { useState, useRef } from "react";
import {
  StyleProp,
  StyleSheet,
  Text,
  View,
  ViewStyle,
  Image,
  TextInput,
  ScrollView,
} from "react-native";
import { Colors } from "./Colors";
import logoText from "../assets/logo-text.png";
import IconButton from "./IconButton";
import ThemedSwitch from "./ThemedSwitch";

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 20,
  },
  inputContainer: {
    width: "100%",
    maxWidth: 350,
    alignItems: "center",
  },
  logo: {
    width: 314,
    height: 90,
  },
  formConatiner: {
    flex: -1,
    flexDirection: "column",
    justifyContent: "center",
    marginBottom: 20,
    marginTop: 20,
  },
  input: {
    height: 45,
    paddingLeft: 10,
    borderWidth: 1,
    borderColor: Colors.DarkGrey,
    backgroundColor: "white",
    borderRadius: 10,
    fontSize: 14,
  },
  password: {
    marginBottom: 20,
    marginTop: 20,
  },
  feedback: {
    marginBottom: 20,
    height: 100,
    paddingTop: 10,
  },
  submitButtonWrapper: {
    height: 40,
    marginHorizontal: 5,
    marginBottom: 10,
  },
  submitButton: {
    flex: 1,
    backgroundColor: Colors.Danger,
    color: "white",
    borderRadius: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  spacer: {
    flex: 1,
    flexBasis: 50,
  },
  accountEmailHint: {
    marginTop: 20,
  },
  accountEmail: {
    fontWeight: "bold",
  },
  agreeWrapper: {
    flexDirection: "row",
    marginBottom: 20,
    alignItems: "center",
  },
  agreeSwitch: {},
  agreeText: { marginRight: 20, fontWeight: "bold", color: Colors.Danger },
});

export interface Props {
  readonly style?: StyleProp<ViewStyle>;
  readonly email: string;
  readonly loading?: boolean;
  readonly onSubmit?: (password: string, feedback: string) => void;
}

const DeleteAccount: React.FunctionComponent<Props> = ({
  style,
  email,
  loading,
  onSubmit,
}: Props) => {
  const [password, setPassword] = useState("");
  const [feedback, setFeedback] = useState("");
  const [agreed, setAgreed] = useState(false);
  const submitEnabled = agreed && password.length > 0;
  const feedbackRef = useRef<TextInput>(null);
  const loadingValue = loading || false;
  return (
    <ScrollView
      style={[styles.container, style]}
      contentContainerStyle={styles.contentContainer}
    >
      <View style={styles.inputContainer}>
        <Image style={styles.logo} source={logoText} resizeMode="contain" />
        <View style={styles.formConatiner}>
          <Text>
            Sorry to see you go. To continue deleting your account, please use
            the form below. Please note that some of your data may remain in our
            system cache or backup for up to one week after deletion.
          </Text>
          <Text style={styles.accountEmailHint}>
            Account to delete: <Text style={styles.accountEmail}>{email}</Text>
          </Text>
          <TextInput
            style={[styles.input, styles.password]}
            placeholder="Your password"
            autoComplete="password"
            textContentType="password"
            value={password}
            onChangeText={(text) => {
              setPassword(text);
            }}
            onSubmitEditing={() => {
              feedbackRef.current?.focus();
            }}
            returnKeyType="next"
            autoCapitalize="none"
            editable={!loadingValue}
            secureTextEntry
            autoFocus
          />
          <TextInput
            ref={feedbackRef}
            style={[styles.input, styles.feedback]}
            placeholder="Feedback (optional)"
            value={feedback}
            onChangeText={(text) => {
              setFeedback(text);
            }}
            maxLength={5000}
            returnKeyType="done"
            autoCapitalize="none"
            editable={!loadingValue}
            multiline
          />
          <View style={styles.agreeWrapper}>
            <Text style={styles.agreeText}>
              I understand that once the account is deleted, there's no way to
              recover the account's data.
            </Text>
            <ThemedSwitch
              style={styles.agreeSwitch}
              value={agreed}
              onValueChange={(value) => setAgreed(value)}
            />
          </View>
          <View style={styles.submitButtonWrapper}>
            <IconButton
              disabled={!submitEnabled}
              style={styles.submitButton}
              name="trash-alt"
              text="Delete my account"
              color="white"
              size={18}
              loading={loadingValue}
              onPress={() => onSubmit?.(password, feedback)}
            />
          </View>
        </View>
      </View>
    </ScrollView>
  );
};
export default DeleteAccount;
