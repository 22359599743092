// Copyright 2018 Twitter, Inc.
// Licensed under the Apache License, Version 2.0
// http://www.apache.org/licenses/LICENSE-2.0

import hashSigns from "./hashSigns";
import hashtagAlphaNumeric from "./hashtagAlphaNumeric";
import regexSupplant from "./regexSupplant";

const validHashtag = regexSupplant(
  /(#{hashSigns})(?!\uFE0F|\u20E3)(#{hashtagAlphaNumeric}+)/i,
  { hashSigns, hashtagAlphaNumeric }
);

export default validHashtag;
