import React, { FunctionComponent, useEffect, useRef } from "react";
import {
  Animated,
  Easing,
  StyleProp,
  StyleSheet,
  Text,
  View,
  ViewStyle,
} from "react-native";
import { Colors } from "../Colors";
import IconButton from "../IconButton";

const styles = StyleSheet.create({
  animatedContainer: {},
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: 10,
    paddingHorizontal: 10,
  },
  hintText: {
    color: Colors.MediumGrey,
  },
  keywordTitle: {
    fontWeight: "bold",
    color: "black",
  },
  keywordText: {
    color: "black",
  },
});

export interface Props {
  readonly keywords?: string;
  readonly visible?: boolean;
  readonly style?: StyleProp<ViewStyle>;
  readonly onCancel?: () => void;
}

const SearchBar: FunctionComponent<Props> = ({
  style,
  keywords,
  visible,
  onCancel,
}: Props) => {
  const height = 40;
  const animatedProgress = useRef(new Animated.Value(0)).current;
  const animatedHeight = useRef(new Animated.Value(height)).current;
  const easing = Easing.inOut(Easing.cubic);
  const duration = 300;

  useEffect(() => {
    Animated.timing(animatedProgress, {
      toValue: visible ?? false ? 1 : 0,
      easing,
      duration,
      useNativeDriver: false,
    }).start();
  }, [visible]);

  useEffect(() => {
    Animated.timing(animatedHeight, {
      toValue: visible ?? false ? height : 0,
      easing,
      duration,
      useNativeDriver: false,
    }).start();
  }, [visible, height]);

  return (
    <Animated.View
      style={[
        styles.animatedContainer,
        {
          opacity: animatedProgress,
          height: animatedHeight,
        },
        style,
      ]}
    >
      <View style={styles.container}>
        <Text>
          {keywords === undefined ? (
            <Text style={styles.hintText}>Submit keywords to search</Text>
          ) : (
            <>
              <Text style={styles.keywordTitle}>Keywords: </Text>
              <Text style={styles.keywordText}>{keywords}</Text>
            </>
          )}
        </Text>
        <IconButton
          name="times-circle"
          text="Cancel"
          color={Colors.Danger}
          onPress={onCancel}
        />
      </View>
    </Animated.View>
  );
};

export default SearchBar;
