import React from "react";
import { Pages } from "../../routers";
import PageContainer from "../Page/PageContainer";
import ForgotPasswordContainer from "./ForgotPasswordContainer";

const ForgotPasswordPage: React.FunctionComponent = () => (
  <PageContainer name={Pages.FORGOT_PASSWORD}>
    <ForgotPasswordContainer />
  </PageContainer>
);

export default ForgotPasswordPage;
