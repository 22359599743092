import { storiesOf } from "@storybook/react-native";
import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import ThemedSwitch from "../../components/ThemedSwitch";

const storyName = "Components/" + ThemedSwitch.name;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
});

storiesOf(storyName, module).add("themed switch", () => {
  const [value, setValue] = useState(false);
  return (
    <View style={styles.container}>
      <ThemedSwitch value={value} onValueChange={setValue} />
    </View>
  );
});
