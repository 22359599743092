import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AnyAction } from "typescript-fsa";
import inputActions from "../../actions/Input";
import { Mode } from "../../components/File/File";
import UploadingList, { Props } from "../../components/File/UploadingList";
import { BuildInCommandType, State, UploadingFileState } from "../../states";

const mapStateToProps = (
  state: State
): Pick<Props, "files" | "visible" | "addButtonDisabled"> => ({
  files: state.input.fileIds.map((id) => {
    const file = state.input.files[id];
    return {
      id,
      icon: file.icon,
      fileName: file.name,
      progress: file.progress,
      mode: (() => {
        switch (file.state) {
          case UploadingFileState.UPLOADING: {
            return Mode.UPLOADING;
          }
          case UploadingFileState.UPLOADED: {
            return Mode.UPLOADED;
          }
          case UploadingFileState.FAILED: {
            return Mode.FAILED;
          }
        }
      })(),
    };
  }),
  visible: state.input.activeCommandMode === BuildInCommandType.UPLOAD,
  addButtonDisabled: state.input.fileIds.length >= 5,
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): Pick<Props, "onAdd" | "onCancel" | "onDelete"> => {
  return {
    onAdd: () => {
      dispatch(inputActions.addFileTapped());
    },
    onCancel: () => {
      dispatch(inputActions.onCancelUploadingTapped());
    },
    onDelete: (id: string) => {
      dispatch(inputActions.onDeleteFileTapped(id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadingList);
