import React from "react";
import { Pages } from "../../routers";
import PageContainer from "../Page/PageContainer";
import AccountContainer from "./AccountContainer";

const AccountPage: React.FunctionComponent = () => (
  <PageContainer
    name={Pages.SETTINGS_ACCOUNT}
    displayErrors={true}
    keyboardAvoiding={false}
  >
    <AccountContainer />
  </PageContainer>
);

export default AccountPage;
