import { storiesOf } from "@storybook/react-native";
import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import { Colors } from "../../../components/Colors";
import SystemMessage from "../../../components/Message/SystemMessage";

const storyName = "Components/Message/SystemMessage";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
  },
  message: {
    flex: -1,
    width: "100%",
    borderWidth: 1,
  },
});

const shortText = "Should buy milk later";
const longText =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.";
const markdownCode = `## Important

- First
- Second
- Third

> To eat or not to eat

`;

storiesOf(storyName, module).add("short", () => (
  <View style={styles.container}>
    <SystemMessage
      id=""
      style={styles.message}
      message={shortText}
      buttons={[
        {
          id: "dismiss",
          icon: "times",
          text: "DISMISS",
        },
      ]}
    />
  </View>
));

storiesOf(storyName, module).add("long", () => (
  <View style={styles.container}>
    <SystemMessage
      id=""
      style={styles.message}
      message={longText}
      buttons={[
        {
          id: "dismiss",
          icon: "times",
          text: "DISMISS",
        },
      ]}
    />
  </View>
));

storiesOf(storyName, module).add("markdown", () => (
  <View style={styles.container}>
    <SystemMessage
      id=""
      style={styles.message}
      message={markdownCode}
      buttons={[
        {
          id: "dismiss",
          icon: "times",
          text: "DISMISS",
        },
      ]}
    />
  </View>
));

storiesOf(storyName, module).add("multiple buttons", () => (
  <View style={styles.container}>
    <SystemMessage
      id=""
      style={styles.message}
      message={shortText}
      buttons={[
        {
          id: "yes",
          icon: "check",
          text: "YES",
          style: {
            backgroundColor: Colors.DarkGrey,
          },
        },
        {
          id: "no",
          icon: "times",
          text: "NO",
          style: {
            backgroundColor: Colors.DarkGrey,
          },
        },
      ]}
    />
  </View>
));

storiesOf(storyName, module).add("dismissing", () => {
  const [dissmissing, setDismissing] = useState<boolean>(false);
  setTimeout(() => setDismissing(true), 1000);
  return (
    <View style={styles.container}>
      <SystemMessage
        id=""
        style={styles.message}
        message={longText}
        dismissing={dissmissing}
        buttons={[
          {
            id: "dismiss",
            icon: "times",
            text: "DISMISS",
          },
        ]}
      />
    </View>
  );
});
