import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AnyAction } from "typescript-fsa";
import signUpActions from "../../actions/SignUp";
import SignUp, { Props } from "../../components/SignUp";
import { State } from "../../states";

const mapStateToProps = (state: State): Pick<Props, "loading"> => ({
  loading: state.signUp.loading,
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): Pick<Props, "onSignUp"> => {
  return {
    onSignUp: (username: string, password: string) => {
      dispatch(signUpActions.onSignUp({ username, password }));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
