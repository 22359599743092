import { action } from "@storybook/addon-actions";
import { storiesOf } from "@storybook/react-native";
import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import InputBar from "../../../components/Input/InputBar";

const storyName = "Components/Input/" + InputBar.name;

const placeholder = "Type something here";
const shortText = "Some text here";
const longText =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.";
const multipleLineText = `- First
- Second
- Third
- Forth`;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
  },
  inputBar: {
    paddingLeft: 10,
    paddingRight: 5,
    paddingTop: 10,
    paddingBottom: 45,
  },
  backgroundMessage: {
    position: "absolute",
    left: 0,
    right: 0,
  },
});

storiesOf(storyName, module).add("with placeholder", () => (
  <View style={styles.container}>
    <InputBar
      value=""
      placeholder={placeholder}
      style={styles.inputBar}
      onSend={() => action("send-click")}
      onMenu={() => action("menu-click")}
      onChangeText={() => {}}
    />
  </View>
));

storiesOf(storyName, module).add("with short text", () => (
  <View style={styles.container}>
    <InputBar
      value={shortText}
      placeholder={placeholder}
      style={styles.inputBar}
      onSend={() => action("send-click")}
      onMenu={() => action("menu-click")}
      onChangeText={() => {}}
    />
  </View>
));

storiesOf(storyName, module).add("with long text", () => (
  <View style={styles.container}>
    <InputBar
      value={longText}
      placeholder={placeholder}
      style={styles.inputBar}
      onSend={() => action("send-click")}
      onMenu={() => action("menu-click")}
      onChangeText={() => {}}
    />
  </View>
));

storiesOf(storyName, module).add("with mutiline text", () => (
  <View style={styles.container}>
    <InputBar
      value={multipleLineText}
      placeholder={placeholder}
      style={styles.inputBar}
      onSend={() => action("send-click")}
      onMenu={() => action("menu-click")}
      onChangeText={() => {}}
    />
  </View>
));

storiesOf(storyName, module).add("change icon", () => {
  const [icon, setIcon] = useState<string | undefined>(undefined);
  setTimeout(() => setIcon("file-upload"), 1000);
  return (
    <View style={styles.container}>
      <InputBar
        value={shortText}
        placeholder={placeholder}
        style={styles.inputBar}
        icon={icon}
        onSend={() => action("send-click")}
        onMenu={() => action("menu-click")}
        onChangeText={() => {}}
      />
    </View>
  );
});
