import {
  Device,
  DevicePage,
  DeviceUpdateRequest,
} from "../services/api/interface";
import { actionCreator } from "./shared";

export const prefix = "DEVICE/";
const actions = {
  loadCurrentDevice: actionCreator.async<void, Device>(
    prefix + "LOAD_CURRENT_DEVICE"
  ),
  listDevices: actionCreator.async<void, DevicePage>(prefix + "LIST_DEVICES"),
  signOutDevice: actionCreator.async<string, Device>(
    prefix + "SIGN_OUT_DEVICE"
  ),
  updateDevice: actionCreator.async<DeviceUpdateRequest, Device>(
    prefix + "UPDATE_DEVICE"
  ),
  onSignOutDevice: actionCreator<string>(prefix + "ON_SIGN_OUT_DEVICE"),
  onLoadDevices: actionCreator(prefix + "ON_LOAD_DEVICES"),
  onUpgradePress: actionCreator(prefix + "ON_UPGRADE_PRESS"),
};
export default actions;
