import { groupBy, sortBy } from "lodash";
import { Platform } from "react-native";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { createSelector } from "reselect";
import { AnyAction } from "typescript-fsa";
import messageActions from "../../actions/Message";
import MessageList, {
  DateSection,
  Props,
} from "../../components/Message/MessageList";
import { KeywordType, parseSearchKeywords } from "../../shared/utils";
import { Message, MessageUnion, State } from "../../states";

const getMessageIdsState = (state: State) => state.messages.messageIds;
const getMessages = (state: State) => state.messages.messages;
const getSearchKeywords = (state: State) => state.messages.searchKeywords;

export const getSections = createSelector(
  [getMessageIdsState, getMessages, getSearchKeywords],
  (
    messageIds: Array<string>,
    messages: Record<string, MessageUnion>,
    searchKeywords: string | null
  ) => {
    const keywords =
      searchKeywords !== null ? parseSearchKeywords(searchKeywords) : null;
    const filteredMessageIds =
      keywords !== null
        ? messageIds.filter(
            (messageId) =>
              messages[messageId].type === "system" ||
              keywords.every((keyword) => {
                const msg = messages[messageId] as Message;
                switch (keyword.type) {
                  case KeywordType.HASHTAG: {
                    return (
                      keyword.value.toLowerCase() in msg.searchIndex.hashtags
                    );
                  }
                  case KeywordType.WORD: {
                    return msg.searchIndex.text
                      .toLowerCase()
                      .includes(keyword.value.toLowerCase());
                  }
                }
              })
          )
        : messageIds;

    let result = sortBy(
      Object.entries(
        groupBy(filteredMessageIds, (messageId) => {
          const message = messages[messageId];
          const localDate = new Date(
            message.timestamp.getFullYear(),
            message.timestamp.getMonth(),
            message.timestamp.getDate()
          );
          return localDate.getTime().toString();
        })
      ),
      // Sort by date
      ([date, _]) => parseInt(date)
    ).map(
      ([date, messageIds]) =>
        ({
          date: new Date(parseInt(date)),
          data: messageIds,
        } as DateSection)
    );

    if (Platform.OS !== "web") {
      // For mobile device, we are using reversed section list instead
      result = result
        .map((section) => ({ ...section, data: section.data.reverse() }))
        .reverse();
    }

    return result;
  }
);

const mapStateToProps = (
  state: State
): Pick<Props, "sections" | "scrollCounter" | "scrollTarget" | "ready"> => {
  // TODO: cache this section breaking process, otherwise it would be slow if the number
  //       of messages is big
  // TODO: another way is to move this logic to reducer
  return {
    sections: getSections(state),
    scrollCounter: state.messages.scrollCounter,
    scrollTarget: state.messages.scrollTarget,
    ready: state.messages.readyStatus,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): Pick<Props, "onViewingLatestChanged"> => {
  return {
    onViewingLatestChanged: (viewingLatest: boolean) => {
      dispatch(messageActions.onViewingLatestChanged(viewingLatest));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageList);
