import { reducerWithInitialState } from "typescript-fsa-reducers";
import actions from "../actions/Input";
import loginActions from "../actions/Login";
import { typingHashtagRegex } from "../epics/input";
import { commonPrefixLength } from "../shared/utils";
import { BuildInCommandType, InputState, UploadingFileState } from "../states";
import { lookupIcon } from "./helpers";

const defaultState: InputState = {
  value: "",
  files: {},
  fileIds: [],
  displayCommands: false,
  activeCommandMode: null,
  focusCounter: 0,
  displayHashtags: false,
  matchedHashtags: [],
  matchedCommandIds: [],
  matchedHashtagPrefixLength: 0,
  commands: {
    [BuildInCommandType.SEARCH]: {
      icon: "search",
      command: "/search",
      parameters: ["KEYWORD"],
      description: "Search messages",
      inputMode: true,
      placeholder: "Type search keywords here",
      returnKeyType: "search",
      resetAfterSbumit: false,
      multiline: false,
    },
    [BuildInCommandType.UPLOAD]: {
      icon: "file-upload",
      command: "/upload",
      parameters: ["[COMMENT]"],
      description: "Upload files",
      inputMode: true,
      placeholder: "Comment your files here (optional)",
    },
    [BuildInCommandType.SETTINGS]: {
      icon: "cog",
      command: "/settings",
      description: "Open settings view",
    },
    [BuildInCommandType.ABOUT]: {
      icon: "info-circle",
      command: "/about",
      description: "Information about this app",
    },
    [BuildInCommandType.FEEDBACK]: {
      icon: "comment-dots",
      command: "/feedback",
      description: "Open feedbacks dialog",
    },
    [BuildInCommandType.MANUAL]: {
      icon: "file-alt",
      command: "/manual",
      description: "Manual for Markdown format, special syntax and HOWTOS",
    },
  },
  commandIds: [
    BuildInCommandType.ABOUT,
    BuildInCommandType.MANUAL,
    BuildInCommandType.FEEDBACK,
    BuildInCommandType.SETTINGS,
    BuildInCommandType.UPLOAD,
    BuildInCommandType.SEARCH,
  ],
};

const event = reducerWithInitialState<InputState>(defaultState)
  .case(loginActions.logout, (state, value) => defaultState)
  .case(actions.onMenuTapped, (state, value) => ({
    ...state,
    displayCommands: !state.displayCommands,
  }))
  .case(actions.onTextChanged, (state, value) => {
    if (state.activeCommandMode !== null) {
      return state;
    }
    const matchedCommandIds = state.commandIds.filter((commandId) => {
      if (value.length === 0) {
        return false;
      }
      const command = state.commands[commandId];
      return command.command.startsWith(value);
    });
    return {
      ...state,
      matchedCommandIds,
      displayCommands: matchedCommandIds.length > 0,
    };
  })
  .case(actions.onMenuTapped, (state, value) => {
    if (state.matchedCommandIds.length > 0) {
      return state;
    }
    return {
      ...state,
      displayCommands: !state.displayCommands,
    };
  })
  .case(actions.updateText, (state, value) => ({
    ...state,
    value,
  }))
  .case(actions.sendMessage.started, (state, value) => ({
    ...state,
    value: "",
    displayHashtags: false,
    matchedHashtags: [],
    matchedHashtagPrefixLength: 0,
  }))
  .case(actions.sendLocalCommand, (state, value) => ({
    ...state,
    value: state.commands[value.id].resetAfterSbumit ?? true ? "" : state.value,
    matchedCommandIds: [],
    displayCommands: false,
  }))
  .case(actions.enterCommandMode, (state, commandId) => {
    let { value, commands } = state;
    if (commandId !== null) {
      const command = commands[commandId];
      const prefixLength = commonPrefixLength(state.value, command.command);
      value = value.substr(prefixLength);
    }
    return {
      ...state,
      value,
      activeCommandMode: commandId,
      displayCommands: false,
      matchedCommandIds: [],
      focusCounter: state.focusCounter + 1,
    };
  })
  .case(actions.leaveCommandMode, (state) => ({
    ...state,
    value: "",
    files: {},
    fileIds: [],
    displayCommands: false,
    matchedCommandIds: [],
    activeCommandMode: null,
  }))
  .case(actions.pickUploadingFile.done, (state, { result }) => ({
    ...state,
    files: {
      ...state.files,
      [result.id]: {
        id: result.id,
        name: result.name,
        progress: 0,
        icon: lookupIcon(result.name),
        state: UploadingFileState.UPLOADING,
      },
    },
    fileIds: [...state.fileIds, result.id],
  }))
  .case(actions.uploadFileProgressUpdate, (state, { params, result }) => ({
    ...state,
    files: {
      ...state.files,
      [params.localId]: {
        ...state.files[params.localId],
        progress: (result.loaded / result.total) * 100,
      },
    },
  }))
  .case(actions.uploadFile.done, (state, { params, result }) => {
    // Remove the local file
    const { [params.localId]: localFile, ...remainFiles } = state.files;
    return {
      ...state,
      files: {
        ...remainFiles,
        [result.id]: {
          id: result.id,
          name: result.name,
          progress: 100,
          icon: localFile.icon,
          state: UploadingFileState.UPLOADED,
        },
      },
      fileIds: state.fileIds.map((id) =>
        id === params.localId ? result.id : id
      ),
    };
  })
  .case(actions.uploadFile.failed, (state, { params, error }) => ({
    ...state,
    files: {
      ...state.files,
      [params.localId]: {
        ...state.files[params.localId],
        state: UploadingFileState.FAILED,
      },
    },
  }))
  .case(actions.deleteFile, (state, id) => {
    // Remove the local file
    const { [id]: localFile, ...remainFiles } = state.files;
    return {
      ...state,
      files: remainFiles,
      fileIds: state.fileIds.filter((fileId) => fileId !== id),
    };
  })
  .case(actions.updateMatchedHashtags, (state, hashtags) => {
    return {
      ...state,
      displayHashtags: hashtags.hashtags.length > 0,
      matchedHashtags: hashtags.hashtags,
      matchedHashtagPrefixLength: hashtags.prefixLength,
    };
  })
  .case(actions.onHashtagTapped, (state, hashtag) => {
    const capture = state.value.match(typingHashtagRegex)!;
    return {
      ...state,
      value: `${state.value.substring(0, capture.index!)}${hashtag} `,
      displayHashtags: false,
      matchedHashtags: [],
      matchedHashtagPrefixLength: 0,
    };
  });
export default event;
