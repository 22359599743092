import { FontAwesome5 } from "@expo/vector-icons";
import React from "react";
import {
  ActivityIndicator,
  StyleProp,
  StyleSheet,
  Text,
  TouchableOpacity,
  ViewStyle,
} from "react-native";
import { Colors } from "../Colors";

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
    flexDirection: "row",
    paddingVertical: 14,
    alignItems: "center",
    paddingHorizontal: 20,
  },
  loadingContainer: {
    opacity: 0.5,
    justifyContent: "center",
  },
  icon: {
    paddingRight: 10,
  },
  text: {
    fontSize: 18,
  },
});

export interface Props {
  readonly style?: StyleProp<ViewStyle>;
  readonly icon?: string;
  readonly title: string;
  readonly color?: string;
  readonly loading?: boolean;
  readonly onPress?: () => void;
}

const MenuItemButton: React.FunctionComponent<Props> = ({
  style,
  icon,
  title,
  color,
  loading,
  onPress,
}: Props) => {
  const loadingValue = loading ?? false;
  const colorValue = color ?? Colors.Launch;
  return (
    <TouchableOpacity
      style={[
        styles.container,
        loadingValue ? styles.loadingContainer : null,
        style,
      ]}
      onPress={onPress}
      disabled={loadingValue}
    >
      {!loadingValue ? (
        <>
          {icon !== undefined ? (
            <FontAwesome5
              name={icon}
              size={18}
              style={styles.icon}
              color={colorValue}
            />
          ) : null}
          <Text style={[styles.text, { color: colorValue }]}>{title}</Text>
        </>
      ) : (
        <ActivityIndicator color={Colors.Launch} />
      )}
    </TouchableOpacity>
  );
};

export default MenuItemButton;
