import AnalyticsInterface from "./interface";

const ERROR_MSG = "Wrong module used, this is meant for mobile devices only";

export default class MobileAnalytics implements AnalyticsInterface {
  constructor(apiKey: string) {
    console.error(ERROR_MSG);
    throw new Error(ERROR_MSG);
  }

  async initialize(): Promise<void> {
    console.error(ERROR_MSG);
    throw new Error(ERROR_MSG);
  }

  setCollecting(value: boolean): void {
    console.error(ERROR_MSG);
    throw new Error(ERROR_MSG);
  }

  async logEvent(event: string): Promise<void> {
    console.error(ERROR_MSG);
    throw new Error(ERROR_MSG);
  }

  async logEventWithProperties(
    event: string,
    properties: Record<string, any>
  ): Promise<void> {
    console.error(ERROR_MSG);
    throw new Error(ERROR_MSG);
  }

  async setUserId(userId: string): Promise<void> {
    console.error(ERROR_MSG);
    throw new Error(ERROR_MSG);
  }

  async clearUserProperties(): Promise<void> {
    console.error(ERROR_MSG);
    throw new Error(ERROR_MSG);
  }
}
