import { storiesOf } from "@storybook/react-native";
import React from "react";
import { StyleSheet, View } from "react-native";
import CommandOption from "../../../components/Command/CommandOption";

const storyName = "Components/Command/" + CommandOption.name;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  commandOption: {
    width: "100%",
    paddingHorizontal: 20,
  },
});

storiesOf(storyName, module).add("search", () => (
  <View style={styles.container}>
    <CommandOption
      id=""
      style={styles.commandOption}
      icon="search"
      command="/search"
      parameters={["KEYWORD"]}
      description="Search messages"
    />
  </View>
));

storiesOf(storyName, module).add("upload", () => (
  <View style={styles.container}>
    <CommandOption
      id=""
      style={styles.commandOption}
      icon="file-upload"
      command="/upload"
      parameters={["[MESSAGE]"]}
      description="Upload files"
    />
  </View>
));

storiesOf(storyName, module).add("settings", () => (
  <View style={styles.container}>
    <CommandOption
      id=""
      style={styles.commandOption}
      icon="cog"
      command="/settings"
      description="Open settings view"
    />
  </View>
));

storiesOf(storyName, module).add("logout", () => (
  <View style={styles.container}>
    <CommandOption
      id=""
      style={styles.commandOption}
      icon="sign-out-alt"
      command="/logout"
      description="Logout current user"
    />
  </View>
));

storiesOf(storyName, module).add("search with bold prefix", () => (
  <View style={styles.container}>
    <CommandOption
      id=""
      style={styles.commandOption}
      icon="search"
      command="/search"
      commandPrefixBoldLength={3}
      parameters={["KEYWORD"]}
      description="Search messages"
    />
  </View>
));
