import { combineEpics } from "redux-observable";
import analyticsEpic from "./analytics";
import appEpic from "./app";
import deviceEpic from "./device";
import errorEpic from "./error";
import eventEpic from "./events";
import feedbacksEpic from "./feedbacks";
import forgotPasswordEpic from "./forgotPassword";
import inputEpic from "./input";
import loginEpic from "./login";
import messageEpic from "./messages";
import routerEpic from "./router";
import settingsEpic from "./settings";
import signUpEpic from "./signUp";
import subscriptionEpic from "./subscription";
import userEpic from "./user";
import notificationEpic from "./notifications";

const rootEpic = combineEpics(
  appEpic,
  userEpic,
  deviceEpic,
  eventEpic,
  messageEpic,
  inputEpic,
  loginEpic,
  signUpEpic,
  forgotPasswordEpic,
  errorEpic,
  settingsEpic,
  routerEpic,
  feedbacksEpic,
  subscriptionEpic,
  analyticsEpic,
  notificationEpic
);

export default rootEpic;
