import { storiesOf } from "@storybook/react-native";
import { StyleSheet, View } from "react-native";
import React, { useState } from "react";
import RetryMenu from "../../../components/Message/RetryMenu";
import { Colors } from "../../../components/Colors";

const storyName = "Components/Message/" + RetryMenu.name;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  retryMenu: {
    width: "100%",
    paddingHorizontal: 20,
    backgroundColor: Colors.Warning,
  },
});

storiesOf(storyName, module).add("visible", () => (
  <View style={styles.container}>
    <RetryMenu style={styles.retryMenu} visible />
  </View>
));

storiesOf(storyName, module).add("invisible", () => {
  const [visible, setVisible] = useState(true);
  setTimeout(() => setVisible(false), 1000);
  return (
    <View style={styles.container}>
      <RetryMenu style={styles.retryMenu} visible={visible} />
    </View>
  );
});
