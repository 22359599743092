import { storiesOf } from "@storybook/react-native";
import { flatMap, range } from "lodash";
import React from "react";
import { LayoutChangeEvent, SectionList, StyleSheet, View } from "react-native";
import { SafeAreaProvider, SafeAreaView } from "react-native-safe-area-context";
import Message, { MessageState } from "../../../components/Message/Message";
import SystemMessage from "../../../components/Message/SystemMessage";
import MessageList from "../../../components/Message/MessageList";

const storyName = "Components/Message/" + MessageList.name;

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
  },
  container: {
    flex: 1,
    backgroundColor: "#fff",
    justifyContent: "center",
  },
});

const data = [
  {
    date: new Date(2021, 3, 22),
    data: [
      {
        id: "m0_0",
        type: "message",
        text: "This is my very first message",
        time: "9:41 AM",
        state: MessageState.CONFIRMED,
      },
      {
        id: "m0_1",
        type: "message",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        time: "9:42 AM",
        state: MessageState.CONFIRMED,
      },
    ],
  },
  {
    date: new Date(2021, 3, 23),
    data: [
      {
        id: "m1_0",
        type: "message",
        text: "I should add search function first",
        time: "10:50 AM",
        state: MessageState.CONFIRMED,
      },
      {
        id: "m1_1",
        type: "message",
        text: "Hmmm, or maybe it's a bad idea? 🤔",
        time: "10:51 AM",
        state: MessageState.CONFIRMED,
      },
      {
        id: "m1_2",
        type: "message",
        text: "Screw it, let's do it anyway!",
        time: "10:51 AM",
        state: MessageState.CONFIRMED,
      },
      {
        id: "m1_3",
        type: "message",
        text: "I also forgot to implement hash tag function",
        time: "10:52 AM",
        state: MessageState.CONFIRMED,
      },
      {
        id: "m1_4",
        type: "message",
        text: "What about login UI?",
        time: "10:55 AM",
        state: MessageState.CONFIRMED,
      },
      {
        id: "m1_5",
        type: "message",
        text: "Is it normal to talk to yourself?",
        time: "11:55 AM",
        state: MessageState.CONFIRMED,
      },
      {
        id: "m1_6",
        type: "message",
        text: "I think it's very normal",
        time: "11:56 AM",
        state: MessageState.CONFIRMED,
      },
      {
        id: "m1_7",
        type: "message",
        text: "This is fine I guess?",
        time: "12:00 PM",
        state: MessageState.CONFIRMED,
      },
      ...range(8, 8 + 100).map((number) => ({
        id: `m1_${number}`,
        type: "message",
        text: `Make it very long ${number}`,
        time: "1:43 PM",
        state: MessageState.CONFIRMED,
      })),
    ],
  },
  {
    date: new Date(2021, 3, 24),
    data: [
      {
        id: "m2_0",
        type: "message",
        text: "hello there",
        time: "5:05 PM",
        state: MessageState.CONFIRMED,
      },
      {
        id: "m2_1",
        type: "message",
        text: "Files for my contracting job",
        time: "6:26 PM",
        state: MessageState.CONFIRMED,
        files: [
          {
            id: "file-00",
            name: "software-contracting-agreement.pdf",
            icon: "file-pdf",
          },
          { id: "file-01", name: "NDA.doc", icon: "file-word" },
        ],
      },
      {
        id: "m2_2",
        type: "message",
        text: "hello there",
        time: "5:05 PM",
        state: MessageState.CONFIRMED,
      },
      {
        id: "m2_3",
        type: "system",
        text: "## Version Information\n\n**Version**: 1.0.0\n\n**Build Number**: 123",
      },
      {
        id: "m2_4",
        type: "message",
        text: "should buy milk later",
        time: "6:12 PM",
        state: MessageState.FAILED,
      },
      {
        id: "m2_5",
        type: "message",
        text: "remember to pay the bill",
        time: "6:19 PM",
        state: MessageState.PENDING,
      },
      {
        id: "m2_6",
        type: "message",
        text: "This is now selected",
        time: "6:25 PM",
        state: MessageState.SELECTED,
      },
    ],
  },
];

const msgIdMap = Object.fromEntries(
  flatMap(data, (section) => section.data.map((item) => [item.id, item]))
);

storiesOf(storyName, module).add("with text", () => (
  <SafeAreaProvider>
    <SafeAreaView style={styles.safeArea}>
      <View style={styles.container}>
        <MessageList
          renderMessage={(
            id: string,
            onLayout: (event: LayoutChangeEvent) => void
          ) => {
            const item = msgIdMap[id];
            switch (item.type) {
              case "message": {
                return (
                  <Message
                    id={id}
                    message={item.text}
                    time={item.time}
                    state={item.state!}
                    onLayout={onLayout}
                  />
                );
              }
              case "system": {
                return (
                  <SystemMessage
                    id={id}
                    message={item.text}
                    onLayout={onLayout}
                  />
                );
              }
              default:
                throw new Error(`Invalid message type`);
            }
          }}
          today={new Date(2021, 3, 24)}
          sections={data.map((section) => ({
            ...section,
            data: section.data.map((items) => items.id),
          }))}
        />
      </View>
    </SafeAreaView>
  </SafeAreaProvider>
));
