import { Platform } from "react-native";
import { connect } from "react-redux";
import { ButtonType, SwitchType } from "../../actions/Settings";
import Settings, {
  MenuItem,
  MenuItemSwitchRecord,
  MenuItemType,
  Props,
} from "../../components/Settings/Settings";
import { State } from "../../states";
import { mapDispatchToProps } from "./shares";
import * as bytes from "bytes";
import { Colors } from "../../components/Colors";

export const makeItems = (
  email: string,
  storageUsage: number,
  storageSize: number,
  accountType?: string,
  settings?: Record<string, any>
): Array<MenuItem> => {
  let accountActionIcon: string | undefined = undefined;
  let accountActionTitle: string | undefined = undefined;
  if (Platform.OS === "web") {
    accountActionIcon = "external-link-alt";
    accountActionTitle =
      accountType === undefined ? "Upgrade to Pro" : "Manage Subscription";
  }
  return [
    {
      type: MenuItemType.TEXT_FIELD,
      id: "email",
      icon: "envelope",
      title: "Email",
      text: email,
      topSeparator: true,
    },
    {
      type: MenuItemType.TEXT_FIELD,
      id:
        accountType === undefined
          ? ButtonType.ACCOUNT_UPGRADE_ACCOUNT
          : ButtonType.ACCOUNT_MANAGE_SUBSCRIPTION,
      icon: "user",
      title: "Account Type",
      actionIcon: accountActionIcon,
      actionTitle: accountActionTitle,
      text: accountType ?? "Free",
    },
    {
      type: MenuItemType.TEXT_FIELD,
      id: "dataStorage",
      icon: "database",
      title: "Data Storage",
      progress: storageUsage / storageSize,
      text: `${bytes.format(storageUsage)} / ${bytes.format(storageSize)}`,
    },
    ...(settings?.share_analytics_data !== undefined
      ? [
          {
            type: MenuItemType.SWITCH,
            id: SwitchType.ACCOUNT_SHARE_DATA,
            icon: "chart-bar",
            title: "Share Usage Data",
            value: settings.share_analytics_data,
          } as MenuItemSwitchRecord,
        ]
      : []),
    {
      type: MenuItemType.SPACER,
    },
    {
      type: MenuItemType.BUTTON,
      id: ButtonType.ACCOUNT_REMOVE_WELCOME_MESSAGES,
      icon: "trash-alt",
      title: "Remove Welcome Messages",
      topSeparator: true,
    },

    {
      type: MenuItemType.BUTTON,
      id: ButtonType.ACCOUNT_CHANGE_PASSWORD,
      icon: "edit",
      title: "Change Password",
    },
    {
      type: MenuItemType.BUTTON,
      id: ButtonType.ACCOUNT_SIGN_OUT,
      icon: "sign-out-alt",
      title: "Sign out",
    },
    {
      type: MenuItemType.SPACER,
    },
    {
      type: MenuItemType.BUTTON,
      id: ButtonType.ACCOUNT_DELETE,
      color: Colors.Danger,
      icon: "trash-alt",
      title: "Delete Account",
      topSeparator: true,
    },
  ];
};

const mapStateToProps = (state: State): Pick<Props, "items"> => ({
  items: makeItems(
    state.user.email!,
    state.user.storageUsage!,
    state.user.storageSize!,
    state.user.productType,
    state.user.settings
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
