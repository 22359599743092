import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AnyAction } from "typescript-fsa";
import forgotPasswordActions from "../../actions/ForgotPassword";
import ResetPassword, {
  Props,
} from "../../components/ForgotPassword/ResetPassword";
import { State } from "../../states";

const mapStateToProps = (state: State): Pick<Props, "loading"> => ({
  loading: state.forgotPassword.loadingResetPassword,
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): Pick<Props, "onSubmit"> => {
  return {
    onSubmit: (password: string, passwordRepeat) => {
      dispatch(
        forgotPasswordActions.onSubmitResetPassword({
          password,
          passwordRepeat,
        })
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
