import React from "react";
import { Pages } from "../../routers";
import PageContainer from "../Page/PageContainer";
import SubscriptionContainer from "./SubscriptionContainer";

const SubscriptionPage: React.FunctionComponent = () => (
  <PageContainer
    name={Pages.SUBSCRIPTION}
    displayErrors={false}
    keyboardAvoiding={false}
  >
    <SubscriptionContainer />
  </PageContainer>
);

export default SubscriptionPage;
