import React from "react";
import { Pages } from "../../routers";
import PageContainer from "../Page/PageContainer";
import ResetPasswordContainer from "./ResetPasswordContainer";

const ResetPasswordPage: React.FunctionComponent = () => (
  <PageContainer name={Pages.RESET_PASSWORD}>
    <ResetPasswordContainer />
  </PageContainer>
);

export default ResetPasswordPage;
