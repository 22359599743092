import { connect } from "react-redux";
import { ButtonType } from "../../actions/Settings";
import Settings, {
  MenuItem,
  MenuItemType,
  Props,
} from "../../components/Settings/Settings";
import { State } from "../../states";
import { mapDispatchToProps } from "./shares";

export const items: Array<MenuItem> = [
  {
    type: MenuItemType.LOGO,
  },
  {
    type: MenuItemType.BUTTON,
    id: ButtonType.SETTINGS_ACCOUNT,
    icon: "user",
    title: "Account",
    topSeparator: true,
  },
  {
    type: MenuItemType.BUTTON,
    id: ButtonType.SETTINGS_GENERAL,
    icon: "cog",
    title: "General",
  },
  {
    type: MenuItemType.BUTTON,
    id: ButtonType.SETTINGS_SECURITY,
    icon: "lock",
    title: "Security",
  },
];

const mapStateToProps = (state: State): Pick<Props, "items"> => ({
  items,
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
