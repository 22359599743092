import { BlurView } from "expo-blur";
import { StatusBar } from "expo-status-bar";
import React from "react";
import { LayoutChangeEvent, Platform, StyleSheet, View } from "react-native";
import { Colors } from "../../components/Colors";
import { Pages } from "../../routers";
import HashtagListContainer from "../Hashtag/HashtagListContainer";
import CommandListOptionalContainer from "../Input/CommandListContainer";
import InputBarContainer from "../Input/InputBarContainer";
import SearchBarContainer from "../Input/SearchBarContainer";
import UploadingListContainer from "../Input/UploadingListContainer";
import PageContainer from "../Page/PageContainer";
import ErrorMessageListContaoler from "./ErrorMessageListContainer";
import MessageListContainer from "./MessageListContainer";
import MessageUnionContainer from "./MessageUnionContainer";
import ScrollToLatestButton from "./ScrollToLatestButton";

const styles = StyleSheet.create({
  pageContainer: {
    overflow: "hidden",
  },
  keyboardAvoiding: {
    flex: 1,
  },
  headerBackground: {
    backgroundColor: "white",
    position: "absolute",
    top: -100,
    height: 100,
    left: 0,
    right: 0,
  },
  container: {
    flex: 1,
    backgroundColor: "#fff",
    justifyContent: "center",
  },
  inputContainer: {},
  inputContainerBackground: {
    backgroundColor: Colors.LightGrey,
    position: "absolute",
    top: 0,
    bottom: -40,
    left: 0,
    right: 0,
    paddingTop: 5,
    paddingBottom: 42,
    paddingHorizontal: 5,
  },
  commandList: {},
  hashtagList: {},
  searchBar: {},
  uploadingList: {},
  inputBar: { paddingTop: 5 },
  errorMessageList: {
    position: "absolute",
    top: 10,
    left: 20,
    right: 20,
  },
  messageListWraper: {
    flex: 1,
  },
  messageListContainer: {
    flex: 1,
    overflow: "visible",
  },
  scrollToLatestButton: {},
});

const MessagePage: React.FunctionComponent = () => {
  const InputBackgroundView = Platform.OS === "android" ? View : BlurView;
  return (
    <PageContainer
      style={styles.pageContainer}
      name={Pages.MESSAGE}
      displayErrors={false}
      keyboardAvoiding
      keyboardDetection
    >
      <View style={styles.container}>
        <View style={styles.messageListWraper}>
          <MessageListContainer
            style={styles.messageListContainer}
            renderMessage={(
              id: string,
              onLayout: (event: LayoutChangeEvent) => void
            ) => <MessageUnionContainer id={id} onLayout={onLayout} />}
          />
          <ScrollToLatestButton style={styles.scrollToLatestButton} />
        </View>
        <View style={styles.inputContainer}>
          <InputBackgroundView
            style={styles.inputContainerBackground}
            intensity={95}
            tint="light"
          />
          <CommandListOptionalContainer style={styles.commandList} />
          <HashtagListContainer style={styles.hashtagList} />
          <SearchBarContainer style={styles.searchBar} />
          <UploadingListContainer style={styles.uploadingList} />
          <InputBarContainer autoFocus style={styles.inputBar} />
        </View>
        <ErrorMessageListContaoler style={styles.errorMessageList} />
        <StatusBar style="auto" />
      </View>
      {Platform.OS !== "web" ? <View style={styles.headerBackground} /> : null}
    </PageContainer>
  );
};
export default MessagePage;
