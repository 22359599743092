import React, { useEffect, useRef } from "react";
import { Keyboard } from "react-native";

export interface Props {
  onKeyboardWillShow?: () => void;
  onKeyboardWillHide?: () => void;
  onKeyboardDidShow?: () => void;
  onKeyboardDidHide?: () => void;
}

const KeyboardDetector: React.FunctionComponent<Props> = (props: Props) => {
  const eventHandlers = useRef<Props>(props);
  eventHandlers.current = props;
  useEffect(() => {
    Keyboard.addListener("keyboardWillShow", keyboardWillShow);
    Keyboard.addListener("keyboardWillHide", keyboardWillHide);
    Keyboard.addListener("keyboardDidShow", keyboardDidShow);
    Keyboard.addListener("keyboardDidHide", keyboardDidHide);

    // cleanup function
    return () => {
      Keyboard.removeListener("keyboardWillShow", keyboardWillShow);
      Keyboard.removeListener("keyboardWillHide", keyboardWillHide);
      Keyboard.removeListener("keyboardDidShow", keyboardDidShow);
      Keyboard.removeListener("keyboardDidHide", keyboardDidHide);
    };
  }, []);

  const keyboardWillShow = () => eventHandlers.current?.onKeyboardWillShow?.();
  const keyboardWillHide = () => eventHandlers.current?.onKeyboardWillHide?.();
  const keyboardDidShow = () => eventHandlers.current?.onKeyboardDidShow?.();
  const keyboardDidHide = () => eventHandlers.current?.onKeyboardDidHide?.();
  return null;
};

export default KeyboardDetector;
