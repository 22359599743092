import React, {
  Fragment,
  PropsWithChildren,
  ReactElement,
  ReactNode,
} from "react";

export type Props = PropsWithChildren<{
  readonly condition: boolean;
  readonly render: (children?: ReactNode) => ReactElement;
}>;

export const ConditionalWrapper: React.FunctionComponent<Props> = ({
  condition,
  render,
  children,
}: Props) => (condition ? render(children) : <Fragment>{children}</Fragment>);

export default ConditionalWrapper;
