import { createElement } from "react";
import { Text, View, ViewStyle } from "react-native";
import {
  anyScopeRegex,
  blockRegex,
  Capture,
  defaultRules,
  inlineRegex,
  Parser,
  ReactArrayRule,
  ReactOutput,
  ReactOutputRule,
  SingleASTNode,
  SingleNodeParserRule,
  State,
} from "simple-markdown";
import regexSupplant from "../../third-parties/twitter-text/regexSupplant";
import validHashtag from "../../third-parties/twitter-text/validHashtag";
import NativeLink from "./NativeLink";

// Notice: the reason we need this `—` is that if you type -- in iOS, it will
// automatiscally turn it into an unicdoe dash. In other words, if you type `---`,
// it will become `—-` instead. To make hr also works for that, we are changing the
// original simple markdown regex a bit
const optionalDash = /(?: *[-*_—])*/;
const requiredDash = /(?: *[-*_—])+/;
const classicalDash = /(?: *[-*_]){3,}/;
const unicodeDash = /(?: *— *)/;
export const hrRegex = regexSupplant(
  /^(#{classicalDash}|#{optionalDash}#{unicodeDash}#{requiredDash}|#{requiredDash}#{unicodeDash}#{optionalDash}) *(?:\n *)+\n/,
  {
    classicalDash,
    optionalDash,
    requiredDash,
    unicodeDash,
  }
);

export interface ReactOutputRules {
  readonly [type: string]:
    | Partial<SingleNodeParserRule & ReactOutputRule>
    | ReactArrayRule;
}

export interface Props {
  readonly styles: Record<string, ViewStyle>;
  readonly onUrlPress?: (url: string) => void;
  readonly onHashtagPress?: (hashtag: string) => void;
}

const makeRules = ({
  styles,
  onUrlPress,
  onHashtagPress,
}: Props): ReactOutputRules => ({
  heading: {
    react: (node: SingleASTNode, output: ReactOutput, state: State) =>
      createElement(
        Text,
        {
          key: state.key,
          style: [styles.heading, styles[`heading${node.level}`]],
        },
        output(node.content, state)
      ),
  },
  hr: {
    match: blockRegex(hrRegex),
    react: (node: SingleASTNode, output: ReactOutput, state: State) =>
      createElement(View, {
        key: state.key,
        style: styles.hr,
      }),
  },
  codeBlock: {
    react: (node: SingleASTNode, output: ReactOutput, state: State) =>
      createElement(
        Text,
        {
          key: state.key,
          style: styles.codeBlock,
        },
        node.content
      ),
  },
  blockQuote: {
    react: (node: SingleASTNode, output: ReactOutput, state: State) =>
      createElement(
        View,
        {
          key: state.key,
          style: [styles.blockQuoteContainer],
        },
        [
          createElement(View, {
            key: `${state.key}-1`,
            style: styles.blockQuoteBar,
          }),
          createElement(
            Text,
            { key: `${state.key}-2`, style: styles.blockQuoteText },
            output(node.content, state)
          ),
        ]
      ),
  },
  paragraph: {
    react: (node: SingleASTNode, output: ReactOutput, state: State) =>
      createElement(
        Text,
        {
          key: state.key,
          style: styles.paragraph,
        },
        output(node.content, state)
      ),
  },
  escape: {
    react: (node: SingleASTNode, output: ReactOutput, state: State) =>
      createElement(
        Text,
        {
          key: state.key,
          style: styles.paragraph,
        },
        node.content
      ),
  },
  url: {
    match: inlineRegex(/^(https?:\/\/[^\s<]+[^<.,:;"')\]\s])/i),
    react: (node: SingleASTNode, output: ReactOutput, state: State) =>
      createElement(
        NativeLink,
        {
          key: state.key,
          style: styles.link,
          url: node.target,
          onPress: () => {
            onUrlPress?.(node.target);
          },
        },
        output(node.content, state)
      ),
  },
  link: {
    react: (node: SingleASTNode, output: ReactOutput, state: State) =>
      createElement(
        NativeLink,
        {
          key: state.key,
          style: styles.link,
          url: node.target,
          onPress: () => {
            onUrlPress?.(node.target);
          },
        },
        output(node.content, state)
      ),
  },
  list: {
    react: (node: SingleASTNode, output: ReactOutput, state: State) => {
      const children = node.items.map((item: any, index: number) => {
        let bullet;
        if (node.ordered) {
          bullet = createElement(
            Text,
            { key: state.key, style: styles.listItemNumber },
            `${index + 1} . `
          );
        } else {
          bullet = createElement(
            Text,
            { key: state.key, style: styles.listItemBullet },
            styles.listItemBulletType
              ? `${styles.listItemBulletType} `
              : "\u2022 "
          );
        }
        const listItemText = createElement(
          Text,
          { key: (state.key as number) + 1, style: styles.listItemText },
          output(item, state)
        );
        return createElement(
          View,
          {
            key: index,
            style: styles.listItem,
          },
          [bullet, listItemText]
        );
      });
      return createElement(
        View,
        { key: state.key, style: styles.list },
        children
      );
    },
  },
  hashtag: {
    order: defaultRules.url.order + 0.5,
    match: inlineRegex(regexSupplant(/^#{validHashtag}/i, { validHashtag })),
    parse: (capture: Capture, parse: Parser, state: State) => ({
      hashsign: capture[1],
      content: capture[2],
    }),
    react: (node: SingleASTNode, output: ReactOutput, state: State) =>
      createElement(
        Text,
        {
          key: state.key,
          style: styles.hashtag,
          onPress: () => {
            onHashtagPress?.(node.content);
          },
        },
        `${node.hashsign}${node.content}`
      ),
  },
  em: {
    react: (node: SingleASTNode, output: ReactOutput, state: State) =>
      createElement(
        Text,
        {
          key: state.key,
          style: styles.em,
        },
        output(node.content, state)
      ),
  },
  strong: {
    react: (node: SingleASTNode, output: ReactOutput, state: State) =>
      createElement(
        Text,
        {
          key: state.key,
          style: styles.strong,
        },
        output(node.content, state)
      ),
  },
  u: {
    react: (node: SingleASTNode, output: ReactOutput, state: State) =>
      createElement(
        Text,
        {
          key: state.key,
          style: styles.u,
        },
        output(node.content, state)
      ),
  },
  del: {
    react: (node: SingleASTNode, output: ReactOutput, state: State) =>
      createElement(
        Text,
        {
          key: state.key,
          style: styles.del,
        },
        output(node.content, state)
      ),
  },
  inlineCode: {
    react: (node: SingleASTNode, output: ReactOutput, state: State) =>
      createElement(
        Text,
        {
          key: state.key,
          style: styles.inlineCode,
        },
        node.content
      ),
  },
  text: {
    match: anyScopeRegex(
      // We added "＃" so that hashtags with full width # can be matched
      /^[\s\S]+?(?=[^0-9A-Za-z\s\u00c0-\uffff]|＃|\n\n| {2,}\n|\w+:\S|$)/
    ),
    react: (node: SingleASTNode, output: ReactOutput, state: State) =>
      createElement(
        Text,
        {
          key: state.key,
          style: styles.text,
        },
        node.content
      ),
  },
});

export default makeRules;
