import {
  findFocusedRoute,
  getStateFromPath as getStateFromPathDefault,
  PathConfigMap,
} from "@react-navigation/core";
import {
  NavigationState,
  ParamListBase,
  PartialState,
} from "@react-navigation/routers";
import { mapValues } from "lodash";

export enum Pages {
  LOADING = "LOADING",
  LOGIN = "LOGIN",
  SIGNUP = "SIGNUP",
  FORGOT_PASSWORD = "FORGOT_PASSWORD",
  RESET_PASSWORD = "RESET_PASSWORD",
  MESSAGE = "MESSAGE",
  FEEDBACKS = "FEEDBACKS",
  SUBSCRIPTION = "SUBSCRIPTION",
  TOO_MANY_DEVICES = "TOO_MANY_DEVICES",
  SETTINGS = "SETTINGS",
  SETTINGS_ACCOUNT = "SETTINGS_ACCOUNT",
  SETTINGS_GENERAL = "SETTINGS_GENERAL",
  SETTINGS_SECURITY = "SETTINGS_SECURITY",
  SETTINGS_CHANGE_PASSWORD = "SETTINGS_CHANGE_PASSWORD",
  SETTINGS_ACCOUNT_DELETE = "SETTINGS_ACCOUNT_DELETE",
}

export enum AuthRequirement {
  ANY = "ANY",
  SIGNED_IN_ONLY = "SIGNED_IN_ONLY",
  SIGNED_OUT_ONLY = "SIGNED_OUT_ONLY",
}

export interface PageConfig {
  // Path of screen
  readonly path: string;
  // Authentication requirement for this page, default to SIGNED_IN_ONLY
  readonly authRequirement?: AuthRequirement;
  readonly prependPages?: Array<Pages>;
  // Is this page ready to be shown by default? if false,
  // it means there are some loading operations need to be done
  // before we remove the loading page. deafult value of this
  // field is true
  readonly readyStatus?: boolean;
  // Is this page pushed when visited with the URL? mostly used
  // for pages like reset_password which could be done in either when signed in and signed out
  // default to false
  readonly pushPage?: boolean;
}

export const pageConfigs: Record<Pages, PageConfig> = {
  [Pages.LOADING]: {
    path: "/",
    authRequirement: AuthRequirement.ANY,
  },
  [Pages.LOGIN]: {
    path: "login/",
    authRequirement: AuthRequirement.SIGNED_OUT_ONLY,
  },
  [Pages.SIGNUP]: {
    path: "signup/",
    authRequirement: AuthRequirement.SIGNED_OUT_ONLY,
    prependPages: [Pages.LOGIN],
  },
  [Pages.FORGOT_PASSWORD]: {
    path: "forgot_password/",
    authRequirement: AuthRequirement.SIGNED_OUT_ONLY,
    prependPages: [Pages.LOGIN],
  },
  [Pages.RESET_PASSWORD]: {
    path: "reset_password/",
    authRequirement: AuthRequirement.ANY,
    readyStatus: false,
    pushPage: true,
  },
  [Pages.MESSAGE]: {
    path: "messages/",
    readyStatus: false,
  },
  [Pages.FEEDBACKS]: {
    path: "feedbacks/",
    prependPages: [Pages.MESSAGE],
  },
  [Pages.SUBSCRIPTION]: {
    path: "subscription/",
    prependPages: [Pages.MESSAGE],
  },
  [Pages.TOO_MANY_DEVICES]: {
    path: "too_many_devices/",
    prependPages: [Pages.MESSAGE],
  },
  [Pages.SETTINGS]: {
    path: "settings/",
    prependPages: [Pages.MESSAGE],
  },
  [Pages.SETTINGS_ACCOUNT]: {
    path: "settings/account/",
    prependPages: [Pages.MESSAGE, Pages.SETTINGS],
  },
  [Pages.SETTINGS_CHANGE_PASSWORD]: {
    path: "settings/account/change_password/",
    prependPages: [Pages.MESSAGE, Pages.SETTINGS, Pages.SETTINGS_ACCOUNT],
  },
  [Pages.SETTINGS_GENERAL]: {
    path: "settings/general/",
    prependPages: [Pages.MESSAGE, Pages.SETTINGS],
  },
  [Pages.SETTINGS_SECURITY]: {
    path: "settings/security/",
    prependPages: [Pages.MESSAGE, Pages.SETTINGS],
  },
  [Pages.SETTINGS_ACCOUNT_DELETE]: {
    path: "settings/account/delete",
    prependPages: [Pages.MESSAGE, Pages.SETTINGS, Pages.SETTINGS_ACCOUNT],
  },
};

export const defaultSignedInPage = Pages.MESSAGE;
export const defaultSignedOutPage = Pages.LOGIN;

export const screens: PathConfigMap<ParamListBase> = mapValues(
  pageConfigs,
  (pageConfig) => pageConfig.path
);

export const URLPrefixes = [
  "http://localhost:19006",
  "exp://192.168.50.80:19000/--/",
  "https://app.monoline.io",
];

export type Options<ParamList extends {}> = {
  initialRouteName?: string;
  screens: PathConfigMap<ParamList>;
};

export type ResultState = PartialState<NavigationState> & {
  state?: ResultState;
};

export const makeGetStateFromPath =
  (signedIn?: boolean, oldState?: NavigationState) =>
  (path: string, options?: Options<ParamListBase>): ResultState | undefined => {
    if (signedIn === undefined) {
      return;
    }
    let defaultState = getStateFromPathDefault(path, options);
    if (defaultState === undefined) {
      return;
    }
    let focusRoute = findFocusedRoute(defaultState);
    if (focusRoute === undefined || focusRoute.name === Pages.LOADING) {
      return;
    }

    let page = Pages[focusRoute.name as keyof typeof Pages];
    let pageConfig = pageConfigs[page];
    let { prependPages, authRequirement } = pageConfig;
    const authRequirementValue =
      authRequirement ?? AuthRequirement.SIGNED_IN_ONLY;

    // Redirect page if it's not authorized
    let redirectPage: Pages | undefined;
    if (authRequirementValue === AuthRequirement.SIGNED_IN_ONLY && !signedIn) {
      redirectPage = defaultSignedOutPage;
      console.info(
        "Cannot access",
        page,
        "when signed out, redirect to",
        redirectPage
      );
    } else if (
      authRequirementValue === AuthRequirement.SIGNED_OUT_ONLY &&
      signedIn
    ) {
      redirectPage = defaultSignedInPage;
      console.info(
        "Cannot access",
        page,
        "when signed in, redirect to",
        redirectPage
      );
    }
    if (redirectPage !== undefined) {
      console.info("Redirect to page", redirectPage);
      let redirectPath = pageConfigs[redirectPage].path;
      if (!redirectPath.startsWith("/")) {
        redirectPath = "/" + redirectPath;
      }
      if (redirectPath.endsWith("/")) {
        redirectPath = redirectPath.slice(0, redirectPath.length - 1);
      }
      defaultState = getStateFromPathDefault(redirectPath, options);
      if (defaultState === undefined) {
        return;
      }
      focusRoute = findFocusedRoute(defaultState);
      if (focusRoute === undefined || focusRoute.name === Pages.LOADING) {
        return;
      }
      page = Pages[focusRoute.name as keyof typeof Pages];
      pageConfig = pageConfigs[page];
      prependPages = pageConfig.prependPages;
    }

    let newRoutes: ResultState["routes"];
    if (pageConfig.pushPage ?? false) {
      let oldRoutes: ResultState["routes"] = (oldState?.routes ?? []).filter(
        (route) => route.name !== Pages.LOADING && route.name !== page
      ) as any;
      // Well, no old routes means, the user visit this reset password link
      // instead of change url of a running app or web, to avoid there's no page
      // to pop, let's insert the original default page for them
      if (oldRoutes.length === 0) {
        if (signedIn) {
          oldRoutes = [{ name: defaultSignedInPage }];
        } else {
          oldRoutes = [{ name: defaultSignedOutPage }];
        }
      }
      newRoutes = [
        ...oldRoutes,
        {
          // The focus route may contain param, so merge it here if it's pprovided
          ...focusRoute,
          name: page,
        },
        { name: Pages.LOADING },
      ] as any;
    } else {
      newRoutes = [
        ...(prependPages ?? []).map((name: string) => ({ name })),
        {
          // The focus route may contain param, so merge it here if it's pprovided
          ...focusRoute,
          name: page,
        },
        { name: Pages.LOADING },
      ];
    }

    const oldRouteMap = Object.fromEntries(
      oldState?.routes.map((route) => [route.name, route]) ?? []
    );
    return {
      // TODO: maybe we need a better way to merge existing route?
      routes: newRoutes.map((route) => ({
        ...route,
        ...(oldRouteMap[route.name!] ?? {}),
      })) as any,
    } as any;
  };
