import * as Device from "expo-device";
import { Alert, AppState, AppStateStatus, Platform } from "react-native";
import { Epic } from "redux-observable";
import { Observable, of } from "rxjs";
import { catchError, map, mergeMap, takeUntil } from "rxjs/operators";
import {
  Action,
  AnyAction,
  AsyncActionCreators,
  Failure,
  Success,
} from "typescript-fsa";
import { ofActionPayload } from "typescript-fsa-redux-observable";
import loginActions from "../actions/Login";
import { DeviceInfo } from "../services/api/interface";
import { State } from "../states";
import { Dependencies } from "./dependencies";

export function makeAPICallEpic<Params, Result, Error = {}>(
  actionCreactor: AsyncActionCreators<Params, Result, Error>,
  makeAPICall: (
    params: Params,
    dependencies: Dependencies
  ) => Observable<Result>
): Epic<
  AnyAction,
  Action<Success<Params, Result> | Failure<Params, Error>>,
  State,
  Dependencies
> {
  return (action$, state, dependencies) =>
    action$.pipe(
      ofActionPayload(actionCreactor.started),
      mergeMap((params) =>
        makeAPICall(params, dependencies).pipe(
          map((result) =>
            actionCreactor.done({
              params,
              result,
            })
          ),
          catchError((error) =>
            of(
              actionCreactor.failed({
                params,
                error,
              })
            )
          ),
          takeUntil(action$.pipe(ofActionPayload(loginActions.logout)))
        )
      )
    );
}

// ref: https://stackoverflow.com/a/12300351/25077
export const dataURItoBlob = (dataURI: string) => {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(",")[1]);

  // separate out the mime component
  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  var ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  var blob = new Blob([ab], { type: mimeString });
  return blob;
};

export const confirmAlert = (title: string, message: string) =>
  new Promise<undefined>((resolve, reject) => {
    if (Platform.OS === "web") {
      if (confirm(message)) {
        resolve(undefined);
        return;
      }
      reject();
      return;
    }
    Alert.alert(title, message, [
      {
        text: "Cancel",
        style: "cancel",
        onPress: () => {
          reject();
        },
      },
      {
        text: "OK",
        onPress: () => {
          resolve(undefined);
        },
      },
    ]);
  });

export const alertMessage = (title: string, message: string) =>
  new Promise<void>((resolve, reject) => {
    if (Platform.OS === "web") {
      alert(message);
      resolve(undefined);
      return;
    }
    Alert.alert(title, message, [
      {
        text: "OK",
        onPress: () => {
          resolve(undefined);
        },
      },
    ]);
  });

export const makeDeviceInfo = (localId: string): DeviceInfo => ({
  localId,
  name: Device.deviceName,
  brand: Device.brand,
  manufacturer: Device.manufacturer,
  modelName: Device.modelName,
  modelId: Device.modelId,
  osName: Device.osName,
  osVersion: Device.osVersion,
  osBuildId: Device.osBuildId,
  isDevice: Device.isDevice,
  platform: Platform.OS,
});

export const checkPasswordRulesViolation = (
  password: string
): string | null => {
  if (password.length < 8) {
    return "Password needs to be at least 8 characters";
  }
  return null;
};

export const observeAppState = () =>
  new Observable<AppStateStatus>((subscriber) => {
    const handler = (nextAppState: AppStateStatus) => {
      subscriber.next(nextAppState);
    };
    AppState.addEventListener("change", handler);
    return () => {
      AppState.removeEventListener("change", handler);
    };
  });
