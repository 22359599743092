import { storiesOf } from "@storybook/react-native";
import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import Settings, {
  MenuItem,
  MenuItemType,
} from "../../../components/Settings/Settings";

const storyName = "Components/Settings/" + Settings.name;

const styles = StyleSheet.create({
  settings: {
    flex: 1,
  },
});

const settingsPageItems: Array<MenuItem> = [
  {
    type: MenuItemType.LOGO,
  },
  {
    type: MenuItemType.BUTTON,
    id: "account",
    icon: "user",
    title: "Account",
    topSeparator: true,
  },
  {
    type: MenuItemType.BUTTON,
    id: "general",
    icon: "cog",
    title: "General",
  },
  {
    type: MenuItemType.BUTTON,
    id: "security",
    icon: "lock",
    title: "Security",
  },
];

const accountPageItems: Array<MenuItem> = [
  {
    type: MenuItemType.TEXT_FIELD,
    id: "email",
    icon: "envelope",
    title: "Email",
    text: "support@monoline.io",
    topSeparator: true,
  },
  {
    type: MenuItemType.TEXT_FIELD,
    id: "accountType",
    icon: "user",
    title: "Account Type",
    text: "Free",
    actionTitle: "Upgrade to Pro",
    actionIcon: "star",
  },
  {
    type: MenuItemType.SWITCH,
    id: "shareData",
    icon: "chart-bar",
    title: "Share Usage Data",
    value: true,
  },
  {
    type: MenuItemType.SPACER,
  },
  {
    type: MenuItemType.BUTTON,
    id: "removeWelcome",
    icon: "trash-alt",
    title: "Remove Welcome Messages",
    topSeparator: true,
  },
  {
    type: MenuItemType.BUTTON,
    id: "changePassword",
    icon: "edit",
    title: "Change Password",
  },
  {
    type: MenuItemType.BUTTON,
    id: "logout",
    icon: "sign-out-alt",
    title: "Sign out",
  },
];

const generalPageItems: Array<MenuItem> = [
  {
    type: MenuItemType.SWITCH,
    id: "displayMessageTime",
    icon: "clock",
    title: "Display message time",
    value: true,
    topSeparator: true,
  },
];

const makeChangePasswordPageItems = (visible: boolean): Array<MenuItem> => [
  {
    type: MenuItemType.TEXT_INPUT,
    id: "currentPassword",
    placeholder: "Current password",
    autoComplete: "password",
    textContentType: "password",
    secureTextEntry: true,
    topSeparator: true,
  },
  {
    type: MenuItemType.TEXT_INPUT,
    id: "newPassword",
    secureTextEntry: true,
    autoComplete: "password",
    textContentType: "newPassword",
    placeholder: "New password",
  },
  {
    type: MenuItemType.TEXT_INPUT,
    id: "newPasswordRepeat",
    secureTextEntry: true,
    autoComplete: "password",
    textContentType: "newPassword",
    placeholder: "New password repeat",
  },
  {
    type: MenuItemType.SPACER,
  },
  {
    type: MenuItemType.BUTTON,
    id: "update",
    icon: "check",
    title: "Update",
    topSeparator: true,
  },
  {
    type: MenuItemType.TEXT,
    id: "updateResult",
    icon: "check-circle",
    text: "Your password has been updated",
    visible,
    bottomSeparator: false,
  },
];

storiesOf(storyName, module).add("settings", () => (
  <Settings style={styles.settings} items={settingsPageItems} />
));

storiesOf(storyName, module).add("account", () => (
  <Settings style={styles.settings} items={accountPageItems} />
));

storiesOf(storyName, module).add("general", () => (
  <Settings style={styles.settings} items={generalPageItems} />
));

storiesOf(storyName, module).add("change password", () => {
  const [visible, setVisible] = useState(false);
  setTimeout(() => setVisible(true), 3000);
  return (
    <Settings
      style={styles.settings}
      items={makeChangePasswordPageItems(visible)}
    />
  );
});
