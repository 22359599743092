import React, { FunctionComponent } from "react";
import { View, StyleProp, StyleSheet, ViewStyle } from "react-native";
import { Colors } from "../Colors";
import IconButton from "../IconButton";
import NativeLink from "../Markdown/NativeLink";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  removeIcon: {
    padding: 0,
    marginRight: 5,
  },
  title: {
    flex: -1,
    textDecorationLine: "underline",
  },
});

export interface Props {
  readonly style?: StyleProp<ViewStyle>;
  readonly title?: string;
  readonly url: string;
  readonly editing?: boolean;
  readonly onLinkPress?: () => void;
  readonly onDelete?: () => void;
}

const PreviewHeader: FunctionComponent<Props> = ({
  style,
  title,
  url,
  editing,
  onLinkPress,
  onDelete,
}: Props) => (
  <View style={[styles.container, style]}>
    <NativeLink style={styles.title} url={url} onPress={onLinkPress}>
      {title !== undefined ? title : "No title"}
    </NativeLink>
    {editing ?? false ? (
      <IconButton
        hitSlop={{ top: 5, bottom: 5, left: 10, right: 10 }}
        style={styles.removeIcon}
        name="times"
        onPress={onDelete}
        color={Colors.MediumGrey}
        size={14}
      />
    ) : null}
  </View>
);

export default PreviewHeader;
