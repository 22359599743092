import React from "react";
import { StyleSheet } from "react-native";
import Loading from "../../components/Loading";
import { Pages } from "../../routers";
import PageContainer from "../Page/PageContainer";

const styles = StyleSheet.create({
  loading: {},
});

const LoadingPage: React.FunctionComponent = () => (
  <PageContainer
    name={Pages.LOADING}
    displayErrors={false}
    keyboardAvoiding={false}
  >
    <Loading style={styles.loading} />
  </PageContainer>
);

export default LoadingPage;
