import { storiesOf } from "@storybook/react-native";
import React from "react";
import { StyleSheet, View } from "react-native";
import MenuItemButton from "../../../components/Settings/MenuItemButton";

const storyName = "Components/Settings/" + MenuItemButton.name;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
  },
  menuItem: {
    flex: -1,
    width: "100%",
    borderWidth: 1,
  },
});

storiesOf(storyName, module).add("with icon", () => (
  <View style={styles.container}>
    <MenuItemButton style={styles.menuItem} icon="user" title="Account" />
  </View>
));

storiesOf(storyName, module).add("without icon", () => (
  <View style={styles.container}>
    <MenuItemButton style={styles.menuItem} title="Account" />
  </View>
));
