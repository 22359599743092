import { storiesOf } from "@storybook/react-native";
import React from "react";
import { StyleSheet, View } from "react-native";
import SignUp from "../../components/SignUp";

const storyName = "Components/" + SignUp.name;

const styles = StyleSheet.create({
  signUp: {
    flex: 1,
  },
});

storiesOf(storyName, module).add("sign up", () => (
  <SignUp style={styles.signUp} />
));

storiesOf(storyName, module).add("sign up loading", () => (
  <SignUp style={styles.signUp} loading />
));
