import React, { FunctionComponent, useEffect, useRef } from "react";
import {
  TextInput,
  StyleSheet,
  ViewStyle,
  StyleProp,
  ReturnKeyTypeOptions,
  Platform,
  View,
} from "react-native";
import { Colors } from "../Colors";

const styles = StyleSheet.create({
  container: {
    paddingLeft: 10,
    borderWidth: 1,
    borderColor: Colors.DarkGrey,
    backgroundColor: "white",
    borderRadius: 10,
    justifyContent: "center",
  },
  textInput: {
    fontSize: 14,
    overflow: "visible",
    lineHeight: 16,
  },
});

export interface Props {
  readonly placeholder?: string;
  readonly value?: string;
  readonly autoFocus?: boolean;
  readonly style?: StyleProp<ViewStyle>;
  readonly returnKeyType?: ReturnKeyTypeOptions;
  readonly focusCounter?: number;
  readonly multiline?: boolean;
  readonly onChangeText: (value: string) => void;
  readonly onSubmit?: () => void;
  readonly onBackspace?: () => void;
}

const InputField: FunctionComponent<Props> = ({
  placeholder,
  value,
  autoFocus,
  style,
  returnKeyType,
  focusCounter,
  multiline,
  onChangeText,
  onSubmit,
  onBackspace,
}: Props) => {
  const textRef = useRef<TextInput>(null);
  useEffect(() => {
    textRef.current?.focus();
  }, [focusCounter]);
  const lineCount = (value ?? "").split(/\r\n|\r|\n/).length;
  return (
    <View style={[styles.container, style]}>
      <TextInput
        ref={textRef}
        style={[
          styles.textInput,
          {
            height: 16 * Math.min(lineCount, 5) + 4,
          },
        ]}
        placeholder={placeholder}
        placeholderTextColor={Colors.DarkGrey}
        onChangeText={(text) => onChangeText?.(text)}
        blurOnSubmit={false}
        onKeyPress={(event) => {
          if (Platform.OS === "web") {
            const nativeEvent: KeyboardEvent =
              event.nativeEvent as KeyboardEvent;
            if (nativeEvent.key === "Enter" && !nativeEvent.shiftKey) {
              onSubmit?.();
              event.preventDefault();
              return;
            }
          }
          if (event.nativeEvent.key == "Backspace") {
            onBackspace?.();
          }
        }}
        onSubmitEditing={onSubmit}
        value={value}
        returnKeyType={returnKeyType}
        autoFocus={autoFocus ?? false}
        multiline={multiline}
      />
    </View>
  );
};

export default InputField;
