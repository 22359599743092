import React, { FunctionComponent } from "react";
import {
  ImageSourcePropType,
  View,
  StyleProp,
  StyleSheet,
  ViewStyle,
} from "react-native";
import { Colors } from "../Colors";
import PreviewBody from "./PreviewBody";
import PreviewHeader from "./PreviewHeader";

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    alignItems: "center",
    borderLeftWidth: 3,
    borderColor: Colors.MediumGrey,
    paddingLeft: 10,
  },
  title: {
    width: "100%",
    paddingVertical: 5,
  },
  body: {
    width: "100%",
    paddingBottom: 5,
  },
});

export interface Props {
  readonly style?: StyleProp<ViewStyle>;
  readonly title?: string;
  readonly thumbnail?: ImageSourcePropType;
  readonly description?: string;
  readonly url: string;
  readonly editing?: boolean;
  readonly onLinkPress?: () => void;
  readonly onDelete?: () => void;
}

const Preview: FunctionComponent<Props> = ({
  style,
  title,
  thumbnail,
  description,
  url,
  editing,
  onLinkPress,
  onDelete,
}: Props) => (
  <View style={[styles.container, style]}>
    <PreviewHeader
      style={styles.title}
      title={title}
      url={url}
      editing={editing}
      onLinkPress={onLinkPress}
      onDelete={onDelete}
    />
    {thumbnail !== undefined || description !== undefined ? (
      <PreviewBody
        style={styles.body}
        url={url}
        thumbnail={thumbnail}
        description={description}
        onLinkPress={onLinkPress}
      />
    ) : null}
  </View>
);

export default Preview;
