import { StackActions } from "@react-navigation/routers";
import { combineEpics, Epic } from "redux-observable";
import { from, of } from "rxjs";
import { filter, map, mapTo, mergeMap, mergeMapTo } from "rxjs/operators";
import { Action, AnyAction, isType } from "typescript-fsa";
import { ofActionPayload, ofAction } from "typescript-fsa-redux-observable";
import { v4 as uuid } from "uuid";
import errorActions, { ErrorMessage } from "../actions/Error";
import forgotPasswordActions, {
  ResetPasswordPayload,
} from "../actions/ForgotPassword";
import routerActions from "../actions/Router";
import { ResetPasswordRequest } from "../services/api/interface";
import { Pages } from "../routers";
import { State } from "../states";
import {
  alertMessage,
  checkPasswordRulesViolation,
  makeAPICallEpic,
} from "./helpers";

const forgotPassword = makeAPICallEpic<string, void>(
  forgotPasswordActions.forgotPassword,
  (email, { apiClient }) => apiClient.forgotPassword(email)
);

const checkResetPasswordToken = makeAPICallEpic<string, void>(
  forgotPasswordActions.checkResetPasswordToken,
  (token, { apiClient }) => apiClient.checkResetPasswordToken(token)
);

const resetPassword = makeAPICallEpic<ResetPasswordRequest, void>(
  forgotPasswordActions.resetPassword,
  (request, { apiClient }) => apiClient.resetPassword(request)
);

const startForgotPassword: Epic<AnyAction, AnyAction, State> = (
  action$,
  state
) =>
  action$.pipe(
    ofActionPayload(forgotPasswordActions.onSubmitForgotPassword),
    map((email) => forgotPasswordActions.forgotPassword.started(email))
  );

const alertForgotPassword: Epic<AnyAction, AnyAction, State> = (
  action$,
  state
) =>
  action$.pipe(
    ofActionPayload(forgotPasswordActions.forgotPassword.done),
    mergeMap(() =>
      alertMessage(
        "Check your mailbox",
        "An email with reset password link has been sent to your email address, please check your mailbox"
      )
    ),
    mapTo(routerActions.route(StackActions.pop()))
  );

const startCheckResetPasswordToken: Epic<AnyAction, AnyAction, State> = (
  action$,
  state
) =>
  action$.pipe(
    ofActionPayload(routerActions.onPageMount),
    filter((route) => route.name === Pages.RESET_PASSWORD),
    mergeMap((route) => {
      const token = (route?.params as any)?.token ?? "";
      return of(forgotPasswordActions.checkResetPasswordToken.started(token));
    })
  );

const setReadyAfterCheckResetPasswordTokenDone: Epic<
  AnyAction,
  AnyAction,
  State
> = (action$, state) =>
  action$.pipe(
    ofActionPayload(forgotPasswordActions.checkResetPasswordToken.done),
    map(() => {
      const route = state.value.router.routerState?.routes.find(
        (route) => route.name === Pages.RESET_PASSWORD
      )!;
      return routerActions.setPageReadyStatus({
        key: route.key,
        value: true,
      });
    })
  );

const showErrorAfterCheckResetPasswordTokenFailed: Epic<
  AnyAction,
  AnyAction,
  State
> = (action$, state) =>
  action$.pipe(
    ofActionPayload(forgotPasswordActions.checkResetPasswordToken.failed),
    mergeMap(() =>
      from(
        alertMessage("Invalid token", "The reset password token is invalid")
      ).pipe(mergeMapTo(of(routerActions.route(StackActions.pop(2)))))
    )
  );

const arePasswordValuesFilled = (payload: ResetPasswordPayload) =>
  [payload.password, payload.passwordRepeat].every(
    (value) => value !== undefined && value.length > 0
  );

const arePasswordsMatch = (payload: ResetPasswordPayload) =>
  payload.password === payload.passwordRepeat;

const startValidatingPassword: Epic<
  Action<ResetPasswordPayload>,
  Action<ResetPasswordPayload>,
  State
> = (action$, state) =>
  action$.pipe(
    ofActionPayload(forgotPasswordActions.onSubmitResetPassword),
    filter((payload) => arePasswordValuesFilled(payload)),
    map((payload) => forgotPasswordActions.validatePassword(payload))
  );

const validatePassword: Epic<
  Action<ResetPasswordRequest>,
  Action<ErrorMessage | ResetPasswordRequest>,
  State
> = (action$, state) =>
  action$.pipe(
    ofActionPayload(forgotPasswordActions.validatePassword),
    map((payload) => {
      if (!arePasswordsMatch(payload)) {
        return errorActions.addMessage({
          id: uuid(),
          message: "Password does not match",
        });
      }
      const violation = checkPasswordRulesViolation(payload.password);
      if (violation !== null) {
        return errorActions.addMessage({
          id: uuid(),
          message: violation,
        });
      }
      const route = state.value.router.routerState?.routes.find(
        (route) => route.name === Pages.RESET_PASSWORD
      )!;
      const token = (route?.params as any)?.token!;
      return forgotPasswordActions.resetPassword.started({
        token,
        password: payload.password,
      });
    })
  );

const showFinishAlertForResetPassword: Epic<AnyAction, AnyAction, State> = (
  action$,
  state
) =>
  action$.pipe(
    ofActionPayload(forgotPasswordActions.resetPassword.done),
    mergeMap(() =>
      from(
        alertMessage("Password reset", "The password has been updated")
      ).pipe(mergeMapTo(of(routerActions.route(StackActions.pop()))))
    )
  );

const preventNavigationForForgotPassword: Epic<
  AnyAction,
  Action<boolean>,
  State
> = (action$, state) =>
  action$.pipe(
    ofAction(
      forgotPasswordActions.forgotPassword.started,
      forgotPasswordActions.forgotPassword.done,
      forgotPasswordActions.forgotPassword.failed
    ),
    map((action) =>
      routerActions.setPreventNavigation(
        isType(action, forgotPasswordActions.forgotPassword.started)
      )
    )
  );

// TODO: find a better way to do this
const preventNavigationForResetPassword: Epic<
  AnyAction,
  Action<boolean>,
  State
> = (action$, state) =>
  action$.pipe(
    ofAction(
      forgotPasswordActions.resetPassword.started,
      forgotPasswordActions.resetPassword.done,
      forgotPasswordActions.resetPassword.failed
    ),
    map((action) =>
      routerActions.setPreventNavigation(
        isType(action, forgotPasswordActions.resetPassword.started)
      )
    )
  );

const forgotPasswordEpic = combineEpics(
  forgotPassword,
  checkResetPasswordToken,
  resetPassword,
  startForgotPassword,
  alertForgotPassword,
  startCheckResetPasswordToken,
  setReadyAfterCheckResetPasswordTokenDone,
  showErrorAfterCheckResetPasswordTokenFailed,
  startValidatingPassword,
  validatePassword,
  showFinishAlertForResetPassword,
  preventNavigationForForgotPassword,
  preventNavigationForResetPassword
);

export default forgotPasswordEpic;
