import { storiesOf } from "@storybook/react-native";
import React from "react";
import { StyleSheet } from "react-native";
import ResetPassword from "../../../components/ForgotPassword/ResetPassword";

const storyName = "Components/ForgotPassword/" + ResetPassword.name;

const styles = StyleSheet.create({
  resetPassword: {
    flex: 1,
  },
});

storiesOf(storyName, module).add("normal", () => (
  <ResetPassword style={styles.resetPassword} />
));

storiesOf(storyName, module).add("loading", () => (
  <ResetPassword style={styles.resetPassword} loading />
));
