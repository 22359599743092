import { reducerWithInitialState } from "typescript-fsa-reducers";
import deviceActions from "../actions/Device";
import eventActions from "../actions/Event";
import loginActions from "../actions/Login";
import { DeviceEvent, DeviceEventType } from "../services/api/interface";
import { Device, DeviceState, ListDeviceState } from "../states";

const processDeviceEvents = (
  events: Array<DeviceEvent>,
  currentDevice: Device
): Device => {
  let newCurrentDevice = currentDevice;
  for (const event of events) {
    const deviceEvent = event as DeviceEvent;
    switch (deviceEvent.type) {
      case DeviceEventType.TOO_MANY_DEVICES: {
        newCurrentDevice = {
          ...newCurrentDevice,
          tooManyDevices: deviceEvent.value,
        };
        break;
      }
      case DeviceEventType.NOTIFICATION_ENABLED_UPDATE: {
        newCurrentDevice = {
          ...newCurrentDevice,
          notificationEnabled: deviceEvent.value,
        };
        break;
      }
    }
  }
  return newCurrentDevice;
};

const defaultState: DeviceState = {
  listDeviceState: ListDeviceState.LOADING,
  devices: [],
};

const device = reducerWithInitialState<DeviceState>(defaultState)
  .case(loginActions.logout, (state, payload) => defaultState)
  .case(deviceActions.loadCurrentDevice.done, (state, { result }) => ({
    ...state,
    currentDevice: {
      id: result.id,
      name: result.name,
      brand: result.brand,
      manufacturer: result.manufacturer,
      modelName: result.modelName,
      modelId: result.modelId,
      osName: result.osName,
      osVersion: result.osVersion,
      osBuildId: result.osBuildId,
      eventStreamId: result.eventStreamId,
      tooManyDevices: result.tooManyDevices,
      notificationEnabled: result.notificationEnabled,
    },
  }))
  .case(deviceActions.listDevices.started, (state, payload) => ({
    ...state,
    listDeviceState: ListDeviceState.LOADING,
    devices: [],
  }))
  .case(deviceActions.listDevices.done, (state, payload) => ({
    ...state,
    listDeviceState: ListDeviceState.DONE,
    devices: payload.result.devices.map(
      (device) =>
        ({
          id: device.id,
          name: device.name,
          brand: device.brand,
          manufacturer: device.manufacturer,
          modelName: device.modelName,
          modelId: device.modelId,
          osName: device.osName,
          osVersion: device.osVersion,
          osBuildId: device.osBuildId,
          eventStreamId: device.eventStreamId,
          tooManyDevices: device.tooManyDevices,
          notificationEnabled: device.notificationEnabled,
        } as Device)
    ),
  }))
  .case(deviceActions.listDevices.failed, (state, payload) => ({
    ...state,
    listDeviceState: ListDeviceState.FAILED,
  }))
  .case(deviceActions.signOutDevice.started, (state, payload) => ({
    ...state,
    listDeviceState: ListDeviceState.LOADING,
    devices: [],
  }))
  .case(deviceActions.signOutDevice.failed, (state, payload) => ({
    ...state,
    listDeviceState: ListDeviceState.FAILED,
  }))
  .case(eventActions.loadEvents.done, (state, payload) => {
    if (payload.params.eventStreamId !== state.currentDevice?.eventStreamId) {
      return state;
    }
    const { currentDevice } = state;
    return {
      ...state,
      currentDevice: processDeviceEvents(
        payload.result.events as Array<DeviceEvent>,
        currentDevice
      ),
    };
  })
  .case(eventActions.eventUpdate, (state, payload) => {
    if (payload.eventStreamId !== state.currentDevice?.eventStreamId) {
      return state;
    }
    const { currentDevice } = state;
    return {
      ...state,
      currentDevice: processDeviceEvents(
        payload.eventPage.events as Array<DeviceEvent>,
        currentDevice
      ),
    };
  });

export default device;
