import React from "react";
import { Pages } from "../../routers";
import PageContainer from "../Page/PageContainer";
import SignUpContainer from "./SignUpContainer";

const SignUpPage: React.FunctionComponent = () => (
  <PageContainer name={Pages.SIGNUP}>
    <SignUpContainer />
  </PageContainer>
);

export default SignUpPage;
