import { Observable } from "rxjs";

export interface Credentials {
  readonly username: string;
  readonly password: string;
}

export interface DeviceInfo {
  readonly localId: string;
  readonly name: string | null;
  readonly brand: string | null;
  readonly manufacturer: string | null;
  readonly modelName: string | null;
  readonly modelId: string | null;
  readonly osName: string | null;
  readonly osVersion: string | null;
  readonly osBuildId: string | null;
  readonly isDevice: boolean;
  readonly platform: string | null;
}

export interface DeviceUpdateRequest {
  readonly notificationEnvironment: string | null;
  readonly notificationToken: string | null;
  readonly notificationEnabled: boolean | null;
}

export interface LoginInfo {
  readonly credentials: Credentials;
}

export interface AccessToken {
  readonly tokenType: string;
  readonly accessToken: string;
}

export interface AuthToken extends AccessToken {
  readonly refreshToken: string;
}

export enum MessageEventType {
  ADD_MESSAGE = "ADD_MESSAGE",
  UPDATE_MESSAGE = "UPDATE_MESSAGE",
  DELETE_MESSAGE = "DELETE_MESSAGE",
}

export enum DeviceEventType {
  SIGNED_OUT = "SIGNED_OUT",
  TOO_MANY_DEVICES = "TOO_MANY_DEVICES",
  NOTIFICATION_ENABLED_UPDATE = "NOTIFICATION_ENABLED_UPDATE",
}

export interface EventFile {
  readonly id: string;
  readonly name: string;
}

export interface BaseEvent {
  readonly id: string;
  readonly sequenceId: number;
  readonly timestamp: Date;
}

export interface MessageEvent extends BaseEvent {
  readonly eventType: "MESSAGE";
  readonly revision: number;
  readonly localId: string | null;
  readonly type: MessageEventType;
  readonly messageId: string;
  readonly content: string | null;
  readonly files?: Array<EventFile>;
  readonly previews?: Array<Preview>;
}

export interface DeviceEvent extends BaseEvent {
  readonly eventType: "DEVICE";
  readonly type: DeviceEventType;
  readonly value: any;
}

export type Event = MessageEvent | DeviceEvent;

export interface EventPage {
  readonly events: Event[];
  readonly lastSequenceId: number | null;
}

export enum WebSocketMessageType {
  EVENT_UPDATE = "EVENT_UPDATE",
}

export interface WebSocketEventUpdateMessage {
  readonly type: WebSocketMessageType.EVENT_UPDATE;
  readonly eventStreamId: string;
  readonly events: MessageEvent[];
  readonly lastSequenceId: number | null;
}

export type WebSocketMessage = WebSocketEventUpdateMessage;

export interface User {
  readonly id: string;
  readonly name: string;
  readonly email: string;
  readonly storageSize: number;
  readonly storageUsage: number;
  readonly productType: string | null;
  readonly eventStreamId: string;
  readonly settings: Record<string, any>;
}

export interface Device {
  readonly id: string;
  readonly name: string;
  readonly brand: string | null;
  readonly manufacturer: string | null;
  readonly modelName: string | null;
  readonly modelId: string | null;
  readonly osName: string | null;
  readonly osVersion: string | null;
  readonly osBuildId: string | null;
  readonly eventStreamId: string;
  readonly tooManyDevices: boolean;
  readonly notificationEnabled: boolean;
}

export interface DevicePage {
  readonly devices: Array<Device>;
}

export interface MessageResponse {
  readonly id: string;
  readonly localId: string;
  readonly content: string;
  readonly revision: number;
  readonly timestamp: Date;
  readonly files?: Array<EventFile>;
  readonly previews?: Array<Preview>;
}

export interface MessageRequest {
  readonly content: string;
  readonly localId: string;
  readonly files?: Array<EventFile>;
  readonly previewURLs?: Array<string>;
}

export interface ResetPasswordRequest {
  readonly password: string;
  readonly token: string;
}

export interface MessageUpdateRequest {
  readonly id: string;
  readonly content: string;
  readonly previewURLs?: Array<string>;
}

export interface FileUploadRequest {
  readonly name: string;
  readonly localId: string;
  readonly data: any;
}

export interface FileUploadResponse {
  readonly id: string;
  readonly name: string;
  readonly size: number;
  readonly mimeType: string;
  readonly localId: string;
}

export interface FileUploadProgress {
  readonly total: number;
  readonly loaded: number;
}

export interface FileUploadDoneEvent extends FileUploadResponse {
  readonly type: "done";
}

export interface FileUploadProgressEvent extends FileUploadProgress {
  readonly type: "progress";
}

export interface UpdatePasswordRequest {
  readonly currentPassword: string;
  readonly newPassword: string;
}

export interface DeleteAccountRequest {
  readonly password: string;
  readonly feedback?: string;
}

export interface Checkout {
  readonly url: string;
}

export interface PortalSession {
  readonly url: string;
}

export type FileUploadEvent = FileUploadProgressEvent | FileUploadDoneEvent;

export interface WebSocketConfig {
  readonly token: string;
  readonly heartbeatInterval: number;
  readonly heartbeatEnabled: boolean;
}

export enum PreviewState {
  PENDING = "PENDING",
  DONE = "DONE",
  FAILED = "FAILED",
}

export interface Preview {
  readonly id: string;
  readonly url: string;
  readonly title: string | null;
  readonly description: string | null;
  readonly thumbnailURL: string | null;
  readonly thumbnailWidth: number | null;
  readonly thumbnailHeight: number | null;
  readonly state: PreviewState;
}

export default interface APIInterface {
  setAuthInfo(
    tokenType: string | null,
    accessToken: string | null,
    refreshToken: string | null
  ): void;

  setDeviceInfo(deviceInfo: DeviceInfo): void;

  setTokenUpdateCallback(
    tokenUpdateCallback?: (token: AuthToken) => void
  ): void;

  login(credentials: LoginInfo): Observable<AuthToken>;

  signUp(credentials: Credentials): Observable<User>;

  updatePassword(request: UpdatePasswordRequest): Observable<User>;

  updateSettings(settings: Record<string, any>): Observable<User>;

  forgotPassword(request: string): Observable<void>;

  checkResetPasswordToken(token: string): Observable<void>;

  resetPassword(request: ResetPasswordRequest): Observable<void>;

  getStreamEvents(
    eventStreamId: string,
    lastSequenceId: number
  ): Observable<EventPage>;

  getCurrentDevice(): Observable<Device>;

  listDevices(): Observable<DevicePage>;

  updateDevice(request: DeviceUpdateRequest): Observable<Device>;

  signOutDevice(id: string): Observable<Device>;

  getCurrentUser(): Observable<User>;

  createMessage(request: MessageRequest): Observable<MessageResponse>;

  updateMessage(request: MessageUpdateRequest): Observable<MessageResponse>;

  deleteMessage(id: string): Observable<void>;

  deleteSystemMessages(tag: string): Observable<void>;

  createFile(request: FileUploadRequest): Observable<FileUploadEvent>;

  downloadFile(id: string): Observable<string>;

  createFeedback(content: string): Observable<void>;

  getWebSocketConfig(): Observable<WebSocketConfig>;

  createCheckout(): Observable<Checkout>;

  createPortalSession(): Observable<PortalSession>;

  deletePreview(id: string): Observable<void>;

  deleteAccount(request: DeleteAccountRequest): Observable<void>;
}
