import AsyncStorage from "@react-native-async-storage/async-storage";
import { StackActions } from "@react-navigation/native";
import { CommonActions } from "@react-navigation/routers";
import * as SecureStore from "expo-secure-store";
import { Platform } from "react-native";
import { combineEpics, Epic } from "redux-observable";
import { concat, from, merge, Observable, of } from "rxjs";
import {
  catchError,
  filter,
  ignoreElements,
  map,
  mapTo,
  mergeMap,
  switchMap,
  tap,
} from "rxjs/operators";
import { Action, AnyAction, Failure, Success } from "typescript-fsa";
import { ofActionPayload } from "typescript-fsa-redux-observable";
import { v4 as uuid } from "uuid";
import appActions from "../actions/App";
import errorActions from "../actions/Error";
import loginActions, { AuthTokenSource, LoginInfo } from "../actions/Login";
import routerActions from "../actions/Router";
import { Pages } from "../routers";
import { AccessToken, AuthToken } from "../services/api/interface";
import { parseAuthToken } from "../services/api/parsers";
import { State } from "../states";
import { Dependencies } from "./dependencies";
import { makeAPICallEpic, makeDeviceInfo } from "./helpers";

const AUTH_TOKEN_KEY = "auth_token";

const login = makeAPICallEpic<LoginInfo, AuthToken>(
  loginActions.login,
  ({ credentials }, { apiClient }) =>
    apiClient.login({
      credentials: {
        username: credentials.username,
        password: credentials.password,
      },
    })
);

const setAPIClientDeviceInfo: Epic<
  AnyAction,
  Action<void>,
  State,
  Dependencies
> = (action$, state, { apiClient }) =>
  action$.pipe(
    ofActionPayload(appActions.ensureDeviceLocalId.done),
    tap(({ result }) => {
      apiClient.setDeviceInfo(makeDeviceInfo(result));
    }),
    ignoreElements()
  );

const loadAuthToken: Epic<
  AnyAction,
  Action<Success<void, AccessToken | null> | Failure<void, any>>,
  State
> = (action$, state) =>
  action$.pipe(
    ofActionPayload(loginActions.loadAuthToken.started),
    mergeMap(() =>
      from(
        Platform.OS === "web"
          ? AsyncStorage.getItem(AUTH_TOKEN_KEY)
          : SecureStore.getItemAsync(AUTH_TOKEN_KEY)
      ).pipe(
        map((token) =>
          loginActions.loadAuthToken.done({
            result: token === null ? null : parseAuthToken(JSON.parse(token)),
          })
        ),
        // TODO: also make API call to ensure the token is still valid?
        catchError((error) => {
          console.warn("Failed to load auth token with error", error);
          return of(loginActions.loadAuthToken.failed({ error }));
        })
      )
    )
  );

const refreshToken: Epic<AnyAction, Action<AuthToken>, State, Dependencies> = (
  action$,
  state,
  { apiClient }
) =>
  action$.pipe(
    ofActionPayload(appActions.init),
    switchMap(
      () =>
        new Observable<Action<AuthToken>>((observer) => {
          apiClient.setTokenUpdateCallback((token) => {
            observer.next(loginActions.refreshToken(token));
          });
        })
    )
  );

const updateAuthTokenAfterLoaded: Epic<AnyAction, AnyAction, State> = (
  action$,
  state
) =>
  action$.pipe(
    ofActionPayload(loginActions.loadAuthToken.done),
    filter(({ result }) => result !== null),
    map(({ result }) =>
      loginActions.updateAuthToken.started({
        token: result!,
        source: AuthTokenSource.STORE,
      })
    )
  );

const updateAuthTokenAfterLogin: Epic<AnyAction, AnyAction, State> = (
  action$,
  state
) =>
  action$.pipe(
    ofActionPayload(loginActions.login.done),
    map(({ result }) =>
      loginActions.updateAuthToken.started({
        token: result,
        source: AuthTokenSource.LOGIN,
      })
    )
  );

const startLogin: Epic<AnyAction, AnyAction, State> = (action$, state) =>
  action$.pipe(
    ofActionPayload(loginActions.onSignIn),
    map(({ username, password }) =>
      loginActions.login.started({
        credentials: { username, password },
      })
    )
  );

const saveToken: Epic<AnyAction, AnyAction, State> = (action$, state) =>
  merge(
    action$.pipe(
      ofActionPayload(loginActions.login.done),
      map(({ result }) => result)
    ),
    action$.pipe(ofActionPayload(loginActions.refreshToken))
  ).pipe(
    mergeMap((token) => {
      const payload = {
        token_type: token.tokenType,
        access_token: token.accessToken,
        refresh_token: token.refreshToken,
      };
      return Platform.OS === "web"
        ? AsyncStorage.setItem(AUTH_TOKEN_KEY, JSON.stringify(payload))
        : SecureStore.setItemAsync(AUTH_TOKEN_KEY, JSON.stringify(payload));
    }),
    ignoreElements()
  );

const updateAPIClientAuthToken: Epic<
  AnyAction,
  AnyAction,
  State,
  Dependencies
> = (action$, state, { apiClient }) =>
  action$.pipe(
    ofActionPayload(loginActions.updateAuthToken.started),
    map((params) => {
      apiClient.setAuthInfo(
        params.token?.tokenType ?? null,
        params.token?.accessToken ?? null,
        params.token?.refreshToken ?? null
      );
      return loginActions.updateAuthToken.done({ params });
    })
  );

const signOutByUser: Epic<AnyAction, AnyAction, State, Dependencies> = (
  action$,
  state,
  { apiClient }
) =>
  action$.pipe(
    ofActionPayload(loginActions.signOutByUser),
    mergeMap(() => {
      const route = state.value.router.routerState?.routes.find(
        (route) => route.name === Pages.SETTINGS_ACCOUNT
      )!;
      return concat(
        of(
          routerActions.route(StackActions.push(Pages.LOADING)),
          routerActions.setPageReadyStatus({
            key: route.key,
            value: false,
          })
        ),
        apiClient.signOutDevice(state.value.device.currentDevice!.id).pipe(
          mergeMap(() =>
            of(
              routerActions.setPageReadyStatus({
                key: route.key,
                value: true,
              }),
              loginActions.logout()
            )
          ),
          catchError((error) => {
            console.warn("Failed to sign out", error);
            return of(
              errorActions.addMessage({
                id: uuid(),
                message: "Fail to sign out, please try again later",
              }),
              routerActions.setPageReadyStatus({
                key: route.key,
                value: true,
              })
            );
          })
        )
      );
    })
  );

const logout: Epic<AnyAction, AnyAction, State, Dependencies> = (
  action$,
  state,
  { apiClient }
) =>
  action$.pipe(
    ofActionPayload(loginActions.logout),
    mergeMap(() => {
      apiClient.setAuthInfo(null, null, null);
      return Platform.OS === "web"
        ? AsyncStorage.removeItem(AUTH_TOKEN_KEY)
        : SecureStore.deleteItemAsync(AUTH_TOKEN_KEY);
    }),
    ignoreElements()
  );

const navigateToLoginAfterLogout: Epic<AnyAction, AnyAction, State> = (
  action$,
  state
) =>
  action$.pipe(
    ofActionPayload(loginActions.logout),
    mapTo(
      routerActions.route(
        CommonActions.reset({ routes: [{ name: Pages.LOGIN }] })
      )
    )
  );

const navigateToMessagesAfterLogin: Epic<AnyAction, AnyAction, State> = (
  action$,
  state
) =>
  action$.pipe(
    ofActionPayload(loginActions.login.done),
    mapTo(
      routerActions.route(
        CommonActions.reset({
          routes: [{ name: Pages.MESSAGE }, { name: Pages.LOADING }],
        })
      )
    )
  );

const loginEpic = combineEpics(
  login,
  setAPIClientDeviceInfo,
  loadAuthToken,
  refreshToken,
  updateAuthTokenAfterLoaded,
  updateAuthTokenAfterLogin,
  startLogin,
  saveToken,
  updateAPIClientAuthToken,
  signOutByUser,
  logout,
  navigateToLoginAfterLogout,
  navigateToMessagesAfterLogin
);

export default loginEpic;
