import { Checkout, PortalSession } from "../services/api/interface";
import { actionCreator } from "./shared";

export enum CheckoutSource {
  click = "click",
  auto = "auto",
}

export const prefix = "SUBSCRIPTION/";
const actions = {
  createCheckout: actionCreator.async<CheckoutSource, Checkout>(
    prefix + "CREATE_CHECKOUT"
  ),
  createPortalSession: actionCreator.async<void, PortalSession>(
    prefix + "CREATE_PORTAL_SESSION"
  ),
  onCheckout: actionCreator(prefix + "ON_CHECKOUT"),
  onManageSubscription: actionCreator(prefix + "ON_MANAGE_SUBSCRIPTION"),
};
export default actions;
