import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AnyAction } from "typescript-fsa";
import errorActions from "../../actions/Error";
import ErrorMessageList, {
  Props,
} from "../../components/Error/ErrorMessageList";
import { State } from "../../states";

const mapStateToProps = (state: State): Pick<Props, "errors"> => ({
  errors: state.error.errorIds.map((id) => {
    const error = state.error.errors[id];
    return {
      id,
      message: error.message,
      dismissing: error.dismissing,
    };
  }),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): Pick<Props, "onDismiss" | "onDismissAnimationFinish"> => {
  return {
    onDismiss: (id: string) => {
      dispatch(errorActions.dismissMessage(id));
    },
    onDismissAnimationFinish: (id: string) => {
      dispatch(errorActions.dismissMessageAnimationFinished(id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorMessageList);
