import React, { ElementType } from "react";
import { StyleSheet } from "react-native";
import { SafeAreaProvider, SafeAreaView } from "react-native-safe-area-context";

export const decorator = (Story: ElementType) => (
  <SafeAreaProvider>
    <SafeAreaView style={styles.safeArea}>
      <Story />
    </SafeAreaView>
  </SafeAreaProvider>
);

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
  },
});
