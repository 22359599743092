import { storiesOf } from "@storybook/react-native";
import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import Preview from "../../../components/Preview/Preview";

const storyName = "Components/Preview/" + Preview.name;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  preview: {
    flex: -1,
    borderWidth: 1,
    paddingHorizontal: 20,
  },
});

const thumbnail = {
  uri: "https://i.ytimg.com/vi/dQw4w9WgXcQ/maxresdefault.jpg",
  width: 1280,
  height: 720,
};

const title = "Rick Astley - Never Gonna Give You Up (Official Music Video)";
const description =
  "The official video for “Never Gonna Give You Up” by Rick AstleyTaken from the album ‘Whenever You Need Somebody’ – deluxe 2CD and digital deluxe out 6th May ...";

storiesOf(storyName, module).add("title", () => (
  <View style={styles.container}>
    <Preview style={styles.preview} title={title} />
  </View>
));

storiesOf(storyName, module).add("thumbnail", () => (
  <View style={styles.container}>
    <Preview style={styles.preview} thumbnail={thumbnail} />
  </View>
));

storiesOf(storyName, module).add("description", () => (
  <View style={styles.container}>
    <Preview style={styles.preview} description={description} />
  </View>
));

storiesOf(storyName, module).add("thumbnail and description", () => (
  <View style={styles.container}>
    <Preview
      style={styles.preview}
      thumbnail={thumbnail}
      description={description}
    />
  </View>
));

storiesOf(storyName, module).add("all", () => (
  <View style={styles.container}>
    <Preview
      style={styles.preview}
      title={title}
      thumbnail={thumbnail}
      description={description}
    />
  </View>
));
