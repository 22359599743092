import { CommonActions, findFocusedRoute } from "@react-navigation/core";
import extractPathFromURL from "@react-navigation/native/src/extractPathFromURL";
import { StackActions } from "@react-navigation/routers";
import * as Linking from "expo-linking";
import { combineEpics, Epic } from "redux-observable";
import { EMPTY, merge, of } from "rxjs";
import {
  buffer,
  filter,
  ignoreElements,
  map,
  mergeMap,
  share,
  skipUntil,
  take,
  tap,
} from "rxjs/operators";
import { Action, AnyAction } from "typescript-fsa";
import { ofActionPayload } from "typescript-fsa-redux-observable";
import appActions from "../actions/App";
import routerActions, { RoutingAction } from "../actions/Router";
import { navigationRef } from "../containers/App";
import {
  defaultSignedInPage,
  defaultSignedOutPage,
  makeGetStateFromPath,
  pageConfigs,
  Pages,
  screens,
  URLPrefixes,
} from "../routers";
import { State } from "../states";

const getInitialURL: Epic<AnyAction, AnyAction, State> = (action$, state) =>
  action$.pipe(
    ofActionPayload(routerActions.getInitialURL.started),
    mergeMap(() => Linking.getInitialURL()),
    map((url) => routerActions.getInitialURL.done({ result: url }))
  );

const makeInitialRoute: Epic<AnyAction, AnyAction, State> = (action$, state) =>
  action$.pipe(
    ofActionPayload(appActions.ready),
    mergeMap((url: string | null) => {
      let path: string = "/";
      if (url !== null) {
        const extractedPath = extractPathFromURL(URLPrefixes, url);
        if (extractedPath !== undefined) {
          path = extractedPath;
        }
      }

      console.log("Making initial route with path=", path);
      const getStateFromPath = makeGetStateFromPath(
        state.value.login.signedIn,
        state.value.router.routerState
      );
      let routerState = getStateFromPath(path, {
        initialRouteName: Pages.LOADING as any,
        screens,
      });
      if (routerState === undefined) {
        const defaultPage = state.value.login.signedIn
          ? defaultSignedInPage
          : defaultSignedOutPage;

        let defaultPath = pageConfigs[defaultPage].path;
        if (!defaultPath.startsWith("/")) {
          defaultPath = "/" + defaultPath;
        }
        if (defaultPath.endsWith("/")) {
          defaultPath = defaultPath.slice(0, defaultPath.length - 1);
        }
        console.log("Use default route page=", defaultPage);
        routerState = getStateFromPath(defaultPath, {
          initialRouteName: Pages.LOADING as any,
          screens,
        });
        if (routerState === undefined) {
          return EMPTY;
        }
      }
      console.log("Made initial route state", routerState);
      return of(routerActions.route(CommonActions.reset(routerState)));
    })
  );

const executeRouterAction: Epic<AnyAction, AnyAction, State> = (
  action$,
  state
) => {
  const stackActions = action$.pipe(
    ofActionPayload(routerActions.route),
    share()
  );
  const ready = action$.pipe(
    ofActionPayload(routerActions.onNavigatorReady),
    take(1),
    share()
  );
  // It's highly possible stack action could come in before the
  // navigator is ready, let's buffer the actions until the
  // navigator is ready then flush them all
  const bufferingActions = stackActions.pipe(
    buffer(ready),
    take(1),
    mergeMap((actions) => of(...actions))
  );
  const actionsAfterReady = stackActions.pipe(skipUntil(ready));
  return merge(bufferingActions, actionsAfterReady).pipe(
    tap((action: RoutingAction) => {
      if (navigationRef.isReady()) {
        navigationRef.dispatch({
          ...action,
          payload: {
            ...(action.payload ?? {}),
            // This let us know the action is not dispatched by user,
            // so we can allow the action when loading page is present
            byUser: false,
          },
        } as RoutingAction);
      } else {
        console.warn("navigationRef is not ready");
      }
    }),
    ignoreElements()
  );
};

const popLoadingPageWhenAllPagesAreReady: Epic<
  AnyAction,
  Action<RoutingAction>,
  State
> = (action$, state) =>
  action$.pipe(
    ofActionPayload(
      routerActions.onNavigatorStateChange,
      routerActions.setPageReadyStatus
    ),
    filter(
      () =>
        state.value.router.routerState !== undefined &&
        state.value.router.routerState.routes.length > 1 &&
        findFocusedRoute(state.value.router.routerState)?.name ===
          Pages.LOADING &&
        Object.entries(state.value.router.readyStatus).every(
          ([_, value]) => value
        )
    ),
    map(() => routerActions.route(StackActions.pop()))
  );

const routerEpic = combineEpics(
  getInitialURL,
  makeInitialRoute,
  executeRouterAction,
  popLoadingPageWhenAllPagesAreReady
);

export default routerEpic;
