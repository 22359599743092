import React, { PropsWithChildren, ReactNode } from "react";
import {
  KeyboardAvoidingView,
  Platform,
  StyleProp,
  StyleSheet,
  ViewStyle,
} from "react-native";
import { SafeAreaProvider, SafeAreaView } from "react-native-safe-area-context";
import ErrorMessageListContainer from "../containers/Message/ErrorMessageListContainer";
import ConditionalWrapper from "./ConditionalWraper";
import KeyboardDetector from "./KeyboardDetector";

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
  },
  keyboardAvoiding: {
    flex: 1,
  },
  errorMessageList: {
    position: "absolute",
    top: 10,
    left: 20,
    right: 20,
  },
});

export type Props = PropsWithChildren<{
  readonly style?: StyleProp<ViewStyle>;
  readonly displayErrors?: boolean;
  readonly keyboardAvoiding?: boolean;
  readonly keyboardDetection?: boolean;
}>;

const Page: React.FunctionComponent<Props> = ({
  style,
  children,
  displayErrors,
  keyboardAvoiding,
  keyboardDetection,
}: Props) => (
  <SafeAreaProvider>
    <SafeAreaView style={[styles.safeArea, style]}>
      <ConditionalWrapper
        render={(children: ReactNode) => (
          <KeyboardAvoidingView
            style={styles.keyboardAvoiding}
            behavior={Platform.OS === "ios" ? "padding" : "height"}
          >
            {children}
          </KeyboardAvoidingView>
        )}
        condition={keyboardAvoiding ?? true}
      >
        {keyboardDetection ? <KeyboardDetector /> : null}
        {children}
        {displayErrors ?? true ? (
          <ErrorMessageListContainer style={styles.errorMessageList} />
        ) : null}
      </ConditionalWrapper>
    </SafeAreaView>
  </SafeAreaProvider>
);
export default Page;
