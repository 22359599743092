import Mime from "mime";

// Mapping mime to font-awesome icon names
const MIME_ICON_MAP: Record<string, string> = {
  "application/pdf": "file-pdf",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    "file-word",
  "application/msword": "file-word",
  "application/vnd.ms-powerpoint": "file-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.slideshow":
    "file-powerpoint",
  "application/vnd.ms-excel": "file-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
    "file-excel",
  "text/csv": "file-csv",
  "application/x-bzip": "file-archive",
  "application/x-bzip2": "file-archive",
  "application/x-7z-compressed": "file-archive",
  "application/gzip": "file-archive",
  "application/lpf+zip": "file-archive",
  "application/tar": "file-archive",
  "application/x-compress": "file-archive",
  "application/x-compressed": "file-archive",
  "application/zip": "file-archive",
  "application/x-rar-compressed": "file-archive",
};

export const lookupMime = (filename: string) => {
  return Mime.getType(filename);
};

export const lookupIcon = (filename: string) => {
  const mime = lookupMime(filename);
  if (mime === null) {
    return "file";
  }
  const result = MIME_ICON_MAP[mime];
  if (result !== undefined) {
    return result;
  }
  if (mime.startsWith("audio/")) {
    return "file-audio";
  } else if (mime.startsWith("video/")) {
    return "file-video";
  } else if (mime.startsWith("image/")) {
    return "file-image";
  } else if (mime.startsWith("text/")) {
    return "file-alt";
  }
  return "file";
};
