import {
  SourceCodePro_700Bold,
  SourceCodePro_400Regular,
  useFonts,
} from "@expo-google-fonts/source-code-pro";
import { merge, pick } from "lodash";
import React from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { pure } from "recompose";
import SimpleMarkdown from "simple-markdown";
import { Colors } from "../Colors";
import makeRules from "./rules";
import ruleStyles from "./styles";

export const defaultWhitelist = [
  "hr",
  "paragraph",
  "blockQuote",
  "escape",
  "url",
  "hashtag",
  "link",
  "em",
  "strong",
  "u",
  "del",
  "inlineCode",
  "text",
  "codeBlock",
  "fence",
  "list",
];

export const fullWhitelist = [...defaultWhitelist, "heading"];

const styles = StyleSheet.create({
  inlineCodeSourcePro: {
    backgroundColor: "#eeeeee",
    borderColor: "#dddddd",
    borderRadius: 3,
    borderWidth: 1,
    fontWeight: "bold",
    fontFamily: "SourceCodePro_700Bold",
  },
  codeBlockSourcePro: {
    fontFamily: "SourceCodePro_400Regular",
    backgroundColor: Colors.CodeBackground,
    borderColor: Colors.CodeBorder,
    borderRadius: 3,
    borderWidth: 1,
    padding: 5,
  },
});

export interface Props {
  readonly id: string;
  readonly message: string;
  readonly style?: StyleProp<ViewStyle>;
  readonly styles?: Record<string, ViewStyle>;
  readonly whitelist?: Array<string>;
  readonly onLinkPressed?: (id: string, url: string) => void;
  readonly onHashtagPressed?: (id: string, hashtag: string) => void;
}

const Markdown: React.FunctionComponent<Props> = ({
  id,
  message,
  style,
  whitelist,
  styles: propStyles,
  onLinkPressed,
  onHashtagPressed,
}) => {
  let [fontsLoaded] = useFonts({
    SourceCodePro_700Bold,
    SourceCodePro_400Regular,
  });
  const mergedStyles = merge(
    ruleStyles,
    propStyles,
    fontsLoaded
      ? {
          inlineCode: styles.inlineCodeSourcePro,
          codeBlock: styles.codeBlockSourcePro,
        }
      : {}
  );
  const mergedRules = pick(
    merge(
      SimpleMarkdown.defaultRules,
      makeRules({
        styles: mergedStyles,
        onUrlPress: (url: string) => {
          onLinkPressed?.(id, url);
        },
        onHashtagPress: (hashtag: string) => {
          onHashtagPressed?.(id, hashtag);
        },
      })
    ),
    whitelist ?? defaultWhitelist
  );
  const parser = SimpleMarkdown.parserFor(mergedRules);
  const tree = parser(message, {
    inline: false,
  });
  var reactOutput = SimpleMarkdown.outputFor(mergedRules, "react");
  return <View style={style}>{reactOutput(tree)}</View>;
};

export default pure(Markdown);
