import { isArray, isString, truncate } from "lodash";
import { combineEpics, Epic } from "redux-observable";
import { EMPTY, of } from "rxjs";
import { filter, mergeMap } from "rxjs/operators";
import { Action, ActionCreator, AnyAction } from "typescript-fsa";
import { v4 as uuid } from "uuid";
import errorActions, { ErrorMessage } from "../actions/Error";
import inputActions, { PickFileCancelError } from "../actions/Input";
import loginActions from "../actions/Login";
import userActions from "../actions/User";
import messageActions from "../actions/Message";
import settingsActions from "../actions/Settings";
import signUpActions from "../actions/SignUp";
import subscriptionActions from "../actions/Subscription";
import forgotPasswordActions from "../actions/ForgotPassword";
import {
  APIClientError,
  XMLHTTPRequestAPIClientError,
} from "../services/api/client";
import { truncateMiddle } from "../shared/utils";
import { Message, State } from "../states";
import { Dependencies } from "./dependencies";

// Notice: we learn following trick from
// https://github.com/dphilipson/typescript-fsa-reducers/blob/a8086ec0667ceea2a150e2f1cc76bd2ab7d4461c/src/index.ts
export type ErrorHandler<S, P> = (state: S, payload: P) => string | undefined;

const extractAPIErrorMessage = (error: any): string | null => {
  if (
    error instanceof APIClientError &&
    error.response.status >= 400 &&
    error.response.status < 500 &&
    error.jsonPayload.detail !== undefined
  ) {
    const { detail } = error.jsonPayload;
    if (isString(detail)) {
      return detail;
    } else if (isArray(detail)) {
      return detail
        .map((item) => {
          let loc: string = "";
          if (
            item.loc !== undefined &&
            isArray(item.loc) &&
            item.loc.length > 0
          ) {
            loc = item.loc[item.loc.length - 1];
          }
          return [loc, item.msg].join(": ");
        })
        .join(", ");
    }
  }
  return null;
};

export interface ErrorHandlerBuilder<S> {
  case<P>(
    actionCreator: ActionCreator<P>,
    handler: ErrorHandler<S, P>
  ): ErrorHandlerBuilder<S>;

  handle(state: S, action: AnyAction): string | undefined;
}

const makeErrorHandlerBuild = <S>() => {
  const builder: ErrorHandlerBuilder<S> = {} as ErrorHandlerBuilder<S>;
  const handlers: Record<any, ErrorHandler<S, any>> = {};
  builder.case = <P>(
    actionCreator: ActionCreator<P>,
    handler: ErrorHandler<S, P>
  ) => {
    handlers[actionCreator.type] = handler;
    return builder;
  };

  builder.handle = (state: S, action: Action<any>) => {
    const handler = handlers[action.type];
    if (handler === undefined) {
      return undefined;
    }
    return handler(state, action.payload);
  };
  return builder;
};

const errorHandler = makeErrorHandlerBuild<State>()
  .case(
    inputActions.sendMessage.failed,
    (state, { params }) =>
      `Failed to post message "${truncate(
        params.content
      )}", please try again later`
  )
  .case(
    messageActions.updateMessage.failed,
    (state, { params }) =>
      `Failed to update message "${truncate(
        params.content
      )}", please try again later`
  )
  .case(
    messageActions.deleteConfirmedMessage.failed,
    (state, { params }) =>
      `Failed to delete message "${truncate(
        state.messages.messages[params].content
      )}"`
  )
  .case(
    messageActions.deletePreview.failed,
    (state, { params }) =>
      `Failed to delete preview for "${truncate(
        (state.messages.messages[params.messageId] as Message).previews[
          params.previewId
        ].url
      )}"`
  )
  .case(
    messageActions.downloadFile.failed,
    (state, { params: { messageId, fileId } }) =>
      `Failed to download file "${truncateMiddle(
        (state.messages.messages[messageId] as Message).files?.find(
          (file) => file.id === fileId
        )?.name ?? "unknown",
        50
      )}"`
  )
  .case(inputActions.uploadFile.failed, (state, { params, error }) => {
    if (error instanceof XMLHTTPRequestAPIClientError) {
      if (error.request.status === 413)
        return `Failed to upload file "${truncateMiddle(
          params.name,
          50
        )}" with file size exceeds limit error`;
    }
    return `Failed to upload file "${truncateMiddle(params.name, 50)}"`;
  })
  .case(inputActions.pickUploadingFile.failed, (_, { error }) =>
    error instanceof PickFileCancelError ? undefined : "Failed to pick the file"
  )
  .case(
    loginActions.login.failed,
    (_, { error }) => extractAPIErrorMessage(error) ?? "Failed to login"
  )
  .case(
    signUpActions.signUp.failed,
    (_, { error }) => extractAPIErrorMessage(error) ?? "Failed to sign up"
  )
  .case(
    settingsActions.updatePassword.failed,
    (_, { error }) =>
      extractAPIErrorMessage(error) ?? "Failed to update password"
  )
  .case(
    forgotPasswordActions.resetPassword.failed,
    (_, { error }) =>
      extractAPIErrorMessage(error) ?? "Failed to reset password"
  )
  .case(
    forgotPasswordActions.forgotPassword.failed,
    (_, { error }) =>
      extractAPIErrorMessage(error) ?? "Failed to request password reset"
  )
  .case(
    subscriptionActions.createCheckout.failed,
    (_, { error }) =>
      extractAPIErrorMessage(error) ??
      "Failed to checkout, please try again later or contact customer support for help"
  )
  .case(
    userActions.deleteAccount.failed,
    (_, { error }) =>
      extractAPIErrorMessage(error) ??
      "Failed to delete account, please try again later or contact customer support for help"
  );
const showErrorMessage: Epic<AnyAction, Action<ErrorMessage>, State> = (
  action$,
  state
) =>
  action$.pipe(
    mergeMap((action) => {
      const message = errorHandler.handle(state.value, action);
      if (message !== undefined) {
        return of(
          errorActions.addMessage({
            id: uuid(),
            message,
          })
        );
      }
      return EMPTY;
    })
  );

const logoutForAuthenticationErrorResponse: Epic<
  AnyAction,
  AnyAction,
  State
> = (action$, state) =>
  action$.pipe(
    filter((action) => (action as Action<any>).error ?? false),
    mergeMap((action) => {
      const { error } = (action as Action<any>).payload;
      if (
        (error instanceof APIClientError && error.response.status === 403) ||
        (error instanceof XMLHTTPRequestAPIClientError &&
          error.request.status === 403)
      ) {
        return of(loginActions.logout());
      }
      return EMPTY;
    })
  );

const errorEpic = combineEpics<AnyAction, AnyAction, State, Dependencies>(
  showErrorMessage,
  logoutForAuthenticationErrorResponse
);

export default errorEpic;
