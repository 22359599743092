import React from "react";
import { StyleSheet, Pressable, View } from "react-native";
import { Colors } from "../Colors";
import IconButton from "../IconButton";
import SubscriptionView, {
  Props as SubscriptionViewProps,
} from "./SubscriptionView";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 50,
    backgroundColor: Colors.MediumGrey,
  },
  subscription: {
    flex: 1,
    backgroundColor: "white",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
  },
  background: {
    position: "absolute",
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  },
  closeButton: {
    left: -20,
    top: -20,
    width: 40,
    height: 40,
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Colors.LightGrey,
    borderRadius: 20,
  },
});

export interface Props extends SubscriptionViewProps {
  readonly onDismiss?: () => void;
}

const SubscriptionModal: React.FunctionComponent<Props> = ({
  style,
  onCheckout,
  onDismiss,
}: Props) => {
  return (
    <View style={[styles.container, style]}>
      <Pressable
        style={styles.background}
        onPress={() => onDismiss?.()}
      ></Pressable>
      <View style={styles.subscription}>
        <View style={styles.closeButton}>
          <IconButton
            name="times"
            size={25}
            color="black"
            onPress={() => onDismiss?.()}
          />
        </View>
        <SubscriptionView onCheckout={onCheckout}></SubscriptionView>
      </View>
    </View>
  );
};
export default SubscriptionModal;
