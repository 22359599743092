import * as Notifications from "expo-notifications";
import { combineEpics, Epic } from "redux-observable";
import { Observable } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { AnyAction } from "typescript-fsa";
import { ofActionPayload } from "typescript-fsa-redux-observable";
import appActions from "../actions/App";
import notificationActions from "../actions/Notification";
import { State } from "../states";

const startNotificationRequestListener: Epic<AnyAction, AnyAction, State> = (
  action$,
  state
) =>
  action$.pipe(
    ofActionPayload(appActions.init),
    mergeMap(
      () =>
        new Observable<Notifications.Notification>((observer) => {
          const subscription = Notifications.addNotificationReceivedListener(
            (notification) => {
              observer.next(notification);
            }
          );
          return () => {
            subscription.remove();
          };
        })
    ),
    map((notification) => notificationActions.receivedRequest(notification))
  );

const startNotificationResponseListener: Epic<AnyAction, AnyAction, State> = (
  action$,
  state
) =>
  action$.pipe(
    ofActionPayload(appActions.init),
    mergeMap(
      () =>
        new Observable<Notifications.NotificationResponse>((observer) => {
          const subscription =
            Notifications.addNotificationResponseReceivedListener(
              (response) => {
                observer.next(response);
              }
            );
          return () => {
            subscription.remove();
          };
        })
    ),
    map((response) => notificationActions.receivedResponse(response))
  );

const notificationEpic = combineEpics(
  startNotificationRequestListener,
  startNotificationResponseListener
);

export default notificationEpic;
