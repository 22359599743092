import { actionCreator } from "./shared";
import { AuthToken } from "../services/api/interface";

export interface Credentials {
  readonly username: string;
  readonly password: string;
}

export interface DeviceInfo {
  readonly localId: string;
  readonly name: string | null;
  readonly brand: string | null;
  readonly manufacturer: string | null;
  readonly modelName: string | null;
  readonly modelId: string | null;
  readonly osName: string | null;
  readonly osVersion: string | null;
  readonly osBuildId: string | null;
  readonly isDevice: boolean;
}

export interface LoginInfo {
  readonly credentials: Credentials;
}

export enum AuthTokenSource {
  STORE = "STORE",
  LOGIN = "LOGIN",
}

export const prefix = "LOGIN/";
const actions = {
  onSignIn: actionCreator<Credentials>(prefix + "ON_SIGN_IN"),
  onSignUp: actionCreator(prefix + "ON_SIGN_UP"),
  onForgotPassword: actionCreator(prefix + "ON_FORGOT_PASSWORD"),
  login: actionCreator.async<LoginInfo, AuthToken>(prefix + "LOGIN"),
  logout: actionCreator(prefix + "LOGOUT"),
  signOutByUser: actionCreator(prefix + "SIGN_OUT_BY_USER"),
  loadAuthToken: actionCreator.async<void, AuthToken | null>(
    prefix + "LOAD_AUTH_TOKEN"
  ),
  updateAuthToken: actionCreator.async<
    { token: AuthToken; source: AuthTokenSource },
    undefined
  >(prefix + "UPDATE_AUTH_TOKEN"),
  refreshToken: actionCreator<AuthToken>(prefix + "REFRESH_TOKEN"),
};
export default actions;
