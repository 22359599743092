import React, { FunctionComponent, useEffect, useRef } from "react";
import {
  Animated,
  Easing,
  StyleProp,
  StyleSheet,
  ViewStyle,
} from "react-native";
import { Colors } from "../Colors";
import IconButton from "../IconButton";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

export interface Props {
  readonly height?: number;
  readonly visible: boolean;
  readonly style?: StyleProp<ViewStyle>;
  readonly onSave?: () => void;
  readonly onCancel?: () => void;
  readonly onDismissAnimationFinished?: () => void;
}

const EditMenu: FunctionComponent<Props> = ({
  style,
  visible,
  height: heightProp,
  onSave,
  onCancel,
  onDismissAnimationFinished,
}: Props) => {
  const animatedProgress = useRef(new Animated.Value(0)).current;
  const easing = Easing.inOut(Easing.cubic);
  const duration = 100;

  useEffect(() => {
    Animated.timing(animatedProgress, {
      toValue: visible ? 1.0 : 0,
      easing,
      duration,
      useNativeDriver: false,
    }).start(({ finished }) => {
      if (!finished || visible) {
        return;
      }
      onDismissAnimationFinished?.();
    });
  }, [visible]);

  return (
    <Animated.View
      style={[
        styles.container,
        {
          opacity: animatedProgress,
          height: animatedProgress.interpolate({
            inputRange: [0, 1],
            outputRange: [0, heightProp ?? 40],
          }),
        },
        style,
      ]}
      pointerEvents={visible ? "box-none" : "none"}
    >
      <IconButton name="check" text="Save" onPress={onSave} />
      <IconButton
        name="times"
        text="Cancel"
        color={Colors.Danger}
        onPress={onCancel}
      />
    </Animated.View>
  );
};

export default EditMenu;
