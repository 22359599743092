import React, { FunctionComponent } from "react";
import { FlatList, StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import ErrorMessage from "./ErrorMessage";

const styles = StyleSheet.create({
  animatedContainer: {},
  container: {
    flex: -1,
  },
  list: {
    flexGrow: 0,
  },
  message: {
    paddingVertical: 5,
    paddingHorizontal: 10,
    marginBottom: 10,
  },
});

export interface ErrorItem {
  readonly id: string;
  readonly message: string;
  readonly dismissing?: boolean;
}

export interface Props {
  readonly errors: Array<ErrorItem>;
  readonly style?: StyleProp<ViewStyle>;
  readonly onDismiss?: (id: string) => void;
  readonly onDismissAnimationFinish?: (id: string) => void;
}

const ErrorMessageList: FunctionComponent<Props> = ({
  style,
  errors,
  onDismiss,
  onDismissAnimationFinish,
}: Props) => (
  <View style={[styles.animatedContainer, style]}>
    <View style={[styles.container]}>
      <FlatList
        data={errors}
        style={[styles.list]}
        keyboardShouldPersistTaps="always"
        renderItem={({ item }) => (
          <ErrorMessage
            id={item.id}
            style={styles.message}
            dismissing={item.dismissing}
            message={item.message}
            onDismiss={onDismiss}
            onDismissAnimationFinish={onDismissAnimationFinish}
          />
        )}
        keyExtractor={({ id }) => id}
      />
    </View>
  </View>
);

export default ErrorMessageList;
