import { storiesOf } from "@storybook/react-native";
import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import InputField from "../../../components/Input/InputField";

const storyName = "Components/Input/" + InputField.name;

const placeholder = "Type something here";
const shortText = "Some text here";
const longText =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.";
const multipleLineText = `- First
- Second
- Third
- Forth`;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    paddingHorizontal: 10,
  },
});

storiesOf(storyName, module).add("with placeholder", () => (
  <View style={styles.container}>
    <InputField
      placeholder={placeholder}
      onSubmit={() => {}}
      onChangeText={() => {}}
    />
  </View>
));

storiesOf(storyName, module).add("with short text", () => (
  <View style={styles.container}>
    <InputField value={shortText} onSubmit={() => {}} onChangeText={() => {}} />
  </View>
));

storiesOf(storyName, module).add("with long text", () => (
  <View style={styles.container}>
    <InputField value={longText} onSubmit={() => {}} onChangeText={() => {}} />
  </View>
));

storiesOf(storyName, module).add("with multiline text", () => (
  <View style={styles.container}>
    <InputField
      value={multipleLineText}
      onSubmit={() => {}}
      onChangeText={() => {}}
    />
  </View>
));
