import React, { Component, ReactElement } from "react";
import {
  LayoutChangeEvent,
  Platform,
  SectionList,
  SectionListData,
  SectionListProps,
  SectionListRenderItemInfo,
  StyleProp,
  StyleSheet,
  ViewStyle,
  ViewToken,
} from "react-native";
import sectionListGetItemLayout from "react-native-section-list-get-item-layout";
import { Subscription, timer } from "rxjs";
import { map, skipWhile, take, tap } from "rxjs/operators";
import DateSeparator from "../DateSeparator";
import Loading from "../Loading";
import InvertedSectionList from "./InvertedSectionListShim";

const styles = StyleSheet.create({
  dateSeparator: {
    height: 48,
    paddingHorizontal: 8,
  },
  loading: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: "white",
  },
});

export interface DateSection {
  readonly date: Date;
  readonly data: Array<string>;
}

export interface Props {
  readonly sections: DateSection[];
  readonly scrollCounter?: number;
  readonly scrollTarget?: string;
  readonly ready?: boolean;
  readonly today?: Date;
  readonly style?: StyleProp<ViewStyle>;
  readonly renderMessage: (
    id: string,
    onLayoutUpdate: (event: LayoutChangeEvent) => void
  ) => ReactElement;
  readonly onViewingLatestChanged?: (viewingLatest: boolean) => void;
}

interface State {
  readonly initialScrollDone: boolean;
}

class MessageList extends Component<Props, State> {
  private listRef: SectionList<string, DateSection> | null = null;
  private isLatestMessageVisible: boolean = false;
  private getItemLayout: (
    data: SectionListData<string, DateSection>[] | null,
    index: number
  ) => { length: number; offset: number; index: number };
  private itemHeights: Record<string, number> = {};
  private onLayoutCache: Record<string, (event: LayoutChangeEvent) => void> =
    {};
  private initialScrollSubscription?: Subscription;

  state: State = {
    initialScrollDone: false,
  };

  constructor(props: Props) {
    super(props);
    // ref: https://medium.com/@jsoendermann/sectionlist-and-getitemlayout-2293b0b916fb
    this.getItemLayout = sectionListGetItemLayout({
      // The height of the row with rowData at the given sectionIndex and rowIndex
      // TODO: calcualte text length based on window size
      getItemHeight: (id: string, sectionIndex: number, rowIndex: number) => {
        if (id in this.itemHeights) {
          return this.itemHeights[id];
        }
        return 57;
      },
      getSectionHeaderHeight: () => 48,
    }) as any;
  }

  componentDidUpdate(prevProps: Props) {
    if (!this.isLatestMessageVisible && this.props.sections.length == 0) {
      // If there's no message, we should consider it latest message visible regardless
      this.isLatestMessageVisible = true;
      this.props.onViewingLatestChanged?.(this.isLatestMessageVisible);
    }

    if (
      Platform.OS === "web" &&
      this.props.ready !== prevProps.ready &&
      (this.props.ready ?? false) &&
      !this.state.initialScrollDone &&
      this.initialScrollSubscription === undefined
    ) {
      this.initialScrollSubscription = timer(0, 100)
        .pipe(
          tap(() => {
            this.listRef
              ?.getScrollResponder()
              ?.scrollToEnd({ animated: false });
          }),
          map(() => this.isLatestMessageVisible),
          skipWhile((visible) => !visible),
          take(1)
          // TODO: add a timeout here
        )
        .subscribe(() => {
          this.setState({ initialScrollDone: true });
        });
    }
    if (this.props.scrollCounter === prevProps.scrollCounter) {
      return;
    }
    if (this.props.scrollTarget !== undefined) {
      let sectionIndex = 0;
      let itemIndex = -1;
      for (const section of this.props.sections) {
        itemIndex = section.data.findIndex(
          (id) => id === this.props.scrollTarget
        );
        if (itemIndex !== -1) {
          break;
        }
        sectionIndex += 1;
      }
      if (itemIndex === -1) {
        return;
      }
      if (this.listRef === null) {
        return;
      }
      console.debug(
        "Scroll to sectionIndex=",
        sectionIndex,
        "itemIndex=",
        itemIndex,
        "id=",
        this.props.scrollTarget
      );
      this.listRef.scrollToLocation({
        itemIndex: itemIndex + 1,
        sectionIndex,
        animated: true,
        viewPosition: 0.5,
      });
      return;
    }
    const lastSectionIndex = this.props.sections.length - 1;
    const lastSection =
      this.props.sections.length > 0
        ? this.props.sections[lastSectionIndex]
        : undefined;

    if (this.listRef === null || lastSection === undefined) {
      return;
    }
    if (Platform.OS === "web") {
      this.listRef.scrollToLocation({
        // Notice: the header also counts as one item, so we need to use the total item count in this section
        itemIndex: lastSection.data.length,
        sectionIndex: lastSectionIndex,
        animated: true,
      });
    } else {
      this.listRef.scrollToLocation({
        itemIndex: 0,
        sectionIndex: 0,
        animated: true,
      });
    }
  }

  componentWillUnmount() {
    this.initialScrollSubscription?.unsubscribe();
  }

  render() {
    const props: SectionListProps<string, DateSection> = {
      style: this.props.style,
      sections: this.props.sections,
      stickySectionHeadersEnabled: true,
      keyExtractor: (item) => item,
      renderItem: this.renderItem,
      getItemLayout: this.getItemLayout,
      onViewableItemsChanged: this.onViewableItemsChanged,
    };

    if (Platform.OS === "web") {
      return (
        <>
          <SectionList
            ref={(ref) => (this.listRef = ref)}
            keyboardDismissMode={"none"}
            renderSectionHeader={this.renderSectionHeader}
            {...props}
          />
          {this.state.initialScrollDone ? null : (
            <Loading style={styles.loading} />
          )}
        </>
      );
    }
    return (
      <InvertedSectionList
        ref={(ref) => (this.listRef = ref as any)}
        keyboardDismissMode={"on-drag"}
        renderSectionFooter={this.renderSectionHeader}
        {...props}
      />
    );
  }

  private renderItem = ({
    item,
  }: SectionListRenderItemInfo<string, DateSection>) => {
    if (!(item in this.onLayoutCache)) {
      // TODO: find a way to clean cache and update when window size changed
      this.onLayoutCache[item] = (event) => {
        const { height } = event.nativeEvent.layout;
        this.itemHeights[item] = height;
      };
    }
    return this.props.renderMessage(item, this.onLayoutCache[item]);
  };

  private renderSectionHeader = (info: {
    section: SectionListData<string, DateSection>;
  }) => {
    return (
      <DateSeparator
        date={info.section.date}
        style={styles.dateSeparator}
        today={this.props.today}
        autoRotate
      />
    );
  };

  private onViewableItemsChanged = (info: {
    viewableItems: Array<ViewToken>;
    changed: Array<ViewToken>;
  }) => {
    //
    const lastSectionIndex =
      Platform.OS === "web" ? this.props.sections.length - 1 : 0;
    const lastSection =
      this.props.sections.length > 0
        ? this.props.sections[lastSectionIndex]
        : undefined;

    if (lastSection === undefined) {
      return;
    }
    const latestMessageViewable = info.viewableItems.find(
      (change) =>
        change.section != undefined &&
        change.section.date.valueOf() === lastSection.date.valueOf() &&
        ((Platform.OS === "web" &&
          // it's a bit odd sometimes the last item won't be in the viewable list,
          // so let's extend the viewable items to latest 3 as viewing the latest
          change.index! >= Math.max(lastSection.data.length - 1 - 2, 0)) ||
          (Platform.OS !== "web" && change.index! <= 2))
    );
    // If the viewable includes the latest message item
    const isLatestMessageVisible = latestMessageViewable?.isViewable ?? false;
    if (isLatestMessageVisible !== this.isLatestMessageVisible) {
      this.props.onViewingLatestChanged?.(isLatestMessageVisible);
    }
    this.isLatestMessageVisible = isLatestMessageVisible;
  };
}

export default MessageList;
