import {
  NavigationAction,
  ParamListBase,
  StackActionType,
  CommonActions,
  StackNavigationState,
  Route,
} from "@react-navigation/native";
import { Pages } from "../routers";
import { actionCreator } from "./shared";

export const prefix = "ROUTER/";

export type RoutingAction = (StackActionType | CommonActions.Action) & {
  readonly payload?: {
    readonly byUser?: boolean;
  };
};

export interface PageReadyStatus {
  readonly key: string;
  readonly value: boolean;
}

const actions = {
  getInitialURL: actionCreator.async<void, string | null>(
    prefix + "GET_INITIAL_URL"
  ),
  setPreventNavigation: actionCreator<boolean>(
    prefix + "SET_PREVENT_NAVIGATION"
  ),
  setPageReadyStatus: actionCreator<PageReadyStatus>(
    prefix + "SET_PAGE_READY_STATUS"
  ),
  route: actionCreator<RoutingAction>(prefix + "ROUTE"),
  onNavigatorReady: actionCreator(prefix + "ON_NAVIGATOR_READY"),
  onNavigatorStateChange: actionCreator<StackNavigationState<ParamListBase>>(
    prefix + "ON_NAVIGATOR_STATE_CHANGE"
  ),
  onNavigatorBeforeRemove: actionCreator<NavigationAction>(
    prefix + "ON_NAVIGATOR_BEFORE_REMOVE"
  ),
  onPageFocus: actionCreator<Pages>(prefix + "ON_PAGE_FOCUS"),
  onPageBlur: actionCreator<Pages>(prefix + "ON_PAGE_BLUR"),
  onPageMount: actionCreator<Route<Pages>>(prefix + "ON_PAGE_MOUNT"),
  onPageUnmount: actionCreator<Pages>(prefix + "ON_PAGE_UNMOUNT"),
};

export default actions;
