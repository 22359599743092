import React, { FunctionComponent, useRef, useState, useEffect } from "react";
import {
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
  Animated,
  Easing,
  ReturnKeyTypeOptions,
} from "react-native";
import IconButton from "../IconButton";
import InputField from "./InputField";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
  },
  input: {
    flex: -1,
    flexGrow: 1,
    marginBottom: 2,
  },
  menuButtonContainer: {
    flex: -1,
    alignItems: "center",
    justifyContent: "flex-end",
  },
  menuButton: {
    marginHorizontal: 5,
    justifyContent: "flex-end",
  },
  sendButtonContainer: {
    flex: -1,
    marginHorizontal: 5,
    width: 36,
    alignItems: "center",
    justifyContent: "flex-end",
  },
  sendButtonIcon: {},
  animatingSendButton: {
    position: "absolute",
    flex: -1,
    width: 36,
    alignItems: "center",
  },
});

export interface Props {
  readonly value: string;
  readonly autoFocus?: boolean;
  readonly placeholder?: string;
  readonly icon?: string;
  readonly style?: StyleProp<ViewStyle>;
  readonly returnKeyType?: ReturnKeyTypeOptions;
  readonly focusCounter?: number;
  readonly multiline?: boolean;
  readonly onChangeText: (text: string) => void;
  readonly onInputSubmit?: () => void;
  readonly onSend: () => void;
  readonly onMenu: () => void;
  readonly onBackspace?: () => void;
}

const InputBar: FunctionComponent<Props> = ({
  value,
  autoFocus,
  placeholder,
  style,
  icon,
  returnKeyType,
  focusCounter,
  multiline,
  onChangeText,
  onSend,
  onMenu,
  onInputSubmit,
  onBackspace,
}: Props) => {
  const iconValue = icon ?? "paper-plane";
  const [currentIcon, setCurrentIcon] = useState(iconValue);
  const [newIcon, setNewIcon] = useState<string | null>(null);

  const animatedProgress = useRef(new Animated.Value(0)).current;
  const easing = Easing.inOut(Easing.cubic);
  const duration = 300;

  useEffect(() => {
    if (iconValue === currentIcon || newIcon !== null) {
      return;
    }
    setNewIcon(iconValue);

    animatedProgress.setValue(0);
    Animated.timing(animatedProgress, {
      toValue: 1,
      easing,
      duration,
      useNativeDriver: false,
    }).start(({ finished }) => {
      if (!finished) {
        return;
      }
      setCurrentIcon(iconValue);
      setNewIcon(null);
    });
  }, [iconValue]);
  const offset = 50;

  return (
    <View style={[styles.container, style]}>
      <View style={styles.menuButtonContainer}>
        <IconButton
          name="bars"
          size={25}
          onPress={onMenu}
          style={styles.menuButton}
        />
      </View>
      <InputField
        value={value}
        onChangeText={onChangeText}
        onSubmit={onInputSubmit}
        onBackspace={onBackspace}
        placeholder={placeholder}
        autoFocus={autoFocus}
        style={styles.input}
        returnKeyType={returnKeyType}
        focusCounter={focusCounter}
        multiline={multiline}
      />
      <View style={styles.sendButtonContainer}>
        <Animated.View
          style={[
            newIcon !== null
              ? {
                  transform: [
                    {
                      translateX: animatedProgress.interpolate({
                        inputRange: [0, 1],
                        outputRange: [0, offset],
                      }),
                    },
                  ],
                  opacity: animatedProgress.interpolate({
                    inputRange: [0, 1],
                    outputRange: [1, 0],
                  }),
                }
              : null,
          ]}
        >
          <IconButton
            name={currentIcon}
            size={25}
            onPress={onSend}
            style={styles.sendButtonIcon}
          />
        </Animated.View>
        {newIcon !== null ? (
          <Animated.View
            style={[
              styles.animatingSendButton,
              {
                transform: [
                  {
                    translateX: animatedProgress.interpolate({
                      inputRange: [0, 1],
                      outputRange: [offset, 0],
                    }),
                  },
                ],
                opacity: animatedProgress,
              },
            ]}
          >
            <IconButton
              name={newIcon}
              size={25}
              style={styles.sendButtonIcon}
            />
          </Animated.View>
        ) : null}
      </View>
    </View>
  );
};

export default InputBar;
