import { FontAwesome5 } from "@expo/vector-icons";
import React, { useEffect, useRef } from "react";
import {
  NativeSyntheticEvent,
  StyleProp,
  StyleSheet,
  Text,
  TextInput,
  TextInputProps,
  TextInputSubmitEditingEventData,
  View,
  ViewStyle,
} from "react-native";

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
    flexDirection: "row",
    paddingVertical: 14,
    paddingHorizontal: 20,
    alignItems: "center",
    justifyContent: "space-between",
  },
  iconTitleContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  icon: {
    paddingRight: 10,
  },
  title: {
    fontSize: 18,
    color: "black",
  },
  text: {
    fontSize: 18,
    color: "black",
  },
  textWithoutTitle: {
    width: "100%",
  },
});

export type SelectedTextInputProps = Pick<
  TextInputProps,
  | "textContentType"
  | "autoComplete"
  | "secureTextEntry"
  | "placeholder"
  | "value"
  | "defaultValue"
  | "editable"
  | "returnKeyType"
  | "autoFocus"
>;

export interface Props extends SelectedTextInputProps {
  readonly style?: StyleProp<ViewStyle>;
  readonly icon?: string;
  readonly title?: string;
  readonly focusCounter?: number;
  readonly onChangeText?: (value: string) => void;
  readonly onSubmitEditing?: (
    event: NativeSyntheticEvent<TextInputSubmitEditingEventData>
  ) => void;
}

const MenuItemTextInput: React.FunctionComponent<Props> = ({
  style,
  icon,
  title,
  focusCounter,
  onChangeText,
  onSubmitEditing,
  ...props
}: Props) => {
  const textRef = useRef<TextInput>(null);
  useEffect(() => {
    if (focusCounter === undefined) {
      return;
    }
    textRef.current?.focus();
  }, [focusCounter]);
  return (
    <View style={[styles.container, style]}>
      <View style={styles.iconTitleContainer}>
        {icon !== undefined ? (
          <FontAwesome5 name={icon} size={18} style={styles.icon} />
        ) : null}
        {title !== undefined ? <Text style={styles.title}>{title}</Text> : null}
      </View>
      <TextInput
        ref={textRef}
        style={[
          styles.text,
          icon === undefined && title === undefined
            ? styles.textWithoutTitle
            : null,
        ]}
        onChangeText={onChangeText}
        onSubmitEditing={onSubmitEditing}
        {...props}
      />
    </View>
  );
};

export default MenuItemTextInput;
