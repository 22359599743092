import { action } from "@storybook/addon-actions";
import { storiesOf } from "@storybook/react-native";
import React from "react";
import { StyleSheet, View } from "react-native";
import { Colors } from "../../components/Colors";
import IconButton from "../../components/IconButton";

const storyName = "Components/" + IconButton.name;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    paddingHorizontal: 20,
  },
  signInButton: {
    height: 40,
    backgroundColor: Colors.Launch,
    borderRadius: 10,
    justifyContent: "center",
    marginBottom: 10,
  },
});

storiesOf(storyName, module).add("retry with text", () => (
  <View style={styles.container}>
    <IconButton name="redo" text="Retry" onPress={action("click")} />
  </View>
));

storiesOf(storyName, module).add("share with text", () => (
  <View style={styles.container}>
    <IconButton name="share" text="Share" onPress={action("click")} />
  </View>
));

storiesOf(storyName, module).add("delete with text", () => (
  <View style={styles.container}>
    <IconButton
      name="trash-alt"
      text="Delete"
      color={Colors.Danger}
      onPress={action("click")}
    />
  </View>
));

storiesOf(storyName, module).add("send just icon", () => (
  <View style={styles.container}>
    <IconButton name="paper-plane" onPress={action("click")} />
  </View>
));

storiesOf(storyName, module).add("sign-in button", () => (
  <View style={styles.container}>
    <IconButton
      name="sign-in-alt"
      text="SIGN IN"
      color="white"
      size={18}
      style={styles.signInButton}
      activityIndicatorColor="white"
    />
  </View>
));

storiesOf(storyName, module).add("sign-in button loading", () => (
  <View style={styles.container}>
    <IconButton
      name="sign-in-alt"
      text="SIGN IN"
      color="white"
      size={18}
      style={styles.signInButton}
      activityIndicatorColor="white"
      loading
    />
  </View>
));

storiesOf(storyName, module).add("sign-in button disabled", () => (
  <View style={styles.container}>
    <IconButton
      name="sign-in-alt"
      text="SIGN IN"
      color="white"
      size={18}
      style={styles.signInButton}
      activityIndicatorColor="white"
      disabled
    />
  </View>
));
