import { storiesOf } from "@storybook/react-native";
import React from "react";
import { StyleSheet } from "react-native";
import SubscriptionModal from "../../../components/Subscription/SubscriptionModal";

const storyName = "Components/Subscription/" + SubscriptionModal.name;

const styles = StyleSheet.create({
  subscriptionModal: {
    flex: 1,
  },
});

storiesOf(storyName, module).add("modal", () => (
  <SubscriptionModal style={styles.subscriptionModal} />
));
