import { FontAwesome5 } from "@expo/vector-icons";
import React, { FunctionComponent } from "react";
import {
  ActivityIndicator,
  Insets,
  StyleProp,
  StyleSheet,
  Text,
  TouchableOpacity,
  ViewStyle,
} from "react-native";
import { Colors } from "./Colors";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    padding: 5,
    flex: -1,
    alignItems: "center",
  },
  icon: {},
  text: {
    marginLeft: 10,
  },
});

export interface Props {
  readonly name: string;
  readonly text?: string;
  readonly color?: string;
  readonly size?: number;
  readonly style?: StyleProp<ViewStyle>;
  readonly disabled?: boolean;
  readonly activityIndicatorColor?: string;
  readonly loading?: boolean;
  readonly hitSlop?: Insets;
  readonly onPress?: () => void;
}

const IconButton: FunctionComponent<Props> = ({
  name,
  text,
  color,
  size,
  style,
  disabled,
  loading,
  activityIndicatorColor,
  hitSlop,
  onPress,
}: Props) => {
  const fontSize = size ?? 14;
  const mainColor = color ?? Colors.Launch;
  const disabledValue = (disabled ?? false) || (loading ?? false);
  return (
    <TouchableOpacity
      style={[styles.container, disabledValue ? { opacity: 0.5 } : null, style]}
      onPress={onPress}
      disabled={disabledValue}
      hitSlop={hitSlop}
    >
      {loading ? (
        <ActivityIndicator color={activityIndicatorColor} />
      ) : (
        <>
          <FontAwesome5
            name={name}
            size={fontSize}
            color={mainColor}
            style={styles.icon}
          />
          {text !== undefined ? (
            <Text style={[styles.text, { fontSize, color: mainColor }]}>
              {text}
            </Text>
          ) : null}
        </>
      )}
    </TouchableOpacity>
  );
};

export default IconButton;
