import { ParamListBase, StackNavigationState } from "@react-navigation/native";
import { ReturnKeyTypeOptions } from "react-native";
import { SystemMessageButton } from "../actions/Message";
import { Pages } from "../routers";

export enum MessageState {
  PENDING = "PENDING",
  FAILED = "FAILED",
  CONFIRMED = "CONFIRMED",
}

export enum MessageDeletingState {
  DELETING = "DELETING",
  DELETED = "DELETED",
}

export interface File {
  readonly id: string;
  readonly name: string;
  readonly icon: string;
}

export enum PreviewState {
  PENDING = "PENDING",
  DONE = "DONE",
  FAILED = "FAILED",
}

export interface Preview {
  readonly id: string;
  readonly url: string;
  readonly title: string | null;
  readonly description: string | null;
  readonly thumbnailURL: string | null;
  readonly thumbnailWidth: number | null;
  readonly thumbnailHeight: number | null;
  readonly state: PreviewState;
  readonly deleting: boolean;
}

export type SearchIndex = {
  readonly hashtags: Record<string, boolean>;
  readonly text: string;
};

export interface Message {
  readonly type: "message";
  readonly id: string;
  readonly content: string;
  readonly localId: string | null;
  readonly timestamp: Date;
  readonly revision: number;
  readonly state: MessageState;
  readonly deletingState?: MessageDeletingState;
  readonly files?: Array<File>;
  readonly previewIds?: Array<string>;
  readonly previews: Record<string, Preview>;
  readonly searchIndex: SearchIndex;
  readonly editing?: boolean;
  readonly updating?: boolean;
  readonly contentOverride?: string;
}

export interface SystemMessage {
  readonly type: "system";
  readonly id: string;
  readonly content: string;
  readonly timestamp: Date;
  readonly buttons?: Array<SystemMessageButton>;
  readonly dismissing: boolean;
}

export type MessageUnion = Message | SystemMessage;

export type HashtagIndex = {
  readonly hashtagToMessageIds: Record<string, Record<string, boolean>>;
};

export type MessagesState = {
  readonly messages: Record<string, MessageUnion>;
  readonly messageIds: string[];
  readonly focusMessageId: string | null;
  readonly scrollCounter: number;
  readonly scrollTarget?: string;
  readonly viewingLatest: boolean;
  readonly displayScrollToLatestButton: boolean;
  readonly skipMessageAnimation: boolean;
  readonly searchKeywords: string | null;
  readonly hashtagIndex: HashtagIndex;
  readonly editingValue: string | null;
  readonly eventStreamId: string | null;
  // Ready status for message page
  readonly readyStatus: boolean;
};

export type UserState = {
  readonly id?: string;
  readonly name?: string;
  readonly email?: string;
  readonly productType?: string;
  readonly storageSize?: number;
  readonly storageUsage?: number;
  readonly settings?: Record<string, any>;
  readonly deletingAccount?: boolean;
};

export type Device = {
  readonly id: string;
  readonly name: string;
  readonly brand: string | null;
  readonly manufacturer: string | null;
  readonly modelName: string | null;
  readonly modelId: string | null;
  readonly osName: string | null;
  readonly osVersion: string | null;
  readonly osBuildId: string | null;
  readonly eventStreamId: string;
  readonly tooManyDevices: boolean;
  readonly notificationEnabled: boolean;
};

export enum ListDeviceState {
  LOADING = "LOADING",
  DONE = "DONE",
  FAILED = "FAILED",
}

export type DeviceState = {
  readonly currentDevice?: Device;
  readonly listDeviceState: ListDeviceState;
  readonly devices: Array<Device>;
};

export type AppState = {
  readonly localDeviceId: string;
};

export type LoginState = {
  readonly loading: boolean;
  // Is the initial token loading finished
  readonly tokenLoaded: boolean;
  // Is current user signed in already
  readonly signedIn: boolean;
  readonly username?: string;
  readonly password?: string;
};

export type SignUpState = {
  readonly loading: boolean;
};

export type ForgotPasswordState = {
  readonly loadingForgotPassword: boolean;
  readonly loadingResetPassword: boolean;
};

export type EventStream = {
  readonly lastSequenceId: number;
  // Are all the inital events loaded by API?
  readonly waitingForUpdate: boolean;
};

export type EventState = {
  readonly streams: Record<string, EventStream>;
};

export enum UploadingFileState {
  UPLOADING = "UPLOADING",
  UPLOADED = "UPLOADED",
  FAILED = "FAILED",
}

export type UploadingFile = {
  readonly id: string;
  readonly icon: string;
  readonly name: string;
  readonly progress: number;
  readonly state: UploadingFileState;
};

export enum BuildInCommandType {
  UPLOAD = "UPLOAD",
  SEARCH = "SEARCH",
  SETTINGS = "SETTINGS",
  ABOUT = "ABOUT",
  FEEDBACK = "FEEDBACK",
  MANUAL = "CHEATSHEET",
}

export type CommandId = BuildInCommandType | string;

export type Command = {
  readonly icon: string;
  readonly command: string;
  readonly parameters?: Array<string>;
  readonly description: string;
  readonly placeholder?: string;
  readonly inputMode?: boolean;
  readonly returnKeyType?: ReturnKeyTypeOptions;
  readonly resetAfterSbumit?: boolean;
  readonly multiline?: boolean;
};

export type Hashtag = {
  readonly name: string;
  readonly description?: string;
};

export type InputState = {
  readonly value: string;
  readonly files: Record<string, UploadingFile>;
  readonly fileIds: Array<string>;
  readonly displayCommands: boolean;
  readonly activeCommandMode: CommandId | null;
  readonly commands: Record<CommandId, Command>;
  readonly commandIds: Array<CommandId>;
  readonly matchedCommandIds: Array<CommandId>;
  readonly displayHashtags: boolean;
  readonly matchedHashtags: Array<Hashtag>;
  readonly matchedHashtagPrefixLength: number;
  readonly focusCounter: number;
};

export type ErrorMessage = {
  readonly id: string;
  readonly message: string;
  readonly dismissing: boolean;
};

export type ErrorState = {
  readonly errors: Record<string, ErrorMessage>;
  readonly errorIds: Array<string>;
};

export type SettingsConfig = {
  readonly displayMessageTime: boolean;
  readonly disablePreview: boolean;
  readonly deviceNotification: boolean;
};

export type ChangePassword = {
  readonly currentPassword?: string;
  readonly newPassword?: string;
  readonly newPasswordRepeat?: string;
  readonly updating?: boolean;
  readonly finished?: boolean;
};

export type SettingsState = {
  readonly config: Partial<SettingsConfig>;
  readonly changePassword: ChangePassword;
};

export type FeedbacksState = {
  readonly loading: boolean;
};

export type SubscriptionState = {};

export type RouterState = {
  readonly initialized: boolean;
  readonly preventNavigation: boolean;
  readonly focusPage: Pages;
  readonly readyStatus: Partial<Record<string, boolean>>;
  readonly routerState?: StackNavigationState<ParamListBase>;
};

export type State = {
  readonly app: AppState;
  readonly login: LoginState;
  readonly signUp: SignUpState;
  readonly forgotPassword: ForgotPasswordState;
  readonly user: UserState;
  readonly device: DeviceState;
  readonly messages: MessagesState;
  readonly input: InputState;
  readonly event: EventState;
  readonly error: ErrorState;
  readonly settings: SettingsState;
  readonly feedbacks: FeedbacksState;
  readonly subscription: SubscriptionState;
  readonly router: RouterState;
};
