import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AnyAction } from "typescript-fsa";
import actions from "../../actions/Input";
import InputBar, { Props } from "../../components/Input/InputBar";
import { State } from "../../states";

const mapStateToProps = (
  state: State
): Pick<
  Props,
  | "value"
  | "icon"
  | "placeholder"
  | "focusCounter"
  | "returnKeyType"
  | "multiline"
> => {
  const { input } = state;
  const activeCommand =
    state.input.activeCommandMode !== null
      ? state.input.commands[state.input.activeCommandMode]
      : undefined;
  return {
    value: input.value,
    focusCounter: input.focusCounter,
    icon: activeCommand?.icon,
    placeholder: activeCommand?.placeholder ?? "Speak up your mind here",
    returnKeyType: activeCommand?.returnKeyType,
    multiline: activeCommand?.multiline ?? true,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): Pick<
  Props,
  "onSend" | "onInputSubmit" | "onChangeText" | "onMenu" | "onBackspace"
> => ({
  onSend: () => {
    dispatch(actions.onSend());
  },
  onInputSubmit: () => {
    dispatch(actions.onInputSubmit());
  },
  onChangeText: (text: string) => {
    dispatch(actions.onTextChanged(text));
  },
  onMenu: () => {
    dispatch(actions.onMenuTapped());
  },
  onBackspace: () => {
    dispatch(actions.onBackspacePressed());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(InputBar);
