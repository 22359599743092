import * as DocumentPicker from "expo-document-picker";
import * as Haptics from "expo-haptics";
import { truncate } from "lodash";
import { Platform } from "react-native";
import { combineEpics, Epic } from "redux-observable";
import { EMPTY, from, merge, of } from "rxjs";
import {
  catchError,
  filter,
  ignoreElements,
  map,
  mapTo,
  mergeMap,
  tap,
} from "rxjs/operators";
import { Action, AnyAction, Failure, Success } from "typescript-fsa";
import { ofActionPayload } from "typescript-fsa-redux-observable";
import { v4 as uuid } from "uuid";
import inputActions, {
  File,
  Hashtag,
  LocalCommand,
  PickFileCancelError,
} from "../actions/Input";
import {
  FileUploadRequest,
  MessageRequest,
  MessageResponse,
} from "../services/api/interface";
import { extractMarkdownURLs, extractPreviewURLs } from "../shared/utils";
import { BuildInCommandType, State, UploadingFileState } from "../states";
import hashtagSigns from "../third-parties/twitter-text/hashSigns";
import hashtagAlphaNumeric from "../third-parties/twitter-text/hashtagAlphaNumeric";
import regexSupplant from "../third-parties/twitter-text/regexSupplant";
import { Dependencies } from "./dependencies";
import { confirmAlert, dataURItoBlob, makeAPICallEpic } from "./helpers";

const URL_REGEX = /(https?:\/\/[^\s<]+[^<.,:;"')\]\s])/;

const sendMessage = makeAPICallEpic<MessageRequest, MessageResponse>(
  inputActions.sendMessage,
  (params, { apiClient }) => apiClient.createMessage(params)
);

const uploadFile: Epic<AnyAction, AnyAction, State, Dependencies> = (
  action$,
  state,
  { apiClient }
) =>
  action$.pipe(
    ofActionPayload(inputActions.uploadFile.started),
    mergeMap((params) =>
      apiClient.createFile(params).pipe(
        map((result) =>
          result.type === "done"
            ? inputActions.uploadFile.done({
                params,
                result,
              })
            : inputActions.uploadFileProgressUpdate({
                params,
                result,
              })
        ),
        catchError((error) =>
          of(
            inputActions.uploadFile.failed({
              params,
              error,
            })
          )
        )
      )
    )
  );

const confirmDeleteFile: Epic<AnyAction, Action<string>, State> = (
  action$,
  state
) =>
  action$.pipe(
    ofActionPayload(inputActions.onDeleteFileTapped),
    mergeMap((id) =>
      from(
        confirmAlert(
          "Delete file",
          `Are you sure to delete file "${truncate(
            state.value.input.files[id].name
          )}"`
        )
      ).pipe(
        catchError(() => EMPTY),
        map(() => inputActions.deleteFile(id))
      )
    )
  );

const updateText: Epic<AnyAction, AnyAction, State> = (action$, state) =>
  action$.pipe(
    ofActionPayload(inputActions.onTextChanged),
    mergeMap((text) => {
      const { input } = state.value;
      const commandId = input.commandIds.find((commandId) =>
        text.startsWith(input.commands[commandId].command + " ")
      );
      if (
        commandId !== undefined &&
        (input.commands[commandId].inputMode ?? false)
      ) {
        return of(
          inputActions.enterCommandMode(commandId),
          inputActions.updateText(
            text.substring(input.commands[commandId].command.length + 1)
          )
        );
      }
      return of(inputActions.updateText(text));
    })
  );

export const typingHashtagRegex = regexSupplant(
  /#{hashtagSigns}(#{hashtagAlphaNumeric}*)$/i,
  { hashtagSigns, hashtagAlphaNumeric }
);

const specialHashtags: Array<Hashtag> = [
  {
    name: "_link_",
    description: "Hashtag for filtering links",
  },
  {
    name: "_file_",
    description: "Hashtag for filtering files",
  },
  {
    name: "_code_",
    description: "Hashtag for filtering code (both inline & block)",
  },
  {
    name: "_inlineCode_",
    description: "Hashtag for filtering inline code",
  },
  {
    name: "_codeBlock_",
    description: "Hashtag for filtering code block",
  },
];

const matchHashtags: Epic<AnyAction, AnyAction, State> = (action$, state) =>
  merge(
    action$.pipe(ofActionPayload(inputActions.onTextChanged)),
    action$.pipe(
      ofActionPayload(inputActions.enterCommandMode),
      map(() => state.value.input.value)
    ),
    action$.pipe(
      ofActionPayload(inputActions.leaveCommandMode),
      map(() => state.value.input.value)
    )
  ).pipe(
    map((text) => text.match(typingHashtagRegex)),
    mergeMap((capture) => {
      if (capture === null) {
        return of(
          inputActions.updateMatchedHashtags({
            hashtags: [],
            prefixLength: 0,
          })
        );
      }
      const { hashtagToMessageIds } = state.value.messages.hashtagIndex;
      const specialHashtagsMap = new Map(
        specialHashtags.map((hashtag) => [hashtag.name, hashtag.description!])
      );
      let hashtags: Array<string> = Array.from(
        Object.keys(hashtagToMessageIds).filter(
          (hashtag) => specialHashtagsMap.get(hashtag) === undefined
        )
      );
      // TODO: use hash tag recent usage for sorting instead
      hashtags.sort();
      if (state.value.input.activeCommandMode === BuildInCommandType.SEARCH) {
        hashtags = [
          ...specialHashtags.map((hashtag) => hashtag.name),
          ...hashtags,
        ];
      }

      const typingHashtag = capture![1];
      const matchedHashtags = hashtags.filter((hashtag) =>
        hashtag.toLowerCase().startsWith(typingHashtag.toLowerCase())
      );

      return of(
        inputActions.updateMatchedHashtags({
          hashtags: matchedHashtags.map(
            (hashtag: string) =>
              ({
                name: "#" + hashtag,
                description: specialHashtagsMap.get(hashtag),
              } as Hashtag)
          ),
          prefixLength: typingHashtag.length,
        })
      );
    })
    // distinctUntilChanged((lhs, rhs) => isEqual(lhs.payload, rhs.payload))
  );

const translateSubmitToSend: Epic<AnyAction, Action<void>, State> = (
  action$,
  state
) =>
  action$.pipe(
    ofActionPayload(inputActions.onInputSubmit),
    filter(
      () =>
        Platform.OS === "web" ||
        (state.value.input.activeCommandMode !== null &&
          !(
            state.value.input.commands[state.value.input.activeCommandMode]
              .multiline ?? true
          ))
    ),
    mapTo(inputActions.onSend())
  );

const sendTextMessage: Epic<
  AnyAction,
  Action<MessageRequest | LocalCommand | string>,
  State
> = (action$, state) =>
  action$.pipe(
    ofActionPayload(inputActions.onSend),
    filter(
      () =>
        state.value.input.activeCommandMode === null &&
        state.value.input.value.trim().length > 0
    ),
    mergeMap(() => {
      const commandId = state.value.input.commandIds.find((commandId) => {
        const command = state.value.input.commands[commandId];
        return state.value.input.value.trimEnd() === command.command;
      });
      if (commandId !== undefined) {
        const command = state.value.input.commands[commandId];
        if (command.inputMode ?? false) {
          return of(inputActions.enterCommandMode(commandId));
        }
        return of(
          inputActions.sendLocalCommand({
            id: commandId,
          })
        );
      }
      return of(
        inputActions.sendMessage.started({
          content: state.value.input.value,
          localId: uuid(),
          previewURLs:
            state.value.settings.config.disablePreview ?? false
              ? []
              : extractPreviewURLs(state.value.input.value),
        })
      );
    })
  );

const sendLocalCommandWithContent: Epic<
  AnyAction,
  Action<LocalCommand>,
  State
> = (action$, state) =>
  action$.pipe(
    ofActionPayload(inputActions.onSend),
    filter(
      () =>
        state.value.input.activeCommandMode !== null &&
        state.value.input.value.trim().length > 0
    ),
    map(() =>
      inputActions.sendLocalCommand({
        id: state.value.input.activeCommandMode!,
        content: state.value.input.value,
      })
    )
  );

const sendFileMessage: Epic<AnyAction, Action<MessageRequest | void>, State> = (
  action$,
  state
) =>
  action$.pipe(
    ofActionPayload(inputActions.onSend),
    filter(
      () =>
        state.value.input.activeCommandMode === BuildInCommandType.UPLOAD &&
        state.value.input.fileIds.every(
          (fileId) =>
            state.value.input.files[fileId].state ===
            UploadingFileState.UPLOADED
        )
    ),
    mergeMap(() =>
      of(
        inputActions.sendMessage.started({
          content: state.value.input.value,
          localId: uuid(),
          files: state.value.input.fileIds.map((fileId) => ({
            id: fileId,
            name: state.value.input.files[fileId].name,
          })),
          previewURLs: extractMarkdownURLs(state.value.input.value),
        }),
        inputActions.leaveCommandMode()
      )
    )
  );

const promptPickingFile: Epic<AnyAction, Action<void>, State> = (
  action$,
  state
) =>
  action$.pipe(
    ofActionPayload(inputActions.enterCommandMode),
    filter((commandId) => commandId === BuildInCommandType.UPLOAD),
    map(() => inputActions.pickUploadingFile.started())
  );

const tapCommandMenu: Epic<AnyAction, Action<string | LocalCommand>, State> = (
  action$,
  state
) =>
  action$.pipe(
    ofActionPayload(inputActions.onCommandTapped),
    map((commandId) => {
      const command = state.value.input.commands[commandId];
      if (command.inputMode ?? false) {
        return inputActions.enterCommandMode(commandId);
      }
      return inputActions.sendLocalCommand({ id: commandId });
    })
  );

const confirmLeaveUploadingFileMode: Epic<AnyAction, Action<void>, State> = (
  action$,
  state
) =>
  merge(
    action$.pipe(ofActionPayload(inputActions.onCancelUploadingTapped)),
    action$.pipe(
      ofActionPayload(inputActions.onBackspacePressed),
      filter(
        () =>
          state.value.input.activeCommandMode === BuildInCommandType.UPLOAD &&
          state.value.input.value.length === 0
      )
    )
  ).pipe(
    mergeMap(() =>
      (state.value.input.fileIds.length > 0
        ? from(
            confirmAlert("Abort uploading", "Are you sure to abort uploading?")
          )
        : of(undefined)
      ).pipe(
        catchError(() => EMPTY),
        mapTo(inputActions.leaveCommandMode())
      )
    )
  );

const leaveSearchMode: Epic<AnyAction, Action<void>, State> = (
  action$,
  state
) =>
  merge(action$.pipe(ofActionPayload(inputActions.onCancelSearchTapped))).pipe(
    mapTo(inputActions.leaveCommandMode())
  );

const leaveInputModeByBackspace: Epic<AnyAction, Action<void>, State> = (
  action$,
  state
) =>
  action$.pipe(
    ofActionPayload(inputActions.onBackspacePressed),
    filter(
      () =>
        state.value.input.activeCommandMode !== null &&
        state.value.input.activeCommandMode !== BuildInCommandType.UPLOAD &&
        state.value.input.value.length === 0
    ),
    mapTo(inputActions.leaveCommandMode(undefined))
  );

const hapticsForInputModeChanged: Epic<AnyAction, Action<void>, State> = (
  action$,
  state
) =>
  action$.pipe(
    ofActionPayload(
      inputActions.enterCommandMode,
      inputActions.leaveCommandMode
    ),
    filter(() => Platform.OS !== "web"),
    mergeMap(() => Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light)),
    ignoreElements()
  );

const addMoreUploadingFile: Epic<AnyAction, Action<void>, State> = (
  action$,
  state
) =>
  action$.pipe(
    ofActionPayload(inputActions.addFileTapped),
    map(() => inputActions.pickUploadingFile.started(undefined))
  );

const pickUploadingFile: Epic<
  AnyAction,
  Action<Success<void, File> | Failure<void, PickFileCancelError>>,
  State
> = (action$, state) =>
  action$.pipe(
    ofActionPayload(inputActions.pickUploadingFile.started),
    mergeMap(() =>
      from(DocumentPicker.getDocumentAsync({})).pipe(
        map((result) => {
          if (result.type === "success") {
            console.info(
              "Picked file name=",
              result.name,
              "size=",
              result.size
            );
            if (!result.uri.startsWith("data:")) {
              console.info("File URI", result.uri);
            }
          } else {
            console.info("Uploading file canceled");
            return inputActions.pickUploadingFile.failed({
              params: undefined,
              error: new PickFileCancelError("Pick uploading file canceld"),
            });
          }
          return inputActions.pickUploadingFile.done({
            params: undefined,
            result: {
              id: uuid(),
              name: result.name,
              size: result.size,
              uri: result.uri,
            },
          });
        }),
        catchError((error) => {
          console.error("Failed to pick file", error);
          return of(
            inputActions.pickUploadingFile.failed({
              params: undefined,
              error: error,
            })
          );
        })
      )
    )
  );

const leaveUploadingModeWhenPickCanceled: Epic<
  AnyAction,
  Action<void>,
  State
> = (action$, state) =>
  action$.pipe(
    ofActionPayload(inputActions.pickUploadingFile.failed),
    filter(
      ({ error }) =>
        error instanceof PickFileCancelError &&
        state.value.input.fileIds.length === 0
    ),
    mapTo(inputActions.leaveCommandMode())
  );

const startUploading: Epic<AnyAction, Action<FileUploadRequest>, State> = (
  action$,
  state
) =>
  action$.pipe(
    ofActionPayload(inputActions.pickUploadingFile.done),
    mergeMap(({ result }) => {
      // Notice: the way form data is prossed is a bit different
      //         for different platform. For react native, it's not standard
      //         FormData, so we need to pass URI directly to let react native code
      //         to load the URI data for us. But for web, it doesn't take "uri" object,
      //         so we need to decode the base64 manually for web
      //         ref: https://github.com/facebook/react-native/blob/3c9e5f1470c91ff8a161d8e248cf0a73318b1f40/Libraries/Network/RCTNetworking.mm#L360-L411
      const uri = result.uri.startsWith("file://")
        ? result.uri
        : `file://${result.uri}`;
      let data: any = {
        uri,
        // The mime type is required by Android, otherwise it won't work
        // ref: https://github.com/facebook/react-native/blob/8c2571171e71cb6f0db5fdc3e2b1360f8b853138/ReactAndroid/src/main/java/com/facebook/react/modules/network/NetworkingModule.java#L417-L421
        type: "application/octet-stream",
        // The filename is also needed otherwise it would be just a field instead of a file
        name: result.name,
      };
      if (Platform.OS === "web") {
        data = dataURItoBlob(result.uri);
        console.info(
          "Uploading file name=",
          result.name,
          "size=",
          result.size,
          "base64_size=",
          data.length
        );
      } else {
        console.info(
          "Uploading file name=",
          result.name,
          "size=",
          result.size,
          "uri=",
          uri
        );
      }
      return of(data).pipe(
        map((data) =>
          inputActions.uploadFile.started({
            name: result.name,
            localId: result.id,
            data,
          })
        )
      );
    })
  );

const inputEpic = combineEpics(
  sendMessage,
  uploadFile,
  confirmDeleteFile,
  updateText,
  matchHashtags,
  translateSubmitToSend,
  sendTextMessage,
  sendLocalCommandWithContent,
  sendFileMessage,
  promptPickingFile,
  tapCommandMenu,
  leaveUploadingModeWhenPickCanceled,
  confirmLeaveUploadingFileMode,
  leaveSearchMode,
  leaveInputModeByBackspace,
  hapticsForInputModeChanged,
  addMoreUploadingFile,
  pickUploadingFile,
  startUploading
);
export default inputEpic;
