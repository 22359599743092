import { reducerWithInitialState } from "typescript-fsa-reducers";

import actions from "../actions/User";
import loginActions from "../actions/Login";
import { UserState } from "../states";

const user = reducerWithInitialState<UserState>({ deletingAccount: false })
  .case(loginActions.logout, (state, payload) => ({}))
  .case(actions.deleteAccount.started, (state, payload) => ({
    ...state,
    deletingAccount: true,
  }))
  .cases(
    [actions.deleteAccount.done, actions.deleteAccount.failed],
    (state) => ({ ...state, deletingAccount: false })
  )
  .case(actions.loadCurrentUser.done, (state, payload) => ({
    ...state,
    id: payload.result.id,
    email: payload.result.email,
    name: payload.result.name,
    productType: payload.result.productType ?? undefined,
    storageSize: payload.result.storageSize,
    storageUsage: payload.result.storageUsage,
    settings: payload.result.settings,
  }))
  .case(actions.updateSettings.done, (state, payload) => ({
    ...state,
    settings: payload.result.settings,
  }));

export default user;
