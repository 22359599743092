import React, { FunctionComponent, useEffect, useRef } from "react";
import {
  Animated,
  Easing,
  StyleProp,
  StyleSheet,
  ViewStyle,
} from "react-native";
import { Colors } from "../Colors";
import IconButton from "../IconButton";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

export interface Props {
  readonly height?: number;
  readonly visible: boolean;
  readonly style?: StyleProp<ViewStyle>;
  readonly displayShareButton?: boolean;
  readonly onEdit?: () => void;
  readonly onShare?: () => void;
  readonly onDelete?: () => void;
  readonly onDismissAnimationFinished?: () => void;
}

const SelectedMenu: FunctionComponent<Props> = ({
  style,
  visible,
  displayShareButton,
  height: heightProp,
  onEdit,
  onShare,
  onDelete,
  onDismissAnimationFinished,
}: Props) => {
  const animatedProgress = useRef(new Animated.Value(0)).current;
  const easing = Easing.inOut(Easing.cubic);
  const duration = 100;

  useEffect(() => {
    Animated.timing(animatedProgress, {
      toValue: visible ? 1.0 : 0,
      easing,
      duration,
      useNativeDriver: false,
    }).start(({ finished }) => {
      if (!finished || visible) {
        return;
      }
      onDismissAnimationFinished?.();
    });
  }, [visible]);

  return (
    <Animated.View
      style={[
        styles.container,
        {
          opacity: animatedProgress,
          height: animatedProgress.interpolate({
            inputRange: [0, 1],
            outputRange: [0, heightProp ?? 40],
          }),
        },
        style,
      ]}
      pointerEvents={visible ? "box-none" : "none"}
    >
      <IconButton name="edit" text="Edit" onPress={onEdit} />
      {displayShareButton ?? true ? (
        <IconButton name="share-square" text="Share" onPress={onShare} />
      ) : null}
      <IconButton
        name="trash-alt"
        text="Delete"
        color={Colors.Danger}
        onPress={onDelete}
      />
    </Animated.View>
  );
};

export default SelectedMenu;
