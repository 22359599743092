import { storiesOf } from "@storybook/react-native";
import { range } from "lodash";
import React from "react";
import { StyleSheet } from "react-native";
import TooManyDevicesModal from "../../../components/TooManyDevices/TooManyDevicesModal";
import {
  DeviceItem,
  ListDeviceState,
} from "../../../components/TooManyDevices/TooManyDevicesView";

const storyName = "Components/TooManyDevices/" + TooManyDevicesModal.name;

const styles = StyleSheet.create({
  subscriptionModal: {
    flex: 1,
  },
});

const devices: Array<DeviceItem> = [
  {
    id: "dev0",
    name: "Fang-Pen's iPhone",
    icon: "mobile",
  },
  {
    id: "dev1",
    name: "Fang-Pen's iPad",
    icon: "tablet",
  },
  {
    id: "dev2",
    name: "Fang-Pen's MacBook Pro",
    icon: "laptop",
  },
];

const manyDevices: Array<DeviceItem> = range(100).map((i) => ({
  id: `dev${i}`,
  name: `Fang-Pen's MacBook Pro ${i}`,
  icon: "laptop",
}));

storiesOf(storyName, module).add("done", () => {
  return (
    <TooManyDevicesModal
      style={styles.subscriptionModal}
      devices={devices}
      listDeviceState={ListDeviceState.DONE}
    />
  );
});

storiesOf(storyName, module).add("loading", () => {
  return (
    <TooManyDevicesModal
      style={styles.subscriptionModal}
      devices={devices}
      listDeviceState={ListDeviceState.LOADING}
    />
  );
});

storiesOf(storyName, module).add("failed", () => {
  return (
    <TooManyDevicesModal
      style={styles.subscriptionModal}
      devices={devices}
      listDeviceState={ListDeviceState.FAILED}
    />
  );
});

storiesOf(storyName, module).add("many devices", () => {
  return (
    <TooManyDevicesModal
      style={styles.subscriptionModal}
      devices={manyDevices}
      listDeviceState={ListDeviceState.DONE}
    />
  );
});
