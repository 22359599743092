import { FontAwesome5 } from "@expo/vector-icons";
import React, { FunctionComponent } from "react";
import {
  StyleProp,
  StyleSheet,
  Text,
  TouchableOpacity,
  ViewStyle,
} from "react-native";
import { Colors } from "../Colors";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  icon: {
    minWidth: 24,
  },
  text: {
    flex: 1,
    paddingLeft: 10,
  },
  commandBoldPrefix: {
    fontWeight: "bold",
  },
  command: {},
  parameter: {},
  description: {},
});

export interface Props {
  readonly id: string;
  readonly style?: StyleProp<ViewStyle>;
  readonly icon: string;
  readonly command: string;
  readonly parameters?: Array<string>;
  readonly description: string;
  readonly commandPrefixBoldLength?: number;
  readonly onPress?: (id: string) => void;
}

const CommandOption: FunctionComponent<Props> = ({
  id,
  style,
  icon,
  command,
  parameters,
  description,
  commandPrefixBoldLength,
  onPress,
}: Props) => {
  const commandPrefixBoldLengthValue = commandPrefixBoldLength ?? 0;
  const boldCommandPrefix = command.substr(0, commandPrefixBoldLengthValue);
  const commandSuffix = command.substr(commandPrefixBoldLengthValue);
  return (
    <TouchableOpacity
      style={[styles.container, style]}
      onPress={() => onPress?.(id)}
    >
      <FontAwesome5
        style={styles.icon}
        name={icon}
        size={25}
        color={Colors.Launch}
      />
      <Text style={styles.text} numberOfLines={1}>
        <Text style={styles.commandBoldPrefix}>{boldCommandPrefix}</Text>
        <Text style={styles.command}>{commandSuffix}</Text>
        {parameters !== undefined && parameters.length > 0 ? (
          <>
            <Text> </Text>
            {parameters.map((parameter) => (
              <Text key={parameter} style={styles.parameter}>
                {parameter}
              </Text>
            ))}
          </>
        ) : null}
        {" - "}
        <Text style={styles.description}>{description}</Text>
      </Text>
    </TouchableOpacity>
  );
};

export default CommandOption;
