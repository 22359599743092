import { storiesOf } from "@storybook/react-native";
import React from "react";
import { StyleSheet, View } from "react-native";
import TooManyDevicesView, {
  DeviceItem,
  ListDeviceState,
} from "../../../components/TooManyDevices/TooManyDevicesView";

const storyName = "Components/TooManyDevices/" + TooManyDevicesView.name;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  tooManyDevices: {
    flex: 1,
    paddingHorizontal: 20,
  },
});

const devices: Array<DeviceItem> = [
  {
    id: "dev0",
    name: "Fang-Pen's iPhone",
    icon: "mobile",
  },
  {
    id: "dev1",
    name: "Fang-Pen's iPad",
    icon: "tablet",
  },
  {
    id: "dev2",
    name: "Fang-Pen's MacBook Pro",
    icon: "laptop",
  },
];

storiesOf(storyName, module).add("loaded", () => (
  <View style={styles.container}>
    <TooManyDevicesView
      style={styles.tooManyDevices}
      devices={devices}
      listDeviceState={ListDeviceState.DONE}
    />
  </View>
));

storiesOf(storyName, module).add("loading", () => (
  <View style={styles.container}>
    <TooManyDevicesView
      style={styles.tooManyDevices}
      devices={devices}
      listDeviceState={ListDeviceState.LOADING}
    />
  </View>
));

storiesOf(storyName, module).add("failed", () => (
  <View style={styles.container}>
    <TooManyDevicesView
      style={styles.tooManyDevices}
      devices={devices}
      listDeviceState={ListDeviceState.FAILED}
    />
  </View>
));
