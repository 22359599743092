import { storiesOf } from "@storybook/react-native";
import React from "react";
import { StyleSheet } from "react-native";
import ForgotPassword from "../../../components/ForgotPassword/ForgotPassword";

const storyName = "Components/ForgotPassword/" + ForgotPassword.name;

const styles = StyleSheet.create({
  forgotPassword: {
    flex: 1,
  },
});

storiesOf(storyName, module).add("normal", () => (
  <ForgotPassword style={styles.forgotPassword} />
));

storiesOf(storyName, module).add("loading", () => (
  <ForgotPassword style={styles.forgotPassword} loading />
));
