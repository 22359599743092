import { actionCreator } from "./shared";

export interface ErrorMessage {
  id: string;
  message: string;
}

export const prefix = "ERROR/";
const actions = {
  addMessage: actionCreator<ErrorMessage>(prefix + "ADD_MESSAGE"),
  dismissMessage: actionCreator<string>(prefix + "DISMISS_MESSAGE"),
  dismissMessageAnimationFinished: actionCreator<string>(
    prefix + "DISMISS_MESSAGE_ANIMATION_FINISHED"
  ),
};
export default actions;
