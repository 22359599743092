import { StackActions } from "@react-navigation/routers";
import Constants from "expo-constants";
import * as Linking from "expo-linking";
import { flatMap, truncate } from "lodash";
import { Platform, Share } from "react-native";
import { combineEpics, Epic } from "redux-observable";
import { concat, EMPTY, from, of, timer, zip } from "rxjs";
import {
  catchError,
  debounce,
  delay,
  filter,
  ignoreElements,
  map,
  mapTo,
  mergeMap,
  switchMap,
  take,
} from "rxjs/operators";
import { Action, AnyAction } from "typescript-fsa";
import { ofActionPayload } from "typescript-fsa-redux-observable";
import { v4 as uuid } from "uuid";
import eventActions from "../actions/Event";
import notificationActions from "../actions/Notification";
import inputActions from "../actions/Input";
import keyboardActions from "../actions/Keyboard";
import messageActions, {
  CancelEditRequest,
  SystemMessageButtonId,
} from "../actions/Message";
import routerActions, {
  PageReadyStatus,
  RoutingAction,
} from "../actions/Router";
import userActions from "../actions/User";
import { Pages } from "../routers";
import {
  MessageRequest,
  MessageResponse,
  MessageUpdateRequest,
} from "../services/api/interface";
import {
  APP_NAME,
  APP_TAGLINE,
  APP_VERSION,
  BUILD_NUMBER,
  ELECTRON_UPDATE_CHANNEL,
  IS_ELECTRON,
} from "../shared/constants";
import {
  extractPreviewURLs,
  KeywordType,
  parseSearchKeywords,
} from "../shared/utils";
import { BuildInCommandType, Message, MessageState, State } from "../states";
import { Dependencies } from "./dependencies";
import { confirmAlert, makeAPICallEpic } from "./helpers";

const updateMessage = makeAPICallEpic<MessageUpdateRequest, MessageResponse>(
  messageActions.updateMessage,
  (params, { apiClient }) => apiClient.updateMessage(params)
);

const deleteConfirmedMessage = makeAPICallEpic<string, void>(
  messageActions.deleteConfirmedMessage,
  (params, { apiClient }) => apiClient.deleteMessage(params)
);

const deleteWelcomeMessages = makeAPICallEpic<void, void, void>(
  messageActions.deleteWelcomeMessages,
  (_, { apiClient }) => apiClient.deleteSystemMessages("welcome")
);

const deletePreview = makeAPICallEpic<
  { messageId: string; previewId: string },
  void
>(messageActions.deletePreview, (params, { apiClient }) =>
  apiClient.deletePreview(params.previewId)
);

const confirmDeleteMessage: Epic<AnyAction, Action<string>, State> = (
  action$,
  state
) =>
  action$.pipe(
    ofActionPayload(messageActions.onDeleteMessageTapped),
    mergeMap((id) =>
      from(
        confirmAlert(
          "Delete message",
          `Are you sure to delete the message "${truncate(
            state.value.messages.messages[id].content
          )}"`
        )
      ).pipe(
        catchError(() => EMPTY),
        map(() =>
          (state.value.messages.messages[id] as Message).state ===
          MessageState.FAILED
            ? messageActions.onDeleteFailedMessage(id)
            : messageActions.deleteConfirmedMessage.started(id)
        )
      )
    )
  );

const confirmAbortMessageEditingByCancelButton: Epic<
  AnyAction,
  Action<CancelEditRequest>,
  State
> = (action$, state) =>
  action$.pipe(
    ofActionPayload(messageActions.onEditCancelTapped),
    mapTo(
      messageActions.confirmCancelEditMessage({
        type: "tapButton",
      })
    )
  );

const confirmAbortMessageEditing: Epic<
  AnyAction,
  Action<void | string>,
  State
> = (action$, state) =>
  action$.pipe(
    ofActionPayload(messageActions.confirmCancelEditMessage),
    mergeMap((params) => {
      const contentChanged =
        state.value.messages.messages[state.value.messages.focusMessageId!]
          .content === state.value.messages.editingValue;
      const result =
        params.type === "tapButton"
          ? of(messageActions.cancelEditMessage())
          : of(
              messageActions.cancelEditMessage(),
              messageActions.selectMessage(params.id)
            );
      if (contentChanged) {
        return result;
      }
      return concat(
        of(
          messageActions.scrollToMessage(state.value.messages.focusMessageId!)
        ),
        from(of(undefined)).pipe(
          delay(0),
          mergeMap(() =>
            confirmAlert(
              "Abort editing message",
              `The message have already been changed, are you sure to discard changes for "${truncate(
                state.value.messages.messages[
                  state.value.messages.focusMessageId!
                ].content
              )}"`
            )
          ),
          catchError(() => EMPTY),
          mergeMap(() => result)
        )
      );
    })
  );

const scrollToMessageAfterStartEditing: Epic<AnyAction, AnyAction, State> = (
  action$,
  state
) =>
  action$.pipe(
    ofActionPayload(messageActions.onEditMessageTapped),
    switchMap((id) =>
      action$.pipe(
        ofActionPayload(keyboardActions.onKeyboardDidShow),
        take(1),
        mapTo(messageActions.scrollToMessage(id))
      )
    )
  );

const startUpdatingMessage: Epic<AnyAction, AnyAction, State> = (
  action$,
  state
) =>
  action$.pipe(
    ofActionPayload(messageActions.onEditSaveTapped),
    filter(() => state.value.messages.editingValue!.trim().length > 0),
    map((params) =>
      state.value.messages.editingValue! !==
      state.value.messages.messages[state.value.messages.focusMessageId!]
        .content
        ? messageActions.updateMessage.started({
            id: state.value.messages.focusMessageId!,
            content: state.value.messages.editingValue!,
            previewURLs:
              state.value.settings.config.disablePreview ?? false
                ? []
                : extractPreviewURLs(state.value.messages.editingValue!),
          })
        : messageActions.cancelEditMessage()
    )
  );

const selectMessage: Epic<AnyAction, AnyAction, State> = (action$, state) =>
  action$.pipe(
    ofActionPayload(messageActions.onMessagePressed),
    mergeMap((id) => {
      if (
        id === state.value.messages.focusMessageId &&
        state.value.messages.editingValue !== null
      ) {
        // Select on already editing msg, do nothing
        return EMPTY;
      }
      return of(
        state.value.messages.editingValue !== null
          ? messageActions.confirmCancelEditMessage({
              type: "selectMessage",
              id,
            })
          : messageActions.selectMessage(id)
      );
    })
  );

const shareMessage: Epic<AnyAction, AnyAction, State> = (action$, state) =>
  action$.pipe(
    ofActionPayload(messageActions.onShareMessageTapped),
    mergeMap((id) =>
      from(
        // TODO: better sharing content details here
        Share.share({ message: state.value.messages.messages[id].content })
      ).pipe(
        ignoreElements(),
        catchError((error) => {
          console.error("Failed to share with error=", error);
          return EMPTY;
        })
      )
    )
  );

const startDeletingPreview: Epic<AnyAction, AnyAction, State> = (
  action$,
  state
) =>
  action$.pipe(
    ofActionPayload(messageActions.onPreviewDelete),
    map((params) => messageActions.deletePreview.started(params))
  );

const startDownloadingFile: Epic<AnyAction, AnyAction, State> = (
  action$,
  state
) =>
  action$.pipe(
    ofActionPayload(messageActions.onDownloadFileTapped),
    map((params) => messageActions.downloadFile.started(params))
  );

const downloadFile: Epic<AnyAction, AnyAction, State, Dependencies> = (
  action$,
  state,
  { apiClient }
) =>
  action$.pipe(
    ofActionPayload(messageActions.downloadFile.started),
    mergeMap((params) =>
      apiClient.downloadFile(params.fileId).pipe(
        mergeMap((downloadUrl) => Linking.openURL(downloadUrl)),
        mapTo(messageActions.downloadFile.done({ params })),
        catchError((error) => {
          return of(messageActions.downloadFile.failed({ params, error }));
        })
      )
    )
  );

/*
const scrollToLatestMessageAfterLoadMessage: Epic<
  AnyAction,
  Action<void>,
  State
> = (action$, state) =>
  action$.pipe(
    ofActionPayload(messageActions.loadEvents.done),
    // Need to delay a bit to let the message appending cycle finishs first so that
    // we can call the scroll method on the list
    delay(0),
    // TODO: only do this if our view window is close enough to the bottom
    mapTo(messageActions.scrollToLatestMessage(undefined))
  );*/

// Scroll to the latest posted message after message sent render iteration is done
// (so that the new message item is available for scrolling to action)
const scrollToLatestMessage: Epic<AnyAction, Action<void>, State> = (
  action$,
  state
) =>
  action$.pipe(
    ofActionPayload(
      inputActions.sendMessage.started,
      messageActions.addSystemMessage
    ),
    // Need to delay a bit to let the message appending cycle finishs first so that
    // we can call the scroll method on the list
    delay(0),
    // TODO: only do this if our view window is close enough to the bottom
    mapTo(messageActions.scrollToLatestMessage())
  );

const scrollToLatestMessageWhenNotificationTapped: Epic<
  AnyAction,
  Action<void>,
  State
> = (action$, state) =>
  action$.pipe(
    ofActionPayload(notificationActions.receivedResponse),
    filter((response) => {
      if (Platform.OS === "android") {
        return (
          response.notification.request.trigger.type === "push" &&
          (response.notification.request.trigger as any)?.remoteMessage?.data
            ?.channelId === "new_messages"
        );
      } else if (Platform.OS === "ios") {
        return (
          response.notification.request.trigger.type === "push" &&
          (response.notification.request.trigger as any)?.payload
            ?.message_id !== undefined
        );
      }
      return false;
    }),
    // Need to delay a bit to let the message appending cycle finishs first so that
    // we can call the scroll method on the list
    delay(0),
    // TODO: only do this if our view window is close enough to the bottom
    mapTo(messageActions.scrollToLatestMessage())
  );

const scrollToLatestMessageButtonClicked: Epic<
  AnyAction,
  Action<void>,
  State
> = (action$, state) =>
  action$.pipe(
    ofActionPayload(messageActions.onScrollToLatestMessageButtonClicked),
    mapTo(messageActions.scrollToLatestMessage())
  );

const updateDisplayScrollToLatestMessageButton: Epic<
  AnyAction,
  Action<boolean>,
  State
> = (action$, state) =>
  action$.pipe(
    ofActionPayload(messageActions.onViewingLatestChanged),
    map((viewingLatest) =>
      messageActions.updateDisplayScrollToLatestMessageButton(!viewingLatest)
    ),
    debounce((event) => timer(event.payload ? 500 : 0))
  );

const scrollToLatestWhenKeyboardWillShow: Epic<
  AnyAction,
  Action<void>,
  State
> = (action$, state) =>
  action$.pipe(
    ofActionPayload(keyboardActions.onKeyboardWillShow),
    mapTo(messageActions.scrollToLatestMessage())
  );

const retryFailedMessage: Epic<AnyAction, Action<MessageRequest>, State> = (
  action$,
  state
) =>
  action$.pipe(
    ofActionPayload(messageActions.onRetryFailedMessage),
    map((localId) =>
      inputActions.sendMessage.started({
        content: state.value.messages.messages[localId].content,
        localId,
        previewURLs:
          state.value.settings.config.disablePreview ?? false
            ? []
            : extractPreviewURLs(state.value.input.value),
        ...((state.value.messages.messages[localId] as Message).files !==
          undefined &&
        (state.value.messages.messages[localId] as Message).files!.length > 0
          ? {
              files: (
                state.value.messages.messages[localId] as Message
              ).files!.map((file) => ({
                id: file.id,
                name: file.name,
              })),
            }
          : {}),
      })
    )
  );

const openMessageLink: Epic<AnyAction, AnyAction, State> = (action$, state) =>
  action$.pipe(
    ofActionPayload(
      messageActions.onMessageLinkPressed,
      messageActions.onSystemMessageLinkPressed
    ),
    mergeMap(({ url }) =>
      Platform.OS === "web"
        ? new Promise((resolve) => {
            window.open(url, "_blank");
            resolve(undefined);
          })
        : Linking.openURL(url)
    ),
    ignoreElements()
  );

const openPreviewLink: Epic<AnyAction, AnyAction, State> = (action$, state) =>
  action$.pipe(
    ofActionPayload(messageActions.onPreviewLinkPressed),
    map(
      ({ messageId, previewId }) =>
        (state.value.messages.messages[messageId] as Message).previews[
          previewId
        ].url
    ),
    mergeMap((url) =>
      Platform.OS === "web"
        ? new Promise((resolve) => {
            window.open(url, "_blank");
            resolve(undefined);
          })
        : Linking.openURL(url)
    ),
    ignoreElements()
  );

const showAboutByLocalCommand: Epic<AnyAction, AnyAction, State> = (
  action$,
  state
) =>
  action$.pipe(
    ofActionPayload(inputActions.sendLocalCommand),
    filter((params) => params.id === BuildInCommandType.ABOUT),
    map(() => {
      let buildNumber: string | undefined;
      switch (Platform.OS) {
        case "ios":
        case "android": {
          buildNumber = BUILD_NUMBER;
          break;
        }
        case "web": {
          buildNumber = Constants.manifest?.extra?.buildNumber;
          break;
        }
      }

      const aboutTail = IS_ELECTRON
        ? `**Update Channel**: ${ELECTRON_UPDATE_CHANNEL}`
        : "";

      return messageActions.addSystemMessage({
        id: uuid(),
        buttons: [
          {
            icon: "times",
            text: "DISMISS",
            id: SystemMessageButtonId.DISMISS,
          },
        ],
        content: `### ${APP_NAME} - ${APP_TAGLINE}

- [Official website](https://monoline.io)
- [Privacy Policy](https://monoline.io/privacy-policy)
- [Terms of Service](https://monoline.io/terms-of-service)
- [Open Source Licenses](https://monoline.io/open-source-licenses)

Copyright 2022 [Launch Platform LLC](https://launchplatform.com). All rights reserved

### Version Information

**App Version**: ${APP_VERSION}

**Build Number**: ${buildNumber}

${aboutTail}
`,
        time: new Date(),
      });
    })
  );

const showManualByLocalCommand: Epic<AnyAction, AnyAction, State> = (
  action$,
  state
) =>
  action$.pipe(
    ofActionPayload(inputActions.sendLocalCommand),
    filter((params) => params.id === BuildInCommandType.MANUAL),
    map(() => {
      return messageActions.addSystemMessage({
        id: uuid(),
        buttons: [
          {
            icon: "times",
            text: "DISMISS",
            id: SystemMessageButtonId.DISMISS,
          },
        ],
        content: [
          "### Markdown Syntax",
          "Write `**your text here**` to make your text **bold**",
          "Write `*your text here*` to make your text *italicized*",
          "Write `__your text here__` to decorate your text with __underscore__",
          "Write `~~your text here~~` to make text ~~strike through~~",
          "Write `> your text here` to make a quote like this:",
          "> To be or not to be, that's the question",
          "Write `- your text here` on multiple lines to make a list like this:",
          "- The good\n- The bad\n- The ugly",
          "Write `` `your text here` `` to make `inline code`",
          "Write `` ```your multi-line code here``` `` to make a code block like this:",
          "```\nif monoline.is_awesome():\n    recommend_to_my_friends()\n```",
          "Write `---` to make a horizontal rule",
          "### Hashtags",
          "To help keeping your messages organized, you can also add #Hashtags to your messages like #this",
          "### Search",
          "Click on a hashtag will bring up 🔎 search mode and only show messages containing the hashtag",
          'To leave #this search mode, just click the "Cancel" button on the bottom right corner above the input bar',
          "Other special hashtags can be used in search mode to find out particular messages, such as #_link_ for finding messages with links, #_file_ for finding messages with file attachments and #_code_ for finding messages with code",
          "To bring up the 🔎 search mode again, just type `/search` plus a space, or you can press the left bottom corner button to bring up the command menu and tap `/search`",
          "### Other commands",
          "More functionalities, can be found in the command menu, such as `/upload` for uploading a file, `/settings` for change settings",
          "### Edit, delete and share",
          "To edit, delete or share a message, you can long press the message on your mobile device, or click the message if you are using web app, the action menu for the message will show up",
          "### Feedbacks",
          "We are constantly improving this app, if you have any feedbacks, suggestions or any question about this app, please feel free to let us know via `/feedback` command or writing an email to [support@monoline.io](mailto:support@monoline.io)",
        ].join("\n\n"),
        time: new Date(),
      });
    })
  );

const addHashtagInSearchKeywords: Epic<AnyAction, AnyAction, State> = (
  action$,
  state
) =>
  action$.pipe(
    ofActionPayload(messageActions.onMessageHashtagPressed),
    mergeMap(({ hashtag }) => {
      const hashtagSearchTerm = `#${hashtag}`;
      const searchKeywords = parseSearchKeywords(state.value.input.value);
      const searchkeywordMap = new Map(
        searchKeywords
          .filter((keyword) => keyword.type === KeywordType.HASHTAG)
          .map((keyword) => [keyword.value.toLowerCase(), true])
      );
      if (searchkeywordMap.get(hashtag.toLowerCase()) !== undefined) {
        // The hashtag already in the input field, just ignore
        return EMPTY;
      }
      if (state.value.input.activeCommandMode === null) {
        return of(
          inputActions.enterCommandMode(BuildInCommandType.SEARCH),
          inputActions.updateText(hashtagSearchTerm),
          inputActions.sendLocalCommand({
            id: BuildInCommandType.SEARCH,
            content: hashtagSearchTerm,
          })
        );
      } else if (
        state.value.input.activeCommandMode === BuildInCommandType.SEARCH
      ) {
        const newValue =
          state.value.input.value +
          (/\s$/.test(state.value.input.value) ? "" : " ") +
          hashtagSearchTerm;
        return of(
          inputActions.updateText(newValue),
          inputActions.sendLocalCommand({
            id: BuildInCommandType.SEARCH,
            content: newValue,
          })
        );
      }
      // We don't want to change input mode, so just ignore
      return EMPTY;
    })
  );

const navigateToSettingsPage: Epic<AnyAction, Action<RoutingAction>, State> = (
  action$,
  state
) =>
  action$.pipe(
    ofActionPayload(inputActions.sendLocalCommand),
    filter((cmd) => cmd.id === BuildInCommandType.SETTINGS),
    map(() => routerActions.route(StackActions.push(Pages.SETTINGS)))
  );

const navigateToFeedbackPage: Epic<AnyAction, Action<RoutingAction>, State> = (
  action$,
  state
) =>
  action$.pipe(
    ofActionPayload(inputActions.sendLocalCommand),
    filter((cmd) => cmd.id === BuildInCommandType.FEEDBACK),
    map(() => routerActions.route(StackActions.push(Pages.FEEDBACKS)))
  );

const updateMessagePageReadyStatus: Epic<
  AnyAction,
  Action<PageReadyStatus>,
  State
> = (action$, state) =>
  action$.pipe(
    ofActionPayload(eventActions.allEventsLoaded),
    filter((payload) => payload === state.value.messages.eventStreamId),
    mergeMap(() =>
      from(
        flatMap(state.value.router.routerState?.routes ?? [], (route) =>
          route.name === Pages.MESSAGE && route.key !== undefined
            ? [
                routerActions.setPageReadyStatus({
                  key: route.key,
                  value: true,
                }),
              ]
            : []
        )
      )
    )
  );

const askShareDataPermission: Epic<AnyAction, AnyAction, State> = (
  action$,
  state
) =>
  zip(
    action$.pipe(
      ofActionPayload(eventActions.allEventsLoaded),
      filter((payload) => payload === state.value.messages.eventStreamId)
    ),
    action$.pipe(ofActionPayload(userActions.loadCurrentUser.done))
  ).pipe(
    filter(
      ([_, user]) => user.result.settings.share_analytics_data === undefined
    ),
    map(() =>
      messageActions.addSystemMessage({
        id: uuid(),
        buttons: [
          {
            icon: "check",
            text: "Yes, I want to help",
            id: SystemMessageButtonId.YES_SHARE_DATA,
          },
          {
            icon: "times",
            text: "No, maybe next time",
            id: SystemMessageButtonId.NO_SHARE_DATA,
          },
        ],
        content: `### We need your help! 🙏

To improve Monoline, we need your help by sharing your usage data. We will only use the data to learn how people use our product in general. Please read our [privacy policy](https://monoline.io/privacy-policy) for more details about how we will collect data and use it. You can always opt-out or change the settings later at any time from "Settings > Account".

`,
        time: new Date(),
      })
    )
  );

const updateShareDataSettings: Epic<AnyAction, AnyAction, State> = (
  action$,
  state
) =>
  action$.pipe(
    ofActionPayload(messageActions.onSystemMessageButtonPressed),
    filter(
      ({ buttonId }) =>
        buttonId === SystemMessageButtonId.YES_SHARE_DATA ||
        buttonId === SystemMessageButtonId.NO_SHARE_DATA
    ),
    mergeMap(({ id, buttonId }) =>
      of(
        messageActions.dismissSystemMessage(id),
        userActions.updateSettings.started({
          ...(state.value.user.settings ?? {}),
          share_analytics_data:
            buttonId === SystemMessageButtonId.YES_SHARE_DATA,
        })
      )
    )
  );

const dismissSystemMessage: Epic<AnyAction, Action<string>, State> = (
  action$,
  state
) =>
  action$.pipe(
    ofActionPayload(messageActions.onSystemMessageButtonPressed),
    filter(({ buttonId }) => buttonId === SystemMessageButtonId.DISMISS),
    map(({ id }) => messageActions.dismissSystemMessage(id))
  );

const messageEpic = combineEpics(
  updateMessage,
  deleteConfirmedMessage,
  deleteWelcomeMessages,
  deletePreview,
  confirmDeleteMessage,
  confirmAbortMessageEditingByCancelButton,
  confirmAbortMessageEditing,
  scrollToMessageAfterStartEditing,
  startUpdatingMessage,
  selectMessage,
  shareMessage,
  startDeletingPreview,
  startDownloadingFile,
  downloadFile,
  // scrollToLatestMessageAfterLoadMessage,
  scrollToLatestMessage,
  scrollToLatestMessageWhenNotificationTapped,
  scrollToLatestMessageButtonClicked,
  // scrollToLatestWhenKeyboardWillShow,
  updateDisplayScrollToLatestMessageButton,
  retryFailedMessage,
  openMessageLink,
  openPreviewLink,
  showAboutByLocalCommand,
  showManualByLocalCommand,
  addHashtagInSearchKeywords,
  navigateToSettingsPage,
  navigateToFeedbackPage,
  updateMessagePageReadyStatus,
  dismissSystemMessage,
  askShareDataPermission,
  updateShareDataSettings
);

export default messageEpic;
