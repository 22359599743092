import AsyncStorage from "@react-native-async-storage/async-storage";
import { StackActions } from "@react-navigation/routers";
import * as Notifications from "expo-notifications";
import { Alert, Linking, Platform } from "react-native";
import { combineEpics, Epic } from "redux-observable";
import { EMPTY, from } from "rxjs";
import {
  catchError,
  filter,
  ignoreElements,
  map,
  mapTo,
  mergeMap,
} from "rxjs/operators";
import { Action, AnyAction, isType, Success } from "typescript-fsa";
import { ofAction, ofActionPayload } from "typescript-fsa-redux-observable";
import { v4 as uuid } from "uuid";
import deviceActions from "../actions/Device";
import subscriptionActions, { CheckoutSource } from "../actions/Subscription";
import errorActions, { ErrorMessage } from "../actions/Error";
import loginActions from "../actions/Login";
import messageActions from "../actions/Message";
import routerActions, { RoutingAction } from "../actions/Router";
import settingsActions, {
  ButtonType,
  InputType,
  SwitchType,
} from "../actions/Settings";
import userActions from "../actions/User";
import { Pages } from "../routers";
import {
  DeviceUpdateRequest,
  UpdatePasswordRequest,
  User,
} from "../services/api/interface";
import {
  ChangePassword,
  SettingsConfig,
  SettingsState,
  State,
} from "../states";
import {
  alertMessage,
  checkPasswordRulesViolation,
  confirmAlert,
  makeAPICallEpic,
} from "./helpers";
import * as Application from "expo-application";

const SETTINGS_KEY = "settings-v2";

const buttonTypePageMap: Partial<Record<ButtonType, Pages>> = {
  [ButtonType.SETTINGS_ACCOUNT]: Pages.SETTINGS_ACCOUNT,
  [ButtonType.SETTINGS_GENERAL]: Pages.SETTINGS_GENERAL,
  [ButtonType.SETTINGS_SECURITY]: Pages.SETTINGS_SECURITY,
  [ButtonType.ACCOUNT_CHANGE_PASSWORD]: Pages.SETTINGS_CHANGE_PASSWORD,
  [ButtonType.ACCOUNT_DELETE]: Pages.SETTINGS_ACCOUNT_DELETE,
};

const updatePassword = makeAPICallEpic<UpdatePasswordRequest, User>(
  settingsActions.updatePassword,
  (params, { apiClient }) => apiClient.updatePassword(params)
);

const navigateToSettingPages: Epic<AnyAction, Action<RoutingAction>, State> = (
  action$,
  state
) =>
  action$.pipe(
    ofActionPayload(settingsActions.onMenuButtonTap),
    filter((buttonType) => buttonType in buttonTypePageMap),
    map((buttonType) =>
      routerActions.route(StackActions.push(buttonTypePageMap[buttonType]!))
    )
  );

const confirmSignOut: Epic<AnyAction, Action<void>, State> = (action$, state) =>
  action$.pipe(
    ofActionPayload(settingsActions.onMenuButtonTap),
    filter((buttonType) => buttonType === ButtonType.ACCOUNT_SIGN_OUT),
    mergeMap((id) =>
      from(
        confirmAlert("Sign out", "Are you sure to sign out current account?")
      ).pipe(
        catchError(() => EMPTY),
        mapTo(loginActions.signOutByUser())
      )
    )
  );

const confirmRemoveWelcomeMessages: Epic<AnyAction, AnyAction, State> = (
  action$,
  state
) =>
  action$.pipe(
    ofActionPayload(settingsActions.onMenuButtonTap),
    filter(
      (buttonType) => buttonType === ButtonType.ACCOUNT_REMOVE_WELCOME_MESSAGES
    ),
    mergeMap((id) =>
      from(
        confirmAlert(
          "Remove Welcome Messages",
          "Are you sure to remove all welcome messages (the tutorial messages)?"
        )
      ).pipe(
        catchError(() => EMPTY),
        mapTo(messageActions.deleteWelcomeMessages.started())
      )
    )
  );

const removeWelcomeMessagesDone: Epic<AnyAction, AnyAction, State> = (
  action$,
  state
) =>
  action$.pipe(
    ofActionPayload(messageActions.deleteWelcomeMessages.done),
    mergeMap((id) =>
      from(
        alertMessage(
          "Remove Welcome Messages done",
          "All welcome messages are deleted"
        )
      ).pipe(ignoreElements())
    )
  );

const updateDisplayMessageTime: Epic<
  AnyAction,
  Action<Partial<SettingsConfig>>,
  State
> = (action$, state) =>
  action$.pipe(
    ofActionPayload(settingsActions.onMenuSwitchValueChange),
    filter(({ type }) => type === SwitchType.GENERAL_DISPLAY_MESSAGE_TIME),
    map(() =>
      settingsActions.updateSettings({
        ...state.value.settings.config,
        displayMessageTime: !state.value.settings.config.displayMessageTime,
      })
    )
  );

const updateURLPreview: Epic<
  AnyAction,
  Action<Partial<SettingsConfig>>,
  State
> = (action$, state) =>
  action$.pipe(
    ofActionPayload(settingsActions.onMenuSwitchValueChange),
    filter(({ type }) => type === SwitchType.DISABLE_URL_PREVIEW),
    map(() =>
      settingsActions.updateSettings({
        ...state.value.settings.config,
        disablePreview: !state.value.settings.config.disablePreview,
      })
    )
  );

const updateMessageNotification: Epic<
  AnyAction,
  Action<DeviceUpdateRequest>,
  State
> = (action$, state) =>
  action$.pipe(
    ofActionPayload(settingsActions.onMenuSwitchValueChange),
    filter(({ type }) => type === SwitchType.MESSAGE_NOTIFICATION_ENABLED),
    mergeMap(async ({ value }) => {
      if (!value) {
        return deviceActions.updateDevice.started({
          notificationEnabled: false,
        } as DeviceUpdateRequest);
      }
      const { status: existingStatus } =
        await Notifications.getPermissionsAsync();
      let finalStatus = existingStatus;
      if (existingStatus !== "granted") {
        const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
      }
      if (finalStatus !== "granted") {
        console.info(
          "Failed to get push token for push notification, status=",
          finalStatus
        );
        if (Platform.OS === "ios") {
          try {
            await new Promise<undefined>((resolve, reject) => {
              Alert.alert(
                "Permission is required",
                "Notification permission for Monoline is required, you can change the setting from the Settings app:\n\nNotifications > Monoline > Allow Notifications",
                [
                  {
                    text: "Open Settings app",
                    style: "default",
                    onPress: () => {
                      reject();
                    },
                  },
                  {
                    text: "Dismiss",
                    onPress: () => {
                      resolve(undefined);
                    },
                  },
                ]
              );
            });
          } catch {
            const settingsURL = "app-settings:notifications";
            if (await Linking.canOpenURL(settingsURL)) {
              await Linking.openURL(settingsURL);
            }
          }
        } else {
          // TODO: provide guide message here
        }
        return;
      }
      let notificationEnvironment: string | null = null;
      if (Platform.OS === "ios") {
        notificationEnvironment =
          await Application.getIosPushNotificationServiceEnvironmentAsync();
      } else if (Platform.OS === "android") {
        await Notifications.setNotificationChannelAsync("new_messages", {
          name: "New Messages",
          importance: Notifications.AndroidImportance.HIGH,
          lightColor: "#0686FF",
        });
      }
      const devicePushToken = await Notifications.getDevicePushTokenAsync();
      if (Platform.OS === "ios" && notificationEnvironment === null) {
        // Notice: somehow for production build (.ipa), the `notificationEnvironment` value
        //         we get here is null. Not sure why yet, but here's a workaround to hardwire
        //         the value here to `production` if it's null.
        notificationEnvironment = "production";
      }
      return deviceActions.updateDevice.started({
        notificationEnabled: true,
        notificationToken: devicePushToken.data,
        notificationEnvironment,
      } as DeviceUpdateRequest);
    }),
    filter((result) => result !== undefined),
    map((result) => result!)
  );

const updateShareDataSettings: Epic<AnyAction, AnyAction, State> = (
  action$,
  state
) =>
  action$.pipe(
    ofActionPayload(settingsActions.onMenuSwitchValueChange),
    filter(({ type }) => type === SwitchType.ACCOUNT_SHARE_DATA),
    map(({ value }) =>
      userActions.updateSettings.started({
        ...(state.value.user.settings ?? {}),
        share_analytics_data: value,
      })
    )
  );

const resetToDefaultSettings: Epic<
  AnyAction,
  Action<Partial<SettingsConfig>>,
  State
> = (action$, state) =>
  action$.pipe(
    ofActionPayload(settingsActions.onMenuButtonTap),
    filter((type) => type === ButtonType.GENERAL_RESET_SETTINGS),
    mergeMap(() =>
      from(
        confirmAlert(
          "Reset to default settings",
          "Are you sure you want to reset to default settings?"
        )
      ).pipe(catchError(() => EMPTY))
    ),
    map(() => settingsActions.updateSettings({}))
  );

const loadSettings: Epic<
  AnyAction,
  Action<Success<void, Partial<SettingsConfig>>>,
  State
> = (action$, state) =>
  action$.pipe(
    ofActionPayload(settingsActions.loadSettings.started),
    mergeMap(() =>
      from(AsyncStorage.getItem(SETTINGS_KEY)).pipe(
        map((payload) =>
          settingsActions.loadSettings.done({
            result:
              payload !== null
                ? (JSON.parse(payload) as Partial<SettingsConfig>)
                : ({} as Partial<SettingsConfig>),
          })
        )
      )
    )
  );

const saveSettings: Epic<AnyAction, Action<SettingsState>, State> = (
  action$,
  state
) =>
  action$.pipe(
    ofActionPayload(settingsActions.updateSettings),
    mergeMap((settings) =>
      AsyncStorage.setItem(SETTINGS_KEY, JSON.stringify(settings))
    ),
    ignoreElements()
  );

const isActionForUpdatingPassword = (action: AnyAction) =>
  (isType(action, settingsActions.onMenuButtonTap) &&
    action.payload === ButtonType.CHANGE_PASSWORD_UPDATE) ||
  (isType(action, settingsActions.onMenuInputSubmit) &&
    action.payload.type === InputType.CHANGE_PASSWORD_NEW_PASSWORD_REPEAT);

const arePasswordValuesFilled = (changePassword: ChangePassword) =>
  [
    changePassword.currentPassword,
    changePassword.newPassword,
    changePassword.newPasswordRepeat,
  ].every((value) => value !== undefined && value.length > 0);

const arePasswordsMatch = (changePassword: ChangePassword) =>
  changePassword.newPassword === changePassword.newPasswordRepeat;

const startValidatingPassword: Epic<AnyAction, Action<void>, State> = (
  action$,
  state
) =>
  action$.pipe(
    ofAction(
      settingsActions.onMenuButtonTap,
      settingsActions.onMenuInputSubmit
    ),
    filter(
      (action) =>
        isActionForUpdatingPassword(action) &&
        arePasswordValuesFilled(state.value.settings.changePassword)
    ),
    mapTo(settingsActions.validatePassword())
  );

const validatePassword: Epic<
  AnyAction,
  Action<ErrorMessage | UpdatePasswordRequest>,
  State
> = (action$, state) =>
  action$.pipe(
    ofAction(settingsActions.validatePassword),
    map(() => {
      if (!arePasswordsMatch(state.value.settings.changePassword)) {
        return errorActions.addMessage({
          id: uuid(),
          message: "Password does not match",
        });
      }
      const violation = checkPasswordRulesViolation(
        state.value.settings.changePassword.newPassword!
      );
      if (violation !== null) {
        return errorActions.addMessage({
          id: uuid(),
          message: violation,
        });
      }
      return settingsActions.updatePassword.started({
        currentPassword: state.value.settings.changePassword.currentPassword!,
        newPassword: state.value.settings.changePassword.newPassword!,
      });
    })
  );

const preventNavigationForPasswordUpdate: Epic<
  AnyAction,
  Action<boolean>,
  State
> = (action$, state) =>
  action$.pipe(
    ofAction(
      settingsActions.updatePassword.started,
      settingsActions.updatePassword.done,
      settingsActions.updatePassword.failed
    ),
    map((action) =>
      routerActions.setPreventNavigation(
        isType(action, settingsActions.updatePassword.started)
      )
    )
  );

const startCreatingCheckout: Epic<AnyAction, AnyAction, State> = (
  action$,
  state
) =>
  action$.pipe(
    ofActionPayload(settingsActions.onMenuButtonTap),
    filter((buttonType) => buttonType === ButtonType.ACCOUNT_UPGRADE_ACCOUNT),
    mapTo(subscriptionActions.createCheckout.started(CheckoutSource.click))
  );

const settingsEpic = combineEpics(
  updatePassword,
  navigateToSettingPages,
  confirmSignOut,
  confirmRemoveWelcomeMessages,
  removeWelcomeMessagesDone,
  updateDisplayMessageTime,
  updateURLPreview,
  updateMessageNotification,
  updateShareDataSettings,
  resetToDefaultSettings,
  saveSettings,
  loadSettings,
  startValidatingPassword,
  preventNavigationForPasswordUpdate,
  validatePassword,
  startCreatingCheckout
);

export default settingsEpic;
