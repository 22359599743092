import { storiesOf } from "@storybook/react-native";
import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import Markdown from "../../../components/Markdown/Markdown";

const storyName = "Components/Markdown/MarkDown";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
  },
  message: {
    flex: -1,
    width: "100%",
    borderWidth: 1,
  },
});

const shortText = "Should buy milk later";
const longText =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.";

storiesOf(storyName, module).add("short", () => (
  <View style={styles.container}>
    <Markdown id="" style={styles.message} message={shortText} />
  </View>
));

storiesOf(storyName, module).add("long", () => (
  <View style={styles.container}>
    <Markdown id="" style={styles.message} message={longText} />
  </View>
));

storiesOf(storyName, module).add("links", () => (
  <View style={styles.container}>
    <Markdown
      id=""
      style={styles.message}
      message={
        "This app is super cool https://monolinel.io, check it out!\n\nMy email is support@monoline.io"
      }
    />
  </View>
));

storiesOf(storyName, module).add("hashtags", () => (
  <View style={styles.container}>
    <Markdown
      id=""
      style={styles.message}
      message={
        "My website https://monolinel.io#hash, #cool #awesome-site #123-ABC_cool!"
      }
    />
  </View>
));

storiesOf(storyName, module).add("hr", () => (
  <View style={styles.container}>
    <Markdown
      id=""
      style={styles.message}
      message={"Hi there\n\n---\n\nHello baby"}
    />
  </View>
));

storiesOf(storyName, module).add("del", () => (
  <View style={styles.container}>
    <Markdown
      id=""
      style={styles.message}
      message={"This is ~~Deleted~~ text"}
    />
  </View>
));

storiesOf(storyName, module).add("strong", () => (
  <View style={styles.container}>
    <Markdown
      id=""
      style={styles.message}
      message={"This is **Strong** text"}
    />
  </View>
));

storiesOf(storyName, module).add("em", () => (
  <View style={styles.container}>
    <Markdown
      id=""
      style={styles.message}
      message={"This is *Emphasized* text"}
    />
  </View>
));

storiesOf(storyName, module).add("u", () => (
  <View style={styles.container}>
    <Markdown
      id=""
      style={styles.message}
      message={"This is __Underlined__ text"}
    />
  </View>
));

storiesOf(storyName, module).add("inlineCode", () => (
  <View style={styles.container}>
    <Markdown
      id=""
      style={styles.message}
      message={"Use `docker run -it monoline` to start the server"}
    />
  </View>
));

storiesOf(storyName, module).add("blockQuote", () => (
  <View style={styles.container}>
    <Markdown
      id=""
      style={styles.message}
      message={`Launch Platform LLC's tagline:

> It's either going to the moon or explode!

By founder Fang-Pen Lin
`}
    />
  </View>
));

storiesOf(storyName, module).add("list", () => (
  <View style={styles.container}>
    <Markdown
      id=""
      style={styles.message}
      message={`- This is first
- This is second
- This is third
`}
    />
  </View>
));

storiesOf(storyName, module).add("codeBlock", () => (
  <View style={styles.container}>
    <Markdown
      id=""
      style={styles.message}
      message={`\`\`\`python
import sys
if sys.argv[0].endswith(".exe"):
    print("hi there")
for arg in sys.argv:
    print(arg)
\`\`\``}
    />
  </View>
));
