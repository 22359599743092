import { reducerWithInitialState } from "typescript-fsa-reducers";
import actions from "../actions/Error";
import loginActions from "../actions/Login";
import { ErrorMessage, ErrorState } from "../states";

const error = reducerWithInitialState<ErrorState>({
  errors: {},
  errorIds: [],
})
  .case(loginActions.logout, (state, value) => ({
    ...state,
    errors: {},
    errorIds: [],
  }))
  .case(actions.addMessage, (state, { id, message }) => ({
    ...state,
    errors: {
      ...state.errors,
      [id]: {
        id,
        message,
      } as ErrorMessage,
    },
    errorIds: [...state.errorIds, id],
  }))
  .case(actions.dismissMessage, (state, id) => ({
    ...state,
    errors: {
      ...state.errors,
      [id]: {
        ...state.errors,
        dismissing: true,
      } as ErrorMessage,
    },
  }))
  .case(actions.dismissMessageAnimationFinished, (state, id) => {
    const { [id]: deletedError, ...remainingErrors } = state.errors;
    return {
      ...state,
      errors: remainingErrors,
      errorIds: state.errorIds.filter((errorId) => errorId !== id),
    };
  });
export default error;
