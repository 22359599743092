import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AnyAction } from "typescript-fsa";
import loginActions from "../../actions/Login";
import Login, { Props } from "../../components/Login";
import { State } from "../../states";

const mapStateToProps = (
  state: State
): Pick<Props, "loading" | "username" | "password"> => ({
  loading: state.login.loading,
  username: state.login.username,
  password: state.login.password,
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): Pick<Props, "onSignIn"> => {
  return {
    onSignIn: (username: string, password: string) => {
      dispatch(loginActions.onSignIn({ username, password }));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
