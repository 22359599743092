import moment from "moment";
import { flatMap, merge, pick, uniqWith } from "lodash";
import { defaultRules, parserFor, SingleASTNode } from "simple-markdown";
import { defaultWhitelist } from "../components/Markdown/Markdown";
import makeRules from "../components/Markdown/rules";

export const commonPrefixLength = (lhs: string, rhs: string) => {
  const minLength = Math.min(lhs.length, rhs.length);
  for (let i = 0; i < minLength; ++i) {
    if (lhs.charAt(i) !== rhs.charAt(i)) {
      return i;
    }
  }
  return minLength;
};

// ref: https://stackoverflow.com/a/5723274/25077
export const truncateMiddle = function (
  fullStr: string,
  strLen: number,
  separator?: string
) {
  if (fullStr.length <= strLen) return fullStr;

  separator = separator || "...";

  var sepLen = separator.length,
    charsToShow = strLen - sepLen,
    frontChars = Math.ceil(charsToShow / 2),
    backChars = Math.floor(charsToShow / 2);

  return (
    fullStr.substr(0, frontChars) +
    separator +
    fullStr.substr(fullStr.length - backChars)
  );
};

const textHashtagRules = pick(merge(defaultRules, makeRules({ styles: {} })), [
  "paragraph",
  "text",
  "hashtag",
]);
const textHashtagParser = parserFor(textHashtagRules);

export enum KeywordType {
  WORD = "WORD",
  HASHTAG = "HASHTAG",
}

export interface Keyword {
  readonly type: KeywordType;
  readonly value: string;
}

export const parseSearchKeywords = (keywords: string): Array<Keyword> => {
  if (keywords.trim().length === 0) {
    return [];
  }
  const tree = textHashtagParser(keywords);
  if (tree[0].type === "text") {
    return [];
  }
  const children: Array<SingleASTNode> = tree[0].content;

  const mergedNodes = children.reduce(
    (nodes: Array<SingleASTNode>, currentValue: SingleASTNode) => {
      if (!nodes.length || currentValue.type !== "text") {
        nodes.push(currentValue);
        return nodes;
      }
      const lastNode = nodes[nodes.length - 1];
      if (lastNode.type === currentValue.type) {
        lastNode.content = lastNode.content + currentValue.content;
      } else {
        nodes.push(currentValue);
      }
      return nodes;
    },
    [] as Array<SingleASTNode>
  );

  return flatMap(mergedNodes, (node) => {
    if (node.type === "text") {
      return node.content
        .split(/\s+/)
        .map(
          (word: string) =>
            ({
              type: KeywordType.WORD,
              value: word,
            } as Keyword)
        )
        .filter((keyword: Keyword) => keyword.value.trim().length > 0);
    } else {
      return {
        type: KeywordType.HASHTAG,
        value: node.content,
      } as Keyword;
    }
  });
};

export interface SearchIndex {
  readonly hashtags: Array<string>;
  readonly text: string;
  // TODO: in the future, if we have to, we can also provide n-grams and words breaking down
  //       for reverse indexing
}

const messageMarkdownParser = parserFor(
  pick(merge(defaultRules, makeRules({ styles: {} })), defaultWhitelist)
);

const visitASTNode = (
  node: SingleASTNode,
  hashtags: Array<string>,
  textPieces: Array<string>
) => {
  switch (node.type) {
    case "text": {
      textPieces.push(node.content);
      break;
    }
    case "inlineCode": {
      textPieces.push(node.content + " ");
      hashtags.push("_inlineCode_");
      hashtags.push("_code_");
      break;
    }
    case "codeBlock": {
      textPieces.push(node.content + " ");
      hashtags.push("_codeBlock_");
      hashtags.push("_code_");
      break;
    }
    case "del":
    case "u":
    case "strong":
    case "em": {
      node.content.forEach((subNode: SingleASTNode) => {
        visitASTNode(subNode, hashtags, textPieces);
      });
      break;
    }
    case "list": {
      node.items.forEach((item: Array<SingleASTNode>) => {
        item.forEach((subNode) => {
          visitASTNode(subNode, hashtags, textPieces);
        });
      });
      break;
    }
    case "paragraph":
    case "blockQuote": {
      node.content.forEach((subNode: SingleASTNode) => {
        visitASTNode(subNode, hashtags, textPieces);
      });
      textPieces.push(" ");
      break;
    }
    case "link":
    case "url": {
      for (const subnode of node.content) {
        visitASTNode(subnode, hashtags, textPieces);
      }
      hashtags.push("_link_");
      break;
    }
    case "hashtag": {
      hashtags.push(node.content);
      break;
    }
  }
};

export const extractSearchIndex = (text: string): SearchIndex => {
  const tree = messageMarkdownParser(text);
  const hashtags: Array<string> = [];
  const textPieces: Array<string> = [];
  for (const node of tree) {
    visitASTNode(node, hashtags, textPieces);
  }
  return {
    hashtags: uniqWith(hashtags),
    text: textPieces
      .map((piece) => piece.trim())
      .filter((piece) => piece.length > 0)
      .join(" "),
  };
};

const visitASTNodeForLinks = (node: SingleASTNode, links: Array<string>) => {
  switch (node.type) {
    case "del":
    case "u":
    case "strong":
    case "em": {
      node.content.forEach((subNode: SingleASTNode) => {
        visitASTNodeForLinks(subNode, links);
      });
      break;
    }
    case "list": {
      node.items.forEach((item: Array<SingleASTNode>) => {
        item.forEach((subNode) => {
          visitASTNodeForLinks(subNode, links);
        });
      });
      break;
    }
    case "paragraph":
    case "blockQuote": {
      node.content.forEach((subNode: SingleASTNode) => {
        visitASTNodeForLinks(subNode, links);
      });
      break;
    }
    case "link":
    case "url": {
      for (const subnode of node.content) {
        visitASTNodeForLinks(subnode, links);
      }
      links.push(node.target);
      break;
    }
    default:
      break;
  }
};

export const extractMarkdownURLs = (text: string): Array<string> => {
  const tree = messageMarkdownParser(text);
  const links: Array<string> = [];
  for (const node of tree) {
    visitASTNodeForLinks(node, links);
  }
  return links;
};

export const extractPreviewURLs = (text: string): Array<string> => {
  const links = extractMarkdownURLs(text);
  return uniqWith(links).slice(0, 10);
};

const millisecondInADay = 24 * 60 * 60 * 1000;

/***
 * Calculate milliseconds left to next day for given datetime.
 * Mostly used for updating the "Today" in date separator
 */
export const millisecondToNextDay = (timeMoment: moment.Moment): number => {
  const beginOfDay = timeMoment
    .clone()
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0);
  const elapsedMs = timeMoment.valueOf() - beginOfDay.valueOf();
  return millisecondInADay - elapsedMs;
};
