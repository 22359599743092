import { storiesOf } from "@storybook/react-native";
import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import ErrorMessage from "../../../components/Error/ErrorMessage";

const storyName = "Components/Error/" + ErrorMessage.name;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 20,
  },
  errorMessage: {
    width: "100%",
  },
});

storiesOf(storyName, module).add("short", () => {
  const [dismissing, setDismissing] = useState(false);
  return (
    <View style={styles.container}>
      <ErrorMessage
        id=""
        style={styles.errorMessage}
        message={'Failed to post message "hello"'}
        dismissing={dismissing}
        onDismiss={() => {
          setDismissing(true);
        }}
      />
    </View>
  );
});

storiesOf(storyName, module).add("long", () => {
  const [dismissing, setDismissing] = useState(false);
  return (
    <View style={styles.container}>
      <ErrorMessage
        id=""
        style={styles.errorMessage}
        message={
          'Failed to upload file "software-contracting-agreement.pdf", exceed maximum file size limitation 10 MB'
        }
        dismissing={dismissing}
        onDismiss={() => {
          setDismissing(true);
        }}
      />
    </View>
  );
});
