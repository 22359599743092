import { StackActions, useNavigation } from "@react-navigation/native";
import React from "react";
import { Pages } from "../../routers";
import PageContainer from "../Page/PageContainer";
import LoginContainer from "./LoginContainer";

const LoginPage: React.FunctionComponent = () => {
  const navigation = useNavigation();
  return (
    <PageContainer name={Pages.LOGIN}>
      <LoginContainer
        onForgetPassword={() => {
          navigation.dispatch(StackActions.push(Pages.FORGOT_PASSWORD));
        }}
        onSignUp={() => {
          navigation.dispatch(StackActions.push(Pages.SIGNUP));
        }}
      />
    </PageContainer>
  );
};

export default LoginPage;
