import { actionCreator } from "./shared";
import * as Notifications from "expo-notifications";

export const prefix = "NOTIFICATION/";
const actions = {
  receivedRequest: actionCreator<Notifications.Notification>(
    prefix + "RECEIVED_REQUEST"
  ),
  receivedResponse: actionCreator<Notifications.NotificationResponse>(
    prefix + "RECEIVED_RESPONSE"
  ),
};
export default actions;
