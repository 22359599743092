import React, { useRef, useState } from "react";
import {
  ScrollView,
  StyleProp,
  StyleSheet,
  TextInput,
  View,
  ViewStyle,
} from "react-native";
import { Colors } from "../Colors";
import IconButton from "../IconButton";

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 20,
  },
  inputContainer: {
    width: "100%",
    maxWidth: 350,
  },
  input: {
    height: 45,
    paddingLeft: 10,
    borderWidth: 1,
    borderColor: Colors.DarkGrey,
    backgroundColor: "white",
    borderRadius: 10,
    fontSize: 14,
  },
  email: {
    marginBottom: 20,
  },
  submitButton: {
    height: 40,
    backgroundColor: Colors.Launch,
    borderRadius: 10,
    justifyContent: "center",
    marginBottom: 10,
  },
});

export interface Props {
  readonly style?: StyleProp<ViewStyle>;
  readonly loading?: boolean;
  readonly onSubmit?: (email: string) => void;
}

const ForgotPassword: React.FunctionComponent<Props> = ({
  style,
  loading,
  onSubmit,
}: Props) => {
  const [email, setEmail] = useState("");
  const emailRef = useRef<TextInput>(null);
  const submitEnabled = email.length > 0;
  return (
    <ScrollView
      style={[styles.container, style]}
      contentContainerStyle={styles.contentContainer}
    >
      <View style={styles.inputContainer}>
        <TextInput
          ref={emailRef}
          style={[styles.input, styles.email]}
          placeholder="Email"
          autoComplete="email"
          textContentType="emailAddress"
          keyboardType="email-address"
          value={email}
          onChangeText={(text) => {
            setEmail(text);
          }}
          onSubmitEditing={() => {
            onSubmit?.(email);
          }}
          returnKeyType="done"
          blurOnSubmit={false}
          autoCapitalize="none"
          editable={!(loading || false)}
          autoFocus
        />
        <IconButton
          name="check"
          text="Submit"
          color="white"
          size={18}
          style={styles.submitButton}
          loading={loading}
          activityIndicatorColor="white"
          disabled={!submitEnabled}
          onPress={() => {
            onSubmit?.(email);
          }}
        />
      </View>
    </ScrollView>
  );
};

export default ForgotPassword;
