import { connect } from "react-redux";
import { ButtonType, InputType } from "../../actions/Settings";
import Settings, {
  MenuItem,
  MenuItemType,
  Props,
} from "../../components/Settings/Settings";
import { ChangePassword, State } from "../../states";
import { mapDispatchToProps } from "./shares";

export const makeItems = (changePassword: ChangePassword): Array<MenuItem> => {
  const updating = changePassword.updating ?? false;
  const finished = changePassword.finished ?? false;
  const editable = !updating;
  const msgVisible = updating || finished;
  let msgText: string = "Updating ...";
  let msgIcon: string | undefined = undefined;
  if (finished) {
    msgText = "Your password has been updated";
    msgIcon = "check-circle";
  }
  return [
    {
      type: MenuItemType.TEXT_INPUT,
      id: InputType.CHANGE_PASSWORD_CURRENT_PASSWORD,
      placeholder: "Current password",
      secureTextEntry: true,
      topSeparator: true,
      value: changePassword.currentPassword ?? "",
      returnKeyType: "next",
      nextInput: InputType.CHANGE_PASSWORD_NEW_PASSWORD,
      autoFocus: true,
      editable,
    },
    {
      type: MenuItemType.TEXT_INPUT,
      id: InputType.CHANGE_PASSWORD_NEW_PASSWORD,
      secureTextEntry: true,
      placeholder: "New password",
      value: changePassword.newPassword ?? "",
      returnKeyType: "next",
      nextInput: InputType.CHANGE_PASSWORD_NEW_PASSWORD_REPEAT,
      editable,
    },
    {
      type: MenuItemType.TEXT_INPUT,
      id: InputType.CHANGE_PASSWORD_NEW_PASSWORD_REPEAT,
      secureTextEntry: true,
      placeholder: "New password repeat",
      value: changePassword.newPasswordRepeat ?? "",
      returnKeyType: "done",
      editable,
    },
    {
      type: MenuItemType.SPACER,
    },
    {
      type: MenuItemType.BUTTON,
      id: ButtonType.CHANGE_PASSWORD_UPDATE,
      icon: "check",
      title: "Update",
      loading: changePassword.updating ?? false,
      topSeparator: true,
    },
    {
      type: MenuItemType.TEXT,
      id: "updateResult",
      icon: msgIcon,
      text: msgText,
      visible: msgVisible,
      bottomSeparator: false,
    },
  ];
};

const mapStateToProps = (state: State): Pick<Props, "items"> => ({
  items: makeItems(state.settings.changePassword),
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
