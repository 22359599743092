import React from "react";
import { Pages } from "../../routers";
import PageContainer from "../Page/PageContainer";
import GeneralContainer from "./GeneralContainer";

const GeneralPage: React.FunctionComponent = () => (
  <PageContainer
    name={Pages.SETTINGS_GENERAL}
    displayErrors={false}
    keyboardAvoiding={false}
  >
    <GeneralContainer />
  </PageContainer>
);

export default GeneralPage;
