import React from "react";
import {
  ActivityIndicator,
  ScrollView,
  StyleProp,
  StyleSheet,
  Text,
  View,
  ViewStyle,
} from "react-native";
import { Colors } from "../Colors";
import IconButton from "../IconButton";
import { FontAwesome5 } from "@expo/vector-icons";

const styles = StyleSheet.create({
  container: {
    paddingVertical: 20,
    paddingHorizontal: 40,
    flex: 1,
    width: "100%",
  },
  title: {
    textAlign: "center",
    fontSize: 48,
  },
  subTitle: {
    marginTop: 20,
    textAlign: "center",
    fontSize: 18,
  },
  deviceIcon: {
    width: 30,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  loadingDevicesContainer: {
    marginVertical: 20,
    flex: 1,
  },
  devicesContainer: {
    marginTop: 20,
    marginBottom: 20,
    paddingHorizontal: 10,
    flex: 1,
  },
  device: {
    height: 35,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginVertical: 10,
  },
  deviceName: {
    flex: 1,
    fontSize: 16,
    marginHorizontal: 15,
  },
  listDeviceFailureContainer: {
    flexDirection: "row",
    marginVertical: 20,
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  listDeviceFailureText: {
    fontSize: 16,
    color: Colors.Danger,
    marginRight: 10,
  },
  upgradeButtonWrapper: {
    height: 40,
    marginHorizontal: 5,
    marginBottom: 10,
  },
  upgradeButton: {
    flex: 1,
    backgroundColor: Colors.Launch,
    color: "white",
    borderRadius: 10,
    justifyContent: "center",
    alignItems: "center",
  },
});

export interface DeviceItem {
  readonly id: string;
  readonly icon: string;
  readonly name: string;
}

export enum ListDeviceState {
  LOADING = "LOADING",
  DONE = "DONE",
  FAILED = "FAILED",
}

export interface Props {
  readonly style?: StyleProp<ViewStyle>;
  readonly devices: Array<DeviceItem>;
  readonly listDeviceState?: ListDeviceState;
  readonly onUpgrade?: () => void;
  readonly onLoadDevice?: () => void;
  readonly onSignOutDevice?: (id: string) => void;
}

const TooManyDevicesView: React.FunctionComponent<Props> = ({
  style,
  devices,
  listDeviceState,
  onUpgrade,
  onLoadDevice,
  onSignOutDevice,
}: Props) => {
  const listDeviceStateValue = listDeviceState ?? ListDeviceState.LOADING;
  let devicesElement: JSX.Element | null = null;
  switch (listDeviceStateValue) {
    case ListDeviceState.LOADING: {
      devicesElement = (
        <View style={styles.loadingDevicesContainer}>
          <ActivityIndicator color={Colors.Launch} />
        </View>
      );
      break;
    }
    case ListDeviceState.DONE: {
      devicesElement = (
        <ScrollView style={styles.devicesContainer}>
          {devices.map((device) => (
            <View key={device.id} style={styles.device}>
              <View style={styles.deviceIcon}>
                <FontAwesome5
                  name={device.icon}
                  size={25}
                  color={Colors.Launch}
                />
              </View>
              <Text style={styles.deviceName}>{device.name}</Text>
              <IconButton
                name="sign-out-alt"
                color={Colors.Launch}
                onPress={() => onSignOutDevice?.(device.id)}
                size={20}
              />
            </View>
          ))}
        </ScrollView>
      );
      break;
    }
    case ListDeviceState.FAILED: {
      devicesElement = (
        <View style={styles.listDeviceFailureContainer}>
          <Text style={styles.listDeviceFailureText}>
            Oops, something went wrong, please try again
          </Text>
          <IconButton
            name="redo"
            color={Colors.Launch}
            size={20}
            onPress={onLoadDevice}
          />
        </View>
      );
      break;
    }
  }
  return (
    <View style={[styles.container, style]}>
      <Text style={styles.title}>Too many devices</Text>
      <Text style={styles.subTitle}>
        Your current plan Free only allows 2 devices, please upgrade to Pro or
        sign out other devices in order to continue
      </Text>
      {devicesElement}
      <View style={styles.upgradeButtonWrapper}>
        <IconButton
          style={styles.upgradeButton}
          name="star"
          text="Upgrade to Pro"
          color="white"
          size={18}
          onPress={onUpgrade}
        />
      </View>
    </View>
  );
};
export default TooManyDevicesView;
