import {
  getStorybookUI,
  configure,
  addDecorator,
} from "@storybook/react-native";
// For uuid4 polyfill
import "react-native-get-random-values";
import Constants from "expo-constants";

import { decorator } from "./preview";
import "./rn-addons";
// import "@storybook/addon-actions/register";

configure(() => {
  require("./stories");
}, module);

const hostname = "192.168.50.80";
addDecorator(decorator as any);

const StorybookUI =
  __DEV__ && Constants.manifest!.extra!.enableStorybook
    ? getStorybookUI({
        port: 7007,
        host: hostname,

        // Pass AsyncStorage below if you want Storybook to open your
        // last visited story after you close and re-open your app
        asyncStorage: null,
      })
    : undefined;

export default StorybookUI;
