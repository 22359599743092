import { storiesOf } from "@storybook/react-native";
import React from "react";
import { StyleSheet, View } from "react-native";
import MenuItemTextInput from "../../../components/Settings/MenuItemTextInput";

const storyName = "Components/Settings/" + MenuItemTextInput.name;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
  },
  menuItem: {
    flex: -1,
    width: "100%",
    borderWidth: 1,
  },
});

storiesOf(storyName, module).add("with icon", () => (
  <View style={styles.container}>
    <MenuItemTextInput
      style={styles.menuItem}
      icon="envelope"
      title="Email"
      value="support@monoline.io"
    />
  </View>
));

storiesOf(storyName, module).add("without icon", () => (
  <View style={styles.container}>
    <MenuItemTextInput
      style={styles.menuItem}
      title="Email"
      value="support@monoline.io"
    />
  </View>
));

storiesOf(storyName, module).add("without title", () => (
  <View style={styles.container}>
    <MenuItemTextInput
      style={styles.menuItem}
      icon="envelope"
      value="support@monoline.io"
    />
  </View>
));

storiesOf(storyName, module).add("without icon and title", () => (
  <View style={styles.container}>
    <MenuItemTextInput style={styles.menuItem} value="support@monoline.io" />
  </View>
));

storiesOf(storyName, module).add("just placeholder", () => (
  <View style={styles.container}>
    <MenuItemTextInput
      style={styles.menuItem}
      placeholder="Type your email address here"
    />
  </View>
));
