import { connect } from "react-redux";
import { Platform } from "react-native";
import { ButtonType, SwitchType } from "../../actions/Settings";
import Settings, {
  MenuItem,
  MenuItemType,
  Props,
} from "../../components/Settings/Settings";
import { defaultSettings } from "../../reducers/settings";
import { State } from "../../states";
import { mapDispatchToProps } from "./shares";

export const makeItems = (
  displayMessageTime: boolean,
  disableURLPreview: boolean,
  deviceNotification: boolean
): Array<MenuItem> => [
  {
    type: MenuItemType.SWITCH,
    id: SwitchType.GENERAL_DISPLAY_MESSAGE_TIME,
    icon: "clock",
    title: "Display message time",
    value: displayMessageTime,
    topSeparator: true,
  },
  {
    type: MenuItemType.SWITCH,
    id: SwitchType.DISABLE_URL_PREVIEW,
    icon: "link",
    title: "Disable URL Preview",
    value: disableURLPreview,
  },
  ...(Platform.OS === "ios" || Platform.OS === "android"
    ? [
        {
          type: MenuItemType.SWITCH,
          id: SwitchType.MESSAGE_NOTIFICATION_ENABLED,
          icon: "bell",
          title: "Notify new messages",
          value: deviceNotification,
        } as MenuItem,
      ]
    : []),
  {
    type: MenuItemType.SPACER,
  },
  {
    type: MenuItemType.BUTTON,
    id: ButtonType.GENERAL_RESET_SETTINGS,
    icon: "redo",
    title: "Reset to default settings",
    loading: false,
    topSeparator: true,
  },
];

const mapStateToProps = (state: State): Pick<Props, "items"> => ({
  items: makeItems(
    state.settings.config.displayMessageTime ??
      defaultSettings.displayMessageTime,
    state.settings.config.disablePreview ?? defaultSettings.disablePreview,
    state.device.currentDevice?.notificationEnabled ??
      defaultSettings.deviceNotification
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
