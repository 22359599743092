import React from "react";
import { Platform, Switch, SwitchProps } from "react-native";
import { Colors } from "./Colors";

const ThemedSwitch: React.FunctionComponent<SwitchProps> = (
  props: SwitchProps
) => {
  const newProsp: SwitchProps = {
    ...(Platform.OS === "web"
      ? {
          activeThumbColor: Colors.Launch,
          activeTrackColor: Colors.MediumGrey,
          thumbColor: Colors.DarkGrey,
          trackColor: Colors.MediumGrey,
        }
      : {
          thumbColor: Colors.Launch,
          trackColor: { false: Colors.MediumGrey, true: Colors.DarkGrey },
        }),
    ...props,
  };

  return <Switch {...newProsp} />;
};

export default ThemedSwitch;
