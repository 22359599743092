import { actionCreator } from "./shared";
import { User } from "../services/api/interface";

export interface UserData {
  readonly username: string;
  readonly password: string;
}

export const prefix = "SIGN_UP/";
const actions = {
  signUp: actionCreator.async<UserData, User>(prefix + "SIGN_UP"),
  validatePassword: actionCreator<UserData>(prefix + "VALIDATE_PASSWORD"),
  onSignUp: actionCreator<UserData>(prefix + "ON_SIGN_UP"),
};
export default actions;
