import { FontAwesome5 } from "@expo/vector-icons";
import React from "react";
import { StyleProp, StyleSheet, Text, View, ViewStyle } from "react-native";
import { Colors } from "../Colors";
import IconButton from "../IconButton";
import ProgressBar from "../ProgressBar";

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
    flexDirection: "row",
    paddingHorizontal: 20,
    alignItems: "center",
    justifyContent: "space-between",
  },
  iconTitleContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 14,
  },
  icon: {
    paddingRight: 10,
  },
  title: {
    fontSize: 18,
    color: "black",
  },
  text: {
    fontSize: 18,
    color: "black",
  },
  progressBar: {
    height: 5,
    width: "100%",
  },
  actionButtonWrapper: {
    backgroundColor: Colors.Launch,
    borderRadius: 10,
    marginRight: 20,
  },
  actionButton: {
    marginVertical: 2,
    marginHorizontal: 8,
  },
  textFieldWrapper: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  textField: {},
});

export interface Props {
  readonly style?: StyleProp<ViewStyle>;
  readonly icon?: string;
  readonly progress?: number;
  readonly actionTitle?: string;
  readonly actionIcon?: string;
  readonly title: string;
  readonly text: string;
  readonly onAction?: () => void;
}

const MenuItemTextField: React.FunctionComponent<Props> = ({
  style,
  icon,
  text,
  title,
  actionIcon,
  actionTitle,
  progress,
  onAction,
}: Props) => {
  return (
    <View style={[styles.container, style]}>
      <View style={styles.iconTitleContainer}>
        {icon !== undefined ? (
          <FontAwesome5 name={icon} size={18} style={styles.icon} />
        ) : null}
        <Text style={styles.title}>{title}</Text>
      </View>
      <View style={styles.textFieldWrapper}>
        {actionIcon !== undefined ? (
          <View style={styles.actionButtonWrapper}>
            <IconButton
              style={styles.actionButton}
              text={actionTitle}
              name={actionIcon}
              color="white"
              size={12}
              onPress={onAction}
            />
          </View>
        ) : null}
        <View style={styles.textField}>
          {progress === undefined ? null : (
            <ProgressBar style={styles.progressBar} progress={progress} />
          )}
          <Text style={styles.text} selectable>
            {text}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default MenuItemTextField;
