import { BlurView } from "expo-blur";
import React from "react";
import {
  Platform,
  StyleProp,
  StyleSheet,
  Text,
  TextInput,
  View,
  ViewStyle,
} from "react-native";
import tinycolor from "tinycolor2";
import { APP_NAME } from "../shared/constants";
import { Colors } from "./Colors";
import IconButton from "./IconButton";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 20,
    backgroundColor: Colors.LightGrey,
  },
  containerWithBackground: {
    backgroundColor: tinycolor(Colors.DarkGrey).setAlpha(0.95).toString(),
  },
  feedbackText: {
    marginVertical: 10,
  },
  input: {
    flex: 1,
    paddingLeft: 10,
    borderWidth: 1,
    borderColor: Colors.DarkGrey,
    backgroundColor: "white",
    borderRadius: 10,
    fontSize: 14,
  },
  buttonContainer: {
    marginVertical: 10,
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

export interface Props {
  readonly style?: StyleProp<ViewStyle>;
  readonly content?: string;
  readonly loading?: boolean;
  readonly contentValid?: boolean;
  readonly onCancel?: () => void;
  readonly onSubmit?: () => void;
  readonly onContentChange?: (content: string) => void;
}

const Feedbacks: React.FunctionComponent<Props> = ({
  style,
  content,
  loading,
  contentValid,
  onCancel,
  onSubmit,
  onContentChange,
}: Props) => {
  const loadingValue = loading ?? false;
  const contentValueValue = contentValid ?? false;
  const BackgroundView = Platform.OS === "android" ? View : BlurView;
  return (
    <BackgroundView
      style={[styles.container, style]}
      intensity={95}
      tint="light"
    >
      <Text style={styles.feedbackText}>Feedbacks:</Text>
      <TextInput
        style={styles.input}
        value={content}
        placeholder={`Any thing you like, don't like about ${APP_NAME}, any suggestions, please feel free to write down your feedbacks here!`}
        onChangeText={onContentChange}
        maxLength={5000}
        editable={!loadingValue}
        multiline
      />
      <View style={styles.buttonContainer}>
        <IconButton
          name="times"
          text="Cancel"
          onPress={onCancel}
          disabled={loadingValue}
        />
        <IconButton
          name="paper-plane"
          text="Submit"
          onPress={onSubmit}
          disabled={!contentValueValue}
          activityIndicatorColor={Colors.Launch}
          loading={loadingValue}
        />
      </View>
    </BackgroundView>
  );
};
export default Feedbacks;
