import {
  AccessToken,
  AuthToken,
  Subscription as Subscription,
  MessageEvent,
  EventPage,
  MessageEventType,
  FileUploadResponse,
  MessageResponse,
  User,
  WebSocketConfig,
  WebSocketMessageType,
  WebSocketMessage,
  WebSocketEventUpdateMessage,
  Device,
  DeviceEventType,
  DeviceEvent,
  DevicePage,
  PortalSession,
  Preview,
  PreviewState,
  Checkout,
} from "./interface";

export const parsePosixTimestamp = (timestamp: number) =>
  new Date(timestamp * 1000);

export const parseAccessToken = (payload: any): AccessToken => ({
  tokenType: payload.token_type,
  accessToken: payload.access_token,
});

export const parseAuthToken = (payload: any): AuthToken => ({
  ...parseAccessToken(payload),
  refreshToken: payload.refresh_token ?? null,
});

export const parseUser = (payload: any): User => ({
  id: payload.id,
  name: payload.name,
  email: payload.email,
  productType: payload.product_type,
  storageSize: payload.storage_size,
  storageUsage: payload.storage_usage,
  eventStreamId: payload.event_stream_id,
  settings: payload.settings,
});

export const parseMessageEvent = (payload: any) =>
  ({
    id: payload.id,
    sequenceId: payload.sequence_id,
    localId: payload.local_id,
    type: MessageEventType[payload.type as keyof typeof MessageEventType],
    messageId: payload.message_id,
    content: payload.content !== null ? payload.content : "",
    timestamp: parsePosixTimestamp(payload.posix_timestamp),
    ...(payload.files !== null &&
    payload.files !== undefined &&
    payload.files.length > 0
      ? {
          files: payload.files.map((file: any) => ({
            id: file.id,
            name: file.name,
          })),
        }
      : {}),
    ...(payload.previews !== null &&
    payload.previews !== undefined &&
    payload.previews.length > 0
      ? {
          previews: payload.previews.map((preview: any) => ({
            id: preview.id,
            url: preview.url,
            title: preview.title,
            description: preview.description,
            thumbnailURL: preview.thumbnail_url,
            state: PreviewState[preview.state as keyof typeof PreviewState],
          })),
        }
      : {}),
    eventType: "MESSAGE",
  } as MessageEvent);

export const parseDeviceEvent = (payload: any) =>
  ({
    id: payload.id,
    sequenceId: payload.sequence_id,
    type: DeviceEventType[payload.type as keyof typeof DeviceEventType],
    value: payload.value,
    timestamp: parsePosixTimestamp(payload.posix_timestamp),
    eventType: "DEVICE",
  } as DeviceEvent);

export const parseEventPage = (payload: any) =>
  ({
    events: payload.events.map((eventPayload: Record<string, any>) =>
      eventPayload.event_type === "MESSAGE"
        ? parseMessageEvent(eventPayload)
        : parseDeviceEvent(eventPayload)
    ),
    lastSequenceId: payload.last_sequence_id,
  } as EventPage);

export const parseWebSocketEventUpdateMessage = (payload: any) =>
  ({
    type: WebSocketMessageType.EVENT_UPDATE,
    eventStreamId: payload.event_stream_id,
    events: payload.events.map((eventPayload: Record<string, any>) =>
      eventPayload.event_type === "MESSAGE"
        ? parseMessageEvent(eventPayload)
        : parseDeviceEvent(eventPayload)
    ),
    lastSequenceId: payload.last_sequence_id,
  } as WebSocketEventUpdateMessage);

export const parseWebSocketMessage = (
  payload: any
): WebSocketMessage | null => {
  if (payload.type === WebSocketMessageType.EVENT_UPDATE) {
    return parseWebSocketEventUpdateMessage(payload);
  }
  // Unknown type, just return null for now
  return null;
};

export const parseMessage = (payload: any) =>
  ({
    id: payload.id,
    localId: payload.local_id,
    content: payload.content !== null ? payload.content : "",
    timestamp: parsePosixTimestamp(payload.posix_timestamp),
    ...(payload.previews !== undefined &&
    payload.previews !== null &&
    payload.previews.length > 0
      ? {
          previews: payload.previews.map((preview: any) =>
            parsePreview(preview)
          ),
        }
      : {}),
    ...(payload.files !== null && payload.files.length > 0
      ? {
          files: payload.files.map((file: any) => ({
            id: file.id,
            name: file.name,
          })),
        }
      : {}),
  } as MessageResponse);

export const parseFile = (payload: any) =>
  ({
    id: payload.id,
    localId: payload.local_id,
    name: payload.name,
    size: payload.size,
    mimeType: payload.mime_type,
    timestamp: parsePosixTimestamp(payload.posix_timestamp),
  } as FileUploadResponse);

export const parseWebSocketConfig = (payload: any) =>
  ({
    token: payload.token,
    heartbeatInterval: payload.heartbeat_interval,
    heartbeatEnabled: payload.heartbeat_enabled,
  } as WebSocketConfig);

export const parseCheckout = (payload: any) =>
  ({
    url: payload.url,
  } as Checkout);

export const parsePortalSession = (payload: any) =>
  ({
    url: payload.url,
  } as PortalSession);

export const parseDevice = (payload: any) =>
  ({
    id: payload.id,
    name: payload.name,
    brand: payload.brand,
    manufacturer: payload.manufacturer,
    modelName: payload.model_name,
    modelId: payload.model_id,
    osName: payload.os_name,
    osVersion: payload.os_version,
    osBuildId: payload.os_build_id,
    tooManyDevices: payload.too_many_devices,
    notificationEnabled: payload.notification_enabled,
    eventStreamId: payload.event_stream_id,
  } as Device);

export const parseDevicePage = (payload: any) =>
  ({
    devices: payload.devices.map(parseDevice),
  } as DevicePage);

export const parsePreview = (payload: any) =>
  ({
    id: payload.id,
    url: payload.url,
    title: payload.title,
    description: payload.description,
    thumbnailURL: payload.thumbnail_url,
    thumbnailWidth: payload.thumbnail_width,
    thumbnailHeight: payload.thumbnail_height,
    state: PreviewState[payload.state as keyof typeof PreviewState],
  } as Preview);
