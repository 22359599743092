import React from "react";
import { Pages } from "../../routers";
import PageContainer from "../Page/PageContainer";
import SettingsContainer from "./SettingsContainer";

const SettingsPage: React.FunctionComponent = () => (
  <PageContainer
    name={Pages.SETTINGS}
    displayErrors={false}
    keyboardAvoiding={false}
  >
    <SettingsContainer />
  </PageContainer>
);

export default SettingsPage;
