import React from "react";
import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { createEpicMiddleware } from "redux-observable";
import matchAll from "string.prototype.matchall";
import { AnyAction } from "typescript-fsa";
import APIClient from "./services/api/client";
import RootApp from "./containers/App";
import rootEpic from "./epics";
import { Dependencies } from "./epics/dependencies";
import rootReducer from "./reducers";
import {
  AMPLITUDE_API_KEY,
  API_BASE_URL,
  ENABLE_STORYBOOK,
} from "./shared/constants";
import { State } from "./states";
import StorybookUI from "./storybook";
import { Platform } from "react-native";
import WebAnalytics from "./services/analytics/web";
import MobileAnalytics from "./services/analytics/mobile";
import NoopAnalytics from "./services/analytics/noop";

matchAll.shim();

const epicMiddleware = createEpicMiddleware<
  AnyAction,
  AnyAction,
  State,
  Dependencies
>({
  dependencies: {
    apiClient: new APIClient(API_BASE_URL),
    analytics:
      AMPLITUDE_API_KEY === null
        ? new NoopAnalytics()
        : Platform.OS === "web"
        ? new WebAnalytics(AMPLITUDE_API_KEY)
        : new MobileAnalytics(AMPLITUDE_API_KEY),
  },
});
const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(epicMiddleware))
);
epicMiddleware.run(rootEpic);

const App = () => {
  return (
    <Provider store={store}>
      <RootApp />
    </Provider>
  );
};

export default __DEV__ && ENABLE_STORYBOOK ? StorybookUI : App;
