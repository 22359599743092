import { connect } from "react-redux";
import { SwitchType } from "../../actions/Settings";
import Settings, {
  MenuItem,
  MenuItemType,
  Props,
} from "../../components/Settings/Settings";
import { State } from "../../states";
import { mapDispatchToProps } from "./shares";

export const makeItems = (): Array<MenuItem> => [
  {
    type: MenuItemType.SWITCH,
    id: SwitchType.SECURITY_TWO_FACTOR_AUTH,
    icon: "key",
    title: "Two Factor Authentication",
    value: false,
    topSeparator: true,
  },
];

const mapStateToProps = (state: State): Pick<Props, "items"> => ({
  items: makeItems(),
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
