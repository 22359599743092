import { CommonActions, StackActions } from "@react-navigation/native";
import { combineEpics, Epic } from "redux-observable";
import { EMPTY, merge, of } from "rxjs";
import {
  distinctUntilChanged,
  filter,
  map,
  mapTo,
  mergeMap,
  tap,
} from "rxjs/operators";
import { Action, AnyAction } from "typescript-fsa";
import { ofActionPayload } from "typescript-fsa-redux-observable";
import deviceActions from "../actions/Device";
import eventActions from "../actions/Event";
import loginActions from "../actions/Login";
import subscriptionActions, { CheckoutSource } from "../actions/Subscription";
import routerActions, { RoutingAction } from "../actions/Router";
import { Pages } from "../routers";
import {
  Device,
  DeviceEventType,
  DevicePage,
  DeviceUpdateRequest,
} from "../services/api/interface";
import { State } from "../states";
import { makeAPICallEpic } from "./helpers";

const loadCurrentDevice = makeAPICallEpic<void, Device>(
  deviceActions.loadCurrentDevice,
  (params, { apiClient }) => apiClient.getCurrentDevice()
);

const listDevices = makeAPICallEpic<void, DevicePage>(
  deviceActions.listDevices,
  (params, { apiClient }) => apiClient.listDevices()
);

const signOutDevice = makeAPICallEpic<string, Device>(
  deviceActions.signOutDevice,
  (id, { apiClient }) => apiClient.signOutDevice(id)
);

const updateDevice = makeAPICallEpic<DeviceUpdateRequest, Device>(
  deviceActions.updateDevice,
  (request, { apiClient }) => apiClient.updateDevice(request)
);

const startLoadingDevice: Epic<AnyAction, AnyAction, State> = (
  action$,
  state
) =>
  action$.pipe(
    ofActionPayload(loginActions.updateAuthToken.done),
    map(() => deviceActions.loadCurrentDevice.started())
  );

const startSignOutDevice: Epic<AnyAction, AnyAction, State> = (
  action$,
  state
) =>
  action$.pipe(
    ofActionPayload(deviceActions.onSignOutDevice),
    map((id) => deviceActions.signOutDevice.started(id))
  );

const reloadDevicesAfterSignOut: Epic<
  AnyAction,
  Action<RoutingAction | void>,
  State
> = (action$, state) =>
  action$.pipe(
    ofActionPayload(deviceActions.signOutDevice.done),
    mapTo(deviceActions.listDevices.started())
  );

const reloadDevices: Epic<AnyAction, Action<RoutingAction | void>, State> = (
  action$,
  state
) =>
  action$.pipe(
    ofActionPayload(deviceActions.onLoadDevices),
    mapTo(deviceActions.listDevices.started())
  );

const shownOrHideTooManyDevices: Epic<
  AnyAction,
  Action<RoutingAction | void>,
  State
> = (action$, state) =>
  state.pipe(
    filter(
      (value) =>
        value.device.currentDevice !== undefined &&
        (value.event.streams[value.device.currentDevice.eventStreamId]
          ?.waitingForUpdate ??
          false)
    ),
    map((value) => value.device.currentDevice!.tooManyDevices),
    distinctUntilChanged(),
    mergeMap((value) => {
      if (value) {
        return of(
          deviceActions.listDevices.started(),
          routerActions.route(StackActions.push(Pages.TOO_MANY_DEVICES))
        );
      }
      if (
        state.value.router.routerState?.routes.find(
          (route) => route.name === Pages.TOO_MANY_DEVICES
        ) === undefined
      ) {
        return EMPTY;
      }
      return of(
        routerActions.route(
          CommonActions.reset({
            ...state.value.router.routerState!,
            routes: state.value.router.routerState!.routes.filter(
              (route) => route.name !== Pages.TOO_MANY_DEVICES
            ),
            index: state.value.router.routerState!.index - 1,
          })
        )
      );
    })
  );

// TODO: find a better way to do this
const preventNavigationForTooManyDevices: Epic<
  AnyAction,
  Action<boolean>,
  State
> = (action$, state) =>
  merge(
    action$.pipe(
      ofActionPayload(routerActions.onPageFocus),
      filter((page) => page === Pages.TOO_MANY_DEVICES),
      mapTo(true)
    ),
    action$.pipe(
      ofActionPayload(routerActions.onPageBlur),
      filter((page) => page === Pages.TOO_MANY_DEVICES),
      mapTo(false)
    )
  ).pipe(map((value) => routerActions.setPreventNavigation(value)));

const startCheckOut: Epic<AnyAction, AnyAction, State> = (action$, state) =>
  action$.pipe(
    ofActionPayload(deviceActions.onUpgradePress),
    mapTo(subscriptionActions.createCheckout.started(CheckoutSource.click))
  );

const signOutForEvent: Epic<AnyAction, Action<void>, State> = (
  action$,
  state
) =>
  merge(
    action$.pipe(
      ofActionPayload(eventActions.loadEvents.done),
      filter(
        ({ params }) =>
          params.eventStreamId ===
          state.value.device.currentDevice?.eventStreamId
      ),
      map(({ result }) => result)
    ),
    action$.pipe(
      ofActionPayload(eventActions.eventUpdate),
      filter(
        ({ eventStreamId }) =>
          eventStreamId === state.value.device.currentDevice?.eventStreamId
      ),
      map((payload) => payload.eventPage)
    )
  ).pipe(
    filter((eventPage) =>
      eventPage.events.some(
        (event) =>
          event.eventType === "DEVICE" &&
          event.type === DeviceEventType.SIGNED_OUT
      )
    ),
    tap(() => console.info("Received device sign-out event, sign out")),
    mapTo(loginActions.logout())
  );

const deviceEpic = combineEpics(
  loadCurrentDevice,
  listDevices,
  signOutDevice,
  updateDevice,
  startLoadingDevice,
  startSignOutDevice,
  reloadDevicesAfterSignOut,
  reloadDevices,
  shownOrHideTooManyDevices,
  preventNavigationForTooManyDevices,
  startCheckOut,
  signOutForEvent
);
export default deviceEpic;
