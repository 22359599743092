import { reducerWithInitialState } from "typescript-fsa-reducers";
import { LoginState } from "../states";
import loginActions from "../actions/Login";

const login = reducerWithInitialState<LoginState>({
  loading: false,
  signedIn: false,
  tokenLoaded: false,
})
  .case(
    loginActions.login.started,
    (state, { credentials: { username, password } }) => ({
      ...state,
      loading: true,
      username,
      password,
    })
  )
  .case(loginActions.login.done, (state, payload) => ({
    loading: false,
    signedIn: true,
    tokenLoaded: state.tokenLoaded,
  }))
  .case(loginActions.login.failed, (state, payload) => ({
    loading: false,
    signedIn: false,
    tokenLoaded: state.tokenLoaded,
  }))
  .case(loginActions.logout, (state, payload) => ({
    loading: false,
    signedIn: false,
    tokenLoaded: state.tokenLoaded,
  }))
  .case(loginActions.loadAuthToken.done, (state, { result }) => ({
    ...state,
    signedIn: result !== null,
    tokenLoaded: true,
  }));
export default login;
