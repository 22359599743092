import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AnyAction } from "typescript-fsa";
import actions from "../../actions/Input";
import CommandList, { Props } from "../../components/Command/CommandList";
import { commonPrefixLength } from "../../shared/utils";
import { State } from "../../states";

const mapStateToProps = (state: State): Pick<Props, "commands" | "visible"> => {
  const { input } = state;
  return {
    commands: (input.matchedCommandIds.length > 0
      ? input.matchedCommandIds
      : input.commandIds
    ).map((id) => {
      const command = input.commands[id];
      return {
        id,
        icon: command.icon,
        command: command.command,
        parameters: command.parameters,
        description: command.description,
        commandPrefixBoldLength: commonPrefixLength(
          input.value,
          command.command
        ),
      };
    }),
    visible: input.displayCommands,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): Pick<Props, "onPress"> => {
  return {
    onPress: (id: string) => {
      dispatch(actions.onCommandTapped(id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CommandList);
