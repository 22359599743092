import { pick } from "lodash";
import moment from "moment";
import { connect } from "react-redux";
import { AnyAction, Dispatch } from "redux";
import { createSelector } from "reselect";
import actions from "../../actions/Message";
import Message, {
  MessageDeletingState,
  MessageState,
  Preview as PreviewRecord,
  Props,
} from "../../components/Message/Message";
import { defaultSettings } from "../../reducers/settings";
import { IS_ELECTRON } from "../../shared/constants";
import {
  Message as MessageRecordState,
  PreviewState,
  State,
} from "../../states";

const getFocusMessageId = (state: State) => state.messages.focusMessageId;
const getDisplayTime = (state: State) =>
  state.settings.config.displayMessageTime ??
  defaultSettings.displayMessageTime;

const mapStateToProps = (_: State, initialProps: Pick<Props, "id">) => {
  const { id } = initialProps;
  const getMessageState = (state: State) =>
    state.messages.messages[id] as MessageRecordState;
  const selector = createSelector(
    [getMessageState, getFocusMessageId, getDisplayTime],
    (
      message: MessageRecordState,
      focusMessageId: string | null,
      displayTime: boolean
    ): Pick<
      Props,
      | "id"
      | "message"
      | "time"
      | "state"
      | "deletingState"
      | "files"
      | "displayTime"
      | "displayShareButton"
    > => {
      let messageState =
        MessageState[message.state as keyof typeof MessageState];
      const editing = message.editing ?? false;
      const updating = message.updating ?? false;
      if (id === focusMessageId && message.state === MessageState.CONFIRMED) {
        if (editing) {
          messageState = updating ? MessageState.PENDING : MessageState.EDIT;
        } else {
          messageState = MessageState.SELECTED;
        }
      }
      return {
        id,
        message: message.contentOverride ?? message.content,
        time: moment(message.timestamp).format("h:mm a"),
        state: messageState,
        displayTime,
        displayShareButton: !IS_ELECTRON,
        deletingState:
          message.deletingState !== undefined
            ? MessageDeletingState[
                message.deletingState as keyof typeof MessageDeletingState
              ]
            : undefined,
        ...(message.files !== undefined
          ? {
              files: message.files.map((file) => ({
                id: file.id,
                name: file.name,
                icon: file.icon,
              })),
            }
          : {}),
        ...(message.previewIds !== undefined
          ? {
              previews: message.previewIds
                .map((previewId) => message.previews[previewId])
                .filter((preview) => preview.state === PreviewState.DONE)
                .map(
                  (preview) =>
                    ({
                      id: preview.id,
                      title: preview.title !== null ? preview.title : undefined,
                      description:
                        preview.description !== null
                          ? preview.description
                          : undefined,
                      url: preview.url,
                      thumbnail:
                        preview.thumbnailURL !== null
                          ? {
                              uri: preview.thumbnailURL,
                              width: preview.thumbnailWidth,
                              height: preview.thumbnailHeight,
                            }
                          : undefined,
                    } as PreviewRecord)
                ),
            }
          : {}),
      };
    }
  );
  return (
    state: State
  ): Pick<
    Props,
    | "id"
    | "message"
    | "time"
    | "state"
    | "deletingState"
    | "files"
    | "displayTime"
    | "displayShareButton"
  > => selector(state);
};

const mapDispatchToProps =
  (_: Dispatch<AnyAction>, initialProps: Pick<Props, "id">) =>
  (
    dispatch: Dispatch<AnyAction>
  ): Pick<
    Props,
    | "onPressed"
    | "onRetry"
    | "onDelete"
    | "onShare"
    | "onEdit"
    | "onEditSave"
    | "onEditCancel"
    | "onEditValueUpdate"
    | "onDeleteAnimationFinish"
    | "onPreviewLinkPress"
    | "onPreviewDelete"
    | "onDownloadFile"
    | "onLinkPressed"
    | "onHashtagPressed"
  > => {
    const id = initialProps.id;
    return {
      onPressed: () => {
        dispatch(actions.onMessagePressed(id));
      },
      onRetry: () => {
        dispatch(actions.onRetryFailedMessage(id));
      },
      onDelete: () => {
        dispatch(actions.onDeleteMessageTapped(id));
      },
      onShare: () => {
        dispatch(actions.onShareMessageTapped(id));
      },
      onEdit: () => {
        dispatch(actions.onEditMessageTapped(id));
      },
      onEditSave: () => {
        dispatch(actions.onEditSaveTapped(id));
      },
      onEditCancel: () => {
        dispatch(actions.onEditCancelTapped(id));
      },
      onEditValueUpdate: (text: string) => {
        dispatch(actions.onEditValueUpdated(text));
      },
      onDeleteAnimationFinish: () => {
        dispatch(actions.onMessageDeletionAnimationFinish(id));
      },
      onPreviewLinkPress: (messageId: string, previewId: string) => {
        dispatch(actions.onPreviewLinkPressed({ messageId, previewId }));
      },
      onPreviewDelete: (messageId: string, previewId: string) => {
        dispatch(actions.onPreviewDelete({ messageId, previewId }));
      },
      onDownloadFile: (messageId: string, fileId: string) => {
        dispatch(actions.onDownloadFileTapped({ messageId, fileId }));
      },
      onLinkPressed: (id: string, url: string) => {
        dispatch(actions.onMessageLinkPressed({ id, url }));
      },
      onHashtagPressed: (id: string, hashtag: string) => {
        dispatch(actions.onMessageHashtagPressed({ id, hashtag }));
      },
    };
  };

export default connect(mapStateToProps, mapDispatchToProps)(Message);
