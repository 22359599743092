import { reducerWithInitialState } from "typescript-fsa-reducers";
import { SignUpState } from "../states";
import signUpActions from "../actions/SignUp";

const signUp = reducerWithInitialState<SignUpState>({
  loading: false,
})
  .case(signUpActions.signUp.started, (state, payload) => ({
    ...state,
    loading: true,
  }))
  .case(signUpActions.signUp.done, (state, payload) => ({
    ...state,
    loading: false,
  }))
  .case(signUpActions.signUp.failed, (state, payload) => ({
    ...state,
    loading: false,
  }));

export default signUp;
