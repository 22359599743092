import AnalyticsInterface from "./interface";

export default class NoopAnalytics implements AnalyticsInterface {
  initialize(): Promise<void> {
    return Promise.resolve();
  }

  setCollecting(value: boolean): void {}

  logEvent(event: string): Promise<void> {
    return Promise.resolve();
  }

  logEventWithProperties(
    event: string,
    properties: Record<string, any>
  ): Promise<void> {
    return Promise.resolve();
  }
  setUserId(userId: string): Promise<void> {
    return Promise.resolve();
  }

  clearUserProperties(): Promise<void> {
    return Promise.resolve();
  }
}
