import { reducerWithInitialState } from "typescript-fsa-reducers";
import { AppState } from "../states";
import appActions from "../actions/App";

const app = reducerWithInitialState<AppState>({
  localDeviceId: "",
}).case(appActions.ensureDeviceLocalId.done, (state, { result }) => ({
  ...state,
  localDeviceId: result,
}));
export default app;
