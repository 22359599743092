import { storiesOf } from "@storybook/react-native";
import React from "react";
import { StyleSheet, View } from "react-native";
import { Mode } from "../../../components/File/File";
import UploadingList from "../../../components/File/UploadingList";

const storyName = "Components/File/" + UploadingList.name;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  uploadList: {
    width: "100%",
    borderWidth: 1,
  },
});

storiesOf(storyName, module).add("list", () => (
  <View style={styles.container}>
    <UploadingList
      visible
      style={styles.uploadList}
      files={[
        {
          id: "file-01",
          icon: "file-pdf",
          fileName: "software-contracting-agreement.pdf",
          progress: 100,
          mode: Mode.UPLOADED,
        },
        {
          id: "file-02",
          icon: "file-word",
          fileName: "NDA.doc",
          progress: 100,
          mode: Mode.UPLOADED,
        },
        {
          id: "file-03",
          icon: "file-archive",
          fileName: "driver-license.pdf",
          progress: 38,
          mode: Mode.UPLOADING,
        },
      ]}
    />
  </View>
));
