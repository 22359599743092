import { StackActions } from "@react-navigation/routers";
import { combineEpics, Epic } from "redux-observable";
import { of } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { AnyAction } from "typescript-fsa";
import { ofActionPayload } from "typescript-fsa-redux-observable";
import { v4 as uuid } from "uuid";
import errorActions from "../actions/Error";
import loginActions from "../actions/Login";
import routerActions from "../actions/Router";
import signUpActions, { UserData } from "../actions/SignUp";
import { User } from "../services/api/interface";
import { State } from "../states";
import {
  checkPasswordRulesViolation,
  makeAPICallEpic,
  makeDeviceInfo,
} from "./helpers";

const signUp = makeAPICallEpic<UserData, User>(
  signUpActions.signUp,
  (params, { apiClient }) =>
    apiClient.signUp({ username: params.username, password: params.password })
);

const startValidatePassword: Epic<AnyAction, AnyAction, State> = (
  action$,
  state
) =>
  action$.pipe(
    ofActionPayload(signUpActions.onSignUp),
    map(({ username, password }) =>
      signUpActions.validatePassword({ username, password })
    )
  );

const validatePassword: Epic<AnyAction, AnyAction, State> = (action$, state) =>
  action$.pipe(
    ofActionPayload(signUpActions.validatePassword),
    map(({ username, password }) => {
      const violation = checkPasswordRulesViolation(password);
      if (violation !== null) {
        return errorActions.addMessage({
          id: uuid(),
          message: violation,
        });
      }
      return signUpActions.signUp.started({ username, password });
    })
  );

const autoLoginAfterSigningUp: Epic<AnyAction, AnyAction, State> = (
  action$,
  state
) =>
  action$.pipe(
    ofActionPayload(signUpActions.signUp.done),
    mergeMap(({ params: { username, password } }) =>
      of(
        routerActions.route(StackActions.pop()),
        loginActions.login.started({
          credentials: { username, password },
          deviceInfo: makeDeviceInfo(state.value.app.localDeviceId),
        })
      )
    )
  );

const signUpEpic = combineEpics(
  signUp,
  startValidatePassword,
  validatePassword,
  autoLoginAfterSigningUp
);

export default signUpEpic;
