import { storiesOf } from "@storybook/react-native";
import React from "react";
import { StyleSheet, View } from "react-native";
import SubscriptionView from "../../../components/Subscription/SubscriptionView";

const storyName = "Components/Subscription/" + SubscriptionView.name;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  subscriptionModal: {
    flex: 1,
    paddingHorizontal: 20,
  },
});

storiesOf(storyName, module).add("view", () => (
  <View style={styles.container}>
    <SubscriptionView style={styles.subscriptionModal} />
  </View>
));
