import React from "react";
import {
  LayoutChangeEvent,
  StyleProp,
  StyleSheet,
  ViewStyle,
} from "react-native";
import { connect } from "react-redux";
import { State } from "../../states";
import EditFieldContainer from "./EditFieldContainer";
import MessageContainer from "./MessageContainer";
import SystemMessageContainer from "./SystemMessageContainer";

const styles = StyleSheet.create({
  message: {},
  systemMessage: {},
});

export interface Props {
  readonly id: string;
  readonly style?: StyleProp<ViewStyle>;
  readonly type: "message" | "system";
  readonly onLayout?: (event: LayoutChangeEvent) => void;
}

export const MessageUnionContainer = ({ id, style, type, onLayout }: Props) => {
  switch (type) {
    case "message": {
      return (
        <MessageContainer
          id={id}
          style={[styles.message, style]}
          onLayout={onLayout}
          renderEditField={() => <EditFieldContainer autoFocus />}
        />
      );
    }
    case "system": {
      return (
        <SystemMessageContainer
          id={id}
          style={[styles.systemMessage, style]}
          onLayout={onLayout}
        />
      );
    }
    default:
      throw new Error(`Invalid message type`);
  }
};

const mapStateToProps =
  (_: State, initialProps: Pick<Props, "id">) =>
  (state: State): Pick<Props, "type"> => {
    const { id } = initialProps;
    const message = state.messages.messages[id];
    return {
      type: message.type,
    };
  };

export default connect(mapStateToProps)(MessageUnionContainer);
