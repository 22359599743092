import { connect } from "react-redux";
import { AnyAction, Dispatch } from "redux";
import actions from "../../actions/Message";
import SystemMessage, { Props } from "../../components/Message/SystemMessage";
import { State, SystemMessage as SystemMessageRecordState } from "../../states";

const mapStateToProps =
  (_: State, initialProps: Pick<Props, "id">) =>
  // TODO: use reselect here to optimize
  (state: State): Pick<Props, "id" | "message" | "dismissing" | "buttons"> => {
    const id = initialProps.id;
    const message = state.messages.messages[id] as SystemMessageRecordState;
    return {
      id: message.id,
      message: message.content,
      dismissing: message.dismissing,
      buttons: message.buttons?.map((button) => ({
        id: button.id,
        icon: button.icon,
        text: button.text,
      })),
    };
  };

const mapDispatchToProps =
  (_: Dispatch<AnyAction>, initialProps: Pick<Props, "id">) =>
  (
    dispatch: Dispatch<AnyAction>
  ): Pick<
    Props,
    "onButtonPressed" | "onDismissAnimationFinish" | "onLinkPressed"
  > => {
    const id = initialProps.id;
    return {
      onButtonPressed: (id, buttonId) => {
        dispatch(actions.onSystemMessageButtonPressed({ id, buttonId }));
      },
      onDismissAnimationFinish: () => {
        dispatch(actions.onSystemMessageDismissAnimationFinish(id));
      },
      onLinkPressed: (id: string, url: string) => {
        dispatch(actions.onSystemMessageLinkPressed({ id, url }));
      },
    };
  };

export default connect(mapStateToProps, mapDispatchToProps)(SystemMessage);
