import { actionCreator } from "./shared";
import { DeleteAccountRequest, User } from "../services/api/interface";

export const prefix = "USER/";
const actions = {
  loadCurrentUser: actionCreator.async<void, User>(
    prefix + "LOAD_CURRENT_USER"
  ),
  updateSettings: actionCreator.async<Record<string, any>, User>(
    prefix + "UPDATE_SETTINGS"
  ),
  deleteAccount: actionCreator.async<DeleteAccountRequest, void>(
    prefix + "DELETE_ACCOUNT"
  ),
};
export default actions;
