import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AnyAction } from "typescript-fsa";
import actions from "../../actions/Message";
import EditField, { Props } from "../../components/Message/EditField";
import { State } from "../../states";

const mapStateToProps = (state: State): Pick<Props, "value"> => {
  return {
    value: state.messages.editingValue!,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): Pick<Props, "onChangeText" | "onSubmit"> => ({
  onChangeText: (text: string) => {
    dispatch(actions.onEditValueUpdated(text));
  },
  onSubmit: () => {},
});

export default connect(mapStateToProps, mapDispatchToProps)(EditField);
