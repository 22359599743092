import { reducerWithInitialState } from "typescript-fsa-reducers";
import feedbacksActions from "../actions/Feedbacks";
import { FeedbacksState, ForgotPasswordState } from "../states";

const feedbacks = reducerWithInitialState<FeedbacksState>({
  loading: false,
})
  .case(feedbacksActions.sendFeedback.started, (state, payload) => ({
    ...state,
    loading: true,
  }))
  .cases(
    [feedbacksActions.sendFeedback.done, feedbacksActions.sendFeedback.failed],
    (state, payload) => ({
      ...state,
      loading: false,
    })
  );
export default feedbacks;
