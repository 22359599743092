import { ResetPasswordRequest } from "../services/api/interface";
import { actionCreator } from "./shared";

export interface ResetPasswordPayload {
  readonly password: string;
  readonly passwordRepeat: string;
}

export const prefix = "FORGOT_PASSWORD/";
const actions = {
  forgotPassword: actionCreator.async<string, void>(prefix + "FORGOT_PASSWORD"),
  resetPassword: actionCreator.async<ResetPasswordRequest, void>(
    prefix + "RESET_PASSWORD"
  ),
  checkResetPasswordToken: actionCreator.async<string, void>(
    prefix + "CHECK_RESET_PASSWORD_TOKEN"
  ),
  validatePassword: actionCreator<ResetPasswordPayload>(
    prefix + "VALIDATE_PASSWORD"
  ),
  onSubmitForgotPassword: actionCreator<string>(
    prefix + "ON_SUBMIT_FORGOT_PASSWORD"
  ),
  onSubmitResetPassword: actionCreator<ResetPasswordPayload>(
    prefix + "ON_SUBMIT_RESET_PASSWORD"
  ),
};
export default actions;
