import { storiesOf } from "@storybook/react-native";
import React from "react";
import { StyleSheet, View } from "react-native";
import { Mode } from "../../../components/File/File";
import CommandList from "../../../components/Command/CommandList";

const storyName = "Components/Command/" + CommandList.name;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  commandList: {
    width: "100%",
    borderWidth: 1,
  },
});

storiesOf(storyName, module).add("list", () => (
  <View style={styles.container}>
    <CommandList
      visible
      style={styles.commandList}
      commands={[
        {
          id: "search",
          icon: "search",
          command: "/search",
          parameters: ["KEYWORD"],
          description: "Search messages",
        },
        {
          id: "upload",
          icon: "file-upload",
          command: "/upload",
          parameters: ["[COMMENT]"],
          description: "Upload files",
        },
        {
          id: "settings",
          icon: "cog",
          command: "/settings",
          description: "Open settings view",
        },
        {
          id: "logout",
          icon: "sign-out-alt",
          command: "/logout",
          description: "Logout current user",
        },
      ]}
    />
  </View>
));
