import { PropsWithChildren } from "hoist-non-react-statics/node_modules/@types/react";
import React from "react";
import { Platform, StyleProp, Text, ViewStyle } from "react-native";
import { pure } from "recompose";

export interface Props {
  readonly url: string;
  readonly style?: StyleProp<ViewStyle>;
  readonly onPress?: () => void;
}

const NativeLink: React.FunctionComponent<PropsWithChildren<Props>> = ({
  style,
  url,
  children,
  onPress,
}) => (
  <Text
    style={style}
    {...(Platform.OS === "web"
      ? {
          href: url,
          hrefAttrs: { target: "_blank" },
          onClick: (event: any) => {
            event.stopPropagation();
            event.preventDefault();
            onPress?.();
          },
        }
      : { onPress })}
  >
    {children}
  </Text>
);

export default pure(NativeLink);
