export enum Colors {
  Launch = "#036BE3",
  Platform = "#FF6E00",
  DarkGrey = "#999999",
  MediumGrey = "#D2D2D2",
  LightGrey = "#F8F8F8",
  Warning = "#FFD60A",
  Danger = "#FF453A",
  CodeBackground = "#eeeeee",
  CodeBorder = "#dddddd",
}
