import { actionCreator } from "./shared";

export const prefix = "KEYBOARD/";
const actions = {
  onKeyboardWillShow: actionCreator(prefix + "ON_KEYBOARD_WILL_SHOW"),
  onKeyboardWillHide: actionCreator(prefix + "ON_KEYBOARD_WILL_HIDE"),
  onKeyboardDidShow: actionCreator(prefix + "ON_KEYBOARD_DID_SHOW"),
  onKeyboardDidHide: actionCreator(prefix + "ON_KEYBOARD_DID_HIDE"),
};
export default actions;
