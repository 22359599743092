import { FontAwesome5 } from "@expo/vector-icons";
import React from "react";
import {
  StyleProp,
  StyleSheet,
  Switch,
  Text,
  View,
  ViewStyle,
} from "react-native";
import { Colors } from "../Colors";
import ThemedSwitch from "../ThemedSwitch";

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
    flexDirection: "row",
    paddingVertical: 14,
    paddingHorizontal: 20,
    alignItems: "center",
    justifyContent: "space-between",
  },
  iconTitleContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  icon: {
    paddingRight: 10,
  },
  title: {
    fontSize: 18,
    color: "black",
  },
  switch: {},
});

export interface Props {
  readonly style?: StyleProp<ViewStyle>;
  readonly icon?: string;
  readonly title: string;
  readonly value?: boolean;
  readonly onValueChange?: (value: boolean) => void;
}

const MenuItemSwitch: React.FunctionComponent<Props> = ({
  style,
  icon,
  title,
  value,
  onValueChange,
}: Props) => {
  return (
    <View style={[styles.container, style]}>
      <View style={styles.iconTitleContainer}>
        {icon !== undefined ? (
          <FontAwesome5 name={icon} size={18} style={styles.icon} />
        ) : null}
        <Text style={styles.title}>{title}</Text>
      </View>
      <ThemedSwitch
        style={styles.switch}
        value={value}
        onValueChange={onValueChange}
      />
    </View>
  );
};

export default MenuItemSwitch;
