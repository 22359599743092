import { Dispatch } from "redux";
import { AnyAction } from "typescript-fsa";
import settingsActions, {
  ButtonType,
  InputType,
  SwitchType,
} from "../../actions/Settings";
import { Props } from "../../components/Settings/Settings";

export const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): Pick<
  Props,
  | "onMenuButtonPress"
  | "onMenuSwitchValueChange"
  | "onMenuInputValueChange"
  | "onMenuInputValueSubmit"
> => {
  return {
    onMenuButtonPress: (id: string) => {
      dispatch(
        settingsActions.onMenuButtonTap(
          ButtonType[id as keyof typeof ButtonType]
        )
      );
    },
    onMenuSwitchValueChange: (id: string, value: boolean) => {
      dispatch(
        settingsActions.onMenuSwitchValueChange({
          type: SwitchType[id as keyof typeof SwitchType],
          value,
        })
      );
    },
    onMenuInputValueChange: (id: string, value: string) => {
      dispatch(
        settingsActions.onMenuInputValueChange({
          type: InputType[id as keyof typeof InputType],
          value,
        })
      );
    },
    onMenuInputValueSubmit: (id: string, value: string) => {
      dispatch(
        settingsActions.onMenuInputSubmit({
          type: InputType[id as keyof typeof InputType],
          value,
        })
      );
    },
  };
};
