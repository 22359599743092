import { template } from "lodash";
import React, { Fragment, useState } from "react";
import {
  Image,
  Platform,
  ScrollView,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from "react-native";
import icon from "../../assets/adaptive-icon.png";
import { Colors } from "../Colors";
import MenuItemButton from "./MenuItemButton";
import MenuItemSwitch from "./MenuItemSwitch";
import MenuItemText from "./MenuItemText";
import MenuItemTextField from "./MenuItemTextField";
import MenuItemTextInput, { SelectedTextInputProps } from "./MenuItemTextInput";

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
  },
  contentContainer: {
    flex: 1,
    alignItems: "center",
  },
  icon: {
    width: 250,
    height: 250,
  },
  line: {
    width: "100%",
    height: 0,
    borderBottomWidth: 1,
    borderColor: Colors.MediumGrey,
  },
  menuItem: {
    width: "100%",
  },
});

export enum MenuItemType {
  LOGO = "LOGO",
  SPACER = "SPACER",
  SEPARATOR = "SEPARATOR",
  BUTTON = "BUTTON",
  SWITCH = "SWITCH",
  TEXT = "TEXT",
  TEXT_FIELD = "TEXT_FIELD",
  TEXT_INPUT = "TEXT_INPUT",
}

export interface MenuItemButtonRecord {
  readonly type: MenuItemType.BUTTON;
  readonly icon: string;
  readonly title: string;
  readonly color?: string;
  readonly loading?: boolean;
  readonly topSeparator?: boolean;
  readonly bottomSeparator?: boolean;
}

export interface MenuItemTextRecord {
  readonly type: MenuItemType.TEXT;
  readonly icon?: string;
  readonly text?: string;
  readonly visible?: boolean;
  readonly topSeparator?: boolean;
  readonly bottomSeparator?: boolean;
}

export interface MenuItemTextFieldRecord {
  readonly type: MenuItemType.TEXT_FIELD;
  readonly icon?: string;
  readonly title: string;
  readonly text: string;
  readonly progress?: number;
  readonly actionTitle?: string;
  readonly actionIcon?: string;
  readonly topSeparator?: boolean;
  readonly bottomSeparator?: boolean;
}

export interface MenuItemSwitchRecord {
  readonly type: MenuItemType.SWITCH;
  readonly icon?: string;
  readonly title: string;
  readonly value?: boolean;
  readonly topSeparator?: boolean;
  readonly bottomSeparator?: boolean;
}

export interface MenuItemInputRecord extends SelectedTextInputProps {
  readonly type: MenuItemType.TEXT_INPUT;
  readonly icon?: string;
  readonly title?: string;
  readonly nextInput?: string;
  readonly topSeparator?: boolean;
  readonly bottomSeparator?: boolean;
}

export interface MenuItemLogo {
  readonly type: MenuItemType.LOGO;
}

export interface MenuItemSpacer {
  readonly type: MenuItemType.SPACER;
  readonly height?: number;
}

export interface MenuItemSeparator {
  readonly type: MenuItemType.SEPARATOR;
}

export interface MenuItemBase {
  readonly id?: string;
}

export type MenuItem = MenuItemBase &
  (
    | MenuItemButtonRecord
    | MenuItemTextRecord
    | MenuItemTextFieldRecord
    | MenuItemInputRecord
    | MenuItemSwitchRecord
    | MenuItemLogo
    | MenuItemSpacer
    | MenuItemSeparator
  );

export interface Props {
  readonly style?: StyleProp<ViewStyle>;
  readonly items: Array<MenuItem>;
  readonly onMenuButtonPress?: (id: string) => void;
  readonly onMenuSwitchValueChange?: (id: string, value: boolean) => void;
  readonly onMenuInputValueChange?: (id: string, value: string) => void;
  readonly onMenuInputValueSubmit?: (id: string, value: string) => void;
}

const Settings: React.FunctionComponent<Props> = ({
  style,
  items,
  onMenuButtonPress,
  onMenuSwitchValueChange,
  onMenuInputValueChange,
  onMenuInputValueSubmit,
}: Props) => {
  const [focusCounter, setFocusCounter] = useState<Record<string, number>>({});
  return (
    <ScrollView
      style={[styles.container, style]}
      contentContainerStyle={styles.contentContainer}
      keyboardShouldPersistTaps="always"
    >
      {items.map((item: MenuItem, index: number) => {
        const key = (item.id ?? index).toString();
        switch (item.type) {
          case MenuItemType.BUTTON: {
            return (
              <Fragment key={key}>
                {item.topSeparator ?? false ? (
                  <View style={styles.line} />
                ) : null}
                <MenuItemButton
                  style={styles.menuItem}
                  icon={item.icon}
                  title={item.title}
                  color={item.color}
                  loading={item.loading}
                  onPress={() => {
                    onMenuButtonPress?.(key);
                  }}
                />
                {item.bottomSeparator ?? true ? (
                  <View style={styles.line} />
                ) : null}
              </Fragment>
            );
          }
          case MenuItemType.TEXT: {
            return (
              <Fragment key={key}>
                {item.topSeparator ?? false ? (
                  <View style={styles.line} />
                ) : null}
                <MenuItemText
                  text={item.text}
                  icon={item.icon}
                  visible={item.visible}
                />
                {item.bottomSeparator ?? true ? (
                  <View style={styles.line} />
                ) : null}
              </Fragment>
            );
          }
          case MenuItemType.TEXT_FIELD: {
            return (
              <Fragment key={key}>
                {item.topSeparator ?? false ? (
                  <View style={styles.line} />
                ) : null}
                <MenuItemTextField
                  style={styles.menuItem}
                  icon={item.icon}
                  title={item.title}
                  progress={item.progress}
                  actionTitle={item.actionTitle}
                  actionIcon={item.actionIcon}
                  text={item.text}
                  onAction={() => onMenuButtonPress?.(key)}
                />
                {item.bottomSeparator ?? true ? (
                  <View style={styles.line} />
                ) : null}
              </Fragment>
            );
          }
          case MenuItemType.SWITCH: {
            return (
              <Fragment key={key}>
                {item.topSeparator ?? false ? (
                  <View style={styles.line} />
                ) : null}
                <MenuItemSwitch
                  style={styles.menuItem}
                  icon={item.icon}
                  title={item.title}
                  value={item.value}
                  onValueChange={(value: boolean) =>
                    onMenuSwitchValueChange?.(key, value)
                  }
                />
                {item.bottomSeparator ?? true ? (
                  <View style={styles.line} />
                ) : null}
              </Fragment>
            );
          }
          case MenuItemType.TEXT_INPUT: {
            const { icon, title, nextInput, ...props } = item;
            return (
              <Fragment key={key}>
                {item.topSeparator ?? false ? (
                  <View style={styles.line} />
                ) : null}
                <MenuItemTextInput
                  style={styles.menuItem}
                  icon={icon}
                  title={title}
                  focusCounter={focusCounter[key]}
                  onChangeText={(value: string) =>
                    onMenuInputValueChange?.(key, value)
                  }
                  onSubmitEditing={(event) => {
                    if (nextInput !== undefined) {
                      setFocusCounter({
                        ...focusCounter,
                        [nextInput]: (focusCounter[nextInput] ?? 0) + 1,
                      });
                      return;
                    }
                    onMenuInputValueSubmit?.(key, event.nativeEvent.text);
                  }}
                  {...props}
                />
                {item.bottomSeparator ?? true ? (
                  <View style={styles.line} />
                ) : null}
              </Fragment>
            );
          }
          case MenuItemType.LOGO: {
            return <Image key={key} style={styles.icon} source={icon} />;
          }
          case MenuItemType.SEPARATOR: {
            return <View key={key} style={styles.line} />;
          }
          case MenuItemType.SPACER: {
            return <View key={key} style={{ height: item.height ?? 40 }} />;
          }
          default:
            throw new Error("Unknown menu item type");
        }
      })}
    </ScrollView>
  );
};

export default Settings;
