import React, { FunctionComponent, useEffect, useRef } from "react";
import {
  Animated,
  Easing,
  FlatList,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from "react-native";
import File, { Mode } from "./File";
import UploadingMenu from "./UploadingMenu";

const styles = StyleSheet.create({
  animatedContainer: {},
  container: {
    flex: -1,
  },
  list: {
    flexGrow: 0,
  },
  file: {
    paddingVertical: 5,
    paddingHorizontal: 10,
  },
  menu: {
    paddingTop: 10,
    paddingHorizontal: 10,
  },
});

export interface File {
  readonly id: string;
  readonly fileName: string;
  readonly progress: number;
  readonly mode: Mode;
  readonly icon?: string;
}

export interface Props {
  readonly files: Array<File>;
  readonly visible?: boolean;
  readonly style?: StyleProp<ViewStyle>;
  readonly addButtonDisabled?: boolean;
  readonly onAdd?: () => void;
  readonly onCancel?: () => void;
  readonly onDelete?: (id: string) => void;
}

const calcualteHeight = (fileCount: number) => {
  return 42 * fileCount + 40;
};

const UploadingList: FunctionComponent<Props> = ({
  style,
  files,
  visible,
  addButtonDisabled,
  onAdd,
  onCancel,
  onDelete,
}: Props) => {
  const currentHeight = calcualteHeight(files.length);
  const animatedProgress = useRef(new Animated.Value(0)).current;
  const animatedHeight = useRef(new Animated.Value(currentHeight)).current;
  const easing = Easing.inOut(Easing.cubic);
  const duration = 300;

  useEffect(() => {
    Animated.timing(animatedProgress, {
      toValue: visible ?? false ? 1 : 0,
      easing,
      duration,
      useNativeDriver: false,
    }).start();
  }, [visible]);

  useEffect(() => {
    Animated.timing(animatedHeight, {
      toValue: visible ?? false ? currentHeight : 0,
      easing,
      duration,
      useNativeDriver: false,
    }).start();
  }, [visible, currentHeight]);

  return (
    <Animated.View
      style={[
        styles.animatedContainer,
        {
          opacity: animatedProgress,
          height: animatedHeight,
        },
        style,
      ]}
    >
      <View style={[styles.container]}>
        <FlatList
          data={files}
          style={[styles.list]}
          keyboardShouldPersistTaps="always"
          renderItem={({ item }) => (
            <File
              style={styles.file}
              fileName={item.fileName}
              progress={item.progress}
              icon={item.icon}
              mode={item.mode}
              onDelete={() => onDelete?.(item.id)}
            />
          )}
          keyExtractor={({ id }) => id}
        />
        <UploadingMenu
          style={[styles.menu]}
          addButtonDisabled={addButtonDisabled}
          onAdd={onAdd}
          onCancel={onCancel}
        />
      </View>
    </Animated.View>
  );
};

export default UploadingList;
