import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AnyAction } from "typescript-fsa";
import forgotPasswordActions from "../../actions/ForgotPassword";
import ForgotPassword, {
  Props,
} from "../../components/ForgotPassword/ForgotPassword";
import { State } from "../../states";

const mapStateToProps = (state: State): Pick<Props, "loading"> => ({
  loading: state.forgotPassword.loadingForgotPassword,
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): Pick<Props, "onSubmit"> => {
  return {
    onSubmit: (email: string) => {
      dispatch(forgotPasswordActions.onSubmitForgotPassword(email));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
