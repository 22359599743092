import React from "react";
import {
  ActivityIndicator,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from "react-native";
import { Colors } from "./Colors";

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.MediumGrey,
  },
  bar: {
    backgroundColor: Colors.Launch,
    height: "100%",
  },
});

export interface Props {
  readonly style?: StyleProp<ViewStyle>;
  readonly progress: number;
}

const ProgressBar: React.FunctionComponent<Props> = ({
  style,
  progress,
}: Props) => {
  return (
    <View style={[styles.container, style]}>
      <View style={[styles.bar, { width: `${progress * 100}%` }]} />
    </View>
  );
};

export default ProgressBar;
