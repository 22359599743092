import React, { useRef, useState } from "react";
import {
  ScrollView,
  StyleProp,
  StyleSheet,
  TextInput,
  View,
  ViewStyle,
} from "react-native";
import { Colors } from "../Colors";
import IconButton from "../IconButton";

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 20,
  },
  inputContainer: {
    width: "100%",
    maxWidth: 350,
  },
  input: {
    height: 45,
    paddingLeft: 10,
    borderWidth: 1,
    borderColor: Colors.DarkGrey,
    backgroundColor: "white",
    borderRadius: 10,
    fontSize: 14,
  },
  password: {
    marginBottom: 20,
  },
  submitButton: {
    height: 40,
    backgroundColor: Colors.Launch,
    borderRadius: 10,
    justifyContent: "center",
    marginBottom: 10,
  },
});

export interface Props {
  readonly style?: StyleProp<ViewStyle>;
  readonly loading?: boolean;
  readonly onSubmit?: (password: string, passwordRepeat: string) => void;
}

const ResetPassword: React.FunctionComponent<Props> = ({
  style,
  loading,
  onSubmit,
}: Props) => {
  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const passwordRef = useRef<TextInput>(null);
  const passwordRepeatRef = useRef<TextInput>(null);
  const submitEnabled = password.length > 0 && passwordRepeat.length > 0;
  return (
    <ScrollView
      style={[styles.container, style]}
      contentContainerStyle={styles.contentContainer}
    >
      <View style={styles.inputContainer}>
        <TextInput
          ref={passwordRef}
          style={[styles.input, styles.password]}
          placeholder="Password"
          autoComplete="password"
          textContentType="newPassword"
          value={password}
          onChangeText={(text) => {
            setPassword(text);
          }}
          onSubmitEditing={() => {
            passwordRepeatRef.current?.focus();
          }}
          returnKeyType="next"
          blurOnSubmit={false}
          autoCapitalize="none"
          editable={!(loading || false)}
          secureTextEntry
          autoFocus
        />
        <TextInput
          ref={passwordRepeatRef}
          style={[styles.input, styles.password]}
          placeholder="Password repeat"
          autoComplete="password"
          textContentType="newPassword"
          value={passwordRepeat}
          onChangeText={(text) => {
            setPasswordRepeat(text);
          }}
          onSubmitEditing={() => {
            onSubmit?.(password, passwordRepeat);
          }}
          returnKeyType="done"
          blurOnSubmit={false}
          autoCapitalize="none"
          editable={!(loading || false)}
          secureTextEntry
          autoFocus
        />
        <IconButton
          name="check"
          text="Submit"
          color="white"
          size={18}
          style={styles.submitButton}
          loading={loading}
          activityIndicatorColor="white"
          disabled={!submitEnabled}
          onPress={() => {
            onSubmit?.(password, passwordRepeat);
          }}
        />
      </View>
    </ScrollView>
  );
};

export default ResetPassword;
