import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AnyAction } from "typescript-fsa";
import userActions from "../../actions/User";
import DeleteAccount, { Props } from "../../components/DeleteAccount";
import { State } from "../../states";

const mapStateToProps = (state: State): Pick<Props, "loading" | "email"> => ({
  loading: state.user.deletingAccount ?? false,
  email: state.user.email ?? "",
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): Pick<Props, "onSubmit"> => ({
  onSubmit: (password: string, feedback: string) => {
    dispatch(
      userActions.deleteAccount.started({
        password,
        ...(feedback.trim().length > 0 ? { feedback } : {}),
      })
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAccount);
