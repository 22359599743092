import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AnyAction } from "typescript-fsa";
import deviceActions from "../../actions/Device";
import TooManyDevicesModal from "../../components/TooManyDevices/TooManyDevicesModal";
import {
  DeviceItem,
  Props,
} from "../../components/TooManyDevices/TooManyDevicesView";
import { Device, State } from "../../states";

const getDeviceIcon = (device: Device) => {
  if (device.osName === "iOS" || device.osName === "Android") {
    return "mobile";
  }
  if (device.osName === "iPadOS") {
    return "tablet";
  }
  if (
    device.osName === "Windows" ||
    device.osName === "Linux" ||
    device.osName === "Mac OS"
  ) {
    return "laptop";
  }
  return "mobile";
};

const getDeviceName = (device: Device) => {
  if (device.name === null) {
    if (device.osName !== null) {
      return `Unnamed ${device.osName} device`;
    }
    return "Unnamed device";
  }
  return device.name;
};

const mapStateToProps = (
  state: State
): Pick<Props, "devices" | "listDeviceState"> => ({
  devices: state.device.devices.map(
    (device) =>
      ({
        id: device.id,
        name:
          getDeviceName(device) +
          (device.id === state.device.currentDevice?.id
            ? " (This device)"
            : ""),
        icon: getDeviceIcon(device),
      } as DeviceItem)
  ),
  listDeviceState: state.device.listDeviceState,
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): Pick<Props, "onUpgrade" | "onSignOutDevice" | "onLoadDevice"> => {
  return {
    onUpgrade: () => {
      dispatch(deviceActions.onUpgradePress());
    },
    onLoadDevice: () => {
      dispatch(deviceActions.onLoadDevices());
    },
    onSignOutDevice: (id: string) => {
      dispatch(deviceActions.onSignOutDevice(id));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TooManyDevicesModal);
