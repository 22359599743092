import { isEmpty, sortedLastIndexBy } from "lodash";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import eventActions from "../actions/Event";
import inputActions from "../actions/Input";
import loginActions from "../actions/Login";
import actions from "../actions/Message";
import userActions from "../actions/User";
import {
  MessageEvent,
  EventFile,
  MessageEventType,
} from "../services/api/interface";
import { extractSearchIndex } from "../shared/utils";
import {
  BuildInCommandType,
  HashtagIndex,
  Message,
  MessageDeletingState,
  MessagesState,
  MessageState,
  MessageUnion,
  Preview,
  PreviewState,
  SearchIndex,
  SystemMessage,
} from "../states";
import { lookupIcon } from "./helpers";

const makeSearchIndex = (
  content: string,
  files?: Array<EventFile>
): SearchIndex => {
  const { text, hashtags } = extractSearchIndex(content);
  let allText = text;
  const hashtagsSet = hashtags.reduce(
    (map: Record<string, boolean>, value: string) => {
      map[value.toLowerCase()] = true;
      return map;
    },
    {} as Record<string, boolean>
  );
  if (files !== undefined) {
    hashtagsSet["_file_"] = true;
    for (const file of files) {
      allText += ` ${file.name}`;
    }
  }
  return {
    text: allText,
    hashtags: hashtagsSet,
  };
};

export const updateMessagesWithEvents = (
  messages: Record<string, MessageUnion>,
  messageIds: string[],
  events: Array<MessageEvent>,
  hashtagIndex: HashtagIndex,
  skipMessageAnimation: boolean
) => {
  const newMessages = { ...messages };
  const newMessageIds = [...messageIds];
  const newHashtagIndex = {
    hashtagToMessageIds: { ...hashtagIndex.hashtagToMessageIds },
  };
  for (const event of events) {
    switch (event.type) {
      case MessageEventType.ADD_MESSAGE: {
        // Skip duplicate message
        if (event.messageId in newMessages) {
          continue;
        }
        const { localId } = event;
        const searchIndex = makeSearchIndex(event.content ?? "", event.files);
        const { hashtags } = extractSearchIndex(event.content ?? "");
        if (localId !== null && localId in messages) {
          // Remove the pending message from messages
          delete newMessages[localId];
          // Remove the local id
          const localMessageIndex = newMessageIds.findIndex(
            (messageId) => messageId === localId
          );
          newMessageIds.splice(localMessageIndex, 1);
          // Remove hashtag index for old pending msg
          for (const hashtagName of hashtags) {
            delete newHashtagIndex.hashtagToMessageIds[hashtagName][localId];
            if (isEmpty(newHashtagIndex.hashtagToMessageIds[hashtagName])) {
              delete newHashtagIndex.hashtagToMessageIds[hashtagName];
            }
          }
        }

        for (const hashtagName of hashtags) {
          if (hashtagName in newHashtagIndex.hashtagToMessageIds) {
            newHashtagIndex.hashtagToMessageIds[hashtagName][event.messageId] =
              true;
          } else {
            newHashtagIndex.hashtagToMessageIds[hashtagName] = {
              [event.messageId]: true,
            };
          }
        }
        const previewIds = event.previews?.map((preview) => preview.id);
        const previews: Record<string, Preview> =
          event.previews !== undefined
            ? Object.fromEntries(
                event.previews?.map((preview) => [
                  preview.id,
                  {
                    id: preview.id,
                    url: preview.url,
                    title: preview.title,
                    description: preview.description,
                    thumbnailURL: preview.thumbnailURL,
                    thumbnailWidth: preview.thumbnailWidth,
                    thumbnailHeight: preview.thumbnailHeight,
                    state:
                      PreviewState[preview.state as keyof typeof PreviewState],
                    deleting: false,
                  } as Preview,
                ])
              )
            : {};
        newMessages[event.messageId] = {
          type: "message",
          id: event.messageId,
          revision: event.revision,
          localId: event.localId,
          content: event.content ?? "",
          timestamp: event.timestamp,
          state: MessageState.CONFIRMED,
          searchIndex,
          previewIds,
          previews,
          ...(event.files !== undefined
            ? {
                files: event.files.map((file) => ({
                  id: file.id,
                  name: file.name,
                  icon: lookupIcon(file.name),
                })),
              }
            : {}),
        } as Message;

        // Always insert message based on timestamp to keep it in order,
        // due to networking issue, we may see local message appears as last,
        // but later on actual order messages update come in from websocket.
        const insertIndex = sortedLastIndexBy(
          newMessageIds,
          event.messageId,
          (messageId) => newMessages[messageId].timestamp
        );
        newMessageIds.splice(insertIndex, 0, event.messageId);
        break;
      }
      case MessageEventType.UPDATE_MESSAGE: {
        const { updating, editing, contentOverride, ...message } = newMessages[
          event.messageId
        ] as Message;
        // Looks like revision is not greater than existing one,
        // just skip
        if (event.revision <= message.revision) {
          continue;
        }

        // Remove hashtag index for old msg
        const { hashtags: oldHashTags } = extractSearchIndex(
          message.content ?? ""
        );
        for (const hashtagName of oldHashTags) {
          delete newHashtagIndex.hashtagToMessageIds[hashtagName][
            event.messageId
          ];
          if (isEmpty(newHashtagIndex.hashtagToMessageIds[hashtagName])) {
            delete newHashtagIndex.hashtagToMessageIds[hashtagName];
          }
        }
        // Add new hash tags and search index
        const searchIndex = makeSearchIndex(event.content ?? "", event.files);
        const { hashtags } = extractSearchIndex(event.content ?? "");
        for (const hashtagName of hashtags) {
          if (hashtagName in newHashtagIndex.hashtagToMessageIds) {
            newHashtagIndex.hashtagToMessageIds[hashtagName][event.messageId] =
              true;
          } else {
            newHashtagIndex.hashtagToMessageIds[hashtagName] = {
              [event.messageId]: true,
            };
          }
        }

        const newPreviews =
          (newMessages[event.messageId] as Message).previews ?? {};
        const newPreviewIds = event.previews?.map((preview) => preview.id);
        for (const preview of event.previews ?? []) {
          newPreviews[preview.id] =
            preview.id in newPreviews
              ? ({
                  ...newPreviews[preview.id],
                  title: preview.title,
                  description: preview.description,
                  thumbnailURL: preview.thumbnailURL,
                  thumbnailWidth: preview.thumbnailWidth,
                  thumbnailHeight: preview.thumbnailHeight,
                  state:
                    PreviewState[preview.state as keyof typeof PreviewState],
                } as Preview)
              : ({
                  id: preview.id,
                  url: preview.url,
                  title: preview.title,
                  description: preview.description,
                  thumbnailURL: preview.thumbnailURL,
                  thumbnailWidth: preview.thumbnailWidth,
                  thumbnailHeight: preview.thumbnailHeight,
                  state:
                    PreviewState[preview.state as keyof typeof PreviewState],
                  deleting: false,
                } as Preview);
        }

        newMessages[event.messageId] = {
          ...message,
          content: event.content!,
          revision: event.revision,
          searchIndex,
          previewIds: newPreviewIds,
          previews: newPreviews,
          ...(event.files !== undefined
            ? {
                files: event.files.map((file) => ({
                  id: file.id,
                  name: file.name,
                  icon: lookupIcon(file.name),
                })),
              }
            : {}),
        };
        break;
      }
      case MessageEventType.DELETE_MESSAGE: {
        // Delete the message without animation
        if (skipMessageAnimation) {
          // Skip already deleted message
          if (!(event.messageId in newMessages)) {
            continue;
          }
          delete newMessages[event.messageId];
          // Remove the deleted message id from ids
          const deletingMessageIndex = newMessageIds.findIndex(
            (messageId) => messageId === event.messageId
          );
          newMessageIds.splice(deletingMessageIndex, 1);
          continue;
        }
        // Mark it as deleted, it will really be removed after animation is done
        newMessages[event.messageId] = {
          ...(newMessages[event.messageId] as Message),
          deletingState: MessageDeletingState.DELETED,
        };
        break;
      }
    }
  }
  return {
    messages: newMessages,
    messageIds: newMessageIds,
    hashtagIndex: newHashtagIndex,
  };
};

const defaultState = {
  messages: {},
  messageIds: [],
  focusMessageId: null,
  scrollCounter: 0,
  viewingLatest: false,
  displayScrollToLatestButton: true,
  skipMessageAnimation: true,
  searchKeywords: null,
  hashtagIndex: {
    hashtagToMessageIds: {},
  },
  editingValue: null,
  readyStatus: false,
  eventStreamId: null,
};

const messages = reducerWithInitialState<MessagesState>(defaultState)
  .case(loginActions.logout, (state, payload) => defaultState)
  .case(userActions.loadCurrentUser.done, (state, payload) => ({
    ...state,
    eventStreamId: payload.result.eventStreamId,
  }))
  .case(eventActions.loadEvents.done, (state, { params, result }) => ({
    ...state,
    ...(params.eventStreamId === state.eventStreamId
      ? {
          ...updateMessagesWithEvents(
            state.messages,
            state.messageIds,
            result.events as Array<MessageEvent>,
            state.hashtagIndex,
            state.skipMessageAnimation
          ),
          skipMessageAnimation: result.lastSequenceId !== null,
        }
      : {}),
  }))
  .case(eventActions.eventUpdate, (state, payload) => ({
    ...state,
    ...(payload.eventStreamId === state.eventStreamId
      ? updateMessagesWithEvents(
          state.messages,
          state.messageIds,
          payload.eventPage.events as Array<MessageEvent>,
          state.hashtagIndex,
          state.skipMessageAnimation
        )
      : {}),
  }))
  .case(
    inputActions.sendMessage.started,
    (state, { content, localId, files }) => {
      const searchIndex = makeSearchIndex(content ?? "");
      const newHashtagIndex = {
        hashtagToMessageIds: { ...state.hashtagIndex.hashtagToMessageIds },
      };
      const { hashtags } = extractSearchIndex(content ?? "");
      for (const hashtagName of hashtags) {
        if (hashtagName in newHashtagIndex.hashtagToMessageIds) {
          newHashtagIndex.hashtagToMessageIds[hashtagName][localId] = true;
        } else {
          newHashtagIndex.hashtagToMessageIds[hashtagName] = {
            [localId]: true,
          };
        }
      }

      const newMessageIds = [...state.messageIds];
      if (localId in state.messages) {
        // This is a retry, remove the old msg id
        const localMessageIndex = newMessageIds.findIndex(
          (messageId) => messageId === localId
        );
        newMessageIds.splice(localMessageIndex, 1);
      }

      const timestamp = new Date();
      // insert message to the right position
      const insertIndex = sortedLastIndexBy(
        newMessageIds,
        localId,
        (messageId) =>
          messageId === localId
            ? timestamp
            : state.messages[messageId].timestamp
      );
      newMessageIds.splice(insertIndex, 0, localId);

      return {
        ...state,
        messages: {
          ...state.messages,
          [localId]: {
            id: localId,
            type: "message",
            state: MessageState.PENDING,
            revision: -1,
            timestamp,
            localId,
            content,
            searchIndex,
            previews: {},
            ...(files !== undefined
              ? {
                  files: files.map((file) => ({
                    id: file.id,
                    name: file.name,
                    icon: lookupIcon(file.name),
                  })),
                }
              : {}),
          },
        },
        // Only append message id when this is not a retry message
        messageIds: newMessageIds,
        hashtagIndex: newHashtagIndex,
      };
    }
  )
  .case(inputActions.sendMessage.done, (state, { params, result }) => {
    if (params.localId !== result.localId) {
      throw new Error(
        `Local ID ${params.localId} is different from the one ${result.localId} returned from server`
      );
    }
    return {
      ...state,
      ...updateMessagesWithEvents(
        state.messages,
        state.messageIds,
        [
          {
            id: "",
            sequenceId: 0,
            revision: result.revision,
            localId: result.localId,
            type: MessageEventType.ADD_MESSAGE,
            messageId: result.id,
            content: result.content,
            timestamp: result.timestamp,
            previews: result.previews,
            ...(result.files !== undefined
              ? {
                  files: result.files.map((file) => ({
                    id: file.id,
                    name: file.name,
                  })),
                }
              : {}),
          } as MessageEvent,
        ],
        state.hashtagIndex,
        state.skipMessageAnimation
      ),
    };
  })
  .case(inputActions.sendMessage.failed, (state, { params, error }) => {
    return {
      ...state,
      messages: {
        ...state.messages,
        [params.localId]: {
          ...state.messages[params.localId],
          state: MessageState.FAILED,
        },
      },
    };
  })
  .case(actions.updateMessage.started, (state, { id, content }) => ({
    ...state,
    messages: {
      ...state.messages,
      [id]: {
        ...state.messages[id],
        updating: true,
        contentOverride: content,
      },
    },
  }))
  .case(actions.updateMessage.done, (state, { params, result }) => {
    return {
      ...state,
      ...updateMessagesWithEvents(
        state.messages,
        state.messageIds,
        [
          {
            id: "",
            sequenceId: 0,
            revision: result.revision,
            localId: result.localId,
            type: MessageEventType.UPDATE_MESSAGE,
            messageId: result.id,
            content: result.content,
            timestamp: result.timestamp,
            previews: result.previews,
            ...(result.files !== undefined
              ? {
                  files: result.files.map((file) => ({
                    id: file.id,
                    name: file.name,
                  })),
                }
              : {}),
          } as MessageEvent,
        ],
        state.hashtagIndex,
        state.skipMessageAnimation
      ),
      editingValue: null,
    };
  })
  .case(actions.updateMessage.failed, (state, { params, error }) => {
    const { updating, contentOverride, ...message } = state.messages[
      params.id
    ] as Message;
    return {
      ...state,
      messages: {
        ...state.messages,
        [params.id]: message,
      },
    };
  })
  .case(actions.onDeleteFailedMessage, (state, localId) => ({
    ...state,
    messages: {
      ...state.messages,
      [localId]: {
        ...state.messages[localId],
        deletingState: MessageDeletingState.DELETED,
      },
    },
  }))
  .case(actions.deleteConfirmedMessage.started, (state, id) => ({
    ...state,
    messages: {
      ...state.messages,
      [id]: {
        ...state.messages[id],
        deletingState: MessageDeletingState.DELETING,
      },
    },
    focusMessageId: state.focusMessageId === id ? null : id,
  }))
  .case(actions.deleteConfirmedMessage.done, (state, { params }) => {
    return {
      ...state,
      ...updateMessagesWithEvents(
        state.messages,
        state.messageIds,
        [
          {
            id: "",
            sequenceId: 0,
            revision: 0,
            localId: "",
            type: MessageEventType.DELETE_MESSAGE,
            messageId: params,
            content: "",
            timestamp: new Date(),
          } as MessageEvent,
        ],
        state.hashtagIndex,
        state.skipMessageAnimation
      ),
    };
  })
  .case(actions.deleteConfirmedMessage.failed, (state, { params }) => {
    // Remove the deleting state from message
    const { deletingState, ...message } = state.messages[params] as Message;
    return {
      ...state,
      messages: {
        ...state.messages,
        [params]: message,
      },
    };
  })
  .case(actions.onMessageDeletionAnimationFinish, (state, id) => {
    // Remove the pending message from messages
    const { [id]: pendingMessage, ...messages } = state.messages;
    const messageIds = state.messageIds.filter((messageId) => messageId !== id);
    const newHashtagIndex = {
      hashtagToMessageIds: { ...state.hashtagIndex.hashtagToMessageIds },
    };
    // Remove hashtag index for old pending msg
    const { hashtags } = extractSearchIndex(pendingMessage.content ?? "");
    for (const hashtagName of hashtags) {
      delete newHashtagIndex.hashtagToMessageIds[hashtagName][id];
      if (isEmpty(newHashtagIndex.hashtagToMessageIds[hashtagName])) {
        delete newHashtagIndex.hashtagToMessageIds[hashtagName];
      }
    }
    return {
      ...state,
      messages,
      messageIds,
      hashtagIndex: newHashtagIndex,
    };
  })
  .case(actions.selectMessage, (state, id) => {
    return {
      ...state,
      focusMessageId: state.focusMessageId === id ? null : id,
    };
  })
  .case(actions.scrollToLatestMessage, (state, payload) => ({
    ...state,
    scrollCounter: state.scrollCounter + 1,
    scrollTarget: undefined,
  }))
  .case(actions.scrollToMessage, (state, payload) => ({
    ...state,
    scrollCounter: state.scrollCounter + 1,
    scrollTarget: payload,
  }))
  .case(actions.onViewingLatestChanged, (state, payload) => ({
    ...state,
    viewingLatest: payload,
  }))
  .case(actions.updateDisplayScrollToLatestMessageButton, (state, payload) => ({
    ...state,
    displayScrollToLatestButton: payload,
  }))
  .case(actions.addSystemMessage, (state, { id, content, time, buttons }) => {
    const newMessageIds = [...state.messageIds];
    // insert message to the right position
    const insertIndex = sortedLastIndexBy(newMessageIds, id, (messageId) =>
      messageId === id ? time : state.messages[messageId].timestamp
    );
    newMessageIds.splice(insertIndex, 0, id);

    return {
      ...state,
      messages: {
        ...state.messages,
        [id]: {
          id,
          content,
          buttons,
          type: "system",
          timestamp: time,
        } as SystemMessage,
      },
      messageIds: newMessageIds,
    };
  })
  .case(actions.dismissSystemMessage, (state, id) => ({
    ...state,
    messages: {
      ...state.messages,
      [id]: {
        ...state.messages[id],
        dismissing: true,
      } as SystemMessage,
    },
  }))
  .case(actions.onSystemMessageDismissAnimationFinish, (state, id) => {
    // Remove the system message from messages
    const { [id]: dismissedMessage, ...messages } = state.messages;
    const messageIds = state.messageIds.filter((messageId) => messageId !== id);
    return {
      ...state,
      messages,
      messageIds,
    };
  })
  .case(inputActions.leaveCommandMode, (state, value) => {
    return {
      ...state,
      searchKeywords: null,
    };
  })
  .case(inputActions.sendLocalCommand, (state, value) => {
    if (value.id !== BuildInCommandType.SEARCH) {
      return state;
    }
    return {
      ...state,
      searchKeywords: value.content!,
    };
  })
  .case(actions.onEditMessageTapped, (state, value) => ({
    ...state,
    messages: {
      ...state.messages,
      [state.focusMessageId!]: {
        ...state.messages[state.focusMessageId!],
        editing: true,
      },
    },
    editingValue: (state.messages[state.focusMessageId!] as Message).content,
  }))
  .case(actions.deletePreview.started, (state, { messageId, previewId }) => ({
    ...state,
    messages: {
      ...state.messages,
      [messageId]: {
        ...state.messages[messageId],
        previews: {
          ...(state.messages[messageId] as Message).previews,
          [previewId]: {
            ...(state.messages[messageId] as Message).previews[previewId],
            deleting: true,
          },
        },
      },
    },
  }))
  .case(actions.deletePreview.failed, (state, failure) => ({
    ...state,
    messages: {
      ...state.messages,
      [failure.params.messageId]: {
        ...state.messages[failure.params.messageId],
        previews: {
          ...(state.messages[failure.params.messageId] as Message).previews,
          [failure.params.previewId]: {
            ...(state.messages[failure.params.messageId] as Message).previews[
              failure.params.previewId
            ],
            deleting: false,
          },
        },
      },
    },
  }))
  .case(actions.onEditValueUpdated, (state, value) => ({
    ...state,
    editingValue: value,
  }))
  .case(actions.cancelEditMessage, (state, value) => {
    const { editing, ...message } = state.messages[
      state.focusMessageId!
    ] as Message;
    return {
      ...state,
      messages: {
        ...state.messages,
        [state.focusMessageId!]: message,
      },
      editingValue: null,
    };
  })
  .case(eventActions.allEventsLoaded, (state, eventStreamId) => {
    return {
      ...state,
      ...(eventStreamId === state.eventStreamId
        ? {
            readyStatus: true,
          }
        : {}),
    };
  });

export default messages;
