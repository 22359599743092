import moment from "moment";
import React, { FunctionComponent, useEffect, useState } from "react";
import { StyleProp, StyleSheet, Text, View, ViewStyle } from "react-native";
import { pure } from "recompose";
import { millisecondToNextDay } from "../shared/utils";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "white",
  },
  line: {
    flex: 1,
    height: 1,
    backgroundColor: "#bbb",
  },
  text: {
    fontSize: 20,
    marginTop: 10,
    marginBottom: 10,
    textAlign: "center",
    paddingHorizontal: 10,
  },
});

export interface Props {
  readonly date: Date;
  readonly today?: Date;
  readonly autoRotate?: boolean;
  readonly style?: StyleProp<ViewStyle>;
}

const DateSeparator: FunctionComponent<Props> = ({
  today,
  date,
  autoRotate,
  style,
}: Props) => {
  const now = today !== undefined ? moment(today) : moment();
  const nowDate = now.toDate();
  const isToday =
    date.getFullYear() === nowDate.getFullYear() &&
    date.getMonth() === nowDate.getMonth() &&
    date.getDate() === nowDate.getDate();

  const [rotated, setRotated] = useState(false);
  const autoRotateValue = autoRotate ?? false;
  const text =
    isToday && !rotated ? "Today" : moment(date).format("dddd, MMMM D, YYYY");

  useEffect(() => {
    if (autoRotate && isToday) {
      const msUntillTomorrow = millisecondToNextDay(now) + 1000;
      const timeoutId = setTimeout(() => {
        setRotated(true);
      }, msUntillTomorrow);
      console.debug("Schedule to rotate today after", msUntillTomorrow, "ms");
      return () => {
        clearTimeout(timeoutId);
        console.debug("Cancel today rotation schedule");
      };
    }
  }, [autoRotateValue, isToday]);
  return (
    <View style={[styles.container, style]}>
      <View style={styles.line} />
      <Text style={styles.text}>{text}</Text>
      <View style={styles.line} />
    </View>
  );
};

export default pure(DateSeparator);
